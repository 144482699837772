import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/routes/arrival-route/index.ts";
window.define('mobile-web/components/routes/arrival-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "DbMVCS3j",
  "block": "[[[1,[28,[35,0],[\"Arrival Confirmed\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@order\",\"@utmMedium\"],[[30,0,[\"model\",\"order\"]],[30,0,[\"model\",\"utmMedium\"]]]],null],[1,\"\\n\"]],[],false,[\"page-title\",\"routes/arrival-route\"]]",
  "moduleName": "mobile-web/templates/arrival.hbs",
  "isStrictMode": false
});