import { set } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { buildOloErrorHandler, FriendlyError } from 'mobile-web/lib/errors';
import { channelSlug } from 'mobile-web/lib/hybrid-util';
import { Status } from 'mobile-web/lib/utilities/http';
import isSome from 'mobile-web/lib/utilities/is-some';
import BootstrapDataModel from 'mobile-web/models/bootstrap-data';
import BasketService from 'mobile-web/services/basket';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import * as OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

// Pull objects from bootstrap data that are needed directly in the store
export function primeTheStore(store: DS.Store, data: BootstrapDataModel): void {
  const bootstrapChannel = data.channel;
  const timeWantedModes = data.timeWantedModes;
  const handoffModes = data.handoffModes;
  const customLinks = data.customLinks;
  const check = data.openCheck;

  store.pushPayload({
    timeWantedModes,
    handoffModes,
    channel: bootstrapChannel,
    customLinks,
    checks: check,
  });
}

export default class BootstrapService extends Service {
  // Service injections

  // Untracked properties
  @service('error') error!: ErrorService;
  @service store!: DS.Store;
  @service session!: SessionService;
  @service('basket') basketService!: BasketService;
  @service('features') features!: FeaturesService;
  @service('vendor') vendorService!: VendorService;
  @service orderCriteria!: OrderCriteriaService.default;
  @service storage!: StorageService;
  @service onPremise!: OnPremiseService;
  @service groupOrder!: GroupOrderService;

  // Tracked properties
  @tracked data?: BootstrapDataModel;
  @tracked initBootstrapFailed = false;

  // Getters and setters
  get isTranslated(): boolean {
    return this.data?.translationTest !== this.data?.decodedTranslation;
  }

  // Constructor

  // Other methods
  async initBootstrap(): Promise<{ bootstrapData: BootstrapDataModel }> {
    this.initBootstrapFailed = false;

    // if these bootstrap promises fail continue to app with no saved state.
    const logError = (errorOrMessage: string | Error) => {
      const err = errorOrMessage instanceof Error ? errorOrMessage : new Error(errorOrMessage);
      // @ts-ignore
      if (err && Array.isArray(err.errors) && err.errors.filter(x => x.status === '401').length) {
        return;
      }
      this.error.sendExternalError(
        err,
        {
          cause: err.message,
          stack: err.stack,
          cookiesEnabled: navigator.cookieEnabled,
        },
        ['application-route']
      );
    };

    await this.session.asyncInit();

    return this.store
      .findRecord('bootstrap-data', channelSlug())
      .then(async bootstrapData => {
        primeTheStore(this.store, bootstrapData);
        set(this, 'data', bootstrapData);

        await this.session.validateServeAppToken();

        // Ensure feature flags are set up first
        await this.features.setupFeatureFlags();

        const bootstrapUserId = bootstrapData.userId;
        const bootstrapBasketId = bootstrapData.basketGuid;
        const bootstrapBasketVendor = bootstrapData.basketVendorSlug;
        const maybeUser =
          bootstrapUserId && !this.session.isLoggedIn
            ? this.session.loadUser(bootstrapUserId)
            : RSVP.resolve(undefined);

        const maybeVendor = bootstrapBasketVendor
          ? this.vendorService.ensureVendorLoaded(bootstrapBasketVendor)
          : RSVP.resolve(undefined);

        return (
          RSVP.hash({
            user: maybeUser,
            vendor: maybeVendor,
            bootstrapData,
          })
            .then(() => {
              const currentlyLoadedBasket = this.basketService.basket;

              if (isSome(currentlyLoadedBasket)) {
                return RSVP.resolve(currentlyLoadedBasket);
              }

              if (isSome(bootstrapBasketId)) {
                return this.basketService.loadBasket(bootstrapBasketId);
              }

              return RSVP.resolve(undefined);
            })
            // this must be in sync with components/routes/group-order/join-route/index.ts confirm()
            .then(() => this.groupOrder.loadGroupOrder())
            .then(() => {
              this.onPremise.onBootstrapInit();
            })
        );
      })
      .catch(
        buildOloErrorHandler('app-load-failure', reason => {
          if (isSome(reason) && !isEmpty(reason.errors)) {
            const status = parseInt(reason.errors[0].status, 10);
            if (status === Status.Forbidden && !navigator.cookieEnabled) {
              return RSVP.reject(
                new FriendlyError(
                  'Please enable cookies',
                  'It appears that you have cookies disabled. In order to use this site, please enable cookies and refresh the page.'
                )
              );
            }
          }
          logError(reason);
          this.initBootstrapFailed = true;
          return RSVP.reject(reason);
        })
      );
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    bootstrap: BootstrapService;
  }
}
