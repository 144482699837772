import a0 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "mgfBmcrg",
  "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"link\"]]],[24,\"data-test-card-link\",\"\"],[17,2]],[[\"@route\",\"@model\"],[[30,3],[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-card-link\",\"label\"],[12],[1,[30,4]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"navIcon\"]],\"chevron-right\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"link\"]]],[24,\"data-test-card-link\",\"\"],[17,2]],[[\"@route\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-card-link\",\"label\"],[12],[1,[30,4]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"navIcon\"]],\"chevron-right\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"@model\",\"&attrs\",\"@route\",\"@label\"],false,[\"if\",\"link-to\",\"svg\"]]",
  "moduleName": "mobile-web/components/card/link/index.hbs",
  "isStrictMode": false
});