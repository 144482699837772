import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { CustomField } from 'mobile-web/lib/custom-field';
import { sumBy } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';
import { splitNicely, roundUpToNext, roundDownToPrevious } from 'mobile-web/lib/utilities/math';
import BasketChoiceModel from 'mobile-web/models/basket-choice';
import BasketProductModel from 'mobile-web/models/basket-product';
import Choice from 'mobile-web/models/choice';
import Product from 'mobile-web/models/product';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';

export enum QuantityChoiceViewTypes {
  'Slider' = 'Slider',
  'Input' = 'Input',
}

export type DisplayGroup = {
  name: string;
  choices: Choice[];
};

export default class OptionGroupModel extends DS.Model {
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service intl!: IntlService;

  @DS.attr('string')
  description!: string;
  @DS.attr('string')
  explanationText!: string;
  @DS.attr('string')
  formationGroup!: string;

  @DS.attr('boolean')
  hasChoiceQuantities!: boolean;
  @DS.attr('boolean')
  treatAsProductQuantity!: boolean;
  @DS.attr('number')
  minChoiceQuantity!: number;
  @DS.attr('number')
  maxChoiceQuantity!: number;
  @DS.attr('number')
  choiceQuantityIncrement!: number;
  @DS.attr('string', { defaultValue: QuantityChoiceViewTypes.Slider })
  quantityChoiceView!: string;

  @DS.attr('boolean')
  hideChoicePrices!: boolean;
  @DS.attr('boolean')
  isHidden!: boolean;
  @DS.attr('boolean')
  hasNestedGroups!: boolean;
  @DS.attr('boolean')
  hasCustomFields!: boolean;
  @DS.attr('boolean')
  hasCustomFieldsWithNestedGroups!: boolean;
  @DS.attr('boolean', { defaultValue: true })
  isAvailable!: boolean;

  @DS.attr('boolean')
  private forceSingleSelect!: boolean;
  @DS.attr('number')
  private configuredMinSelect?: number;
  @DS.attr('number')
  private configuredMaxSelect?: number;
  @DS.attr('number')
  private configuredMinAggregateQuantity!: number;
  @DS.attr('number')
  private configuredMaxAggregateQuantity!: number;

  // choices always come sideloaded with the option group API call, OptionGroupsController.Get
  @DS.hasMany('choice', { async: false })
  choices!: DS.ManyArray<Choice>;
  // the only way we get option group ids to load is from a product model, so it must already be loaded
  @DS.belongsTo('product', { async: false })
  product!: Product;

  @DS.belongsTo('choice', { async: false })
  parentChoice?: Choice;

  get minSelects(): number {
    if (this.forceSingleSelect) {
      return 1;
    }
    let minSelect = this.configuredMinSelect ?? 0;
    if (minSelect === 0 && this.minAggregateQuantity > 0) {
      minSelect = 1;
    }
    return minSelect;
  }

  /** maxSelects of 0 or undefined means unlimited */
  get maxSelects(): number | undefined {
    return this.forceSingleSelect ? 1 : this.configuredMaxSelect;
  }

  get isRequired(): boolean {
    return this.minSelects > 0;
  }

  get isDisabledFormationGroup(): boolean {
    return !isEmpty(this.formationGroup) && (this.choices.objectAt(0)?.isDisabled ?? false);
  }

  get multiSelect(): boolean {
    return this.maxSelects !== 1;
  }

  get minAggregateQuantity(): number {
    if (this.treatAsProductQuantity) {
      const primaryGroup = this.product.primaryProductQuantityOptionGroup;
      if (primaryGroup && primaryGroup !== this) {
        return primaryGroup.aggregateQuantity;
      }
      return roundUpToNext(this.choiceQuantityIncrement, this.product.minimumQuantity);
    }
    return this.configuredMinAggregateQuantity;
  }

  get maxAggregateQuantity(): number {
    if (this.treatAsProductQuantity) {
      const primaryGroup = this.product.primaryProductQuantityOptionGroup;
      if (primaryGroup && primaryGroup !== this) {
        return primaryGroup.aggregateQuantity;
      }
      return roundDownToPrevious(this.choiceQuantityIncrement, this.product.maximumQuantity || 100);
    }
    return this.configuredMaxAggregateQuantity;
  }

  get displayGroups(): DisplayGroup[] {
    return this.choices
      .filter(c => c.displayGroup)
      .reduce((groups, c) => {
        // For some odd reason, in display groups, `optionName` is the overall name
        // of the group, and `groupName` is the name of any particular option within
        // the group. We didn't make the rules, we just play by them.
        const existingGroup = groups.find(g => g.name === c.displayGroup.optionName);
        if (existingGroup) {
          existingGroup.choices.push(c);
        } else {
          groups.push({ name: c.displayGroup.optionName, choices: [c] });
        }
        return groups;
      }, [] as DisplayGroup[]);
  }

  get helpText(): string {
    const max = this.maxSelects ?? 0;
    const min = this.minSelects;

    let selectedHelpText = '';
    if (min === 0 && max > 1) {
      selectedHelpText = this.intl.t('mwc.productCustomization.helpText.maxOnlyLabel', { max });
    } else if (min > 0 && max === 0) {
      selectedHelpText = this.intl.t('mwc.productCustomization.helpText.minOnlyLabel', { min });
    } else if (min === max && max > 1) {
      selectedHelpText = this.intl.t('mwc.productCustomization.helpText.bothEqualLabel', {
        max,
        min,
      });
    } else if (min !== max && max > 1) {
      selectedHelpText = this.intl.t('mwc.productCustomization.helpText.bothNotEqualLabel', {
        max,
        min,
      });
    }

    if (this.selectedBasketChoices.length < min) {
      return selectedHelpText;
    }

    if (
      this.hasChoiceQuantities &&
      this.minAggregateQuantity > 0 &&
      this.minAggregateQuantity !== min
    ) {
      if (this.minAggregateQuantity === this.maxAggregateQuantity) {
        return this.intl.t('mwc.productCustomization.helpText.aggregateLabel', {
          aggregate: this.minAggregateQuantity,
        });
      }
      return this.intl.t('mwc.productCustomization.helpText.minAggregateLabel', {
        min: this.minAggregateQuantity,
      });
    }
    return selectedHelpText;
  }

  // ****** Active basket properties, do not use outside product page ******

  get activeBasketProduct(): BasketProductModel | undefined {
    assert(
      'Do not reference `activeBasketProduct` when one has not been set',
      isSome(this.basket.activeBasketProduct)
    );
    return this.basket.activeBasketProduct;
  }

  get basketChoices(): BasketChoiceModel[] {
    if (!this.activeBasketProduct) {
      return [];
    }

    return this.activeBasketProduct.basketChoices.filter(
      bc => bc.choice.content?.optionGroup?.id === this.id
    );
  }

  @dependentKeyCompat
  get selectedBasketChoices(): BasketChoiceModel[] {
    return this.basketChoices.filter(bc => bc.isSelected);
  }

  get selectedOptionGroups(): OptionGroupModel[] {
    return this.selectedBasketChoices.reduce((optionGroups, selectedChoice) => {
      if (!selectedChoice.choice.content?.hasInlineChoices) {
        selectedChoice.optionGroups?.forEach(og => {
          optionGroups.push(og);
        });
      }
      return optionGroups;
    }, [] as OptionGroupModel[]);
  }

  @dependentKeyCompat
  get selectedCustomFields(): CustomField[] {
    return this.selectedBasketChoices.reduce((customFields, selectedChoice) => {
      customFields.push(...selectedChoice.customFieldValues);
      return customFields;
    }, [] as CustomField[]);
  }

  @dependentKeyCompat
  get aggregateQuantity(): number {
    return sumBy(this.selectedBasketChoices, c => c.quantity);
  }

  get maxAggregateReached(): boolean {
    return this.aggregateQuantity >= this.maxAggregateQuantity;
  }

  get canSelectNewChoices(): boolean {
    if (!this.multiSelect) {
      return true;
    }

    const maxSelectsReached =
      this.maxSelects && this.selectedBasketChoices.length >= this.maxSelects;

    const selectedMin = Math.max(this.minChoiceQuantity, this.choiceQuantityIncrement);
    const maxAggregateReached =
      this.hasChoiceQuantities &&
      (this.channel.settings?.defaultChoicesToMax
        ? selectedMin * (this.selectedBasketChoices.length + 1) > this.maxAggregateQuantity
        : this.aggregateQuantity + selectedMin > this.maxAggregateQuantity);

    return !maxSelectsReached && !maxAggregateReached;
  }

  get selectedChoiceCost(): number {
    let price = 0;
    this.selectedBasketChoices.forEach(bc => {
      const choicePrice = bc.choice.get('priceDifference') ?? 0;
      price += choicePrice * bc.quantity;
      bc.optionGroups?.forEach(og => {
        price += og.selectedChoiceCost;
      });
    });

    return price;
  }

  @computed(
    'aggregateQuantity',
    'hasChoiceQuantities',
    'maxAggregateQuantity',
    'maxSelects',
    'minAggregateQuantity',
    'minSelects',
    'selectedBasketChoices.[]',
    'selectedCustomFields.@each.value'
  )
  get isCompleted(): boolean {
    if (
      this.hasChoiceQuantities &&
      (this.aggregateQuantity < this.minAggregateQuantity ||
        this.aggregateQuantity > this.maxAggregateQuantity)
    ) {
      return false;
    }

    if (
      this.selectedBasketChoices.length < this.minSelects ||
      (this.maxSelects && this.selectedBasketChoices.length > this.maxSelects)
    ) {
      return false;
    }

    return this.selectedCustomFields.every(cf => !cf.isRequired || cf.value);
  }

  get canSubmit(): boolean {
    return (
      this.isCompleted &&
      this.selectedBasketChoices.every(bc =>
        bc.choice.content?.optionGroups.every(og => og.canSubmit)
      )
    );
  }

  @dependentKeyCompat
  get completedOptionsLabel(): string {
    if (isSome(this.parentChoice)) {
      const selectedChoices = this.selectedBasketChoices;
      if (!isEmpty(selectedChoices)) {
        const choicesDescriptions = selectedChoices.map(c => c.choiceLabel);
        return `${this.description}: ${choicesDescriptions.join(', ')}`;
      }
    }
    return '';
  }

  quantityChanged(): void {
    this.activeBasketProduct?.setQuantityBasedOnChoiceQuantities();
    if (this.product.primaryProductQuantityOptionGroup === this) {
      this.product.firstLevelOptionGroupModels.forEach(og => {
        if (og.treatAsProductQuantity && og !== this) {
          og.selectedBasketChoices.forEach(bc => {
            bc.quantity = 0;
          });
        }
      });
    }
  }

  splitChoiceQuantities(): void {
    if (this.channel.settings?.defaultChoicesToMax && this.maxAggregateQuantity) {
      const splitVals = splitNicely(this.maxAggregateQuantity, {
        into: this.selectedBasketChoices.length,
        by: this.choiceQuantityIncrement,
      });
      splitVals?.forEach((quantity, index) => {
        this.selectedBasketChoices[index]!.quantity = Math.min(quantity, this.maxChoiceQuantity);
      });
    }
  }
}
