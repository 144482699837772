import a0 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a0;
});
import a1 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a1;
});
import a2 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "zdCZKmD8",
  "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,\"section\"],[16,\"aria-labelledby\",[30,0,[\"ids\",\"body\"]]],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-oneTimeNotification\",\"\"],[17,1],[4,[38,1],[[30,0,[\"shown\"]]],null],[12],[1,\"\\n    \"],[10,0],[15,1,[30,0,[\"ids\",\"body\"]]],[15,0,[30,0,[\"style\",\"body\"]]],[14,\"data-test-oneTimeNotification-body\",\"\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,3],null,[[\"close\"],[[30,0,[\"close\"]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"closeButton\"]]],[24,\"data-test-oneTimeNotification-closeButton\",\"\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"close\"]]],null],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[[28,[37,6],[\"mwc.oneTimeNotification.closeButtonLabel\"],null],[30,0,[\"style\",\"icon\"]],\"x\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"&default\"],false,[\"if\",\"did-insert\",\"yield\",\"hash\",\"on\",\"svg\",\"t\"]]",
  "moduleName": "mobile-web/components/one-time-notification/index.hbs",
  "isStrictMode": false
});