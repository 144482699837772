import a0 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "OSdvgRm5",
  "block": "[[[11,0],[16,0,[28,[37,0],[\"ember-content-placeholders-img\",[30,0,[\"rootClass\"]]],null]],[17,1],[12],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"classes\"]]",
  "moduleName": "mobile-web/components/content-placeholders/img/index.hbs",
  "isStrictMode": false
});