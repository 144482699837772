export default {
  "root": "_root_6lr4xh",
  "isSlider": "_isSlider_6lr4xh",
  "inputContainer": "_inputContainer_6lr4xh",
  "inputWrapper": "_inputWrapper_6lr4xh",
  "label": "_label_6lr4xh",
  "button": "_button_6lr4xh",
  "isStepper": "_isStepper_6lr4xh",
  "leftButton": "_leftButton_6lr4xh _button_6lr4xh",
  "rightButton": "_rightButton_6lr4xh _button_6lr4xh",
  "icon": "_icon_6lr4xh",
  "input": "_input_6lr4xh",
  "thumb": "_thumb_6lr4xh",
  "thumbLarge": "_thumbLarge_6lr4xh"
};
