import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  value: boolean;
  onChange: (newValue: boolean) => void;

  // Optional arguments
}

export default class Toggle extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
