import a0 from "../card/grid/index.ts";
window.define('mobile-web/components/card/grid', function () {
  return a0;
});
import a1 from "../product-card/index.ts";
window.define('mobile-web/components/product-card', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "8EBODF6I",
  "block": "[[[41,[30,0,[\"hasProducts\"]],[[[11,0],[24,\"data-test-singleUseCategory\",\"\"],[17,1],[16,0,[30,0,[\"style\",\"category\"]]],[12],[1,\"\\n  \"],[10,\"h2\"],[14,\"data-test-singleUseCategory-heading\",\"\"],[15,0,[30,0,[\"style\",\"heading\"]]],[12],[1,\"\\n    \"],[1,[30,2,[\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"grid\"]]],[24,\"data-test-singleUseCategory-grid\",\"\"]],[[\"@models\"],[[30,2,[\"products\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,\"data-test-singleUseCategory-product\",\"\"],[16,0,[30,0,[\"style\",\"card\"]]]],[[\"@headingLevel\",\"@location\",\"@product\"],[3,\"single-use\",[30,3]]],null],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@category\",\"product\"],false,[\"if\",\"card/grid\",\"product-card\"]]",
  "moduleName": "mobile-web/components/single-use-category/index.hbs",
  "isStrictMode": false
});