import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { isSome } from 'mobile-web/lib/utilities/is-some';

import style from './index.m.scss';

interface Args {
  // Required arguments
  icon: string;

  // Optional arguments
  ariaLabel?: string;
  class?: string;
  iconClass?: string;
  halo?: boolean;
}

interface Signature {
  Element: HTMLSpanElement;
  Args: Args;
}

export default class Svg extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get ariaLabel(): string | undefined {
    if (isSome(this.args.ariaLabel)) {
      // drop the `aria-label` attribute when it's empty because `[role]` will
      // be set to `presentation` and the label is unnecessary.
      return this.args.ariaLabel || undefined;
    }

    const keyName = `mwc.iconAriaLabel.${camelize(this.iconName)}`;
    return this.intl.exists(keyName) ? this.intl.t(keyName) : undefined;
  }

  get containerClass(): string {
    return [this.style.container, this.args.class ?? ''].join(' ');
  }

  get iconClass(): string {
    return [this.style.icon, this.args.iconClass ?? ''].join(' ');
  }

  get iconName(): string {
    return [this.args.icon, this.args.halo ? '-halo' : ''].join('');
  }

  get role(): string {
    return this.args.ariaLabel === '' ? 'presentation' : 'img';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    svg: typeof Svg;
    Svg: typeof Svg;
  }
}
