import EmberObject from '@ember/object';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import DS from 'ember-data';

import { ProductCustomizationType } from 'mobile-web/components/product-customization';
import ENV from 'mobile-web/config/environment';
import { GlobalBasket } from 'mobile-web/models/basket';
import { GlobalBasketProduct } from 'mobile-web/models/basket-product';
import { GlobalOrder } from 'mobile-web/models/order';
import { StoredOrderSubmission } from 'mobile-web/models/order-submission';
import { GlobalProduct } from 'mobile-web/models/product';
import { GlobalUser } from 'mobile-web/models/user';
import ErrorService from 'mobile-web/services/error';
import GlobalDataService, { ProductClickFrom } from 'mobile-web/services/global-data';

export enum GlobalEventName {
  AddToCart = 'v1.addToCart',
  RemoveFromCart = 'v1.removeFromCart',
  Checkout = 'v1.checkout',
  Transaction = 'v1.transaction',
  ClickProductLink = 'v1.clickProductLink',
  ViewProductDetail = 'v1.viewProductDetail',
  ProductsVisible = 'v1.productsVisible',
  AppFirstOpen = 'v1.appFirstOpen',
  LocationAccessAllowed = 'v1.locationAccessAllowed',
  LocationAccessDenied = 'v1.locationAccessDenied',
  UserLogin = 'v1.userLogin',
  CreateBasket = 'v1.createBasket',
}
const GlobalEventNames = Object.values(GlobalEventName);

export type GlobalEventCallback = (...args: unknown[]) => void;
export type GlobalEventOff = (name: GlobalEventName, cb: GlobalEventCallback) => void;
export interface GlobalEventOnOptions {
  replay: boolean;
}
export type GlobalEventOn = (
  name: GlobalEventName,
  cb: GlobalEventCallback,
  opts?: GlobalEventOnOptions
) => void;
export interface GlobalEventPayloads {
  [GlobalEventName.AddToCart]: [GlobalBasketProduct];
  [GlobalEventName.RemoveFromCart]: [GlobalBasketProduct];
  [GlobalEventName.Checkout]: [GlobalBasket, (..._: unknown[]) => void];
  [GlobalEventName.Transaction]: [GlobalOrder, StoredOrderSubmission, GlobalUser];
  [GlobalEventName.ClickProductLink]: [GlobalProduct, ProductClickFrom];
  [GlobalEventName.ViewProductDetail]: [GlobalProduct, ProductCustomizationType];
  [GlobalEventName.ProductsVisible]: [GlobalProduct[]];
  [GlobalEventName.AppFirstOpen]: [];
  [GlobalEventName.LocationAccessAllowed]: [];
  [GlobalEventName.LocationAccessDenied]: [];
  [GlobalEventName.UserLogin]: [GlobalUser];
  [GlobalEventName.CreateBasket]: [GlobalBasket];
}

const Events = EmberObject.extend(Evented);

export default class GlobalEventsService extends Service {
  // Service injections
  @service error!: ErrorService;
  @service globalData!: GlobalDataService;
  @service store!: DS.Store;

  // Untracked properties
  /**
   * Instance of an event bus.
   */
  private events: Evented;

  /**
   * Event callbacks have to be bound properly so they aren't called with `this` and accidentally
   * expose application internals. In order to call `off` the function has to be _exactly_ the same,
   * so we need to track the original fn against the new fn.
   */
  private callbacks = new WeakMap<GlobalEventCallback>();

  /**
   * Previously triggered events.
   * When `on` is called with a `replay` of `true` (the default behavior), all previous
   * triggers of that event will be re-run immediately.
   */
  private triggerHistory = new Map<GlobalEventName, unknown[]>();

  private initialized = false;

  // Tracked properties

  // Getters and setters
  /**
   * Set the `Olo.on` method globally.
   */
  private get globalOn() {
    return window.Olo.on;
  }
  private set globalOn(on: GlobalEventOn) {
    window.Olo = window.Olo || {};
    window.Olo.on = on;
  }

  /**
   * Set the `Olo.off` method globally.
   */
  private get globalOff() {
    return window.Olo.off;
  }
  private set globalOff(off: GlobalEventOff) {
    window.Olo = window.Olo || {};
    window.Olo.off = off;
  }

  // Constructor
  /**
   * Create an instance of the events mixin.
   * "Favor object composition over class inheritance." - GoF
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(args: object | undefined) {
    super(args);
    this.events = Events.create();
  }

  // Other methods
  /**
   * Add listeners globally.
   */
  setup(): void {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    this.globalOn = this.handleOn.bind(this);
    this.globalOff = this.handleOff.bind(this);
  }

  /**
   * Proxy events to `on`.
   * If `replay` is `true`, re-run all previous triggers of that event.
   * Private because no other parts of the system should listen to events this way.
   */
  private on(
    name: GlobalEventName,
    method: GlobalEventCallback,
    { replay }: GlobalEventOnOptions = { replay: true }
  ) {
    this.events.on(name, method);
    if (replay) {
      (this.triggerHistory.get(name) || []).forEach((args: unknown[]) => method(...args));
    }
    return this;
  }

  /**
   * Proxy events to `off`.
   * Private because no other parts of the system should listen to events this way.
   */
  private off(name: GlobalEventName, method: GlobalEventCallback) {
    this.events.off(name, method);
    return this;
  }

  /**
   * Has a listener of a certain name been set?
   * This is used for things like deferring the transition to checkout if there is a checkout listener in place
   * so that analytics have enough time to run.
   */
  has(name: GlobalEventName): boolean {
    return this.events.has(name);
  }

  /**
   * Track events emitted via trigger so that we can replay the to new subscribers.
   * This is required because otherwise events emitted when the page is first loading may fire
   * before any third-party scripts that listen for them are initialized.
   */
  trigger<T extends GlobalEventName>(name: T, ...args: GlobalEventPayloads[T]): void {
    if (ENV.environment !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Triggering Serve global event', name, ...args);
    }
    this.events.trigger(name, ...args);
    this.triggerHistory.set(name, (this.triggerHistory.get(name) || []).concat([args]));

    if (this.shouldUpdateBasket(name, ...args)) {
      this.globalData.updateBasket();
    }
  }

  /**
   * We used to rely on an array observer on a basket's basketProducts array.
   * Whenever the array contents changed, the observer called updateBasket.
   * Then array observers (not observers in general) got deprecated in 3.26.
   * This is our replacement. It relies on the fact that, when basket contents
   * change, we happen to call this.globalEvents.trigger with the event name.
   * That gives us a new way to detect basket content changes.
   * This is admittedly a little brittle, but the alternative was to add
   * this.globalData.updateBasket for these specific cases while relying on
   * observers everywhere else, which would not be developer-friendly.
   */
  private shouldUpdateBasket<T extends GlobalEventName>(
    name: T,
    ...args: GlobalEventPayloads[T]
  ): boolean {
    if ([GlobalEventName.AddToCart, GlobalEventName.RemoveFromCart].includes(name)) {
      return true;
    }

    const clickFrom = args[1] as ProductClickFrom;
    if (name === GlobalEventName.ClickProductLink && clickFrom === ProductClickFrom.CartUpsell) {
      return true;
    }

    return false;
  }

  private handleOn(name: GlobalEventName, cb: GlobalEventCallback, opts: GlobalEventOnOptions) {
    this.checkEventName(name);
    this.on(name, this.getSafelyBoundCallback(cb), opts);
  }

  private handleOff(name: GlobalEventName, cb: GlobalEventCallback) {
    this.checkEventName(name);
    this.off(name, this.getSafelyBoundCallback(cb));
  }

  private checkEventName(name: GlobalEventName) {
    if (!GlobalEventNames.includes(name)) {
      throw new Error(`The event ${name} is not supported by Olo.on.`);
    }
  }

  /**
   * Create a new callback function that will execute with a `this` that is `undefined`
   * to prevent the ability to accidentally access some part of the app scope.
   * Use `setTimeout` to wait a frame to run so that we aren't blocking any rendering
   * with the callback. Wrap the whole thing in a `try/catch` in case whatever is registered
   * blows up. If we've already made a callback from the given function, just return that
   * instead.
   */
  private getSafelyBoundCallback(cb: GlobalEventCallback): GlobalEventCallback {
    const boundCb =
      this.callbacks.get(cb) ||
      ((...args: unknown[]) => {
        setTimeout(() => {
          try {
            cb.apply(undefined, args); // eslint-disable-line prefer-spread
          } catch (e) {
            this.error.sendExternalError(e, { isGlobalEventCallback: true });
          }
        });
      });
    this.callbacks.set(cb, boundCb);
    return boundCb;
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    'global-events': GlobalEventsService;
  }
}
