// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { assert } from '@ember/debug';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { tagName } from '@ember-decorators/component';
import IntlService from 'ember-intl/services/intl';

import { getMapSymbol, MapIcons, MapMarker } from 'mobile-web/components/map-window';
import config from 'mobile-web/config/environment';
import dayjs from 'mobile-web/lib/dayjs';
import { HandoffMessageParts, handoffMessageParts } from 'mobile-web/lib/order';
import isSome from 'mobile-web/lib/utilities/is-some';
import DispatchStatusModel, { CourierStatus, State } from 'mobile-web/models/dispatch-status';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import ErrorService from 'mobile-web/services/error';
import MwcIntl from 'mobile-web/services/mwc-intl';

import style from './index.m.scss';

const CHECK_MAPS_ERROR_INTERVAL = 1000;
const REFRESH_INTERVAL = config.environment === 'development' ? 5000 : 60000;

const getEstimatedArrival = (estimatedDropoffTime: Date): number => {
  const now = dayjs();
  const dropoffEstimate = dayjs(estimatedDropoffTime);
  const time = dropoffEstimate.diff(now, 'm');

  if (time < 1) {
    return 1;
  }

  return Math.round(time);
};

const NO_MAP_STATUSES = [
  State.DeliveryError,
  State.OrderError,
  State.PickupError,
  State.DeliverySucceeded,
  State.ReturnInProgress,
  State.ReturnCompleted,
];

@tagName('')
export default class DispatchStatusRoute extends Component {
  // Service injections
  @service analytics!: AnalyticsService;
  @service channel!: ChannelService;
  @service store!: DS.Store;
  @service mwcIntl!: MwcIntl;
  @service intl!: IntlService;
  @service error!: ErrorService;

  // Required arguments
  order!: OrderModel;
  status!: DispatchStatusModel;

  // Optional arguments
  hash?: string;

  // Class fields
  style = style;

  @tracked showMap = false;
  @tracked showTracking = false;
  consecutiveMapUpdates = 0;

  // Computed properties
  @computed('mwcIntl.intl', 'order.timeReadyLocal', 'status.{estimatedDropoffTimeLocal,status}')
  get statusMessageParts(): HandoffMessageParts {
    const state = this.status.status;

    if (state === State.DeliverySucceeded) {
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.completedLabel'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.completedTime'),
      };
    } else if (state === State.PickupInProgress) {
      const endTime = dayjs(this.status.estimatedDropoffTimeLocal).format('h:mm A');
      const startTime = dayjs(this.status.estimatedDropoffTimeLocal)
        .subtract(10, 'm')
        .format('h:mm A');
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDelivery'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffRange', {
          endTime,
          startTime,
        }),
      };
    }  else if (state === State.ReturnInProgress) {
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returningLabel'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returningTime'),
      };
    } else if (state === State.ReturnCompleted) {
      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returnedLabel'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.returnedTime'),
      };
    } else if (state === State.PickupSucceeded) {
      const time = getEstimatedArrival(this.status.estimatedDropoffTimeLocal);

      if (time >= 60) {
        const endTime = dayjs(this.status.estimatedDropoffTimeLocal).format('h:mm A');
        const startTime = dayjs(this.status.estimatedDropoffTimeLocal)
          .subtract(10, 'm')
          .format('h:mm A');

        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDelivery'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffRange', {
            endTime,
            startTime,
          }),
        };
      }

      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedArrival'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffTime', {
          time,
        }),
      };
    }
    else if ( state === State.OrderReceived && !this.order.isAdvance ){
      const time = getEstimatedArrival(this.status.estimatedDropoffTimeLocal);

      if (time >= 60) {
        const endTime = dayjs(this.status.estimatedDropoffTimeLocal).format('h:mm A');
        const startTime = dayjs(this.status.estimatedDropoffTimeLocal)
          .subtract(10, 'm')
          .format('h:mm A');

        return {
          prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDelivery'),
          dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffRange', {
            endTime,
            startTime,
          }),
        };
      }

      return {
        prefix: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedArrival'),
        dateTime: this.mwcIntl.intl.t('mwc.dispatchTracker.estimate.estimatedDropoffTime', {
          time,
        }),
      };
    }

    const timeReady = dayjs(this.order.timeReadyLocal);
    const relativeDate = this.mwcIntl.relativeDate(timeReady, { titleCase: false });
    const dateTime = this.mwcIntl.intl.t('mwc.postCheckout.time', {
      date: relativeDate,
      time: timeReady,
    });

    return handoffMessageParts(this.order, dateTime);
  }

  @computed('status.{driverName,status}')
  get displayDriverInfo() {
    return isSome(this.status.driverName) && this.status.status === State.PickupSucceeded;
  }

  @computed('status.{deliveryService,status}')
  get isPickupInProgress() {
    return this.status.status === State.PickupInProgress && isSome(this.status.deliveryService);
  }

  @computed('order.{deliveryAddress,vendor}', 'status.{driverName,latitude,longitude,status}')
  get mapMarkers() {
    const vendor = this.order.vendor;
    const deliveryAddress = this.order.deliveryAddress;
    if (!isSome(deliveryAddress?.latitude)) {
      return [];
    }
    const addressMarker: MapMarker = {
      name: 'Delivery Address',
      lat: deliveryAddress!.latitude!,
      lng: deliveryAddress!.longitude!,
      icon: getMapSymbol(MapIcons.Person, '#2C7D3C'),
      halo: false,
    };
    const markers = [addressMarker];

    if (
      this.status.status === State.PickupSucceeded &&
      isSome(this.status.latitude) &&
      isSome(this.status.longitude)
    ) {
      markers.push({
        name: `${this.status.driverName}'s location`,
        lat: this.status.latitude,
        lng: this.status.longitude,
        icon: getMapSymbol(MapIcons.Car, '#006AD1'),
        halo: true,
      });
      addressMarker.halo = true;
    } else {
      markers.push({
        name: vendor.name,
        lat: vendor.latitude,
        lng: vendor.longitude,
        icon: getMapSymbol(MapIcons.Store, '#006AD1'),
      });
    }

    return markers;
  }

  @computed('intl', 'showMap', 'status.{latitude,longitude,status}')
  get updatesIconData() {
    let className, showIcon, iconLabel, ariaLabel;
    switch (this.status.status) {
      case State.OrderReceived:
        className = style.updatesActive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      case State.PickupInProgress:
        className = style.updatesActive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      case State.PickupSucceeded:
        if ((isSome(this.status.latitude) && isSome(this.status.longitude)) || !this.showMap) {
          className = style.updatesActive;
          iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        } else {
          className = style.updatesActiveNoLocation;
          iconLabel = this.intl.t('mwc.dispatchTracker.noDriverCoordinates');
        }
        showIcon = true;
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      case State.DeliverySucceeded:
        className = style.updatesInactive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.inactiveUpdatesAriaLabel');
        break;
      case State.ReturnInProgress:
        className = style.updatesActive;
        showIcon = true;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesAriaLabel');
        break;
      default:
        // All various error states fall into here
        className = style.updatesInactive;
        showIcon = false;
        iconLabel = this.intl.t('mwc.dispatchTracker.activeUpdatesIconLabel');
        ariaLabel = this.intl.t('mwc.dispatchTracker.inactiveUpdatesAriaLabel');
        break;
    }
    return {
      className,
      showIcon,
      iconLabel,
      ariaLabel,
    };
  }

  // Init
  init() {
    super.init();
    assert('`status` is required', isSome(this.status));
    assert('`order` is required', isSome(this.order));

    this.updateShowTracking();

    // If the initial status is a terminal status, the map is not needed
    // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
    this.showMap = !isEmpty(this.googleMapsApiKey) && !NO_MAP_STATUSES.includes(this.status.status);

    if (config.environment !== 'test') {
      later(this, this.refresh, REFRESH_INTERVAL);
      later(this, this.checkMapsError, CHECK_MAPS_ERROR_INTERVAL);
    }
  }

  checkMapsError() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    // We don't have an event to hook into if there is an API key error, so scrape the DOM for the element :(
    if (document?.querySelector('.gm-err-title')) {
      // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
      this.showMap = false;
      this.error.sendExternalError(
        new Error(
          'Google Maps referrer not allowed: ' +
          this.channel.current?.name +
          '. Add ' +
          document.domain +
          ' to Google Maps API Console '
        )
      );
    } else {
      later(this, this.checkMapsError, CHECK_MAPS_ERROR_INTERVAL);
    }
  }

  get googleMapsApiKey(): string {
    return this.channel.settings?.disableDispatchDriverMap
      ? ''
      : config.DEFAULT_GOOGLE_MAPS_API_KEY;
  }

  // Other methods
  updateShowTracking() {
    // This is not using a `computed` because using `order.dispatchStatuses.[]`
    // does not re-compute as a dependent key. Referencing `this.order.dispatchStatuses`
    // inside a computed to set this value will trigger a fetch of those statuses which
    // is not needed to update the UI state. Also that fetch requires `adapterOptions`.
    this.showTracking = this.order.hasMany('dispatchStatuses').ids().length > 1;
  }

  refresh() {
    const isNotDestroyed = !this.isDestroyed && !this.isDestroying;
    const dataOptions = {
      reload: true,
      adapterOptions: { orderId: this.order.id, hash: this.hash },
    };

    const updateOrder = () =>
      this.store
        .findRecord('order', this.order.id, dataOptions)
        .then(() => this.updateShowTracking());

    if (isNotDestroyed && this.status.courierStatus === CourierStatus.Active) {
      // this.status is a live Ember Data object, so when we do this
      // store.findRecord with reload: true, any updates will automagically
      // be reflected in this.status. We can't do this.status.reload() because
      // of the adapterOptions we need to pass.
      this.store.findRecord('dispatch-status', this.status.id, dataOptions).then(() => {
        if (!isEmpty(this.googleMapsApiKey)) {
          // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
          this.showMap = !NO_MAP_STATUSES.includes(this.status.status);
        }
        if (this.showMap) {
          this.analytics.trackEvent(AnalyticsEvents.LoadDispatchMapData, () => ({
            [AnalyticsProperties.ConsecutiveLoads]: ++this.consecutiveMapUpdates,
            [AnalyticsProperties.OrderId]: this.order.id,
            [AnalyticsProperties.OrderID]: this.order.id,
          }));
        }
      });

      // Refresh the order as well to show tracking CTA for additional statuses
      if (!this.showTracking) {
        updateOrder();
      }

      if (config.environment !== 'test') {
        later(this, this.refresh, REFRESH_INTERVAL);
      }
    } else if (
      isNotDestroyed &&
      !this.showTracking &&
      this.status.courierStatus !== CourierStatus.Completed
    ) {
      // The current status is no longer active
      // Continue to poll for additional statuses.
      updateOrder();

      if (config.environment !== 'test') {
        later(this, this.refresh, REFRESH_INTERVAL);
      }
    }
  }

  // Actions
}
