import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/head-layout.js";
window.define('mobile-web/components/head-layout', function () {
  return a1;
});
import a2 from "../components/application-frame/index.ts";
window.define('mobile-web/components/application-frame', function () {
  return a2;
});
import a3 from "../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "ORdHHlbs",
  "block": "[[[1,[28,[35,0],null,[[\"top\"],[true]]]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@onLogout\"],[[30,0,[\"logout\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"main\"],[15,0,[28,[37,3],[[30,0,[\"style\",\"main\"]],[30,0,[\"session\",\"className\"]]],null]],[12],[1,\"\\n    \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"page-title\",\"head-layout\",\"application-frame\",\"classes\",\"component\",\"-outlet\"]]",
  "moduleName": "mobile-web/templates/application.hbs",
  "isStrictMode": false
});