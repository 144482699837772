import a0 from "../../href/index.ts";
window.define('mobile-web/components/href', function () {
  return a0;
});
import a1 from "../../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "bhf7HeQj",
  "block": "[[[41,[30,0,[\"isDisabled\"]],[[[1,\"  \"],[11,1],[24,\"data-test-logo-link\",\"disabled\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isExternal\"]],[[[1,\"  \"],[8,[39,2],[[16,6,[30,0,[\"href\"]]],[24,\"data-test-logo-link\",\"external\"],[17,1]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],[[24,\"data-test-logo-link\",\"internal\"],[17,1]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"href\",\"olo-link-to\"]]",
  "moduleName": "mobile-web/components/logo/link/index.hbs",
  "isStrictMode": false
});