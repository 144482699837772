import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { MediaService } from 'ember-responsive';
import pick from 'lodash.pick';

import ProductModel, { MIMGroup, MIMImage } from 'mobile-web/models/product';
import VendorModel from 'mobile-web/models/vendor';
import OrderCriteriaService from 'mobile-web/services/order-criteria';

// eslint-disable-next-line no-use-before-define
export type GlobalCategory = Pick<CategoryModel, 'name' | 'description'>;

export default class CategoryModel extends DS.Model {
  @service media!: MediaService;
  @service orderCriteria!: OrderCriteriaService;
  @service store!: DS.Store;

  @DS.attr()
  name!: string;
  @DS.attr()
  description!: string;
  @DS.attr()
  isSingleUse!: boolean;
  @DS.attr('array', { defaultValue: () => [] })
  images!: MIMImage[];

  @DS.hasMany('product')
  products!: DS.PromiseManyArray<ProductModel>;

  @DS.belongsTo('vendor', { async: false })
  vendor!: VendorModel;

  get bannerImage(): string {
    const groupName = this.media.isTablet ? MIMGroup.ResponsiveLarge : MIMGroup.Customize;
    return this.images.find(i => i.groupName === groupName)?.filename ?? '';
  }

  get isAvailable(): boolean {
    return this.products.any(p => p.isAvailable);
  }

  get productIds(): EmberDataId[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.hasMany('products' as any).ids();
  }

  serializeForGlobalData(): GlobalCategory {
    return pick(this, 'name', 'description');
  }
}
