import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import IntlService from 'ember-intl/services/intl';

import ENV from 'mobile-web/config/environment';
import ApplicationController from 'mobile-web/controllers/application';
import { channelSlug } from 'mobile-web/lib/hybrid-util';
import { asPerimeterXResponse } from 'mobile-web/lib/perimeterx';
import { safeNext } from 'mobile-web/lib/runloop';
import clearRaygunStorage from 'mobile-web/lib/utilities/clear-raygun';
import AccessibilityService from 'mobile-web/services/accessibility';
import AnalyticsService from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChallengeService from 'mobile-web/services/challenge';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import FocusService from 'mobile-web/services/focus';
import GlobalDataService from 'mobile-web/services/global-data';
import GlobalEventsService from 'mobile-web/services/global-events';
import PerformanceMetricService from 'mobile-web/services/performance-metric';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

function getMwcRoot() {
  return Array.from(document.querySelectorAll('script'))
    .find(x => /\/vendor/i.test(x.src))
    ?.src.replace(/\/assets\/vendor.*/, '/');
}

function flexGapSupported(): boolean {
  // Create a flex container with row-gap set
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';
  flex.style.position = 'absolute';

  // Create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // Append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);

  // Flex container should be 1px high due to the row-gap
  const isSupported = flex.scrollHeight === 1;

  // Remove element from the DOM after you are done with it
  flex.parentNode?.removeChild(flex);

  return isSupported;
}

function optionalFeatureSetup() {
  if (flexGapSupported()) {
    document.documentElement.classList.add('flexbox-gap');
  } else {
    document.documentElement.classList.add('no-flexbox-gap');
  }
}

function focusRingSetup() {
  document.documentElement!.classList.add('no-focus-outline');
  document.body.addEventListener('keyup', e => {
    /* tab */
    if (e.which === 9) {
      document.documentElement!.classList.remove('no-focus-outline');
    }
  });
  document.body.addEventListener('mousedown', () => {
    document.documentElement!.classList.add('no-focus-outline');
  });
  document.body.addEventListener('touchend', () => {
    document.documentElement!.classList.add('no-focus-outline');
  });
}

export default class ApplicationRoute extends Route {
  @service accessibility!: AccessibilityService;
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bootstrap!: BootstrapService;
  @service challenge!: ChallengeService;
  @service('error') errorService!: ErrorService;
  @service features!: FeaturesService;
  @service focus!: FocusService;
  @service globalData!: GlobalDataService;
  @service globalEvents!: GlobalEventsService;
  @service intl!: IntlService;
  @service performanceMetric!: PerformanceMetricService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service storage!: StorageService;

  constructor() {
    super(...arguments);

    this.performanceMetric.pushTiming('applicationInit');
  }

  @action
  didTransition() {
    this.router.one('routeDidChange', async () => {
      scheduleOnce('afterRender', this, this._trackPage);
    });
  }

  // TODO: seems like this should pretty much all just be... `model()`.
  async beforeModel() {
    clearRaygunStorage();
    focusRingSetup();
    optionalFeatureSetup();
    if (typeof Intl === 'undefined') {
      // @ts-ignore
      await import('intl');
      // @ts-ignore
      await import('intl/locale-data/jsonp/en.js');
    }
    if (typeof Intl !== 'undefined' && typeof Intl.PluralRules === 'undefined') {
      // @ts-ignore
      if (typeof Intl.Locale === 'undefined') {
        await import('@formatjs/intl-locale/polyfill');
      }
      await import('@formatjs/intl-pluralrules/polyfill');
      // @ts-ignore
      await import('@formatjs/intl-pluralrules/locale-data/en');
    }
    // @ts-ignore
    if (typeof Intl !== 'undefined' && typeof Intl.getCanonicalLocales === 'undefined') {
      await import('@formatjs/intl-getcanonicallocales/polyfill');
    }
    if (typeof ResizeObserver === 'undefined') {
      const module = await import('@juggle/resize-observer');
      window.ResizeObserver = module.ResizeObserver;
    }

    // This will use translations from the browser's launguage if we have that language supported.
    // If not, it will fall back to en-us as the default.
    this.intl.setLocale([window.navigator.language, 'en-us']);

    // Set ENV at runtime for lazy-loading
    ENV.scriptBaseUrl = getMwcRoot();

    if (this.challenge.initBootsrapChallenged) {
      // If we proceeded, we would end up in a challenge loop.
      return Promise.resolve();
    }

    return this.bootstrap.initBootstrap().then(() => this.session.refreshServeAppToken());
  }

  afterModel() {
    this.performanceMetric.pushTiming('applicationAfterModel');
    this.performanceMetric.reportTimings();

    // rg4js does not support additive tag changes so we need to
    // manually include previously initialized values
    rg4js('withTags', [channelSlug()]);

    this.router.on('routeDidChange', async () => {
      scheduleOnce('afterRender', this, this._handleCartState);
      scheduleOnce('afterRender', this, this._handleOpenState);

      this.accessibility.setup();
      this.globalData.setup();
      this.globalEvents.setup();
      this.analytics.setup(this.bootstrap.data!);
      this.focus.resetFocus();
    });
  }

  setupController(
    controller: ApplicationController,
    model: ModelForRoute<ApplicationRoute>,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
    if (!isEmpty(controller.src)) {
      this.session.set('src', controller.src);
    }
    if (!isEmpty(controller.restrictedFlow)) {
      this.session.set('restrictedFlow', controller.restrictedFlow === 'true');
    }
  }

  _trackPage() {
    const page = this.router.currentURL;
    this.analytics.trackPageview(page);
    rg4js('trackEvent', { type: 'pageView', path: page });

    if (!this.storage.firstRouteName) {
      this.storage.firstRouteName = this.router.currentRouteName;
    }
  }

  _handleCartState() {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    (this.controllerFor('application') as ApplicationController).handleCartState();
  }

  _handleOpenState() {
    // When automated testing, the `afterRender` gets fired before the `application-loading.hbs` stops rendering,
    // which means there isn't yet a handler for the event that gets fired from this method.
    // Executing the function in the next runloop ensures we have already switched from
    // `application-loading.hbs` to `application.hbs`
    // TODO: Do something similar for _trackPage and _handleCartState, or something to merge the three.
    safeNext(this, () => {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      (this.controllerFor('application') as ApplicationController).handleOpenState();
    });
  }

  @action
  willTransition() {
    this.basket.close();
  }

  /**
   * This handler is triggered when there is an error during route processing,
   * likely originating from a beforeModel, model, or afterModel hook. If we
   * return true from this method, the error.hbs template will be rendered
   * within the application.hbs (or the application-error.hbs template will be
   * rendered standalone if the error originated with the application route).
   *
   * Note that the onerror event in the error service will NOT be triggered by
   * any error that results in this method being called, so any user notifications
   * or raygun logging must be handled in this method.
   */
  @action
  error(error: unknown, transition: Transition) {
    const response = typeof error === 'object' ? asPerimeterXResponse(error) : undefined;
    if (response) {
      this.challenge.routeError(transition, response);
      return false;
    }

    // One possible reason to hit the application error hook is that bootstrap
    // data failed to load entirely. In that case, none of the other special
    // scenarios matter, and the error is not recoverable in any way; we simply
    // need to show the error template.
    if (!this.store.peekRecord('bootstrap-data', channelSlug())) {
      return true;
    }

    this.errorService.reportError(error);

    // if the current transition hasn't been aborted (by transitioning elsewhere)
    // and the current transition isn't already targeting the home page,
    // redirect to the home page.
    // @ts-ignore -- using private API :(
    if (!transition.isAborted && transition.targetName !== 'index') {
      transition.abort();
      this.router.transitionTo('application');
      return false;
    }

    return true;
  }

  @action
  reloadModel() {
    this.refresh();
  }
}
