import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import cloneDeep from 'lodash.clonedeep';

import { CustomField } from 'mobile-web/lib/custom-field';
import isSome from 'mobile-web/lib/utilities/is-some';
import ApplicationSerializer from 'mobile-web/serializers/application';
import ErrorService from 'mobile-web/services/error';
import GroupOrderService from 'mobile-web/services/group-order';

export type BasketPayloadWithCustomFields = {
  basket?: AnyObject;
  upsellGroups?: unknown;
  basketProducts: {
    id: string;
    basketChoices: number[];
    customValues?: {
      choiceId: number;
      value: string;
      label: string;
      customFieldId: number;
    }[];
  }[];
  basketChoices: {
    customFieldValues?: CustomField[];
    id: number;
    choiceId: number;
  }[];
};

export function normalizeBasketProductCustomFields(
  payload: BasketPayloadWithCustomFields,
  error: ErrorService
): BasketPayloadWithCustomFields {
  // TODO: THE TYPES ARE A LIE! [MWC-2560]
  // It is possible that the payload is actually a Basket payload, rather
  // than a sideloaded Basket payload, in which case basketChoices won't exist.
  // Even if the array does exist, if there are no basket choices present there
  // is no reason for us to clone and iterate over things, so bail.
  if (isEmpty(payload.basketChoices)) {
    return payload;
  }

  if (isEmpty(payload.basketProducts)) {
    // TODO: Determine what would cause basketChoices to exist while basketProducts are empty
    error.sendExternalError(new Error('basketProducts is empty while basketChoices is not'));
    return payload;
  }

  const newPayload = cloneDeep(payload);

  // MWC-2440: Can't edit item with custom message
  // Custom field values are represented at the basket product level, but the choice editor
  // needs a way to handle intermediate states. Custom field values must be copied back into
  // the basket-choice model when loading a basket or basket product.

  // When a basket product is first created (initial product load) and added to basket,
  // the server populates basket customValues.

  // When saving a basket product, serializing the custom field happens here:
  // https://github.com/ololabs/mobile-web-client/blob/5253cca1d361866a01267ddceb6f0f8c701c4521/app/serializers/basket-product.ts#L61-L62

  for (const basketProduct of newPayload.basketProducts) {
    for (const choice of newPayload.basketChoices.filter(bpChoice =>
      basketProduct.basketChoices.includes(bpChoice.id)
    )) {
      choice.customFieldValues = (basketProduct.customValues || [])
        .filter(bpCustomValue => Number(bpCustomValue.choiceId) === Number(choice.choiceId))
        .map(x => ({
          id: x.customFieldId,
          label: x.label,
          value: x.value,
        }));
    }
  }

  return newPayload;
}

export default class BasketProductSerializer extends ApplicationSerializer {
  @service error!: ErrorService;
  @service groupOrder!: GroupOrderService;

  // TODO: delete this override of super.normalizeDeleteRecordResponse when FF 'olo-578-use-unavailable-upsells-service' is removed
  normalizeDeleteRecordResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: BasketPayloadWithCustomFields,
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    if (payload.basket) {
      // If there is a basket property, this is a BasketSideLoadModel, and we don't have to do anything special
      return super.normalizeDeleteRecordResponse(
        store,
        primaryModelClass,
        payload,
        id,
        requestType
      );
    }

    // The payload is the raw updated basket, so handle that
    delete payload.upsellGroups;
    this.store.pushPayload('basket', { basket: payload });
    return super.normalizeDeleteRecordResponse(store, primaryModelClass, {}, id, requestType);
  }

  normalizeCreateRecordResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: BasketPayloadWithCustomFields,
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    const basketProducts = payload.basketProducts;
    payload.basketProducts = basketProducts.filter(bp =>
      isNone(store.peekRecord('basket-product', bp.id))
    );
    return super.normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType);
  }

  normalizeResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: BasketPayloadWithCustomFields,
    id: string | number,
    requestType: string
  ): UnknownObject {
    const newPayload = normalizeBasketProductCustomFields(payload, this.error);
    return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
  }

  serializeIntoHash(data: unknown, _type: unknown, record: DS.Snapshot): void {
    Object.assign(data, this.serialize(record));
  }

  serialize(s: DS.Snapshot): UnknownObject {
    const snapshot = s as DS.Snapshot<'basket-product'>;
    const basketProduct = snapshot.record;
    const choices: Array<{ id: EmberDataId; quantity: number; optionGroupId?: EmberDataId }> = [];
    const customFieldIds: EmberDataId[] = [];
    const customFieldTexts: string[] = [];
    basketProduct.basketChoices.forEach(bc => {
      if (bc.isSelected) {
        choices.push({
          id: bc.choice.get('id')!,
          quantity: bc.quantity,
          optionGroupId: bc.choice.get('optionGroup')?.get('id'),
        });
        bc.customFieldValues.forEach(cfv => {
          if (isSome(cfv.value)) {
            customFieldIds.push(`${bc.choice.get('id')}|${cfv.id}`);
            customFieldTexts.push(cfv.value);
          }
        });
      }
    });
    return {
      vendorProductId: basketProduct.product.get('id'),
      quantity: basketProduct.quantity,
      choices,
      customFieldIds,
      customFieldTexts,
      vendorId: basketProduct.vendor.get('id'),
      instructions: basketProduct.specialInstructions,
      recipientName: this.groupOrder.hasGroupOrder
        ? this.groupOrder.currentUserName
        : basketProduct.recipientName,
    };
  }
}
