import Component from '@glimmer/component';

import { range } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

interface Args {
  // Required arguments
  isCheckoutAuth: boolean;
  isCreateAccount: boolean;

  // Optional arguments
}

export default class ApplicationLoadingAuth extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get inputEachArray(): number[] {
    const length = this.args.isCreateAccount ? 6 : this.args.isCheckoutAuth ? 4 : 2;
    return range(0, length);
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
