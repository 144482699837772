import a0 from "../../helpers/media.js";
window.define('mobile-web/helpers/media', function () {
  return a0;
});
import a1 from "../../helpers/has-key.ts";
window.define('mobile-web/helpers/has-key', function () {
  return a1;
});
import a2 from "../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "U9bJ5KJO",
  "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-breadcrumbs\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[\"isTablet\"],null],[[[42,[28,[37,3],[[28,[37,3],[[30,2]],null]],null],null,[[[41,[28,[37,4],[[30,3],\"route\"],null],[[[1,\"        \"],[10,1],[15,0,[30,0,[\"style\",\"crumb\"]]],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[8,[39,5],[[16,0,[30,0,[\"style\",\"link\"]]],[4,[38,6],[\"click\",[30,0,[\"breadcrumbTapped\"]]],null]],[[\"@route\",\"@query\",\"@models\",\"@testSelector\"],[[30,3,[\"route\"]],[30,3,[\"query\"]],[30,3,[\"models\"]],[30,3,[\"label\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,3,[\"label\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[15,0,[30,0,[\"style\",\"crumb\"]]],[14,\"data-test-breadcrumbs-crumb\",\"\"],[12],[1,\"\\n          \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[3]],null]],[]],[[[1,\"    \"],[10,1],[15,0,[30,0,[\"style\",\"crumb\"]]],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[8,[39,5],[[4,[38,6],[\"click\",[30,0,[\"breadcrumbTapped\"]]],null]],[[\"@route\",\"@query\",\"@models\",\"@testSelector\"],[[30,0,[\"backCrumb\",\"route\"]],[30,0,[\"backCrumb\",\"query\"]],[30,0,[\"backCrumb\",\"models\"]],[30,0,[\"backCrumb\",\"label\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"backCrumb\",\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@breadcrumbs\",\"crumb\"],false,[\"if\",\"media\",\"each\",\"-track-array\",\"has-key\",\"olo-link-to\",\"on\"]]",
  "moduleName": "mobile-web/components/breadcrumbs/index.hbs",
  "isStrictMode": false
});