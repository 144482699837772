import { get, set } from '@ember/object';
import { isNone } from '@ember/utils';

import { isString } from 'mobile-web/lib/utilities/_';

import { errResult, isErr, okResult, Result } from './result';
import { PASSWORD_MINLENGTH } from './security';

export type Binding<T = AnyObject> = {
  message: string;
  ruleName: string;
  targetProp: keyof T;
  validationMessagesProp?: keyof T;
  match?: keyof T;
};

export type Rule = {
  name: string;
  check?: (value: Primitive) => boolean;
  pattern?: RegExp | string;
};

export type ValidationConfig<T = AnyObject> = {
  bindings: Binding<T>[];
  customRules?: Rule[];
};

export type Validation<T = AnyObject> = {
  rule?: Rule;
  binding: Binding<T>;
  state: Result<unknown, string | undefined>;
};

export type ValidationMessage = {
  message: string;
  target: string;
};

export type ValidationErrors = Dict<ValidationMessage[]>;
export type ValidationResult = Result<unknown, ValidationErrors>;

export const CARD_NUMBER_PATTERN = /^\d{13,16}$/;
export const EMAIL_PATTERN = /.+@.+\..+/;

const STANDARD_RULES: Rule[] = [
  {
    name: 'cardNumber',
    pattern: CARD_NUMBER_PATTERN,
  },
  {
    name: 'email',
    pattern: EMAIL_PATTERN,
  },
  {
    name: 'minLength',
    pattern: new RegExp(`.{${PASSWORD_MINLENGTH},}`),
  },
  {
    name: 'month',
    pattern: /^\d{2}$/,
  },
  {
    name: 'notBlank',
    pattern: /.+/,
  },
  {
    name: 'phone',
    pattern: /^(?:(?:\+1|1)[\s-]?)?\(?\d{3}\)?[\s-]?\d{3}[\s-]?\d{4}\s?$/,
  },
  {
    name: 'securityCode',
    pattern: /^\d{3,4}$/,
  },
  {
    name: 'year',
    pattern: /^\d{4}$/,
  },
  {
    name: 'expDate',
    pattern: /^\d{2}\/\d{2}$/,
  },
  {
    name: 'cvv',
    pattern: /^\d{3}|\d{4}$/,
  },
  {
    name: 'zip',
    pattern:
      /^(\d{5}(-?\d{4})?|[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ]\s?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i,
  },
  {
    name: 'match',
  },
  {
    name: 'truthful',
    check(value: boolean | string): boolean {
      return value === true || value === 'true';
    },
  },
  {
    name: 'pass',
    check(): boolean {
      return true;
    },
  },
  {
    name: 'fail',
    check(): boolean {
      return false;
    },
  },
];

const nameToRule = (ruleName: string) => (previouslyFoundRule: Rule, rule: Rule) => {
  if (previouslyFoundRule) {
    return previouslyFoundRule;
  }
  if (rule.name === ruleName) {
    return rule;
  }
  return undefined;
};

const byRuleNameIncluded =
  (ruleNames: string[]) =>
  <T>(binding: Binding<T>): boolean =>
    ruleNames.includes(binding.ruleName);

const toValidationRules =
  (rules: Rule[]) =>
  <T>(binding: Binding<T>): Validation<T> => ({
    binding,
    rule: rules.reduce(nameToRule(binding.ruleName), undefined),
    state: errResult(undefined),
  });

const _validate = <T>(
  validation: Validation<T>,
  model: T,
  valueToValidate: Primitive
): Validation<T> => {
  const success = { state: okResult(0) };
  const failure = { state: errResult(validation.binding.message) };

  if (isNone(valueToValidate)) {
    return Object.assign(validation, failure);
  }

  const value = (isString(valueToValidate) ? valueToValidate : valueToValidate.toString()).trim();

  let valid;
  if (validation.rule) {
    if (validation.rule.pattern) {
      valid = value.match(validation.rule.pattern);
    } else if (validation.rule.name === 'match') {
      valid = value === get(model, validation.binding.match!);
    } else if (validation.rule.check) {
      valid = validation.rule.check(value);
    }
  }

  const outcome = valid ? success : failure;
  return Object.assign(validation, outcome);
};

const setErrorMessages = <T>(model: T, validations: Validation<T>[], property?: string) => {
  validations.forEach(v => {
    if (isErr(v.state) && (isNone(property) || property === v.binding.targetProp)) {
      const target = v.binding.validationMessagesProp || `${v.binding.targetProp}Messages`;
      const validationMessage = [toMessage(v)];

      // It's hard to make the types understand that this is safe, but it is.
      set(model as AnyObject, target, validationMessage);
    }
  });
};

export function toMessage<T>(validation: Validation<T>): ValidationMessage {
  const target = `${validation.binding.targetProp}Messages`;
  const message = validation.binding.message ? validation.binding.message : '';

  return { target, message };
}

export const buildValidations = <T>(config: ValidationConfig<T>): Validation<T>[] => {
  const bindings: Binding<T>[] = config.bindings;
  const additionalRules: Rule[] = config.customRules || [];
  const rules = additionalRules.concat(STANDARD_RULES);

  const ruleNames = rules.map(rule => rule.name);

  return bindings.filter(byRuleNameIncluded(ruleNames)).map(toValidationRules(rules));
};

/**
 * Validate a model against a validation configuration
 * @param model - the model you want to validate
 * @param validationConfig - the ValidationConfig to determine validation rules
 * @param setErrors - if true, sets error messages directly onto the model in addition to returning them
 * @returns a Result object that, if an error, has a ValidationErrors object with all error messages
 */
export const validate = <T>(
  model: T,
  validationConfig: ValidationConfig<T>,
  setErrors = false
): ValidationResult => {
  const toState = (validation: Validation<T>): Validation<T> =>
    _validate(validation, model, get(model as AnyObject, validation.binding.targetProp));
  const toError = (errorObj: ValidationErrors, validation: Validation<T>): ValidationErrors => {
    if (isErr(validation.state)) {
      const errorKey = (validation.binding.targetProp as string).replace(/\./g, '_');
      if (errorObj.hasOwnProperty(errorKey)) {
        get(errorObj, errorKey)!.push(toMessage(validation));
      } else {
        set(errorObj, errorKey, [toMessage(validation)]);
      }
    }
    return errorObj;
  };

  const validationResults = buildValidations(validationConfig).map(toState);

  if (setErrors) {
    setErrorMessages(model, validationResults);
  }

  const results = validationResults.reduce(toError, {});
  return Object.keys(results).length === 0 ? okResult(0) : errResult(results);
};

export const Validation = {
  buildValidations,
  toMessage,
  validate,
};

export default Validation;
