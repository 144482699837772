import a0 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "+zpNs4YK",
  "block": "[[[10,\"button\"],[15,0,[30,0,[\"rootClass\"]]],[15,\"onclick\",[28,[37,0],[[30,0],\"share\"],null]],[14,\"aria-label\",\"Share on Twitter\"],[14,\"data-test-twitter-share-button\",\"\"],[14,4,\"button\"],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"Tweet\"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"Twitter\",[30,0,[\"style\",\"icon\"]],\"twitter-logo\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"svg\"]]",
  "moduleName": "mobile-web/components/twitter-share-button/index.hbs",
  "isStrictMode": false
});