import Component from '@ember/component';
import { assert } from '@ember/debug';

import { tagName } from '@ember-decorators/component';

import { isSome } from 'mobile-web/lib/utilities/is-some';

import style from './index.m.scss';

@tagName('')
export default class StaticPage extends Component {
  model!: string;
  style = style;

  init() {
    super.init();
    assert('`model` is required', isSome(this.model));
  }
}
