import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';

import { tagName } from '@ember-decorators/component';
import { dropTask, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import { isFunction } from 'mobile-web/lib/utilities/_';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import OrderModel from 'mobile-web/models/order';

export type SaveFavorite = (orderId: EmberDataId, name: string) => RSVP.Promise<any>;

@tagName('')
export default class SaveFavoriteModal extends Component {
  onClose!: () => void;
  order!: OrderModel;
  saveFavorite!: SaveFavorite;

  name = '';

  @computed('submit.isRunning', 'name')
  get isSubmitDisabled(): boolean {
    return taskFor(this.submit).isRunning || isEmpty(this.name);
  }

  init() {
    super.init();

    assert('`onClose` is required', isFunction(this.onClose));
    assert('`order` is required', isSome(this.order));
    assert('`saveFavorite` is required', isFunction(this.saveFavorite));
  }

  @dropTask *submit(
    saveFavorite: SaveFavorite,
    orderId: string,
    name: string,
    close: () => void
  ): TaskGenerator<void> {
    yield saveFavorite(orderId, name);
    close();
  }

  doSubmit() {
    taskFor(this.submit).perform(this.saveFavorite, this.order.id, this.name, this.onClose);
  }
}
