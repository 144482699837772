import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { Segment } from 'mobile-web/components/segmented-control';
import { getOrCreateBasketChoice, isParentNew, loadNestedMenuItems } from 'mobile-web/lib/menu';
import { safeNext } from 'mobile-web/lib/runloop';
import BasketChoiceModel from 'mobile-web/models/basket-choice';
import BasketProductModel from 'mobile-web/models/basket-product';
import ChoiceModel from 'mobile-web/models/choice';
import OptionGroupModel, { DisplayGroup } from 'mobile-web/models/option-group';

import style from './index.m.scss';

type ArgsBase = {
  // Required arguments
  basketProduct: BasketProductModel;

  // Optional arguments
  onClick?: Action;
};

type DisplayGroupArgs = {
  displayGroup: DisplayGroup;
  optionGroup: OptionGroupModel;
  choice: never;
} & ArgsBase;

type ChoiceArgs = {
  choice: ChoiceModel;
} & ArgsBase;

type Args = DisplayGroupArgs | ChoiceArgs;

function isDisplayGroup(args: Args): args is DisplayGroupArgs {
  return 'displayGroup' in args;
}

const TWO_CHOICE_MAX_CHARS = 11;
const THREE_CHOICE_MAX_CHARS = 7;

export default class ProductCustomizationInlineChoice extends Component<Args> {
  // Service injections
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked basketChoice?: BasketChoiceModel;
  @tracked basketChoices: BasketChoiceModel[] = [];

  // Getters and setters
  /**
   * The `activeChoice` is the selected, active child choice of this group.
   * When using displayGroups, it is always the first child with a quantity,
   *  or the first child if all are quantity 0.
   * When using inline choices, if the parent choice is not selected,
   *  then there is no active choice.
   * If the parent choice is selected, then it is the first child with a quantity,
   *  or the first child if all are quantity 0.
   */
  get activeChoice(): BasketChoiceModel | undefined {
    return isDisplayGroup(this.args) || (this.basketChoice?.quantity ?? 0) > 0
      ? this.basketChoices.find(bc => bc.quantity > 0) ?? this.basketChoices[0]
      : undefined;
  }

  get choiceModel(): ChoiceModel | undefined {
    return this.activeChoice?.choice?.content ?? this.args.choice;
  }

  get choiceName(): string {
    return isDisplayGroup(this.args) ? this.args.displayGroup.name : this.args.choice.name;
  }

  get segments(): Segment<BasketChoiceModel>[] {
    return this.basketChoices.map(bc => ({
      label: bc.choice.content!.groupedName,
      value: bc,
    }));
  }

  get parentOptionGroup(): OptionGroupModel {
    return isDisplayGroup(this.args) ? this.args.optionGroup : this.args.choice.optionGroup!;
  }

  get optionGroup(): OptionGroupModel | undefined {
    return isDisplayGroup(this.args)
      ? this.args.optionGroup
      : this.args.choice.optionGroups.get('firstObject');
  }

  get choices(): ChoiceModel[] {
    return (
      isDisplayGroup(this.args)
        ? this.args.displayGroup.choices
        : this.optionGroup?.choices?.toArray() ?? []
    ).filter(c => !c.isDisabled);
  }

  get useSegmentedControl(): boolean {
    const choiceCount = this.choices.length;
    const maxCharLength = choiceCount === 2 ? TWO_CHOICE_MAX_CHARS : THREE_CHOICE_MAX_CHARS;
    return (
      choiceCount < 4 &&
      this.choices.every(
        c =>
          (this.parentOptionGroup?.hideChoicePrices || !c.priceDifference) &&
          isEmpty(c.calorieLabel) &&
          c.groupedName.length <= maxCharLength
      )
    );
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    safeNext(this, () => {
      if (!isDisplayGroup(this.args)) {
        this.basketChoice = getOrCreateBasketChoice(
          this.store,
          this.args.basketProduct,
          this.args.choice
        ).choice;
      }
      this.updateChoices();
    });
  }

  // Other methods

  // Tasks

  // Actions
  @action
  setActiveChoice(choiceId: EmberDataId) {
    this.basketChoices.forEach(bc => {
      bc.quantity = bc.choice.get('id') === choiceId ? 1 : 0;
    });
  }

  @action
  setActiveSegment(choice: Segment<BasketChoiceModel>) {
    this.setActiveChoice(choice.value.choice.get('id')!);
  }

  @action
  updateChoices() {
    if (this.basketChoices.length === 0) {
      let areAllNew = true;
      this.choices.forEach(c => {
        const { choice, isNew } = getOrCreateBasketChoice(this.store, this.args.basketProduct, c);
        this.basketChoices.pushObject(choice);
        areAllNew = areAllNew && isNew;
        loadNestedMenuItems(this.store, this.args.basketProduct, c, false);
      });
      if (
        areAllNew &&
        this.choices.length &&
        isParentNew(this.args.basketProduct, this.choices[0])
      ) {
        this.optionGroup?.splitChoiceQuantities();
      }
    }
  }

  @action
  toggleChoice(basketChoice: BasketChoiceModel) {
    if (isDisplayGroup(this.args)) {
      this.args.onClick?.(basketChoice);
    } else {
      // This happens when selecting the choice, so make sure we default child quantities
      if (this.basketChoice === basketChoice) {
        this.args.onClick?.(basketChoice);
        if (!isEmpty(this.basketChoices) && this.basketChoices.every(bc => bc.quantity === 0)) {
          this.basketChoices[0].quantity = this.basketChoices[0].choice.content?.minQuantity ?? 1;
        }
      } else {
        // This happens when deselecting the choice, so kick the parent choice up the chain to be deselected
        this.args.onClick?.(this.basketChoice);
      }
    }
  }

  @action
  preventPropagation(e: Event) {
    e.stopPropagation();
  }
}
