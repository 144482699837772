import a0 from "../../../../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a0;
});
import a1 from "../../../../product-customization/index.ts";
window.define('mobile-web/components/product-customization', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "XAITKfUD",
  "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-routes-menu-vendor-productsRoute\",\"\"],[4,[38,0],[[30,0,[\"openModal\"]]],null],[12],[1,\"\\n\"],[41,[51,[30,1,[\"displayInModal\"]]],[[[1,\"    \"],[8,[39,2],null,[[\"@basketProduct\",\"@product\",\"@displayInModal\",\"@previousRoute\",\"@onSave\",\"@isLoading\"],[[30,1,[\"basketProduct\"]],[30,1,[\"product\"]],[30,1,[\"displayInModal\"]],[30,1,[\"previousRoute\"]],[30,0,[\"transitionToVendor\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@model\",\"@isLoading\"],false,[\"did-insert\",\"unless\",\"product-customization\"]]",
  "moduleName": "mobile-web/components/routes/menu/vendor/products-route/index.hbs",
  "isStrictMode": false
});