import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  hidden?: boolean;
  type?: string;
}
interface Signature {
  Element: HTMLElement;

  Args: Args;

  Blocks: {
    default: [{ close(): void }];
  };
}

/**
 * Displays a notification that will be shown to guests once.
 *
 * When a guest has viewed the notificaiton, the view is tracked in
 * `localStorage` based on the provided `type` parameter.
 */
export default class OneTimeNotification extends Component<Signature> {
  // Service injections
  @service storage!: StorageService;

  // Untracked properties
  ids = guids(this, 'body');
  style = style;
  /**
   * cache a snapshot of the one-time notification state so that the
   * notification doesn't disappear when marked as "shown".
   */
  private shownOneTimeNotifications = this.storage.shownOneTimeNotifications;

  // Tracked properties
  @tracked private visible?: boolean;

  // Getters and setters
  get show(): boolean {
    if (this.args.hidden || !this.args.type) {
      return false;
    }

    if (isSome(this.visible)) {
      return this.visible;
    }

    return this.shownOneTimeNotifications?.[this.args.type] !== true;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  close() {
    this.visible = false;
  }

  /**
   * mark the notification as "shown" so that it's not shown again.
   */
  @action
  shown() {
    if (this.args.type) {
      this.storage.shownOneTimeNotifications = {
        ...this.storage.shownOneTimeNotifications,
        [this.args.type]: true,
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OneTimeNotification: typeof OneTimeNotification;
  }
}
