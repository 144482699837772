import a0 from "../form-field-input/index.ts";
window.define('mobile-web/components/form-field-input', function () {
  return a0;
});
import a1 from "../../helpers/eq.js";
window.define('mobile-web/helpers/eq', function () {
  return a1;
});
import a2 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "bApvsIwC",
  "block": "[[[10,\"form\"],[15,0,[30,0,[\"rootClass\"]]],[14,\"data-test-segmented-control\",\"\"],[12],[1,\"\\n  \"],[10,1],[15,1,[28,[37,0],[[30,0,[\"name\"]],\"-group-label\"],null]],[15,0,[30,0,[\"style\",\"groupLabel\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"groupLabel\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"radioGroup\"]]],[14,\"role\",\"radiogroup\"],[15,\"aria-labelledby\",[28,[37,0],[[30,0,[\"name\"]],\"-group-label\"],null]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,3],[[16,\"data-test-segmented-control-input-index\",[30,3]]],[[\"@type\",\"@name\",\"@class\",\"@id\",\"@checked\",\"@onChange\"],[\"radio\",[30,0,[\"name\"]],[30,0,[\"style\",\"input\"]],[28,[37,4],[[30,0,[\"inputIds\"]],[30,3]],null],[28,[37,5],[[30,4],[30,2,[\"value\"]]],null],[28,[37,6],[[30,0,[\"handleChange\"]],[30,2]],null]]],null],[1,\"\\n      \"],[10,\"label\"],[15,0,[28,[37,7],[[28,[37,4],[[30,0,[\"buttonClasses\"]],[30,3]],null],[52,[51,[30,0,[\"hasSingleSegment\"]]],[30,0,[\"style\",\"active\"]]]],null]],[15,\"for\",[28,[37,4],[[30,0,[\"inputIds\"]],[30,3]],null]],[15,\"data-test-segmented-control-label\",[30,2,[\"label\"]]],[12],[1,\"\\n        \"],[1,[30,2,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@segments\",\"segment\",\"index\",\"@value\"],false,[\"concat\",\"each\",\"-track-array\",\"form-field-input\",\"get\",\"eq\",\"fn\",\"classes\",\"unless\"]]",
  "moduleName": "mobile-web/components/segmented-control/index.hbs",
  "isStrictMode": false
});