import Component from '@glimmer/component';

import { Breadcrumb } from 'mobile-web/components/breadcrumbs';
import CategoryModel from 'mobile-web/models/category';

import style from './index.m.scss';

interface Signature {
  Element: HTMLElement;

  Args: {
    // Required arguments
    breadcrumbs: Breadcrumb[];
    heading: string;
    singleUseCategories: CategoryModel[];

    // Optional arguments
  };
}

export default class MenuVendorSingleUseRoute extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::Menu::Vendor::SingleUseRoute': typeof MenuVendorSingleUseRoute;
  }
}
