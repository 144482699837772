import a0 from "../modal/index.ts";
window.define('mobile-web/components/modal', function () {
  return a0;
});
import a1 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a1;
});
import a2 from "../../modifiers/will-destroy.js";
window.define('mobile-web/modifiers/will-destroy', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "eiwXdGyM",
  "block": "[[[11,0],[24,\"data-test-animatedModal\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"renderContent\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@wrapperClass\",\"@modalClass\",\"@title\",\"@buttons\",\"@onClose\",\"@bodyDidInsert\",\"@testSelector\"],[[30,0,[\"wrapperClass\"]],[30,0,[\"modalClass\"]],[30,2],[30,3],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n      \"],[18,9,[[30,7]]],[1,\"\\n    \"]],[7]]]]],[1,\"\\n\"]],[]],null],[41,[30,8],[[[1,\"    \"],[11,1],[4,[38,3],[[30,0,[\"beginOpenAnimation\"]]],null],[4,[38,4],[[30,0,[\"beginCloseAnimation\"]]],null],[12],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"@buttons\",\"@onClose\",\"@bodyDidInsert\",\"@testSelector\",\"modal\",\"@open\",\"&default\"],false,[\"if\",\"modal\",\"yield\",\"did-insert\",\"will-destroy\"]]",
  "moduleName": "mobile-web/components/animated-modal/index.hbs",
  "isStrictMode": false
});