import a0 from "../one-time-notification/index.ts";
window.define('mobile-web/components/one-time-notification', function () {
  return a0;
});
import a1 from "../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a1;
});
import a2 from "../../helpers/format-dayjs.ts";
window.define('mobile-web/helpers/format-dayjs', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "7y4q9zhz",
  "block": "[[[8,[39,0],[[24,\"data-test-privacyPolicyNotification\",\"\"]],[[\"@type\",\"@hidden\"],[[30,0,[\"type\"]],[30,0,[\"hidden\"]]]],[[\"default\"],[[[[1,\"\\n  Olo's\\n  \"],[8,[39,1],[[24,\"data-test-privacyPolicyNotification-link\",\"\"],[4,[38,3],[\"click\",[30,1,[\"close\"]]],null]],[[\"@route\",\"@query\"],[[30,0,[\"router\",\"currentRouteName\"]],[28,[37,2],null,[[\"open\"],[\"privacy-policy\"]]]]],[[\"default\"],[[[[1,\"Privacy Policy\"]],[]]]]],[1,\"\\n  has changed and takes effect immediately if this is your first time here. Otherwise, it takes\\n  effect on\\n  \"],[1,[28,[35,4],[[30,0,[\"effectiveDate\"]],\"MMMM D, YYYY\"],null]],[1,\".\\n\"]],[1]]]]]],[\"notification\"],false,[\"one-time-notification\",\"olo-link-to\",\"hash\",\"on\",\"format-dayjs\"]]",
  "moduleName": "mobile-web/components/privacy-policy-notification/index.hbs",
  "isStrictMode": false
});