import a0 from "../animated-modal/index.ts";
window.define('mobile-web/components/animated-modal', function () {
  return a0;
});
import a1 from "../../helpers/media.js";
window.define('mobile-web/helpers/media', function () {
  return a1;
});
import a2 from "../product-customization/index.ts";
window.define('mobile-web/components/product-customization', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "qFGau/H8",
  "block": "[[[8,[39,0],null,[[\"@open\",\"@title\",\"@transition\",\"@modalClass\",\"@testSelector\",\"@onCloseComplete\",\"@onClose\"],[[30,0,[\"isOpen\"]],[30,0,[\"eventArgs\",\"product\",\"name\"]],[52,[28,[37,2],[\"isTablet\"],null],\"fade\",\"slideUp\"],[30,0,[\"style\",\"modal\"]],\"productModal\",[30,0,[\"onCloseComplete\"]],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,[[\"@basketProduct\",\"@product\",\"@displayInModal\",\"@previousRoute\",\"@onSave\"],[[30,0,[\"eventArgs\",\"basketProduct\"]],[30,0,[\"eventArgs\",\"product\"]],true,[30,0,[\"eventArgs\",\"previousRoute\"]],[30,0,[\"onSave\"]]]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"animated-modal\",\"if\",\"media\",\"product-customization\"]]",
  "moduleName": "mobile-web/components/product-modal/index.hbs",
  "isStrictMode": false
});