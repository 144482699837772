export default {
  "root": "_root_12abkz",
  "carousel": "_carousel_12abkz",
  "header": "_header_12abkz",
  "headings": "_headings_12abkz",
  "heading": "_heading_12abkz",
  "hiddenHeading": "_hiddenHeading_12abkz",
  "subheading": "_subheading_12abkz",
  "viewAllLink": "_viewAllLink_12abkz",
  "description": "_description_12abkz",
  "carouselContainer": "_carouselContainer_12abkz",
  "scrollButtonContainer": "_scrollButtonContainer_12abkz",
  "scrollLeftButtonContainer": "_scrollLeftButtonContainer_12abkz _scrollButtonContainer_12abkz",
  "scrollRightButtonContainer": "_scrollRightButtonContainer_12abkz _scrollButtonContainer_12abkz",
  "scrollButton": "_scrollButton_12abkz",
  "hover": "_hover_12abkz",
  "icon": "_icon_12abkz",
  "item": "_item_12abkz"
};
