import a0 from "../components/application-frame/index.ts";
window.define('mobile-web/components/application-frame', function () {
  return a0;
});
import a1 from "../components/application-loading/index.ts";
window.define('mobile-web/components/application-loading', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "eG2LDYDM",
  "block": "[[[8,[39,0],null,[[\"@isLoading\"],[true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"application-frame\",\"application-loading\"]]",
  "moduleName": "mobile-web/templates/application-loading.hbs",
  "isStrictMode": false
});