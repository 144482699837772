import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import ContentService from 'mobile-web/services/content';

export default class OptOutGuideRoute extends Route {
  @service content!: ContentService;

  model() {
    return RSVP.hash({
      content: this.loadContent(),
      title: this.store.findRecord('content', 'ONLINE_TRACKING_PAGE_LINK_NAME'),
    });
  }

  async loadContent() {
    return this.content.getHTML('ONLINE_TRACKING_PAGE_HTML', {
      BrandContent: await this.content.getEntry('ONLINE_TRACKING_PAGE_BRAND_HTML'),
    });
  }
}
