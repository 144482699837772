import { action, get } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import TransitionContext from 'ember-animated/-private/transition-context';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { enqueueTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { getDuration } from 'mobile-web/lib/animation';
import { CustomizedChoiceModel, CustomizedProductModel } from 'mobile-web/lib/menu';
import isSome from 'mobile-web/lib/utilities/is-some';
import { isToggleKey } from 'mobile-web/lib/utilities/keys';
import { numberOr0 } from 'mobile-web/lib/utilities/numeric';
import BasketProductModel, { isBasketProduct } from 'mobile-web/models/basket-product';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import NotificationsService, { NotificationType } from 'mobile-web/services/notifications';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

const COUNT_SHOWN_BY_DEFAULT = 3;

interface Args {
  // Required arguments
  product: CustomizedProductModel;

  // Optional arguments
  currency?: string;
  editable?: boolean;
  onClose?: Action;
  onEdit?: (product: CustomizedProductModel) => void;
}

interface Signature {
  Args: Args;
}

export default class CartProduct extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service notifications!: NotificationsService;
  @service router!: RouterService;
  @service vendor!: VendorService;

  // Untracked properties
  animationDuration = getDuration(40);
  style = style;

  // Tracked properties
  @tracked isExpanded = false;

  // Getters and setters
  get editable(): boolean {
    return this.args.editable ?? false;
  }

  get displayableChoices(): CustomizedChoiceModel[] {
    if (isBasketProduct(this.args.product)) {
      return (
        // eslint-disable-next-line ember/no-get
        get(this.args.product, 'basketChoices')?.filter(bc => bc.displayInBasket && !bc.isNew) ?? []
      );
    }

    return (
      // eslint-disable-next-line ember/no-get
      get(this.args.product, 'favoriteChoices').toArray() ?? []
    );
  }

  get shownChoices() {
    const choices = this.displayableChoices;
    return this.isExpanded ? choices : choices.slice(0, COUNT_SHOWN_BY_DEFAULT);
  }

  get hiddenChoiceCount() {
    return this.displayableChoices.length - COUNT_SHOWN_BY_DEFAULT;
  }

  get showToggleChoicesButton() {
    return this.displayableChoices.length > COUNT_SHOWN_BY_DEFAULT;
  }

  get quantity(): number {
    return isBasketProduct(this.args.product)
      ? this.args.product.savedAttributes.quantity
      : this.args.product.quantity;
  }

  get recipientName(): string {
    if (this.groupOrder.hasGroupOrder) {
      return '';
    }

    return isBasketProduct(this.args.product)
      ? this.args.product.savedAttributes.recipientName
      : this.args.product.recipient;
  }

  get specialInstructions(): string {
    return isBasketProduct(this.args.product)
      ? this.args.product.savedAttributes.specialInstructions
      : this.args.product.specialInstructions;
  }

  // Constructor

  // Other methods
  *transition({ insertedSprites, removedSprites }: TransitionContext) {
    for (const sprite of insertedSprites) {
      sprite.startTranslatedBy(0, 30);
      fadeIn(sprite, { duration: 200 });
      move(sprite, { duration: 0 });
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite, { duration: 100 });
    }
  }

  // Tasks
  removeProductTask = taskFor(this.removeProductTaskInstance);
  @enqueueTask *removeProductTaskInstance(product: BasketProductModel) {
    const vendorSlug = product.vendor.get('slug')!;
    const basketChoices = product.basketChoices.toArray();

    // Pulling a reference to the vendor product before the basket product is destroyed
    const vendorProduct = product.product;
    const productQuantity = product.quantity;

    yield product.destroyRecord();
    for (const choice of basketChoices) {
      yield choice.unloadRecord();
    }

    this.analytics.trackEvent(AnalyticsEvents.RemoveFromCart, () => ({
      [AnalyticsProperties.ProductCategory]: vendorProduct.get('category')?.name,
      [AnalyticsProperties.ProductName]: vendorProduct.get('name'),
      [AnalyticsProperties.ProductQuantity]: productQuantity,
      [AnalyticsProperties.ProductAvailableOptionGroupCount]: numberOr0(
        vendorProduct.get('optionGroups')?.length
      ),
      [AnalyticsProperties.HasVisibleCalories]:
        isSome(vendorProduct.get('vendor')?.settings.showCalories) &&
        isSome(vendorProduct.get('calorieLabel')),
      [AnalyticsProperties.VisibleLabels]: vendorProduct.get('labels')?.map(l => l.name),
      [AnalyticsProperties.HasProductImages]: !isEmpty(vendorProduct.get('images')),
      [AnalyticsProperties.HasCategoryImages]: !isEmpty(vendorProduct.get('category')?.images),
      [AnalyticsProperties.ProductBasePrice]: vendorProduct.get('baseCost'),
      [AnalyticsProperties.IsFeatured]: vendorProduct.get('isFeatured'),
      [AnalyticsProperties.IsSingleUse]: vendorProduct.get('isSingleUse'),
    }));

    this.globalEvents.trigger(GlobalEventName.RemoveFromCart, product.serializeForGlobalData());

    const currentRoute = this.router.currentRoute;
    if (
      currentRoute.name === 'basket.basket-products.edit' &&
      currentRoute.params.basket_product_id === product.id
    ) {
      this.router.transitionTo('menu.vendor', vendorSlug);
    }

    const message = this.intl.t('mwc.notifications.removed', {
      quantity: product.quantity,
    });

    this.notifications.success({
      message,
      type: NotificationType.ProductRemoved,
    });
  }

  // Actions
  @action
  toggleChoices() {
    this.isExpanded = !this.isExpanded;
  }

  @action
  editProduct() {
    if (this.editable) {
      this.analytics.trackEvent(AnalyticsEvents.ViewProductCustomization, () => {
        if (isBasketProduct(this.args.product)) {
          const vendorProduct = this.args.product.get('product');
          return {
            [AnalyticsProperties.ProductName]: vendorProduct.get('name'),
            [AnalyticsProperties.ProductCategory]: vendorProduct.get('category')?.name,
            [AnalyticsProperties.ProductBasePrice]: vendorProduct.get('baseCost'),
            [AnalyticsProperties.HasVisibleCalories]: !isEmpty(vendorProduct.get('calorieLabel')),
            [AnalyticsProperties.VisibleLabels]: vendorProduct.get('labels')?.map(l => l.name),
            [AnalyticsProperties.HasProductImages]: !isEmpty(vendorProduct.get('images')),
            [AnalyticsProperties.HasCategoryImages]: !isEmpty(
              vendorProduct.get('category')?.bannerImage
            ),
            [AnalyticsProperties.HasVisiblePrice]: true,
            [AnalyticsProperties.ProductAvailableOptionGroupCount]: vendorProduct
              .get('optionGroups')
              ?.map(og => og.id).length,
            [AnalyticsProperties.Source]: 'Cart',
          };
        }

        return {
          [AnalyticsProperties.ProductName]: this.args.product.get('productName'),
          [AnalyticsProperties.Source]: 'Cart',
        };
      });

      this.args.onClose?.();
      this.args.onEdit?.(this.args.product);
    }
  }

  @action
  editProductWithKey(event: KeyboardEvent) {
    if (isToggleKey(event)) {
      this.editProduct();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::Product': typeof CartProduct;
  }
}
