import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/static-page/index.ts";
window.define('mobile-web/components/static-page', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "e+qX4yvn",
  "block": "[[[1,[28,[35,0],[\"Contact Us\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"static-page\"]]",
  "moduleName": "mobile-web/templates/contact-us.hbs",
  "isStrictMode": false
});