import a0 from "../header-button/index.ts";
window.define('mobile-web/components/header-button', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "BV+j2fQ3",
  "block": "[[[8,[39,0],[[24,\"data-test-menuButton\",\"\"],[17,1]],[[\"@label\",\"@showLabel\",\"@icon\",\"@onClick\"],[[28,[37,1],null,[[\"text\"],[[28,[37,2],[\"mwc.header.menuAltText\"],null]]]],false,[28,[37,1],null,[[\"svg\",\"ariaLabel\",\"position\"],[\"hamburger\",\"\",\"left\"]]],[30,0,[\"onClick\"]]]],null]],[\"&attrs\"],false,[\"header-button\",\"hash\",\"t\"]]",
  "moduleName": "mobile-web/components/menu-button/index.hbs",
  "isStrictMode": false
});