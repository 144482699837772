import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import isSome from 'mobile-web/lib/utilities/is-some';

export default class DispatchSummaryRoute extends Route {
  @service router!: RouterService;

  queryParams = {
    id: {
      refreshModel: true,
    },
  };
  hash?: string;

  async model(params: { id: number; order_id: number; hash: string }) {
    const orderId = params.order_id;
    this.hash = params.hash;

    if (isSome(params.id)) {
      this.router.replaceWith('dispatch-status', orderId, params.id, {
        queryParams: { id: undefined },
      });
    }

    return this.store
      .findRecord('order', orderId, { adapterOptions: { hash: this.hash } })
      .then(o =>
        RSVP.hash({
          o,
          orderId: RSVP.resolve(orderId),
          statuses: this.store.query('dispatch-status', {
            orderId,
            hash: this.hash,
          }),
        })
      );
  }

  afterModel(resolvedModel: ModelForRoute<DispatchSummaryRoute>) {
    if (resolvedModel.statuses.length === 1) {
      this.router.replaceWith(
        'dispatch-status',
        resolvedModel.orderId,
        resolvedModel.statuses.get('firstObject')!.id,
        { queryParams: { hash: this.hash } }
      );
    }
  }
}
