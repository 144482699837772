import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a1;
});
import a2 from "../../icons/loading-spinner/index.ts";
window.define('mobile-web/components/icons/loading-spinner', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Vv+jyGQj",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"container\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"healthCheck\",\"isHealthy\"]],[[[1,\"      \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"header\"]]],[14,\"data-test-outage-resolvedHeader\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"mwc.outage.resolvedHeader\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,\"data-test-outage-upDescription\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.upDescription\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"status\"]]],[14,\"data-test-outage-upStatus\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.upStatus\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,2],[[16,0,[30,0,[\"style\",\"link\"]]]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n        Back to home\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"header\"]]],[14,\"data-test-outage-errorHeader\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.errorHeader\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,\"data-test-outage-downDescription\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.downDescription\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"status\"]]],[14,\"data-test-outage-downStatus\",\"\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.outage.downStatus\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],[[24,\"data-test-outage-loadingSpinner\",\"\"]],[[\"@class\"],[[30,0,[\"style\",\"spinnerIcon\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"t\",\"olo-link-to\",\"icons/loading-spinner\"]]",
  "moduleName": "mobile-web/components/routes/outage-route/index.hbs",
  "isStrictMode": false
});