import Component from '@glimmer/component';

import style from './index.m.scss';

export type Type = 'positive' | 'cautionary' | 'negative' | 'neutral';

interface Args {
  // Required arguments
  label: string;
  type: Type;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class InfoTag extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get rootClass(): string {
    return style[this.args.type];
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    InfoTag: typeof InfoTag;
  }
}
