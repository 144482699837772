import a0 from "../../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a0;
});
import a1 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Q2uPxqil",
  "block": "[[[11,1],[16,0,[30,0,[\"rootClass\"]]],[16,\"data-test-badge\",[30,1]],[17,2],[4,[38,0],[[30,0,[\"track\"]]],null],[12],[1,\"\\n  \"],[10,3],[15,1,[30,0,[\"linkId\"]]],[15,6,[30,0,[\"href\"]]],[15,0,[30,0,[\"style\",\"link\"]]],[15,\"data-test-badge-link\",[30,1]],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"imgSrc\"]]],[15,\"alt\",[28,[37,1],[[30,0,[\"imgAltTextKey\"]]],null]],[15,0,[30,0,[\"style\",\"image\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@type\",\"&attrs\"],false,[\"did-insert\",\"t\"]]",
  "moduleName": "mobile-web/components/app-store-badges/badge/index.hbs",
  "isStrictMode": false
});