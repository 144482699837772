import Application from '@ember/application';

export function initialize(application: Application) {
  application.inject('route', 'vendorService', 'service:vendor');
}

export default {
  name: 'injections',
  initialize,
};
