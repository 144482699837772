import a0 from "../../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../../components/routes/menu/vendor-route/index.ts";
window.define('mobile-web/components/routes/menu/vendor-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "p/BvQJRk",
  "block": "[[[1,[28,[35,0],[[30,1,[\"pageTitle\"]]],[[\"noSuffix\"],[true]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isFullScreenProductPage\"]],[[[1,\"  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@skipPreCheck\",\"@vendor\",\"@recentOrders\",\"@showInfoModal\"],[[30,0,[\"skipPreCheck\"]],[30,1,[\"vendor\"]],[30,1,[\"recentOrders\"]],[30,0,[\"showInfoModal\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"isProductPage\"]],[[[1,\"    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[]],null]],[]]]],[\"@model\"],false,[\"page-title\",\"if\",\"component\",\"-outlet\",\"routes/menu/vendor-route\"]]",
  "moduleName": "mobile-web/templates/menu/vendor.hbs",
  "isStrictMode": false
});