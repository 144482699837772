import a0 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import a1 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "QcRfNuMY",
  "block": "[[[8,[39,0],[[24,\"role\",\"status\"],[24,\"aria-busy\",\"true\"],[17,1]],[[\"@ariaLabel\",\"@icon\",\"@class\",\"@iconClass\"],[\"loading...\",\"loading-spinner\",[30,2],[28,[37,1],[[30,0,[\"style\",\"icon\"]],[30,3]],null]]],null]],[\"&attrs\",\"@class\",\"@iconClass\"],false,[\"svg\",\"classes\"]]",
  "moduleName": "mobile-web/components/icons/loading-spinner/index.hbs",
  "isStrictMode": false
});