import a0 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "yd09EPLY",
  "block": "[[[11,0],[16,0,[28,[37,0],[\"ember-content-placeholders-heading\",[30,0,[\"rootClass\"]]],null]],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"ember-content-placeholders-heading__img\"],[14,\"data-test-ember-content-placeholders-heading-img\",\"\"],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"ember-content-placeholders-heading__content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ember-content-placeholders-heading__title\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"ember-content-placeholders-heading__subtitle\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@img\"],false,[\"classes\",\"if\"]]",
  "moduleName": "mobile-web/components/content-placeholders/heading/index.hbs",
  "isStrictMode": false
});