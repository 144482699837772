import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import cloneDeep from 'lodash.clonedeep';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onSubmit: Action<string>;
  address: string;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class RewardsSearchForm extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked _address!: string;

  // Getters and setters

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._address = cloneDeep(args.address) || '';
  }

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    RewardsSearchForm: typeof RewardsSearchForm;
  }
}
