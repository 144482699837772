import Component from '@glimmer/component';

import isSome from 'mobile-web/lib/utilities/is-some';
import { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';

import style from './index.m.scss';

interface Args {
  // Required arguments
  status: GroupOrderParticipantStatus;

  // Optional arguments
  hideIcon: boolean;
  hideLabel: boolean;
  count: number;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

export default class GroupOrderStatus extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;
  displayCount = '';

  // Tracked properties

  // Getters and setters
  get statusDisplay(): { label: string; icon: string } {
    switch (this.args.status) {
      case GroupOrderParticipantStatus.Joined:
        return { label: 'Joined', icon: '' };
      case GroupOrderParticipantStatus.InProgress:
        return { label: 'In Progress', icon: 'clock' };
      case GroupOrderParticipantStatus.Submitted:
        return { label: 'Submitted', icon: 'check' };
      case GroupOrderParticipantStatus.Removed:
        return { label: 'Left the group', icon: 'door' };
      default:
        return { label: '', icon: '' };
    }
  }

  get iconStyle() {
    return style[this.args.status.toLowerCase()];
  }

  // get count(): string {
  //   return this.args.showCount.toString();
  // }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.displayCount = isSome(this.args.count) ? this.args.count.toString() : '';
  }
  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::Status': typeof GroupOrderStatus;
  }
}
