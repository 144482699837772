import Service, { inject as service } from '@ember/service';

import IntlService from 'ember-intl/services/intl';

import dayjs, { Dayjs } from 'mobile-web/lib/dayjs';

type RelativeDateOptions = {
  titleCase?: boolean;
  html?: boolean;
};

type VendorRelativeDateTimeOptions = {
  style?: 'titleCase' | 'sentenceCase';
  html?: boolean;
};

type DateTimeParts = {
  year: string;
  month: string;
  day: string;
  weekday: string;
  hour: string;
  minute: string;
  dayPeriod: string;
  timeZoneName: string;
};

export default class MwcIntlService extends Service {
  @service intl!: IntlService;

  get localTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get now(): Date {
    return new Date();
  }

  /**
   * @summary
   * Gets the ordinal suffix of a number
   *
   * @param number the number to get the suffix for
   *
   * @returns the ordinal suffix (i.e. 'st', 'nd', 'rd', or 'th')
   */
  ordinal(number: number): string {
    const rules = new Intl.PluralRules('en', { type: 'ordinal' });
    const category = rules.select(number);
    return this.intl.t(`mwc.ordinalSuffixes.${category}`);
  }

  /**
   * @summary
   * Displays a time in the guest's local time. Additionally when the vendor's
   * local time doesn't match, displays the difference after in a parenthetical.
   *
   * For dates within a week prior or after the guest's current date, the
   * relative date is shown (I.E. "Last Thursday", "Yesterday", "Today",
   * "Tomorrow", "Tuesday").
   *
   * For all other dates, the full date is used (I.E. Jan 1st 2020).
   *
   * @param {Date} dateTime the date to render
   * @param {string} vendorTimeZone the vendor's IANA time zone name
   * @param {object} [options] options for how to render the date time string
   * @param {string} [options.style=titleCase] the style of letter casing to use
   * for the formatted result. I.E. `'titleCase'` produces "Tomorrow" and
   * `'sentenceCase'` produces "tomorrow". Defaults to `'titleCase'`.
   * @param {boolean} [options.html=false] indicates whether the formatted
   * result should have dates & times wrapped in `<strong>` tags.
   *
   * @returns the formatted date and time
   */
  vendorRelativeDateTime(
    dateTime: Date,
    vendorTimeZone: string,
    { style = 'titleCase', html = false }: VendorRelativeDateTimeOptions = {}
  ): string {
    const localParts = this.getParts(dateTime, this.localTimeZone);

    const vendorParts = this.getParts(dateTime, vendorTimeZone);

    // `dayjs` is reliable for determining the difference between days
    // i.e. '2020-01-01T23:59:59.999-05:00' is "yesterday" when compared to
    // '2020-01-02T00:00:00.000-05:00' in "America/New_York" and "Today" in
    // every time zone that uses a different UTC offset.
    //
    // Unfortunately, dayjs is unreliable for formatting dates, as it produces
    // incorrect results during the transition into Daylight Saving Time.
    //
    // As we already use `dayjs` elsewhere, we can leverage it for determining
    // the format to use, but use the native Intl.DateTimeFormat for formatting
    // to ensure that we receive correct results.
    const delta = dayjs(dateTime)
      .tz(this.localTimeZone)
      .calendar(dayjs(this.now).tz(this.localTimeZone), {
        sameDay: '[today]', // '[Today at] h:mm A z',
        nextDay: '[tomorrow]', // '[Tomorrow at] h:mm A z',
        nextWeek: '[nextWeek]', // 'dddd [at] h:mm A z',
        lastDay: '[yesterday]', // '[Yesterday at] h:mm A z',
        lastWeek: '[lastWeek]', // '[Last] dddd [at] h:mm A z',
        sameElse: '[otherwise]', // 'MMM Do YYYY [at] h:mm A z',
      });

    //           When | Date Format
    // ---------------+-------------------------
    //  previous week | Last {weekday}
    //   previous day | Yesterday
    //       same day | Today
    //       next day | Tomorrow
    //      next week | {weekday}
    // any other time | {MMM Do YYYY}
    const localDate = this.intl.t(`mwc.calendar.relativeDate.${delta}`, {
      style,
      ...localParts,
    });
    // h:mm A z
    const localTime = this.intl.t(`mwc.calendar.relativeTime`, localParts);
    // {date} at {time}
    const localDateTime = this.intl.t(`mwc.calendar.relativeDateTime${html ? 'Html' : ''}`, {
      date: localDate,
      time: localTime,
    });

    // If the local year doesn't match the vendor's year:
    // local date time (vendor date time with year)
    if (localParts.year !== vendorParts.year) {
      return this.intl.t(`mwc.calendar.vendorDifferent${html ? 'Html' : ''}.year`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If the local month/day don't match the vendor's month/day:
    // local date time (vendor date time)
    if (localParts.month !== vendorParts.month || localParts.day !== vendorParts.day) {
      return this.intl.t(`mwc.calendar.vendorDifferent${html ? 'Html' : ''}.date`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If the local hours/minutes don't match the vendor's hours/minutes:
    // local date time (vendor time)
    if (
      localParts.hour !== vendorParts.hour ||
      localParts.minute !== vendorParts.minute ||
      localParts.dayPeriod !== vendorParts.dayPeriod
    ) {
      return this.intl.t(`mwc.calendar.vendorDifferent${html ? 'Html' : ''}.time`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If the local time zone doesn't match the vendor's time zone:
    // local date time (vendor time zone)
    if (localParts.timeZoneName !== vendorParts.timeZoneName) {
      return this.intl.t(`mwc.calendar.vendorDifferent${html ? 'Html' : ''}.timeZone`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If everything is the same:
    // local date time
    return localDateTime;
  }

  /**
   * @summary
   * Very similar to `vendorRelativeDateTime`, this method displays a time in
   * the guest's local time. Additionally when the vendor's local time doesn't
   * match, displays the difference after in a parenthetical.
   *
   * When the date is during the guest's current date, the date portion is
   * omitted (I.E. "12:00 PM EST"). Otherwise the full date and time is used
   * (I.E. "Jan 1st 2020 at 12:00 PM EST").
   *
   * @param {Date} dateTime the date to render
   * @param {string} vendorTimeZone the vendor's IANA time zone name
   */
  checkoutRelativeDateTime(dateTime: Date, vendorTimeZone: string) {
    const localParts = this.getParts(dateTime, this.localTimeZone);

    const vendorParts = this.getParts(dateTime, vendorTimeZone);

    const isToday = dayjs(dateTime)
      .tz(this.localTimeZone)
      .isSame(dayjs(this.now).tz(this.localTimeZone), 'day');

    const localTime = this.intl.t(`mwc.calendar.relativeTime`, localParts);

    let localDateTime: string;
    if (isToday) {
      localDateTime = localTime;
    } else {
      const localDate = this.intl.t(`mwc.calendar.relativeDate.otherwise`, localParts);
      localDateTime = this.intl.t(`mwc.calendar.relativeDateTime`, {
        date: localDate,
        time: localTime,
      });
    }

    // If the local year doesn't match the vendor's year:
    // local date time (vendor date time with year)
    if (localParts.year !== vendorParts.year) {
      return this.intl.t(`mwc.calendar.vendorDifferent.year`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If the local month/day don't match the vendor's month/day:
    // local date time (vendor date time)
    if (localParts.month !== vendorParts.month || localParts.day !== vendorParts.day) {
      return this.intl.t(`mwc.calendar.vendorDifferent.date`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If the local hours/minutes don't match the vendor's hours/minutes:
    // local date time (vendor time)
    if (
      localParts.hour !== vendorParts.hour ||
      localParts.minute !== vendorParts.minute ||
      localParts.dayPeriod !== vendorParts.dayPeriod
    ) {
      return this.intl.t(`mwc.calendar.vendorDifferent.time`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If the local time zone doesn't match the vendor's time zone:
    // local date time (vendor time zone)
    if (localParts.timeZoneName !== vendorParts.timeZoneName) {
      return this.intl.t(`mwc.calendar.vendorDifferent.timeZone`, {
        localDateTime,
        ...vendorParts,
      });
    }

    // If everything is the same:
    // local date time
    return localDateTime;
  }

  /**
   * @summary
   * Gets the date and time relative to now.
   *
   * @param dateTime
   * @param opts
   * @returns
   *
   * @deprecated This method was largely superseded by `vendorRelativeDateTime`
   * as that method also includes the differences in time between the guest's
   * time and the time at the vendor.
   *
   * Additionally this method will produce incorrect results for some edge cases
   * because `dayjs` doesn't correctly format time during some DST transitions
   * (even when the `tz` plugin is used).
   *
   * Remaining usages of this method should be updated to use the native
   * Intl.DateTimeFormat API as it produces correct results.
   */
  relativeDateTime(dateTime: Dayjs, opts?: RelativeDateOptions): string {
    const date = this.relativeDate(dateTime, opts);
    return this.intl.t(`mwc.calendar.timeWanted${opts?.html ? 'Html' : ''}`, {
      date,
      time: dateTime,
    });
  }

  /**
   * @summary
   * Gets the date relative to now.
   *
   * @param dateTime
   * @param opts
   * @returns
   *
   * @deprecated this method will mostly work, however there are still some
   * existing issues due to the lack of explicit time zone names while
   * formatting dates.
   */
  relativeDate(dateTime: Dayjs, opts: RelativeDateOptions = { titleCase: true }): string {
    const dayFormat = {
      sameDay: this.intl.t('mwc.calendar.sameDay', opts),
      nextDay: this.intl.t('mwc.calendar.nextDay', opts),
      nextWeek: this.intl.t('mwc.calendar.nextWeek', opts),
      lastDay: this.intl.t('mwc.calendar.lastDay', opts),
      lastWeek: this.intl.t('mwc.calendar.lastWeek', opts),
      sameElse: this.intl.t('mwc.calendar.sameElse', opts),
    };
    return dateTime.calendar(dayjs(this.now), dayFormat);
  }

  /**
   * @summary
   * Gets the date and time relative to now for the post-checkout page.
   *
   * If the date is today, the date portion is omitted, otherwise it displays
   * the {short month} {ordinal day} {full year}
   *
   * @param dateTime
   * @returns
   *
   * @deprecated This method has been superseded by `checkoutRelativeDateTime`
   */
  checkoutHandoffRelativeDate(dateTime: Dayjs): string {
    return dateTime.isSame(this.now, 'day')
      ? ''
      : dateTime.format(this.intl.t('mwc.calendar.sameElse'));
  }

  /**
   * @summary
   * getParts produces an object of the formatted parts of a given date time
   */
  private getParts(date: Date, timeZone: string): DateTimeParts {
    const formatter = new Intl.DateTimeFormat('en', {
      // 2020
      year: 'numeric',

      // Jan
      month: 'short',

      // 1
      day: 'numeric',

      // Wednesday
      weekday: 'long',

      // 1
      hour: 'numeric',

      // enforce using AM/PM for dayPeriod
      hour12: true,

      // 00
      minute: '2-digit',

      // EST
      timeZoneName: 'short',

      timeZone,
    });

    const entries = formatter
      .formatToParts(date)
      // exclude literals (i.e. commas, spaces, and other punctuation)
      .filter(part => part.type !== 'literal')
      // convert parts to entries
      .map(part =>
        part.type === 'day'
          ? // add the ordinal suffix to day values
            ['day', `${part.value}${this.ordinal(+part.value)}`]
          : [part.type, part.value]
      );

    return Object.fromEntries(entries) as DateTimeParts;
  }
}

declare module '@ember/service' {
  interface Registry {
    'mwc-intl': MwcIntlService;
  }
}
