import a0 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "YNHwrkTZ",
  "block": "[[[41,[30,1,[\"icon\"]],[[[1,\"  \"],[10,1],[15,0,[30,0,[\"style\",\"icon\"]]],[14,\"data-test-popover-item\",\"icon\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@ariaLabel\",\"@icon\"],[\"\",[30,1,[\"icon\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-popover-item\",\"label\"],[12],[1,\"\\n  \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"],[13]],[\"@model\"],false,[\"if\",\"svg\"]]",
  "moduleName": "mobile-web/components/popover-item/index.hbs",
  "isStrictMode": false
});