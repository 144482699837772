export default {
  "header": "_header_1tksyx _header_ykti7y",
  "headerText": "_headerText_1tksyx",
  "orderTime": "_orderTime_1tksyx",
  "headerButton": "_headerButton_1tksyx",
  "productsContainer": "_productsContainer_1tksyx",
  "productList": "_productList_1tksyx",
  "notifications": "_notifications_1tksyx",
  "content": "_content_1tksyx",
  "icon": "_icon_1tksyx",
  "iconLg": "_iconLg_1tksyx",
  "emptyCart": "_emptyCart_1tksyx",
  "emptyText": "_emptyText_1tksyx",
  "minimums": "_minimums_1tksyx",
  "minimumMessage": "_minimumMessage_1tksyx",
  "footer": "_footer_1tksyx",
  "checkoutButtonContainer": "_checkoutButtonContainer_1tksyx",
  "checkoutButton": "_checkoutButton_1tksyx",
  "debugButton": "_debugButton_1tksyx",
  "debugInput": "_debugInput_1tksyx"
};
