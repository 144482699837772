import a0 from "../notification/index.ts";
window.define('mobile-web/components/notification', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "aSMvGxMH",
  "block": "[[[11,0],[16,0,[30,0,[\"style\",\"notifications\"]]],[17,1],[24,\"data-test-notifications\",\"\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"notifications\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@notification\",\"@testMode\"],[[30,2],[30,3]]],null],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"notification\",\"@testMode\"],false,[\"each\",\"-track-array\",\"notification\"]]",
  "moduleName": "mobile-web/components/notifications/index.hbs",
  "isStrictMode": false
});