import Component from '@ember/component';
import { computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { later } from '@ember/runloop';
import { htmlSafe } from '@ember/template';

import { tagName } from '@ember-decorators/component';

import { HEADER_ID } from 'mobile-web/components/header';
import scroll from 'mobile-web/lib/scroll';
import isSome from 'mobile-web/lib/utilities/is-some';

import style from './index.m.scss';

/**
 * We need a delay because the animations take time to complete; for accurate calculations,
 * we need the animations to be done so that the page length is settled.
 * We got this number by experimentation; it's fast enough to not be noticeable,
 * but slow enough that the page length is correct.
 */
const MUTATION_OBSERVER_DELAY = 100;

@tagName('')
export default class StickyElement extends Component {
  // Service injections

  // Required arguments

  // Optional arguments
  /**
   * scrollableContainers do not work entirely right when the container has a visible scrollbar.
   * The size changes from the scrollbar mess up the math in a way we didn't figure out.
   * If you are using this functionality, make sure to test it in Windows, which has always visible scrollbars.
   */
  scrollableContainer?: HTMLElement;
  class = '';
  placeholderClass = '';
  disabled = false;

  // Class fields
  onScroll!: Action;
  scrollLengthObserver?: MutationObserver;
  isSticky: false | 'bottom' | 'top' = false;
  stickyStyle!: string;
  style = style;

  // Computed properties
  @computed('class', 'isSticky', 'scrollableContainer')
  get stickyElementClass() {
    const isStickyClass =
      this.isSticky === 'bottom'
        ? style.isStickyBottom
        : this.isSticky === 'top'
        ? style.isStickyTop
        : '';
    const absoluteClass = this.isSticky && isSome(this.scrollableContainer) ? style.absolute : '';
    return [style.stickyElement, isStickyClass, absoluteClass, this.class].join(' ');
  }

  get uniqueId(): string {
    return guidFor(this);
  }

  // Init (remove if not needed)
  init() {
    super.init();

    // asserts for required properties
  }

  // Lifecycle methods
  // eslint-disable-next-line ember/no-component-lifecycle-hooks,  ember/require-super-in-lifecycle-hooks
  didInsertElement() {
    this.setSticky();
    this.onScroll = scroll(() => {
      this.setSticky();
    });

    this.scrollLengthObserver = new MutationObserver(() => {
      later(this.onScroll, MUTATION_OBSERVER_DELAY);
    });
    const observedElement = isSome(this.scrollableContainer) ? this.scrollableContainer : document;
    this.scrollLengthObserver.observe(observedElement, { childList: true, subtree: true });

    (this.scrollableContainer || window).addEventListener('scroll', this.onScroll);
  }

  // eslint-disable-next-line ember/no-component-lifecycle-hooks,  ember/require-super-in-lifecycle-hooks
  willDestroyElement() {
    if (isSome(this.scrollLengthObserver)) {
      this.scrollLengthObserver.disconnect();
    }

    (this.scrollableContainer || window).removeEventListener('scroll', this.onScroll);
  }

  // Other methods
  setSticky() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (this.disabled) {
      this.set('isSticky', false);
      return;
    }

    /** This element occupies the space that the sticky content should slot into. */
    const placeholderElement = document.getElementById(this.uniqueId)!;
    const stickyElement = placeholderElement.children[0] as HTMLElement;
    const placeholderBottom = placeholderElement.offsetTop + stickyElement.offsetHeight;

    /**
     * This only matters if the scrollable container is the window
     * because only in that case do we have to account for the height of the app header.
     */
    const headerElement = isSome(this.scrollableContainer)
      ? undefined
      : document.getElementById(HEADER_ID);
    const headerHeight = headerElement ? headerElement.offsetHeight : 0;
    const finalBottom = placeholderBottom + headerHeight;

    /**
     * We need scrollTop separate because document.body.scrollTop doesn't work.
     * We need window.scrollY here, but everything else works from document.body.
     */
    const scrollTop = isSome(this.scrollableContainer)
      ? this.scrollableContainer.scrollTop
      : window.scrollY;
    const scrollableContainer = isSome(this.scrollableContainer)
      ? this.scrollableContainer
      : document.body;
    const scrollContainerVisibleHeight = window.innerHeight - scrollableContainer.offsetTop;
    const scrollContainerVisibleBottom = scrollTop + scrollContainerVisibleHeight;
    const isAtBottom = scrollContainerVisibleBottom === scrollableContainer.scrollHeight;

    const isAtTop = window.scrollY >= placeholderElement.offsetTop;

    if (scrollContainerVisibleBottom < finalBottom && !isAtBottom) {
      this.setProperties({
        isSticky: 'bottom',
        stickyStyle: htmlSafe(''),
      });
    } else if (isAtTop) {
      this.setProperties({
        isSticky: 'top',
        stickyStyle: htmlSafe(`top: ${headerHeight}px`),
      });
    } else {
      this.setProperties({
        isSticky: false,
        stickyStyle: htmlSafe(''),
      });
    }
  }

  // Actions
}
