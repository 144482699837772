import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import ENV from 'mobile-web/config/environment';
import { Code } from 'mobile-web/lib/country';
import { allStatesWithCountry } from 'mobile-web/lib/state';
import ChannelService from 'mobile-web/services/channel';

export default class LocationsRoute extends Route {
  @service channel!: ChannelService;
  @service router!: RouterService;

  beforeModel(transition: Transition) {
    if (!this.channel.settings?.showParticipatingRestaurants) {
      transition.abort();
      this.router.transitionTo('index');
    }
  }

  model() {
    const modelRegions = this.store.findAll('region', {});
    const map = modelRegions.then(async regions => {
      const countries = new Set<Code>();
      const allStates = allStatesWithCountry();
      regions.forEach(region => countries.add(allStates[region.id].countryCode));
      const mapSrc = `${ENV.scriptBaseUrl}assets/images/locations-map/${Array.from(countries)
        .sort()
        .join('-')}.svg`;
      try {
        const r = await fetch(mapSrc);
        if (!r.ok) {
          return '';
        }

        const markup = await r.text();
        const fragment = document.createRange().createContextualFragment(markup);
        const svg = fragment.querySelector('svg');
        return svg?.outerHTML ?? '';
      } catch (e) {
        // Allows loading of route despite failed non-essential asset load.
        return '';
      }
    });
    return RSVP.hash({ regions: modelRegions, map });
  }
}
