import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import IntlService from 'ember-intl/services/intl';

import { Breadcrumb } from 'mobile-web/components/breadcrumbs';
import CategoryModel from 'mobile-web/models/category';
import ContentService from 'mobile-web/services/content';
import FeaturesService from 'mobile-web/services/features';
import VendorService from 'mobile-web/services/vendor';

export type Model = {
  vendor_slug: string;
  singleUseCategories: CategoryModel[];
  heading: string;
  breadcrumbs: Breadcrumb[];
};

export default class SingleUseRoute extends Route {
  @service content!: ContentService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service vendor!: VendorService;

  async model({ vendor_slug }: { vendor_slug: string }): Promise<Model> {
    const heading = await this.content.getEntry(
      'RESP_SINGLE_USE_ITEMS_HEADER',
      'mwc.singleUseItems.header'
    );

    const vendor = await this.vendor.ensureVendorLoaded(vendor_slug);
    const singleUseCategories = vendor.singleUseCategories;

    const breadcrumbs = [
      {
        label: this.intl.t('mwc.singleUseItems.returnToCart'),
        route: 'menu.vendor',
        models: [vendor_slug],
        query: { openBasket: true },
      },
      { label: heading },
    ];

    return {
      vendor_slug,
      singleUseCategories,
      breadcrumbs,
      heading,
    };
  }

  async afterModel(model: ModelForRoute<SingleUseRoute>) {
    const noSingleUseCategories = isEmpty(model.singleUseCategories);
    const allSingleUseCategoriesEmpty = model.singleUseCategories.every((suc: CategoryModel) =>
      isEmpty(suc.products)
    );

    if (noSingleUseCategories || allSingleUseCategoriesEmpty) {
      this.router.replaceWith('menu.vendor', model.vendor_slug);
    }
  }
}
