import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Service from '@ember/service';

import { enqueueTask, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import { SECONDS } from 'mobile-web/lib/time/durations';

import ScrollService from './scroll';

type ActivateCallback = () => Promise<void>;

export default class MenuCategoryActivationService extends Service {
  // Service injections
  @service scroll!: ScrollService;

  // Untracked properties
  private readonly activationRegistry = new WeakMap<Element, ActivateCallback>();
  readonly activationObserver = new IntersectionObserver(this.activateCategories);

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods
  /**
   * Adds the category and activation callback to a map as a registry to be used
   * for the intersection observer
   * @param categoryElement
   * @param activateCallback
   */
  register(categoryElement: Element, activateCallback: ActivateCallback) {
    this.activationRegistry.set(categoryElement, activateCallback);
  }

  /**
   * Returns a promise that resolves after the specified time has elapsed.
   *
   * @param duration how long to wait before resolving the promise
   * @returns the promise
   */
  delay(duration: number) {
    return new Promise<void>(res => later(res, duration));
  }

  // Tasks
  /**
   * Activates each menu category in the order that they're queued.
   */
  activateMenuCategoryTask = taskFor(this.activateMenuCategoryTaskInstance);
  @enqueueTask *activateMenuCategoryTaskInstance(
    activationCallback: ActivateCallback
  ): TaskGenerator<void> {
    if (this.scroll.isAutoScrolling) {
      yield this.scroll.scrollStop();
    }
    yield Promise.race([activationCallback(), this.delay(1 * SECONDS)]);
  }

  // Actions
  /**
   * Proactively activates categories that are scrolled into view so that the
   * on screen categories are prioritized over the `activateMenuCategoryTask`
   * queue.
   *
   * @param entries
   */
  @action
  activateCategories(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const activateCallback = this.activationRegistry.get(entry.target);
        activateCallback?.();
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'menu-category-activation': MenuCategoryActivationService;
  }
}
