import a0 from "../../../components/routes/menu/vendor/single-use-route/index.ts";
window.define('mobile-web/components/routes/menu/vendor/single-use-route', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "IQbvSKhW",
  "block": "[[[8,[39,0],null,[[\"@heading\",\"@breadcrumbs\",\"@singleUseCategories\"],[[30,1,[\"heading\"]],[30,1,[\"breadcrumbs\"]],[30,1,[\"singleUseCategories\"]]]],null]],[\"@model\"],false,[\"routes/menu/vendor/single-use-route\"]]",
  "moduleName": "mobile-web/templates/menu/vendor/single-use.hbs",
  "isStrictMode": false
});