import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { isEmpty } from '@ember/utils';

import CustomLinkModel from 'mobile-web/models/custom-link';

export default class AdditionalInfoRoute extends Route {
  @service router!: RouterService;

  model(params: { linkTitle: string }): Promise<CustomLinkModel> {
    const firstMatch = (link: CustomLinkModel) =>
      params.linkTitle === dasherize(link.linkLabelText);

    return this.store
      .findAll('custom-link')
      .then(links => links.toArray())
      .then(links => links.find(firstMatch))
      .then(matchingLink => {
        if (!matchingLink || !isEmpty(matchingLink.url)) {
          this.router.transitionTo('application');
          // This technically returns the wrong thing, but because the model
          // will never resolve (courtesy of the `transitionTo` immediately
          // above), it comes out correct; the semantics here are "abort" rather
          // than proper return.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return undefined as any;
        }

        return matchingLink;
      });
  }

  serialize(model: CustomLinkModel) {
    return { linkTitle: dasherize(model.linkLabelText) };
  }
}
