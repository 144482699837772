import { run } from '@ember/runloop';

import isSome from 'mobile-web/lib/utilities/is-some';

export enum Key {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  Escape = 'Escape',
  PageDown = 'PageDown',
  Tab = 'Tab',
  Space = 'Space',
}

export const KEY_CODES: { [K in Key]: number } = {
  ArrowUp: 38,
  ArrowDown: 40,
  Enter: 13,
  Escape: 27,
  PageDown: 34,
  Tab: 9,
  Space: 32,
};

export const TOGGLE_KEYS: Key[] = [Key.Enter, Key.Space];
export const TOGGLE_KEY_CODES: number[] = [KEY_CODES.Enter, KEY_CODES.Space];

type IsToggleKey = (keyEvent: KeyboardEvent) => boolean;
export const isToggleKey: IsToggleKey = keyEvent =>
  (isSome(keyEvent.key) && TOGGLE_KEYS.includes(keyEvent.key as Key)) ||
  (isSome(keyEvent.keyCode) && TOGGLE_KEY_CODES.includes(keyEvent.keyCode)) ||
  (isSome(keyEvent.which) && TOGGLE_KEY_CODES.includes(keyEvent.which));

type IsKey = (key: Key, keyEvent: KeyboardEvent) => boolean;
export const isKey: IsKey = (key: Key, keyEvent) =>
  (isSome(keyEvent.key) && keyEvent.key === Key[key]) ||
  (isSome(keyEvent.keyCode) && keyEvent.keyCode === KEY_CODES[key]) ||
  (isSome(keyEvent.which) && keyEvent.which === KEY_CODES[key]);

type EventHandler<R> = (...args: unknown[]) => R;

export const bindActionToKeyEvent = <R>(key: Key, handler: EventHandler<R>) => (
  keyEvent: KeyboardEvent
): R | KeyboardEvent => (isKey(key, keyEvent) ? run(handler) : keyEvent);
