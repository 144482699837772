import { computed, set } from '@ember/object';
import { none, alias } from '@ember/object/computed';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Store from 'ember-data/store';

import Vendor from 'mobile-web/models/vendor';
import BasketService from 'mobile-web/services/basket';
import StorageService from 'mobile-web/services/storage';

export default class VendorService extends Service {
  // Service injections
  @service basket!: BasketService;
  @service router!: RouterService;
  @service storage!: StorageService;
  @service store!: Store;

  // Untracked properties
  _vendor?: Vendor;

  // Tracked properties

  // Getters and setters
  @computed('_vendor')
  get vendor(): Vendor | undefined {
    return this._vendor;
  }
  set vendor(value: Vendor | undefined) {
    set(this, '_vendor', value);
    if (value) {
      this.storage.set('lastVendorSlug', value.slug);
    }
  }

  @none('vendor')
  vendorIsNotLoaded!: boolean;

  @alias('vendor.currency')
  currency?: string;

  @alias('vendor.slug')
  vendorSlug?: string;

  // Constructor
  constructor() {
    super(...arguments);

    this.router.on('routeDidChange', () => {
      const targetName = this.router.currentRouteName;

      const isMenuRoute = targetName.indexOf('menu') > -1;
      const isRewardsSearchRoute = targetName === 'rewards-search';
      const isThankYouRoute = targetName === 'thank-you';

      if (!isMenuRoute && !isRewardsSearchRoute && !isThankYouRoute && isNone(this.basket.basket)) {
        if (this.vendor) {
          set(this, 'vendor', undefined);
        }
      }
    });
  }

  // Other methods
  async ensureVendorLoaded(vendorSlug: string): Promise<Vendor> {
    const slug = vendorSlug || this.vendorSlug;
    const currentVendor = this.vendor;

    const setupVendor = (vendor: Vendor) => {
      if (this.storage.lastVendorSlug !== slug) {
        this.storage.ignorePrecheckError = false;
      }

      this.basket.onVendorUpdate(vendor.id);
      set(this, 'vendor', vendor);

      return vendor;
    };

    const store = this.store;

    let vendor: Vendor | undefined;
    if (!isNone(currentVendor) && currentVendor.slug === slug) {
      vendor = await store.findRecord('vendor', currentVendor.id);
    }
    if (!vendor) {
      vendor = store.peekAll('vendor').find(v => v.slug === slug);
    }

    // Check to make sure that the vendor has its products and catogories loaded
    // because side loading vendors via orders may cause some of the data to be missing locally
    // vendor.hasMany('products').ids().length === 0
    if (!vendor || vendor.hasMany('products').ids().length === 0) {
      vendor = await store.queryRecord('vendor', { slug });
    }
    return setupVendor(vendor);
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    vendor: VendorService;
  }
}
