import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a1;
});
import a2 from "../subsection/index.ts";
window.define('mobile-web/components/post-checkout/subsection', function () {
  return a2;
});
import a3 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a3;
});
import a4 from "../../facebook-share-button/index.ts";
window.define('mobile-web/components/facebook-share-button', function () {
  return a4;
});
import a5 from "../../twitter-share-button/index.ts";
window.define('mobile-web/components/twitter-share-button', function () {
  return a5;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "+qSNSu38",
  "block": "[[[11,\"aside\"],[16,\"title\",[28,[37,0],[\"mwc.postCheckout.shareOrderLabel\"],null]],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-post-checkout-share-section\",\"\"],[17,1],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@key\",\"@substitutions\"],[\"THANKS_SOCIALTEXT\",[30,0,[\"substitutions\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[[50,\"svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"quote\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n        \"],[10,2],[12],[1,[30,2,[\"value\"]]],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[30,3,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,4],[[16,0,[30,3,[\"buttonClass\"]]]],[[\"@link\",\"@quote\"],[[30,4],[30,2,[\"value\"]]]],null],[1,\"\\n\\n\"],[1,\"          \"],[8,[39,5],[[16,0,[30,3,[\"buttonClass\"]]]],[[\"@link\",\"@quote\"],[[30,4],[30,2,[\"value\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[3]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"content\",\"s\",\"@socialLink\"],false,[\"t\",\"x-content\",\"post-checkout/subsection\",\"component\",\"facebook-share-button\",\"twitter-share-button\"]]",
  "moduleName": "mobile-web/components/post-checkout/share-section/index.hbs",
  "isStrictMode": false
});