import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { isEmpty } from '@ember/utils';

import { tagName, attribute } from '@ember-decorators/component';

import StickyNav from 'mobile-web/components/sticky-nav';
import { isSome } from 'mobile-web/lib/utilities/is-some';

import style from './index.m.scss';

export function sectionId(sectionElement: Element) {
  return sectionElement.getAttribute('data-section-id');
}

// eslint-disable-next-line ember/require-tagless-components
@tagName('div')
export default class StickyNavSection extends Component {
  // Service injections

  // Required arguments
  stickyNav!: StickyNav;
  title!: string;

  // Optional arguments
  isHidden = false;

  // Class fields
  style = style;

  // Computed properties
  @attribute tabindex = -1; // needs tabindex to have a value in order to focus - see MWC-3294

  @attribute('data-test-stickyNav-section')
  @attribute('data-section-id')
  @computed('elementId', 'title')
  get id() {
    // Some titles have double quotes, and that breaks when doing a querySelector call.
    return dasherize(this.title + this.elementId).replace(/"/g, '');
  }

  get element(): HTMLElement {
    return document.getElementById(this.elementId)!;
  }

  // Init
  init() {
    super.init();

    assert('`stickyNav` is required', isSome(this.stickyNav));
    assert('`title` is required', !isEmpty(this.title));
  }

  // eslint-disable-next-line ember/require-super-in-lifecycle-hooks, ember/no-component-lifecycle-hooks
  didInsertElement() {
    this.stickyNav.registerSection(this);
  }

  // Other methods

  // Actions
}
