export default {
  "loading": "_loading_1mzx8q",
  "minor": "_minor_1mzx8q",
  "major": "_major_1mzx8q",
  "content": "_content_1mzx8q",
  "label": "_label_1mzx8q",
  "labelText": "_labelText_1mzx8q",
  "iconWrapper": "_iconWrapper_1mzx8q",
  "icon": "_icon_1mzx8q"
};
