import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import FeaturesService from 'mobile-web/services/features';

import style from './index.m.scss';

type Crumb = {
  label: string;
};

type LinkableCrumb = {
  label: string;
  route: string;
  query?: Record<string, unknown>;
  models?: Array<string | number>;
};

export type Breadcrumb = Crumb | LinkableCrumb;

function isLinkableCrumb(breadcrumb: Breadcrumb): breadcrumb is LinkableCrumb {
  return 'route' in breadcrumb;
}

interface Signature {
  Element: HTMLDivElement;

  Args: {
    // Required arguments
    breadcrumbs: Breadcrumb[];

    // Optional arguments
  };
}

export default class Breadcrumbs extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get backCrumb(): LinkableCrumb {
    return this.args.breadcrumbs.filter(isLinkableCrumb).pop()!;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  breadcrumbTapped(event: Event) {
    const element = event.currentTarget as HTMLAnchorElement;

    this.analytics.trackEvent(AnalyticsEvents.BreadcrumbTapped, () => ({
      [AnalyticsProperties.Label]: element.innerText,
      [AnalyticsProperties.LinkLocation]: `${element.pathname}${element.search}${element.hash}`,
    }));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Breadcrumbs: typeof Breadcrumbs;
  }
}
