import Route from '@ember/routing/route';

import authenticated from 'mobile-web/decorators/authenticated';

@authenticated
export default class Favorites extends Route {
  model() {
    return this.store.query('favorite', {});
  }
}
