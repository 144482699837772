import a0 from "../../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a0;
});
import a1 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a1;
});
import a2 from "../positioned-container/index.ts";
window.define('mobile-web/components/modal-dialog/positioned-container', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "wbQoNdGo",
  "block": "[[[40,[[[1,\"  \"],[11,0],[16,0,[30,1]],[4,[38,2],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n\"],[1,\"    \"],[11,0],[16,1,[30,2]],[16,0,[28,[37,3],[[30,0,[\"style\",\"overlay\"]],[30,3]],null]],[24,\"tabindex\",\"-1\"],[4,[38,4],[\"mousedown\",[30,0,[\"onClickOverlay\"]]],null],[12],[1,\"\\n\"],[1,\"      \"],[8,[39,5],[[16,1,[30,4]],[16,0,[28,[37,3],[[30,0,[\"style\",\"modal\"]],[30,5]],null]],[17,6]],null,[[\"default\"],[[[[1,\"\\n        \"],[18,7,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[30,0,[\"destinationElement\"]]],null],null]],[\"@wrapperClass\",\"@overlayId\",\"@overlayClass\",\"@modalId\",\"@containerClass\",\"&attrs\",\"&default\"],false,[\"in-element\",\"-in-el-null\",\"did-insert\",\"classes\",\"on\",\"modal-dialog/positioned-container\",\"yield\"]]",
  "moduleName": "mobile-web/components/modal-dialog/basic-dialog/index.hbs",
  "isStrictMode": false
});