import { isNone } from '@ember/utils';

import { MaskFunction, PipeFunction } from 'ember-text-mask';

import { range } from 'mobile-web/lib/utilities/_';

export enum Type {
  VISA = 'VISA',
  Discover = 'Discover',
  Mastercard = 'Mastercard',
  AMEX = 'AMEX',
  Unknown = 'Unknown',
}

type LeadingStrings = string[];

type Rule = [Type, LeadingStrings[]];

/** Generate an inclusive range of numbers converted to strings. */
const rangeOfStrings = (start: number, end: number): string[] =>
  range(start, end + 1).map(v => v.toString());

const RULES: Rule[] = [
  [Type.VISA, [['4'], ['50'], rangeOfStrings(56, 58)]],
  [
    Type.Discover,
    [['6011'], rangeOfStrings(622126, 622925), rangeOfStrings(644, 649), ['65'], ['8']],
  ],
  [
    Type.Mastercard,
    [['2'], ['67'], ['97'], rangeOfStrings(51, 55), ['6004'], ['6008'], ['639'], ['67']],
  ],
  [Type.AMEX, [['34'], ['37']]],
];

const cardMatchesRule =
  (card: string) =>
  (leadingStrings: LeadingStrings): boolean =>
    leadingStrings.some(leadingString => card.startsWith(`${leadingString}`));

const toMatchingType =
  (card: string) =>
  (matched: Type | undefined, [type, leadingStrings]: Rule) =>
    matched ?? (leadingStrings.some(cardMatchesRule(card)) ? type : undefined);

export function detectType(card?: string | null): Type | undefined {
  return isNone(card)
    ? undefined
    : RULES.reduce(toMatchingType(card.replace(/\s/g, '')), undefined);
}

export function matchBrandWithType(brand: string): Type {
  switch (brand) {
    case 'visa':
      return Type.VISA;
    case 'mastercard':
      return Type.Mastercard;
    case 'amex':
      return Type.AMEX;
    case 'discover':
      return Type.Discover;
    default:
      return Type.Unknown;
  }
}

// Input Masking types for credit card forms
const DEFAULT_CC_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const AMEX_CC_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const ccMask: MaskFunction = rawValue =>
  detectType(rawValue) === Type.AMEX ? AMEX_CC_MASK : DEFAULT_CC_MASK;
export const expMask: MaskFunction = rawValue => {
  // These 3 special cases are to handle different autofill/paste possibilities
  if (/^\d{2}\/\d{4}$/.test(rawValue)) {
    return [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  }
  if (/^\d{1}\/\d{4}$/.test(rawValue)) {
    return ['0', /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  }
  if (/^\d{1}\/\d{2}$/.test(rawValue)) {
    return ['0', /\d/, '/', /\d/, /\d/];
  }
  return [/\d/, /\d/, '/', /\d/, /\d/];
};
export const expPipe: PipeFunction = conformedValue => {
  if (/^\d{2}\/\d{4}$/.test(conformedValue)) {
    const [month, year] = conformedValue.split('/');
    return `${month}/${year.slice(2)}`;
  }

  if (conformedValue.length === 1 && Number(conformedValue) > 1) {
    return {
      value: `0${conformedValue}`,
      indexesOfPipedChars: [0],
    };
  }

  return conformedValue;
};

const Card = {
  detectType,
  Type,
};

export default Card;
