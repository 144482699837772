import Route from '@ember/routing/route';

import authenticated from 'mobile-web/decorators/authenticated';

@authenticated
export default class RecentOrderRoute extends Route {
  model() {
    return this.store.findAll('order-search-result');
  }
}
