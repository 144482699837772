import ArrayProxy from '@ember/array/proxy';
import { action, set, setProperties, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import { Segment } from 'mobile-web/components/segmented-control';
import Address from 'mobile-web/lib/location/address';
import SavedAddressModel from 'mobile-web/models/address';

import style from './index.m.scss';

/**
 * The editing modes of the form.
 * Only relevant when there are saved addresses.
 */
export enum Mode {
  /** The model represents a new address. */
  AddNew = 'addNew',
  /** The modle represents changes to an existing address. */
  UseExisting = 'useExisting',
}

export type Model = Address & {
  id: EmberDataId;
  mode: Mode;
};

type AddressOption = {
  label: string;
  addressId: EmberDataId;
};

function makeAddressOptions(addresses: SavedAddressModel[]): AddressOption[] {
  return addresses.map(a => ({
    label: Address.toLabel(a),
    addressId: a.id,
  }));
}

const modeSegments: Segment<Mode>[] = [
  { label: 'Use Existing', value: Mode.UseExisting },
  { label: 'Add New', value: Mode.AddNew },
];

interface Args {
  // Required arguments
  addresses: ArrayProxy<SavedAddressModel>;
  /* Mutated. Callers should make a copy of the model object before passing in. */
  model: Model;
  name: string;

  // Optional arguments
}

export default class AddressForm extends Component<Args> {
  // Service injections
  @service intl!: IntlService;

  // Untracked properties
  modeSegments = modeSegments;
  style = style;

  // Tracked properties

  // Getters and setters
  @computed('args.model.mode')
  get addNew(): boolean {
    return this.args.model.mode === Mode.AddNew;
  }

  @computed('args.model.mode', 'hasAddresses')
  get useExisting(): boolean {
    return this.hasAddresses && this.args.model.mode === Mode.UseExisting;
  }

  get savedAddresses(): SavedAddressModel[] {
    // It is possible to get an address in the saved address list without an id.
    // I don't know how, but it is. So yeah, we gotta deal with that...
    return this.args.addresses.filter(a => !!a.id);
  }

  get addressOptions(): AddressOption[] {
    return makeAddressOptions(this.savedAddresses);
  }

  get hasAddresses(): boolean {
    return this.savedAddresses.length > 0;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  onModeChange(segment: Segment<Mode>) {
    const mode = segment.value;
    const firstAddress = this.savedAddresses[0];
    const addressId = mode === Mode.UseExisting && firstAddress ? firstAddress.id.toString() : '';

    this.resetModel(addressId);
    set(this.args.model, 'mode', mode);
  }

  @action
  resetModel(addressId: string) {
    const existing = this.savedAddresses.find(a => a.id.toString() === addressId);

    setProperties(this.args.model, {
      id: addressId,
      streetAddress: existing?.streetAddress ?? '',
      building: existing?.building ?? '',
      city: existing?.city ?? '',
      zipCode: existing?.zipCode ?? '',
    });
  }
}
