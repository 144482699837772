import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import QualifyingLoyaltyRewardModel from 'mobile-web/models/qualifying-loyalty-reward';
import Vendor from 'mobile-web/models/vendor';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChallengeService from 'mobile-web/services/challenge';
import ErrorService from 'mobile-web/services/error';

import style from './index.m.scss';

interface Args {
  // Required arguments
  reward: QualifyingLoyaltyRewardModel;
  vendor: Vendor;

  // Optional arguments
  onChange?: (undo: Action<void>) => void;
  showScheme?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class RewardCard extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service challenge!: ChallengeService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service store!: DS.Store;

  // Untracked properties
  ids = guids(this, 'label');
  style = style;

  // Tracked properties

  // Getters and setters
  get removeDiscountTitle(): string {
    return this.basket.basket!.hasCoupon
      ? 'mwc.rewardCard.removeCouponTitle'
      : 'mwc.rewardCard.removeRewardTitle';
  }

  get removeDiscountText(): string {
    return this.basket.basket!.hasCoupon
      ? 'mwc.rewardCard.removeCouponText'
      : 'mwc.rewardCard.removeRewardText';
  }

  // Constructor

  // Other methods

  // Tasks
  applyRewardTask = taskFor(this.applyRewardTaskFn);
  @task *applyRewardTaskFn(): TaskGenerator<void> {
    yield taskFor(this.challenge.request).perform(async () => {
      if (!this.basket.basket) {
        await this.basket.createBasket(this.args.vendor);
      }
      await this.basket.basket!.applyReward({
        membershipId: this.args.reward.membershipId,
        reference: this.args.reward.externalReference,
      });
      this.args.reward.set('isApplied', true);
      const basket = this.basket.basket!;
      try {
        await basket.validate();
      } catch (e) {
        this.error.sendUserMessage(e);
        this.removeRewardTask.perform();
      }
    });
  }

  removeRewardTask = taskFor(this.removeRewardTaskFn);
  @task *removeRewardTaskFn(): TaskGenerator<void> {
    const basket = this.basket.basket!;
    const reward = basket.reward;
    if (isSome(reward)) {
      yield basket.removeReward({
        membershipId: reward.id,
        reference: reward.externalReference,
      });
      if (this.args.reward.externalReference === reward.externalReference) {
        this.args.reward.set('isApplied', false);
      } else {
        // Sorry
        const oldReward = this.store
          .peekAll('qualifying-loyalty-reward')
          .find(r => r.externalReference === reward.externalReference);
        if (isSome(oldReward)) {
          oldReward.set('isApplied', false);
        }
      }
    }
  }

  removeCouponTask = taskFor(this.removeCouponTaskFn);
  @task *removeCouponTaskFn(): TaskGenerator<void> {
    yield this.basket.basket!.removeCoupon();
  }

  @task *removeAndApplyTask(): TaskGenerator<void> {
    const basket = this.basket.basket!;
    yield basket.hasCoupon ? this.removeCouponTask.perform() : this.removeRewardTask.perform();
    yield this.applyRewardTask.perform();
  }

  // Actions
  @action
  applyReward() {
    const basket = this.basket.basket;
    if (isSome(basket) && (basket.hasCoupon || basket.hasReward)) {
      this.bus.trigger('confirm', {
        title: this.intl.t(this.removeDiscountTitle),
        content: this.intl.t(this.removeDiscountText),
        buttonLabel: this.intl.t('mwc.rewardCard.confirmRemoveDiscountButton'),
        cancelLabel: this.intl.t('mwc.rewardCard.cancelRemoveDiscountButton'),
        onConfirm: () => taskFor(this.removeAndApplyTask).perform(),
        testSelector: 'removeDiscount',
        buttonTestSelector: 'confirmRemoveDiscount',
        cancelTestSelector: 'cancelRemoveDiscount',
      });
    } else {
      this.applyRewardTask.perform();
    }
  }
}
