import Component from '@glimmer/component';

import { CustomizedChoiceModel } from 'mobile-web/lib/menu';
import isSome from 'mobile-web/lib/utilities/is-some';
import { isBasketChoice } from 'mobile-web/models/basket-choice';

import style from './index.m.scss';

interface Args {
  // Required arguments
  basketChoice: CustomizedChoiceModel;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class CartChoice extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get customFieldValue(): string {
    if (isBasketChoice(this.args.basketChoice)) {
      const basketProduct = this.args.basketChoice.basketProduct;
      // Even though the types indicate that basketProduct is always set,
      // there is one scenario where it is possible it isn't - if we are
      // removing a product, we might get here before the component is
      // removed. So, a quick sanity check keeps us from null crashing
      // into a wall of tears and sadness.
      if (isSome(basketProduct)) {
        const matchingValues = basketProduct.customValues.filter(
          c =>
            isBasketChoice(this.args.basketChoice) &&
            c.choiceId.toString() === this.args.basketChoice.belongsTo('choice').id()
        );
        return matchingValues.length ? matchingValues[0].value : '';
      }
    }
    return '';
  }

  get quantity(): string {
    return isBasketChoice(this.args.basketChoice) &&
      this.args.basketChoice.savedAttributes.quantity > 1
      ? `${this.args.basketChoice.savedAttributes.quantity}`
      : '';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::Choice': typeof CartChoice;
  }
}
