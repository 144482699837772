import a0 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import a1 from "../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a1;
});
import a2 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a2;
});
import a3 from "../../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "ewSyiM8n",
  "block": "[[[41,[30,0,[\"showBanner\"]],[[[1,\"  \"],[11,0],[16,0,[30,0,[\"style\",\"wrapper\"]]],[24,\"data-test-cartSingleUseBanner\",\"\"],[17,1],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"restaurant-menu\"]],null],[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"style\",\"headerGroup\"]]],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@defaultMessage\",\"@key\"],[[28,[37,3],[\"mwc.singleUseItems.header\"],null],\"RESP_SINGLE_USE_ITEMS_HEADER\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"h3\"],[14,\"data-test-cartSingleUseBanner-header\",\"\"],[15,0,[30,0,[\"style\",\"header\"]]],[12],[1,[30,2,[\"value\"]]],[13],[1,\"\\n      \"]],[2]]]]],[1,\"\\n      \"],[8,[39,2],null,[[\"@defaultMessage\",\"@key\"],[[28,[37,3],[\"mwc.singleUseItems.bannerSubheader\"],null],\"RESP_SINGLE_USE_ITEMS_BANNER_SUBHEADER\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,2],[14,\"data-test-cartSingleUseBanner-subheader\",\"\"],[15,0,[30,0,[\"style\",\"subheader\"]]],[12],[1,[30,3,[\"value\"]]],[13],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],[[16,0,[30,0,[\"style\",\"addLink\"]]],[24,\"data-test-cartSingleUseBanner-addLink\",\"\"],[4,[38,5],[\"click\",[30,0,[\"addButtonTapped\"]]],null]],[[\"@route\",\"@model\"],[\"menu.vendor.single-use\",[30,0,[\"vendorSlug\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,3],[\"mwc.singleUseItems.addButton\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"headerText\",\"subHeaderText\"],false,[\"if\",\"svg\",\"x-content\",\"t\",\"olo-link-to\",\"on\"]]",
  "moduleName": "mobile-web/components/cart/single-use-banner/index.hbs",
  "isStrictMode": false
});