export default {
  "root": "_root_zi4xfo",
  "buttonContainer": "_buttonContainer_zi4xfo",
  "addToCartButton": "_addToCartButton_zi4xfo",
  "modal": "_modal_zi4xfo",
  "quantityLabel": "_quantityLabel_zi4xfo",
  "quantityContainer": "_quantityContainer_zi4xfo",
  "quantityText": "_quantityText_zi4xfo",
  "spinnerIcon": "_spinnerIcon_zi4xfo"
};
