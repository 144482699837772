import { action, set } from '@ember/object';
import { once, scheduleOnce } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

import { ANNOUNCER_CONTAINER_ID } from 'mobile-web/components/application-frame';
import { handleFocus, TargetEvent } from 'mobile-web/lib/a11y';
import { noop } from 'mobile-web/lib/utilities/_';
import DeviceService from 'mobile-web/services/device';

type Handler = {
  fn: (event: TargetEvent) => void;
  el: HTMLElement;
};

export default class FocusService extends Service {
  // Service injections
  @service device!: DeviceService;

  // Untracked properties
  lastFocus?: HTMLElement;
  private _handlers: Handler[] = [];

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods
  /**
   * Trap focus within a given element, and set focus within it.
   *
   * @param trapEl  The element within which to trap focus.
   * @param focusEl The first element to focus. If it is not supplied, the focus
   *                is set to `trapEl` instead.
   */
  addTrap(trapEl: HTMLElement, focusEl = trapEl): void {
    if (this._handlers.length) {
      const previousHandler = this._handlers[this._handlers.length - 1];
      document.removeEventListener('focus', previousHandler.fn, true);
    }

    const fn = handleFocus(trapEl, focusEl);
    this._handlers = this._handlers.concat({ el: trapEl, fn });
    document.addEventListener('focus', fn, true);
  }

  /**
   * Remove focus trap from an element.
   * @param trappedEl The previously focus-trapped element to untrap.
   */
  removeTrap(trappedEl: HTMLElement): void {
    this._handlers
      .filter(({ el }) => el === trappedEl)
      .forEach(({ fn }) => document.removeEventListener('focus', fn, true));

    this._handlers = this._handlers.filter(({ el }) => el !== trappedEl);
  }

  willDestroy(): void {
    super.willDestroy();
    for (const { fn } of this._handlers) {
      document.removeEventListener('focus', fn, true);
    }
  }

  /**
   * Set the focus item to return to at a later point.
   *
   * @param element the item to focus, if not the item currently focused in the
   *                document.
   */
  setLastFocus(element?: HTMLElement | null): void {
    const focusEl = element || (document.activeElement as HTMLElement);
    set(this, 'lastFocus', focusEl);
  }

  /**
   * Return focus to the previously focused element.
   */
  focusLast(): void {
    const refocus = this.lastFocus
      ? () => {
          this.focus(this.lastFocus);
        }
      : noop;
    once(this, refocus);
  }

  resetFocus(): void {
    // VoiceOver has issues where focus can end up in the middle of the screen after a transition.
    // To workaround, we force focus onto the announcer after transition.
    if (this.device.isHybridIOS) {
      scheduleOnce('afterRender', this, this.resetVoiceOverFocus);
    }
  }

  focus(el: Element | HTMLElement | null | undefined): void {
    if (el && 'focus' in el) {
      if (this.device.isIOS) {
        setVoiceOverFocus(el);
      } else {
        el.focus();
      }
    }
  }

  // Tasks

  // Actions
  @action
  private resetVoiceOverFocus() {
    const max = 50;
    let i = 0;
    const interval = setInterval(() => {
      if (i >= max) {
        clearInterval(interval);
      }
      const announcer = document.getElementById(ANNOUNCER_CONTAINER_ID)?.children[0] as
        | HTMLElement
        | undefined;
      if (announcer) {
        this.focus(announcer);
        clearInterval(interval);
      }
      i++;
    }, 200);
  }
}

// https://linkedlist.ch/ref/17/
function setVoiceOverFocus(element: HTMLElement) {
  const focusInterval = 10; // ms, time between function calls
  const focusTotalRepetitions = 10; // number of repetitions

  element.setAttribute('tabindex', '0');
  element.blur();

  let focusRepetitions = 0;
  const interval = window.setInterval(() => {
    element.focus();
    focusRepetitions++;
    if (focusRepetitions >= focusTotalRepetitions) {
      window.clearInterval(interval);
    }
  }, focusInterval);
}

declare module '@ember/service' {
  interface Registry {
    focus: FocusService;
  }
}
