import { action } from '@ember/object';
import Service from '@ember/service';

import { TransitionContext } from 'ember-animated';
import move from 'ember-animated/motions/move';
import { fadeOut } from 'ember-animated/motions/opacity';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import config from 'mobile-web/config/environment';
import media from 'mobile-web/decorators/media';

export type AnimationKeyframes = Keyframe[] | PropertyIndexedKeyframes | null;
export type AnimationOptions = Omit<KeyframeAnimationOptions, 'fill'>;

export default class AnimationService extends Service {
  // Service injections

  // Untracked properties

  // Tracked properties
  @media('(prefers-reduced-motion: no-preference)') animationEnabled!: boolean;

  // Getters and setters

  // Constructor

  // Other methods
  /**
   * Normalizes durations for animations and delays so that they run instantly in
   * test environments to speed up test execution.
   *
   * @param desiredDuration
   * @returns
   */
  getDuration(desiredDuration: number): number {
    return config.environment === 'test' ? 0 : desiredDuration;
  }

  /**
   * Runs an animation on an element using the Web Animations API.
   *
   * see https://www.youtube.com/watch?v=9-6CKCz58A8&t=14m26s for detailed
   * explanation.
   *
   * @param element the element to animate
   * @param keyframes the keyframes to use for the animation
   * @param options the animation options
   * @returns a promise that resolves when the animation is complete
   */
  animateTo(
    element: Element,
    keyframes: AnimationKeyframes,
    options: Omit<AnimationOptions, 'fill'>
  ): Promise<void> {
    // skip animation for older browsers that don't support the Web Animations API
    if (!element.animate || !Animation?.prototype?.commitStyles) {
      return Promise.resolve(undefined);
    }

    return new Promise((resolve, reject) => {
      try {
        const animation = element.animate(keyframes, {
          ...options,
          fill: 'both',
        });

        animation.addEventListener('finish', () => {
          try {
            animation.commitStyles();
            animation.cancel();
            resolve();
          } catch (e) {
            reject(e);
          }
        });

        animation.addEventListener('cancel', reject);
      } catch (e) {
        reject(e);
      }
    });
  }

  // Task
  shakeTask = taskFor(this.shakeAnimationInstance);
  /**
   * Performs a shake animation on the specified element.
   *
   * @param element the element to animate
   */
  @task *shakeAnimationInstance(element: HTMLElement) {
    if (this.animationEnabled) {
      yield this.animateTo(
        element,
        [
          { transform: 'translateX(0em)' },
          { transform: 'translateX(-0.1875em)' },
          { transform: 'translateX(0.3125em)' },
          { transform: 'translateX(-0.5em)' },
          { transform: 'translateX(0.5em)' },
          { transform: 'translateX(-0.3125em)' },
          { transform: 'translateX(0.1875em)' },
          { transform: 'translateX(0em)' },
        ],
        {
          duration: this.getDuration(400),
          easing: 'ease-in',
        }
      );
    }
  }

  fadeTask = taskFor(this.fadeInstance);
  /**
   * Performs a fade in or fade out animation on the specified element.
   *
   * @param element the element to animate
   * @param direction fade "in" or "out"
   */
  @task *fadeInstance(element: HTMLElement, direction: 'in' | 'out' = 'in') {
    if (this.animationEnabled) {
      const frames = [
        { opacity: 0 },
        { opacity: 0.1 },
        { opacity: 0.2 },
        { opacity: 0.3 },
        { opacity: 0.4 },
        { opacity: 0.5 },
        { opacity: 0.6 },
        { opacity: 0.7 },
        { opacity: 0.8 },
        { opacity: 0.9 },
        { opacity: 1 },
      ];
      if (direction === 'out') {
        frames.reverse();
      }
      yield this.animateTo(element, frames, {
        duration: this.getDuration(200),
        easing: 'ease-in',
      });
    }
  }

  // Actions
  /**
   * Performs a slide animation on the specified element in an element group during a rearragement event.
   *
   * @param context The context for the transition
   */
  @action
  *slideInFadeOutTransition({ keptSprites, removedSprites }: TransitionContext): Generator<void> {
    yield removedSprites.forEach(sprite => {
      fadeOut(sprite, { duration: this.getDuration(300) });
    });
    yield keptSprites.forEach(sprite => {
      move(sprite, { duration: this.getDuration(500) });
    });
  }
}
declare module '@ember/service' {
  interface Registry {
    animation: AnimationService;
  }
}
