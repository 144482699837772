import Service from '@ember/service';

import ENV from 'mobile-web/config/environment';

export default class PerformanceMetricService extends Service {
  // Service injections

  // Untracked properties
  timings?: Dict<number>;
  reportedToRaygun = false;

  // Do not re-order the contents of this array
  timingKeys = ['scriptsLoaded', 'applicationInit', 'applicationAfterModel'];

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods
  init(): void {
    super.init();
    if (window.Olo.scriptsLoaded) {
      this.timings = { scriptsLoaded: Math.floor(window.Olo.scriptsLoaded) };
    }
  }

  pushTiming(key: string): void {
    if (!this.timings) {
      return;
    }

    if (!this.timingKeys.includes(key)) {
      throw new Error(`Invalid timingKey ${key}`);
    }

    if (this.timings[key] === undefined) {
      this.timings = {
        ...this.timings,
        [key]: Math.floor(performance.now()),
      };
    }
  }

  async reportTimings(): Promise<void> {
    if (!this.timings) {
      return;
    }

    const raygunTimings = this.timingKeys.reduce(
      (prev, curr, index) => ({ ...prev, [`custom${index + 1}`]: this.timings![curr] }),
      {}
    );

    if (ENV.enableRaygun) {
      rg4js('trackEvent', {
        type: 'customTimings',
        timings: raygunTimings,
      });
    } else {
      /* eslint-disable no-console */
      console.table(this.timings);
    }

    this.reportedToRaygun = true;
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    'performance-metric': PerformanceMetricService;
  }
}
