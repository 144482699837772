import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { FuzzyOptions, Searcher } from 'fast-fuzzy';

import isSome from 'mobile-web/lib/utilities/is-some';

import style from './index.m.scss';

interface FilterSearch {
  filter: Action<[string, unknown], boolean>;
}

interface FuzzySearch {
  searcher: Searcher<AnyObject, FuzzyOptions>;
}

type Args = {
  // Required arguments
  items: unknown[];

  // Optional arguments
  onOpen?: Action<[]>;
  onClose?: Action<[]>;
  onSelect?: Action<[unknown]>;
} & (FilterSearch | FuzzySearch);

const MAX_RESULTS = 20;

export default class Typeahead extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked active = false;
  @tracked search = '';
  @tracked rootElement?: HTMLElement;

  // Getters and setters
  get matchedItems(): unknown[] {
    if ('filter' in this.args) {
      return this.args.items
        .filter(obj => (this.args as FilterSearch).filter(this.search, obj))
        .slice(0, MAX_RESULTS);
    } else if (this.args.searcher) {
      return this.args.searcher.search(this.search).slice(0, MAX_RESULTS);
    }
    return [];
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  onFocusIn(): void {
    this.active = true;
    this.args.onOpen?.();
  }

  @action
  close(): void {
    this.active = false;
    this.args.onClose?.();
  }

  @action
  onSelect(item: unknown): void {
    this.args.onSelect?.(item);
    this.close();
  }

  @action
  setupOutsideClick(rootElement: HTMLElement) {
    this.rootElement = rootElement;
    document.addEventListener('click', this.handleOutsideClick);
  }

  @action
  teardownOutsideClick() {
    this.rootElement = undefined;
    document.removeEventListener('click', this.handleOutsideClick);
  }

  @action
  handleOutsideClick(event: MouseEvent) {
    if (!this.active) {
      return;
    }

    const target = event.target as HTMLElement | null;
    if (isSome(target) && !this.rootElement!.contains(target)) {
      this.close();
    }
  }
}
