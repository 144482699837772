import Component from '@glimmer/component';

import Vendor from 'mobile-web/models/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  vendor: Vendor;

  // Optional arguments
  isDelivery?: boolean;
}

interface Signature {
  Args: Args;
}

export default class PostCheckoutOrderLocation extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Getters and setters
  get heading(): string {
    return this.args.isDelivery ? 'Delivering From' : 'Order Location';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::OrderLocation': typeof PostCheckoutOrderLocation;
  }
}
