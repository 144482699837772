import a0 from "../modal/index.ts";
window.define('mobile-web/components/modal', function () {
  return a0;
});
import a1 from "../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a1;
});
import a2 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "vAUZdJ22",
  "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-confirmModal\",\"\"]],[[\"@testSelector\",\"@title\",\"@onClose\",\"@buttons\"],[[30,0,[\"confirmArgs\",\"testSelector\"]],[30,0,[\"confirmArgs\",\"title\"]],[30,0,[\"close\"]],\"yield\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"body\"]],[[[1,\"      \"],[8,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"confirmArgs\",\"content\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"footer\"]],[[[41,[51,[30,0,[\"confirmArgs\",\"hideCancelButton\"]]],[[[1,\"        \"],[8,[39,3],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@onClick\",\"@testSelector\",\"@variant\"],[[30,0,[\"cancel\"]],[30,0,[\"confirmArgs\",\"cancelTestSelector\"]],\"minor\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[52,[30,0,[\"confirmArgs\",\"cancelLabel\"]],[30,0,[\"confirmArgs\",\"cancelLabel\"]],[28,[37,4],[\"mwc.confirmModal.cancelLabel\"],null]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,3],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@onClick\",\"@testSelector\",\"@variant\"],[[30,0,[\"confirm\"]],[30,0,[\"confirmArgs\",\"buttonTestSelector\"]],\"destructive\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"confirmArgs\",\"buttonLabel\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\"],false,[\"if\",\"modal\",\"unless\",\"button\",\"t\"]]",
  "moduleName": "mobile-web/components/confirm-modal/index.hbs",
  "isStrictMode": false
});