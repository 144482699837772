export default {
  "root": "_root_r609vr",
  "isLandscape": "_isLandscape_r609vr",
  "hasImage": "_hasImage_r609vr",
  "menu": "_menu_r609vr",
  "isDisabled": "_isDisabled_r609vr",
  "row": "_row_r609vr",
  "image": "_image_r609vr",
  "page": "_page_r609vr",
  "modal": "_modal_r609vr",
  "list": "_list_r609vr",
  "featured": "_featured_r609vr",
  "quickAdd": "_quickAdd_r609vr",
  "animatedContainer": "_animatedContainer_r609vr",
  "quickAddIconContainer": "_quickAddIconContainer_r609vr",
  "quickAddIcon": "_quickAddIcon_r609vr",
  "doneIcon": "_doneIcon_r609vr _quickAddIcon_r609vr",
  "productInfo": "_productInfo_r609vr",
  "hasFeatured": "_hasFeatured_r609vr",
  "name": "_name_r609vr",
  "hasQuickAdd": "_hasQuickAdd_r609vr",
  "productButton": "_productButton_r609vr",
  "priceLine": "_priceLine_r609vr",
  "hasOverride": "_hasOverride_r609vr",
  "overrideLabel": "_overrideLabel_r609vr",
  "price": "_price_r609vr",
  "calories": "_calories_r609vr",
  "hasPrice": "_hasPrice_r609vr",
  "description": "_description_r609vr _row_r609vr",
  "fullDescription": "_fullDescription_r609vr",
  "availability": "_availability_r609vr _row_r609vr",
  "icon": "_icon_r609vr",
  "isDisabledBanner": "_isDisabledBanner_r609vr _row_r609vr",
  "isDisabledDescription": "_isDisabledDescription_r609vr"
};
