import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { getDuration } from 'mobile-web/lib/animation';
import { QuantityChoiceViewTypes } from 'mobile-web/models/option-group';
import DeviceService from 'mobile-web/services/device';

import style from './index.m.scss';

interface Args {
  // Required arguments
  updateQuantity: Action;

  // Optional arguments
  quantity?: number;
  maximum?: number;
  minimum?: number;
  limit?: number;
  stepSize?: number;
  hideLabel?: boolean;
  type?: QuantityChoiceViewTypes;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

const HOLD_INTERVAL = 50;
const DEFAULT_MAX = 100;

export default class QuantityInput extends Component<Signature> {
  // Service injections
  @service device!: DeviceService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked _quantity = this.minQuantity;
  @tracked inputElement?: HTMLInputElement;
  @tracked thumbElement?: HTMLSpanElement;
  @tracked isMouseDown = false;
  @tracked callCount = 0;

  // Getters and setters
  get quantity(): number {
    return this.args.quantity ?? this._quantity;
  }
  set quantity(val: number) {
    // This can happen if you direct enter a value that is not a valid step size,
    // and it rounds up to the same value that we used to have.
    if (this.inputElement && this._quantity === val) {
      this.inputElement.value = `${val}`;
    }

    this._quantity = val;
    this.quantityChanged(val);
  }

  get decreaseDisabled() {
    return this.quantity - this.stepSize < this.minQuantity;
  }

  get increaseDisabled() {
    return this.limit ? this.quantity + this.stepSize > this.limit : false;
  }

  get minQuantity(): number {
    return this.args.minimum && this.args.minimum > this.stepSize
      ? this.args.minimum
      : this.stepSize;
  }

  get maxQuantity(): number {
    return this.args.maximum ?? DEFAULT_MAX;
  }

  get limit(): number {
    return Math.min(this.args.limit ?? this.maxQuantity, this.maxQuantity);
  }

  get stepSize(): number {
    return this.args.stepSize || 1;
  }

  get type(): QuantityChoiceViewTypes {
    return this.args.type ?? QuantityChoiceViewTypes.Input;
  }

  get isSlider(): boolean {
    return this.type === QuantityChoiceViewTypes.Slider;
  }

  get thumbClass(): string {
    const classes = [this.style.thumb];
    if (!this.maxQuantity || this.maxQuantity > 9) {
      classes.push(this.style.thumbLarge);
    }
    return classes.join(' ');
  }

  get thumbStyle(): SafeString | undefined {
    if (this.inputElement && this.thumbElement && this.maxQuantity) {
      const tw = this.thumbElement.offsetWidth;
      const w = this.inputElement.offsetWidth;
      const xPX =
        ((this.quantity - this.minQuantity) * (w - tw)) / (this.maxQuantity - this.minQuantity);
      return htmlSafe(`left: ${xPX}px`);
    }
    return undefined;
  }

  get hideThumbFromAssistiveTech(): boolean {
    return !this.device.isAndroid;
  }

  // Constructor

  // Other methods
  quantityChanged(quantity: number) {
    this.args.updateQuantity(quantity);
  }

  // Tasks

  // Actions
  @action
  onChange(e: Event) {
    const target = e.target as HTMLInputElement;
    let value = parseInt(target.value, 10) || this.minQuantity;
    if (value === this.quantity) {
      return;
    }

    const extra = value % this.stepSize;
    if (extra > 0) {
      value += this.stepSize - extra;
    }

    const maximum = this.limit;

    if (maximum && value > maximum) {
      this.quantity = maximum;
    } else if (value < this.minQuantity) {
      this.quantity = this.minQuantity;
    } else {
      this.quantity = value;
    }
  }

  @action
  increase() {
    if (!this.increaseDisabled) {
      this.isMouseDown = true;
      this.quantity += this.stepSize;

      later(() => {
        if (this.isMouseDown) {
          this.increase();
        } else {
          this.callCount = 0;
        }
      }, getDuration(HOLD_INTERVAL * Math.max(10 - this.callCount, 1)));
      this.callCount++;
    }
  }

  @action
  decrease() {
    if (this.quantity > this.minQuantity) {
      this.isMouseDown = true;
      this.quantity -= this.stepSize;

      later(() => {
        if (this.isMouseDown) {
          this.decrease();
        } else {
          this.callCount = 0;
        }
      }, getDuration(HOLD_INTERVAL * Math.max(10 - this.callCount, 1)));
      this.callCount++;
    }
  }
}
