import a0 from "../../helpers/not.js";
window.define('mobile-web/helpers/not', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "yEBDit48",
  "block": "[[[11,\"button\"],[24,4,\"button\"],[24,\"role\",\"switch\"],[16,\"aria-checked\",[29,[[30,1]]]],[16,0,[30,0,[\"style\",\"switch\"]]],[24,\"data-test-toggle\",\"\"],[17,2],[4,[38,0],[\"click\",[28,[37,1],[[30,3],[28,[37,2],[[30,1]],null]],null]],null],[12],[1,\"\\n  \"],[10,1],[14,\"aria-hidden\",\"true\"],[15,0,[30,0,[\"style\",\"thumb\"]]],[12],[13],[1,\"\\n\"],[13]],[\"@value\",\"&attrs\",\"@onChange\"],false,[\"on\",\"fn\",\"not\"]]",
  "moduleName": "mobile-web/components/toggle/index.hbs",
  "isStrictMode": false
});