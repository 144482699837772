import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import SideMenuService from 'mobile-web/services/side-menu';

import style from './index.m.scss';

interface Signature {
  Element: HTMLElement;
}

export default class MenuButton extends Component<Signature> {
  // Service injections
  @service sideMenu!: SideMenuService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  onClick(): void {
    this.sideMenu.open();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MenuButton: typeof MenuButton;
  }
}
