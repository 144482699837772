import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { computedLocal } from 'mobile-web/lib/computed';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import VendorSearchService from 'mobile-web/services/vendor-search';

import { VendorSearchParams } from '../vendor-search-results';

export default class MenuRoute extends Route {
  @service analytics!: AnalyticsService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service vendorSearch!: VendorSearchService;

  @computedLocal
  localVendorSearchParams?: VendorSearchParams;

  model() {
    return this.vendorSearch.search(this.localVendorSearchParams);
  }

  afterModel(resolvedModel: ModelForRoute<MenuRoute>) {
    if (!this.localVendorSearchParams) {
      this.router.transitionTo('index');
    }

    const isNearby = (vendor: VendorSearchResultModel): boolean => {
      const nearbyDistance = 6.0;
      const { hasOnlineOrdering, displayNationalMenu } = vendor;
      let { distance } = vendor;

      if (!distance) {
        distance = 0;
      }
      return (hasOnlineOrdering || displayNationalMenu) && distance <= nearbyDistance;
    };

    const nearbyVendors: VendorSearchResultModel[] = resolvedModel.results.filter(isNearby);

    const availableVendor: VendorSearchResultModel | undefined =
      nearbyVendors.length === 1
        ? nearbyVendors.filter(v => v.available && v.preCheckResult?.isValid)[0]
        : undefined;

    let skippedLocation = false;

    if (availableVendor) {
      skippedLocation = true;
      this.store.adapterFor('telemetry').searchLocationsSkipped();
      this.router.transitionTo('menu.vendor', availableVendor.slug);
    } else {
      this.router.transitionTo('vendor-search-results');
    }

    this.analytics.trackEvent(AnalyticsEvents.ManualLocationSelectionSkipped, () => ({
      [AnalyticsProperties.ManualLocationSelectionSkipped]: skippedLocation ? 'Yes' : 'No',
    }));
  }
}
