import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

import { tagName } from '@ember-decorators/component';
import IntlService from 'ember-intl/services/intl';

import CardButton from 'mobile-web/components/card/button';
import { HeadingLevel } from 'mobile-web/components/heading';
import { Dayjs } from 'mobile-web/lib/dayjs';
import TZ from 'mobile-web/lib/time/timezone';
import { isString, isObject } from 'mobile-web/lib/utilities/_';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import ChannelService from 'mobile-web/services/channel';

import style from './index.m.scss';

@tagName('')
export default class VendorCard extends Component {
  @service intl!: IntlService;
  @service channel!: ChannelService;

  model!: VendorSearchResultModel;
  day!: Dayjs;
  destRoute?: string;
  buttons?: { one: CardButton; two?: CardButton };
  headingLevel?: HeadingLevel = 2;

  ids = guids(this, 'heading');
  style = style;

  @computed('intl', 'model.hasOnlineOrdering')
  get orderLabelText() {
    return this.model.hasOnlineOrdering
      ? this.intl.t('mwc.vendorCard.beginOrderText')
      : this.intl.t('mwc.vendorCard.viewMenuText');
  }

  @computed('day', 'intl', 'model')
  get tags() {
    const { displayNationalMenu, hasOnlineOrdering, name, utcOffset } = this.model;
    const value = [...this.model.tags(this.day)];

    if (utcOffset !== TZ.localOffset()) {
      value.push({
        type: 'cautionary',
        content: TZ.offsetDifferenceMessage(name, utcOffset),
      });
    }

    if (displayNationalMenu && !hasOnlineOrdering) {
      value.push({
        type: 'cautionary',
        content: this.intl.t('mwc.vendorCard.noOnlineOrdering'),
      });
    }
    return value;
  }

  @computed('model.distance')
  get hasDistance() {
    return isSome(this.model.distance);
  }

  init() {
    super.init();

    assert('`model` is required', isObject(this.model));
    assert('`day` is required', isObject(this.day));
    assert(
      '`destRoute` or `buttons` is required',
      isSome(this.buttons) || isString(this.destRoute)
    );
  }

  @action
  onToggleFavorite() {
    this.model.toggleFavorite();
  }
}
