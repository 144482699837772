import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { WithBoundArgs } from '@glint/template';

import Svg from 'mobile-web/components/svg';

import PostCheckoutSubsectionButtonContainer from './button-container';
import style from './index.m.scss';
import PostCheckoutSubsectionLineItem from './line-item';
import PostCheckoutSubsectionLineItemContainer from './line-item-container';

interface Args {
  heading?: string;
  icon?: WithBoundArgs<typeof Svg, 'icon'>;
}

interface Signature {
  Args: Args;

  Blocks: {
    buttons: [
      {
        buttonClass: string;
        buttonContainer: typeof PostCheckoutSubsectionButtonContainer;
      }
    ];
    content: [
      {
        lineItem: typeof PostCheckoutSubsectionLineItem;
        lineItemContainer: typeof PostCheckoutSubsectionLineItemContainer;
      }
    ];
  };
}

export default class PostCheckoutSubsection extends Component<Signature> {
  style = style;

  get icon() {
    return this.args.icon ? ensureSafeComponent(this.args.icon, this) : undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::Subsection': typeof PostCheckoutSubsection;
  }
}
