import a0 from "../../helpers/eq.js";
window.define('mobile-web/helpers/eq', function () {
  return a0;
});
import a1 from "../../modifiers/ref.ts";
window.define('mobile-web/modifiers/ref', function () {
  return a1;
});
import a2 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a2;
});
import a3 from "../../helpers/gte.js";
window.define('mobile-web/helpers/gte', function () {
  return a3;
});
import a4 from "../../helpers/format-currency.ts";
window.define('mobile-web/helpers/format-currency', function () {
  return a4;
});
import a5 from "../animated-container.js";
window.define('mobile-web/components/animated-container', function () {
  return a5;
});
import a6 from "../animated-value.js";
window.define('mobile-web/components/animated-value', function () {
  return a6;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "AA3uxexC",
  "block": "[[[11,\"button\"],[16,\"aria-disabled\",[52,[28,[37,1],[[30,1],\"softDisabled\"],null],\"true\"]],[16,0,[30,0,[\"rootClass\"]]],[24,4,\"button\"],[16,\"data-test-button\",[30,0,[\"dataTestButton\"]]],[17,2],[4,[38,2],[\"click\",[30,0,[\"onClick\"]]],null],[4,[38,3],[[30,0],\"buttonElement\"],null],[12],[1,\"\\n  \"],[10,1],[15,0,[28,[37,4],[[30,3],[30,0,[\"style\",\"text\"]]],null]],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[1,[30,4]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,8,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[28,[37,6],[[30,5],0],null],[[[1,\"    \"],[10,1],[14,\"data-test-button-highlight\",\"\"],[15,0,[30,0,[\"style\",\"highlight\"]]],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,1],[12],[1,[28,[35,7],[[30,5]],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,8],[[16,0,[30,0,[\"style\",\"animatedContainer\"]]]],null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,9],null,[[\"@value\",\"@rules\",\"@duration\"],[[30,5],[30,0,[\"rules\"]],[30,0,[\"duration\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[12],[1,[28,[35,7],[[30,7]],null]],[13],[1,\"\\n          \"]],[7]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"icon\"]],[[[1,\"    \"],[8,[30,0,[\"icon\"]],null,[[\"@class\"],[[28,[37,4],[[30,0,[\"style\",\"newIconFormat\"]],[30,0,[\"iconClass\"]]],null]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@variant\",\"&attrs\",\"@textClass\",\"@label\",\"@highlightContent\",\"@disableAnimation\",\"v\",\"&default\"],false,[\"if\",\"eq\",\"on\",\"ref\",\"classes\",\"yield\",\"gte\",\"format-currency\",\"animated-container\",\"animated-value\"]]",
  "moduleName": "mobile-web/components/button/index.hbs",
  "isStrictMode": false
});