import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { getOrCreateBasketChoice, isParentNew, loadNestedMenuItems } from 'mobile-web/lib/menu';
import { safeNext } from 'mobile-web/lib/runloop';
import { classes } from 'mobile-web/lib/utilities/classes';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import BasketChoiceModel from 'mobile-web/models/basket-choice';
import BasketProductModel from 'mobile-web/models/basket-product';
import ChoiceModel from 'mobile-web/models/choice';
import OptionGroupModel from 'mobile-web/models/option-group';
import BasketService from 'mobile-web/services/basket';

import style from './index.m.scss';

interface Args {
  // Required arguments
  basketProduct: BasketProductModel;
  choice: ChoiceModel;

  // Optional arguments
  basketChoice?: BasketChoiceModel;
  onClick?: Action<BasketChoiceModel>;
  showYieldedContent?: boolean;
  name?: string;
  hideExtraAttributes?: boolean;
}

export default class ProductCustomizationChoice extends Component<Args> {
  // Service injections
  @service basket!: BasketService;
  @service store!: DS.Store;

  // Untracked properties
  ids = guids(this, 'calories', 'label', 'overrideLabel', 'price');
  style = style;

  // Tracked properties
  @tracked _basketChoice?: BasketChoiceModel;

  // Getters and setters
  get basketChoice(): BasketChoiceModel | undefined {
    return this.args.basketChoice ?? this._basketChoice;
  }

  get describedby(): string {
    return classes(
      // order matters here, description IDs should match the order they appear in the DOM
      this.args.choice.labels.map((_, idx) => `${this.ids.label}-${idx}`),
      {
        [this.ids.overrideLabel]: this.args.choice.priceDifferenceOverrideLabel,
        [this.ids.price]: this.showPrice,
        [this.ids.calories]: this.showCalories,
      }
    );
  }

  get rootClass(): string {
    return classes(this.style.root, {
      [this.style.hasOneLabel]: this.args.choice.labels.length === 1,
      [this.style.hasOverride]: this.args.choice.priceDifferenceOverrideLabel,
      [this.style.hasPrice]: this.showPrice,
      [this.style.hasCalories]: this.showCalories,
      [this.style.isSelected]: this.basketChoice?.isSelected,
      [this.style.canMultiSelect]: this.args.choice.optionGroup?.multiSelect,
      [this.style.isDisabled]: this.disabled,
    });
  }

  get optionGroup(): OptionGroupModel | undefined | null {
    return this.args.choice.optionGroup;
  }

  get canSelect(): boolean {
    return this.optionGroup?.canSelectNewChoices ?? false;
  }

  get disabled(): boolean {
    return !this.canSelect && this.basketChoice?.quantity === 0;
  }

  get name(): string {
    if (this.args.name) {
      return this.args.name;
    }
    const choice = this.args.choice;
    if (choice.displayGroup) {
      if (choice.optionGroup?.hasChoiceQuantities) {
        return choice.displayGroup.groupName;
      }
      return choice.displayGroup.optionName;
    }
    return choice.name;
  }

  get isDisabled(): boolean {
    return this.args.choice.isDisabled ?? false;
  }

  get hideExtraAttributes(): boolean {
    const choice = this.args.choice;
    return (
      this.args.hideExtraAttributes ??
      (choice.displayGroup && !choice.optionGroup?.hasChoiceQuantities)
    );
  }

  get showPrice(): boolean {
    const choice = this.args.choice;
    return (
      !this.hideExtraAttributes &&
      isSome(choice.priceDifference) &&
      choice.priceDifference !== 0 &&
      !this.optionGroup?.hideChoicePrices
    );
  }

  get showCalories(): boolean {
    const choice = this.args.choice;
    return (
      !this.hideExtraAttributes &&
      isSome(choice.calorieLabel) &&
      (this.args.basketProduct.vendor?.settings.showCalories ?? false)
    );
  }

  get showYieldedContent(): boolean {
    return this.args.showYieldedContent ?? false;
  }

  get showQuantity(): boolean {
    return (
      isSome(this.optionGroup) &&
      this.basketChoice?.isSelected === true &&
      this.optionGroup.hasChoiceQuantities &&
      (this.optionGroup.minChoiceQuantity !== this.optionGroup.maxChoiceQuantity ||
        this.optionGroup.minChoiceQuantity > 1)
    );
  }

  get maxChoiceQuantity(): number {
    return this.optionGroup
      ? Math.min(this.optionGroup.maxChoiceQuantity, this.optionGroup.maxAggregateQuantity)
      : 0;
  }

  get choiceLimit(): number {
    const maxAggregateQuantity = this.optionGroup?.maxAggregateQuantity ?? 0;
    const aggregateQuantity = this.optionGroup?.aggregateQuantity ?? 0;
    const currentQuantity = this.basketChoice?.quantity ?? 0;

    return maxAggregateQuantity - aggregateQuantity + currentQuantity;
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (!this.args.basketChoice) {
      // The `next` is needed because we reference the basketChoices array in the optionGroup
      // component, and Ember doesn't like you to change arrays in the same runloop that you
      // reference them.
      safeNext(this, () => {
        const { choice, isNew } = getOrCreateBasketChoice(
          this.store,
          this.args.basketProduct,
          this.args.choice
        );
        this._basketChoice = choice;
        if (isNew && isParentNew(this.args.basketProduct, this.args.choice)) {
          this.args.choice.optionGroup?.splitChoiceQuantities();
        }
        loadNestedMenuItems(this.store, this.args.basketProduct, this.args.choice, false);
      });
    }
  }

  // Other methods

  // Tasks

  // Actions
  @action
  click(e: Event) {
    e.stopPropagation();

    if (this.isDisabled) {
      return;
    }

    const basketChoice = this.basketChoice;
    if (basketChoice) {
      const canSelect = basketChoice.quantity === 0 && this.canSelect;
      const canDeselect = basketChoice.quantity > 0;
      if (canSelect || canDeselect) {
        this.args.onClick?.(basketChoice);
      }
    }
  }

  @action
  updateQuantity(quantity: number) {
    if (this.basketChoice) {
      this.basketChoice.quantity = quantity;
      this.args.choice.optionGroup?.quantityChanged();
    }
  }

  @action
  preventPropagation(e: Event) {
    e.stopPropagation();
  }
}
