import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import { getDuration } from 'mobile-web/lib/animation';
import HealthCheckService from 'mobile-web/services/health-check';

import style from './index.m.scss';

export default class OutageRoute extends Component {
  // Service injections
  @service healthCheck!: HealthCheckService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked timesChecked = 0;

  // Getters and setters

  // Constructor
  constructor(owner: unknown, args = {}) {
    super(owner, args);

    this.refreshStatus();
  }

  // Other methods
  shouldRefresh(): boolean {
    return !this.isDestroying && !this.isDestroyed && !this.healthCheck.isHealthy;
  }

  refreshStatus(): void {
    if (!this.shouldRefresh() && this.timesChecked) return;

    taskFor(this.runHealthCheck).perform();

    // After 30 seconds: query the healthCheck endpoint incrementally higher
    // until we reach 15 minutes of waiting time between requests
    const interval = getDuration(this.timesChecked <= 58 ? 15e3 * (this.timesChecked + 2) : 58);
    later(this, this.refreshStatus, interval);
  }

  // Tasks
  @dropTask *runHealthCheck(): TaskGenerator<void> {
    yield this.healthCheck.checkSystemHealth();
    this.timesChecked += 1;
  }

  // Actions
}
