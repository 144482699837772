import a0 from "../components/olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "NNuJCb8y",
  "block": "[[[10,\"section\"],[14,5,\"padding: 16px;\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"Page Not Found\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Sorry, that doesn't seem to be a valid URL. Your best bet is to head\\n    \"],[8,[39,0],null,[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"back to the home page\"]],[]]]]],[1,\"!\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"olo-link-to\"]]",
  "moduleName": "mobile-web/templates/four-oh-four.hbs",
  "isStrictMode": false
});