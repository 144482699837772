export default {
  "root": "_root_109wmn",
  "pop-in": "_pop-in_109wmn",
  "neutral": "_neutral_109wmn",
  "negative": "_negative_109wmn",
  "positive": "_positive_109wmn",
  "warning": "_warning_109wmn",
  "icon": "_icon_109wmn",
  "closeAnimation": "_closeAnimation_109wmn",
  "pop-out": "_pop-out_109wmn",
  "message": "_message_109wmn",
  "actions": "_actions_109wmn",
  "action": "_action_109wmn",
  "upperMessage": "_upperMessage_109wmn",
  "minor": "_minor_109wmn"
};
