import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import LoyaltyScheme from 'mobile-web/models/loyalty-scheme';
import LoyaltyService from 'mobile-web/services/loyalty';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

interface Args {
  // Required arguments
  scheme: LoyaltyScheme;

  // Optional arguments
  onLink?: Action;
}

interface Signature {
  Args: Args;
}

export default class LinkRewardsForm extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;
  @service loyalty!: LoyaltyService;
  @service router!: RouterService;
  @service userFeedback!: UserFeedback;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked memberId = '';

  // Getters and setters
  get isDisabledLink(): boolean {
    return this.linkAccountTask.isRunning || isEmpty(this.memberId);
  }

  // Constructor

  // Other methods

  // Tasks
  linkAccountTask = taskFor(this.linkAccountGenerator);
  @task *linkAccountGenerator() {
    yield this.loyalty.linkMembershipAccount(this.memberId, this.args.scheme);
    this.userFeedback.add({
      type: Type.Positive,
      title: this.intl.t('mwc.feedbackBanner.successTitle'),
      message: this.intl.t('mwc.linkRewards.linkMembershipSuccessMessage', {
        provider: this.args.scheme.loyaltyProvider,
      }),
      actions: [
        {
          label: this.intl.t('mwc.linkRewards.linkMembershipSuccessButton'),
          fn: () => {
            this.router.transitionTo('menu.vendor.rewards', this.args.scheme.vendor.slug);
          },
        },
      ],
    });
    this.args.onLink?.();
  }

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'LinkRewards::Form': typeof LinkRewardsForm;
  }
}
