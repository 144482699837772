import a0 from "./basic-dialog/index.ts";
window.define('mobile-web/components/modal-dialog/basic-dialog', function () {
  return a0;
});
import a1 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a1;
});
import a2 from "../../modifiers/will-destroy.js";
window.define('mobile-web/modifiers/will-destroy', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "wy8h5uzG",
  "block": "[[[8,[39,0],[[24,\"data-test-modalDialog\",\"\"],[17,1],[4,[38,1],[[30,0,[\"setup\"]]],null],[4,[38,2],[[30,0,[\"teardown\"]]],null]],[[\"@modalId\",\"@overlayId\",\"@onClose\",\"@clickOutsideToClose\",\"@containerClass\",\"@overlayClass\",\"@wrapperClass\"],[[30,0,[\"ids\",\"modal\"]],[30,0,[\"ids\",\"overlay\"]],[30,2],[30,3],[30,4],[30,5],[30,6]]],[[\"default\"],[[[[1,\"\\n  \"],[18,7,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@onClose\",\"@clickOutsideToClose\",\"@containerClass\",\"@overlayClass\",\"@wrapperClass\",\"&default\"],false,[\"modal-dialog/basic-dialog\",\"did-insert\",\"will-destroy\",\"yield\"]]",
  "moduleName": "mobile-web/components/modal-dialog/index.hbs",
  "isStrictMode": false
});