import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import ContentService, { SafeString } from 'mobile-web/services/content';

import style from './index.m.scss';

type ContentString = string | SafeString;
type ContentType = 'text' | 'html';

interface Args {
  // Required arguments
  key: string;

  // Optional arguments
  type?: ContentType;
  substitutions?: Record<string, ContentString>;
  defaultMessage?: string;
  experimentFeatureFlagKey?: string;
}
interface Signature {
  Args: Args;
  Blocks: {
    default: [{ value: ContentString; isLoaded: boolean }];
  };
}

export default class XContent extends Component<Signature> {
  // Service injections
  @service content!: ContentService;
  @service intl!: IntlService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isLoaded = false;
  @tracked _value?: ContentString;

  // Getters and setters
  get defaultMessage(): string {
    return this.args.defaultMessage ?? '';
  }

  get defaultValue(): string {
    return this.intl.t('mwc.dictionary.loading');
  }

  get substitutions(): Dict<string> {
    const entries = Object.entries(this.args.substitutions ?? {});
    const stringValueEntries = entries.map(([key, value]) => [key, value.toString()]);
    return Object.fromEntries(stringValueEntries);
  }

  get type(): ContentType {
    return this.args.type ?? 'text';
  }

  get value(): ContentString {
    return this._value ?? this.defaultValue;
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.getContent().then(value => {
      this._value = value;
      this.isLoaded = true;
    });
  }

  // Other methods
  async getContent(): Promise<ContentString> {
    switch (this.type) {
      case 'html':
        return this.content.getHTML(
          this.args.key,
          this.defaultMessage,
          this.substitutions,
          this.args.experimentFeatureFlagKey
        );
      case 'text':
      default:
        return this.content.getEntry(
          this.args.key,
          this.defaultMessage,
          this.substitutions,
          this.args.experimentFeatureFlagKey
        );
    }
  }

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    XContent: typeof XContent;
  }
}
