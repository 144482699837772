import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import { isArray } from 'mobile-web/lib/utilities/_';
import ProductModel from 'mobile-web/models/product';
import Vendor from 'mobile-web/models/vendor';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export default class OptionGroupAdapter extends ApplicationAdapter.extend(UrlTemplates) {
  @service vendor!: VendorService;

  findManyIds?: EmberDataId[];

  findMany(
    store: DS.Store,
    _type: DS.Model,
    ids: EmberDataId[],
    snapshots: DS.Snapshot[]
  ): RSVP.Promise<unknown> {
    this.findManyIds = ids.map(id => id.toString());
    const result = super.findMany(store, _type, ids, snapshots).catch(error => {
      // 404s returned from this endpoint indicate that none of the requested option group IDs
      // are available. Maybe they were 86'd, maybe they don't exist on this menu for Serve,
      // or maybe something else happened. Regardless, don't stop the execution of the application
      // on a 404. This is a workaround for the way ember-data handles 404s.
      if (error?.errors?.length === 1 && error.errors[0]?.status === '404') {
        // if the contents of the option groups endpoint changes, this may also have to change
        return {
          choices: [],
          optionGroups: [],
        };
      }
      throw error;
    });
    return result;
  }

  /**
   * There is a single option group endpoint in the back end, but it is well and truly
   * borked. Seriously, don't go there. Evil awaits. So, we need to make sure that
   * the findRecord ends up calling the findMany endpoint, because they both have the
   * same return value and the findMany endpoint can accept a single id _and_ isn't,
   * y'know, borked.
   */
  findRecord(
    store: DS.Store,
    type: DS.Model,
    id: string,
    snapshot: DS.Snapshot
  ): RSVP.Promise<unknown> {
    return this.findMany(store, type, [id], [snapshot]);
  }

  coalesceFindRequests = true;
  urlTemplate = host() + '/{+namespace}/vendors/{vendorId}/optiongroups/{optionGroupId}';
  urlSegments = {
    vendorId(
      this: OptionGroupAdapter,
      _type: unknown,
      _id: unknown,
      snapshot: DS.Snapshot<'option-group'> | DS.Snapshot<'option-group'>[]
    ): string {
      const record = isArray(snapshot) ? snapshot[0] : snapshot;

      /**
       * These types are technically wrong, but they are wrong from the DefinitelyTyped repo.
       * The snapshot belongsTo method actually returns a DS.Snapshot, but is typed as returning
       * a DS.Model. Since all we need this for is to get the productVendor.id, the wrong types
       * happen to work and it is cleaner than a bunch of weird casts.
       */
      const product = record.belongsTo('product') as ProductModel | undefined;
      const productVendor = product?.belongsTo('vendor') as Vendor | undefined;
      const productVendorId = productVendor?.id;

      const vendorIdFromService = this.vendor.vendor?.id;

      if (!productVendorId && !vendorIdFromService) {
        throw new Error('Vendor Id for Option Group Not Found');
      }

      return (productVendorId ?? vendorIdFromService)!;
    },

    optionGroupId(
      this: OptionGroupAdapter,
      _type: unknown,
      _id: unknown,
      snapshot: DS.Snapshot<'option-group'>
    ): string {
      return snapshot.id;
    },

    namespace(this: OptionGroupAdapter): string {
      return this.namespace;
    },
  };
}
