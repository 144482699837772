import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import BasketService from 'mobile-web/services/basket';

import UpsellItemModel from './upsell-item';

export default class UpsellGroupModel extends DS.Model {
  @service basket!: BasketService;

  @DS.attr('string')
  title!: string;

  @DS.hasMany('upsell-item', { async: false })
  items!: DS.ManyArray<UpsellItemModel>;

  @computed('basket.basket.basketProducts.[]', 'items.[]')
  get availableItems(): UpsellItemModel[] {
    if (isNone(this.basket.basket)) {
      return this.items.toArray();
    }
    return this.items.filter(
      item =>
        !this.basket.basket!.basketProducts.any(bp => bp.belongsTo('product').id() === item.id)
    );
  }
}
