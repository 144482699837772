export default {
  "button": "_button_5ae3pf",
  "highlight": "_highlight_5ae3pf",
  "main": "_main_5ae3pf",
  "minor": "_minor_5ae3pf",
  "destructive": "_destructive_5ae3pf",
  "softDisabled": "_softDisabled_5ae3pf",
  "hasHighlightContent": "_hasHighlightContent_5ae3pf",
  "text": "_text_5ae3pf",
  "animatedContainer": "_animatedContainer_5ae3pf",
  "icon": "_icon_5ae3pf",
  "newIconFormat": "_newIconFormat_5ae3pf",
  "iconSvg": "_iconSvg_5ae3pf",
  "size-100": "_size-100_5ae3pf",
  "size-200": "_size-200_5ae3pf",
  "size-300": "_size-300_5ae3pf",
  "size-400": "_size-400_5ae3pf",
  "size-500": "_size-500_5ae3pf",
  "size-600": "_size-600_5ae3pf",
  "size-700": "_size-700_5ae3pf",
  "size-800": "_size-800_5ae3pf",
  "size-900": "_size-900_5ae3pf",
  "size-1000": "_size-1000_5ae3pf",
  "fullWidth": "_fullWidth_5ae3pf"
};
