import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import Modal from 'mobile-web/components/modal';
import { getDuration } from 'mobile-web/lib/animation';
import { safeLater, safeNext } from 'mobile-web/lib/runloop';

import style from './index.m.scss';

/** Matches $-animated-modal-transition-duration in the style */
const TRANSITION_DURATION = 500;

enum TransitionType {
  Fade = 'fade',
  SlideUp = 'slideUp',
}

interface Args {
  // Required arguments
  open: boolean;
  onClose: Modal['onClose'];
  title: Modal['title'];

  // Optional arguments
  buttons?: Modal['buttons'];
  modalClass?: string;
  transition?: TransitionType;
  bodyDidInsert?: Modal['bodyDidInsert'];
  onCloseComplete?: Action;
  testSelector?: Modal['testSelector'];
}

export default class AnimatedModal extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isOpen = false;
  @tracked renderContent = false;

  // Getters and setters
  get wrapperClass(): string {
    return this.style[this.args.transition ?? TransitionType.Fade];
  }

  get modalClass(): string {
    const classes = [this.style.modal, this.isOpen ? this.style.open : this.style.closed];
    if (this.args.modalClass) {
      classes.push(this.args.modalClass);
    }

    return classes.join(' ');
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  beginOpenAnimation() {
    this.renderContent = true;

    // We need the component to render the content once before
    // setting isOpen to true, so that we can get the animation
    // from the close class to the open class.
    safeNext(this, () => {
      this.isOpen = true;
    });
  }

  @action
  beginCloseAnimation() {
    if (!this.args.open) {
      // (∩｀-´)⊃━☆ﾟ.*・｡ﾟ
      // This is needed in order for setting `isOpen` to trigger
      // recalcs on things that observe it. We don't know why.
      safeNext(this, () => {
        this.isOpen = false;

        safeLater(
          this,
          () => {
            this.renderContent = false;
            if (this.args.onCloseComplete) {
              this.args.onCloseComplete();
            }
          },
          getDuration(TRANSITION_DURATION)
        );
      });
    }
  }
}
