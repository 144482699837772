/* eslint-disable no-use-before-define */
import { computed } from '@ember/object';
import CP from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import IntlService from 'ember-intl/services/intl';
import pick from 'lodash.pick';

import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import { CustomIcon } from 'mobile-web/lib/custom-icon';
import {
  HandoffMode,
  isAdvance,
  isDelivery,
  OrderCriteria,
  TimeWantedType,
} from 'mobile-web/lib/order-criteria';
import { Type as CardType } from 'mobile-web/lib/payment/card';
import WeeklySchedule from 'mobile-web/lib/time/weekly-schedule';
import isSome from 'mobile-web/lib/utilities/is-some';
import VendorSearchResultModel, {
  VendorPreCheckResult,
} from 'mobile-web/models/vendor-search-result';
import ErrorService from 'mobile-web/services/error';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import { Type } from 'mobile-web/services/user-feedback';
import UserFeedback from 'mobile-web/services/user-feedback';

import CategoryModel from './category';
import ChannelModel from './channel';
import LoyaltyScheme from './loyalty-scheme';
import ProductModel from './product';
import UpsellGroupModel from './upsell-group';

export type Address = {
  streetAddress: string;
  streetAddress2?: string;
  crossStreet?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

export type Settings = {
  acceptedCreditCardTypes: CardType[];
  canPickup: boolean;
  canDeliver: boolean;
  canCurbSidePickup: boolean;
  canChooseHandoff: boolean;
  isOloPay: boolean;
  supportedHandoffModes: HandoffMode[];
  supportsTip: boolean;
  showCalories: boolean;
  customerFacingMessage: string;
  showCustomerFacingMessage: boolean;
};

export enum LabelCode {
  NYC = 'NYCHighSodium',
  PHL = 'PHLSodiumWarning',
  Prop65 = 'Prop65Toxic',
}

export type ContentDisclaimer = {
  content: string;
};

export type LabelDisclaimer = ContentDisclaimer & {
  code: LabelCode;
  icon: CustomIcon;
};

export type Disclaimer = LabelDisclaimer | ContentDisclaimer;

export type PreCheckPayload = {
  timeWanted?: Date;
  timeWantedType: TimeWantedType;
  handoffMode: HandoffMode;
  addressId?: EmberDataId;
  address?: string;
  building?: string;
  city?: string;
  zipCode?: string;
};

export type GlobalVendor = Pick<
  Vendor,
  | 'id'
  | 'address'
  | 'allowAdvanceOrders'
  | 'allowCoupons'
  | 'allowImmediateOrders'
  | 'allowManualFireOrders'
  | 'currency'
  | 'displayNationalMenu'
  | 'externalReference'
  | 'isAcceptingOrders'
  | 'isFavorite'
  | 'latitude'
  | 'longitude'
  | 'minimumDeliveryOrder'
  | 'minimumPickupOrder'
  | 'name'
  | 'phoneNumber'
  | 'slug'
  | 'status'
  | 'utcOffset'
  | 'timeZoneId'
>;

export type OverrideReason = {
  name: string;
  reason: string;
};

export function getShortOverrideDesc(reason: OverrideReason, intl: IntlService): string {
  return reason.name === 'Default'
    ? intl.t('mwc.vendor.closedNoReason')
    : intl.t('mwc.vendor.closedForReason', { reason: reason.name });
}

function buildPreCheckPayload(criteria: OrderCriteria): PreCheckPayload {
  const timeWantedType = criteria.timeWantedType;
  const timeWanted =
    isAdvance(criteria) && criteria.timeWanted ? criteria.timeWanted.toDate() : undefined;
  const handoffMode = criteria.handoffMode;

  const addressFields = isDelivery(criteria)
    ? {
        addressId: criteria.deliveryAddress?.id,
        address: criteria.deliveryAddress?.streetAddress,
        building: criteria.deliveryAddress?.building,
        city: criteria.deliveryAddress?.city,
        zipCode: criteria.deliveryAddress?.zipCode,
      }
    : {};

  return {
    timeWanted,
    timeWantedType,
    handoffMode,
    ...addressFields,
  };
}

export function toggleVendorFavorite(this: Vendor | VendorSearchResultModel): void {
  if (!this.session.isLoggedIn) {
    this.userFeedback.add({
      type: Type.Warning,
      title: this.intl.t('mwc.errors.loginRequiredTitle'),
      message: this.intl.t('mwc.errors.favoriteVendorLoginRequiredMessage'),
      actions: [
        {
          label: this.intl.t('mwc.errors.loginRequiredAction'),
          fn: () => {
            this.session.transitionToLogin();
          },
        },
      ],
    });
  } else {
    this.toggleProperty('isFavorite');
    this.toggleFavoriteAction().catch(e => {
      this.error.reportError(e);
      this.toggleProperty('isFavorite');
    });

    // Need to match favorite value on related model if present
    this.store
      // eslint-disable-next-line no-use-before-define
      .peekRecord(this instanceof Vendor ? 'vendor-search-result' : 'vendor', this.id)
      ?.toggleProperty('isFavorite');
  }
}

/**
 * Technically there are more statuses, but the others should never end up in the front-end.
 * For reference, those other statuses are in Mobo.QuickService.VendorStatus.
 */
export enum VendorStatus {
  Public = 'Public',
  Private = 'Private',
}

export default class Vendor extends DS.Model {
  @service storage!: StorageService;
  @service intl!: IntlService;
  @service userFeedback!: UserFeedback;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service error!: ErrorService;

  @DS.attr('string')
  name!: string;
  @DS.attr('string')
  slug!: string;
  @DS.attr('string')
  status!: VendorStatus;
  @DS.attr('string')
  phoneNumber!: string;
  @DS.attr('object')
  address!: Address;
  @DS.attr('number')
  latitude!: number;
  @DS.attr('number')
  longitude!: number;
  /**
   * @summary
   * This is the vendor's UTC offset at the current time. It doesn't account for
   * changes to the UTC offset over time, such as by entering or leaving daylight
   * saving time.
   *
   * @deprecated
   */
  @DS.attr('number')
  utcOffset!: number;
  /**
   * @summary
   * This is the vendor's IANA time zone name (e.g. "America/New_York").
   *
   * This will not be set if the `cot-normalize-set-time-wanted-olo-22337` flag
   * is disabled.
   */
  @DS.attr('string')
  timeZoneId?: string;

  @DS.attr('object')
  weeklySchedule!: WeeklySchedule;
  @DS.attr('object')
  settings!: Settings;
  @DS.attr('boolean')
  isFavorite!: boolean;
  @DS.attr('array')
  locationAttributes!: { id: number; name: string }[];
  @DS.attr('boolean', { defaultValue: false })
  allowAdvanceOrders!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  allowImmediateOrders!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  allowManualFireOrders!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  allowCoupons!: boolean;
  @DS.attr('boolean', { defaultValue: true })
  displayNationalMenu!: boolean;
  @DS.attr('boolean', { defaultValue: true })
  hasOnlineOrdering!: boolean;
  @DS.attr('boolean', { defaultValue: true })
  isAcceptingOrders!: boolean;
  @DS.attr('boolean', { defaultValue: false })
  menuIsReadOnly!: boolean;
  @DS.attr('string')
  externalReference!: string;
  @DS.attr('string')
  customOrderPrefix?: string;
  @DS.attr('number', { defaultValue: 0 })
  minimumPickupOrder!: number;
  @DS.attr('number', { defaultValue: 0 })
  minimumDeliveryOrder!: number;
  @DS.attr('boolean', { defaultValue: false })
  hasFreeDelivery!: boolean;
  @DS.attr('string')
  currency?: string;
  @DS.attr('string')
  unavailableMessage?: string;
  @DS.attr('string')
  nextOpenTime?: string;
  @DS.attr('boolean', { defaultValue: true })
  isOpen?: boolean;
  @DS.attr('object')
  overrideReason?: OverrideReason;

  @DS.belongsTo('channel')
  channel!: ChannelModel;
  @DS.attr('array')
  disclaimers!: Disclaimer[];

  @DS.hasMany('category', { async: true })
  categories!: DS.PromiseManyArray<CategoryModel>;

  get menuCategories(): CategoryModel[] {
    return this.categories.filter(c => !c.isSingleUse);
  }

  get singleUseCategories(): CategoryModel[] {
    return this.categories.filter(c => c.isSingleUse);
  }

  @DS.hasMany('loyalty-scheme', { async: false })
  loyaltySchemes!: DS.ManyArray<LoyaltyScheme>;
  @DS.hasMany('product', { async: true })
  products!: CP<DS.PromiseManyArray<ProductModel>>;
  @DS.hasMany('upsell-group', { async: false })
  embeddedProductGroups!: DS.ManyArray<UpsellGroupModel>;

  @computed('settings.acceptedCreditCardTypes.[]')
  get sortedAcceptedCreditCardTypes(): CardType[] {
    const types = [...this.settings.acceptedCreditCardTypes];
    return types.sort().reverse();
  }

  @computed('loyaltySchemes.@each.compCardsEnabled')
  get compCardsEnabled(): boolean {
    return isSome(this.loyaltySchemes.find(s => s.compCardsEnabled));
  }

  @memberAction<void>({ type: 'post', path: 'toggleasfavorite' })
  toggleFavoriteAction!: MemberAction<void>;

  @memberAction<OrderCriteria, VendorPreCheckResult>({
    type: 'post',
    path: 'pre-check',
    before: buildPreCheckPayload,
    after(this: Vendor, response: VendorPreCheckResult) {
      this.storage.set('lastPreCheckValid', response.isValid);
      return response;
    },
  })
  preCheckAction!: MemberAction<OrderCriteria, VendorPreCheckResult>;

  toggleFavorite(): void {
    toggleVendorFavorite.call(this);
  }

  preCheck(criteria: OrderCriteria): RSVP.Promise<VendorPreCheckResult> {
    return this.preCheckAction(criteria);
  }

  serializeForGlobalData(): GlobalVendor {
    return pick(
      this,
      'id',
      'address',
      'allowAdvanceOrders',
      'allowCoupons',
      'allowImmediateOrders',
      'allowManualFireOrders',
      'currency',
      'displayNationalMenu',
      'externalReference',
      'isAcceptingOrders',
      'isFavorite',
      'latitude',
      'longitude',
      'minimumDeliveryOrder',
      'minimumPickupOrder',
      'name',
      'phoneNumber',
      'slug',
      'status',
      'timeZoneId',
      'utcOffset'
    );
  }
}
