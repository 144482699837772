export default {
  "overlay": "_overlay_ykti7y",
  "modal": "_modal_ykti7y",
  "fullHeight": "_fullHeight_ykti7y",
  "header": "_header_ykti7y",
  "title": "_title_ykti7y",
  "closeButton": "_closeButton_ykti7y",
  "closeButtonIcon": "_closeButtonIcon_ykti7y",
  "body": "_body_ykti7y",
  "footer": "_footer_ykti7y",
  "button": "_button_ykti7y",
  "errorMessage": "_errorMessage_ykti7y",
  "errorIcon": "_errorIcon_ykti7y"
};
