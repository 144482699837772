import a0 from "../header-button/index.ts";
window.define('mobile-web/components/header-button', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "oSfpfggA",
  "block": "[[[41,[30,0,[\"channel\",\"settings\",\"parentSiteUrl\"]],[[[1,\"  \"],[11,0],[17,1],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-headerHome\",\"\"]],[[\"@label\",\"@showLabel\",\"@onClick\",\"@icon\",\"@testSelector\"],[[28,[37,2],null,[[\"text\"],[[28,[37,3],[\"mwc.header.homeAltText\"],null]]]],false,[30,0,[\"onClick\"]],[28,[37,2],null,[[\"svg\",\"ariaLabel\",\"position\"],[\"home\",\"\",\"left\"]]],\"home\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"if\",\"header-button\",\"hash\",\"t\"]]",
  "moduleName": "mobile-web/components/header-home/index.hbs",
  "isStrictMode": false
});