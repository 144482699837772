import { assert } from '@ember/debug';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import MotionService from 'ember-animated/services/-ea-motion';
import fade from 'ember-animated/transitions/fade';
import IntlService from 'ember-intl/services/intl';

import { HeadingLevel } from 'mobile-web/components/heading';
import isSome from 'mobile-web/lib/utilities/is-some';
import BasketProductModel from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import NotificationsService from 'mobile-web/services/notifications';

import style from './index.m.scss';

export const MAX_DESC_LENGTH = 70;

export enum ProductCardMode {
  Menu = 'menu',
  Modal = 'modal',
  Page = 'page',
  List = 'list',
}

type ProductCardLocation = 'category' | 'single-use' | 'vendor-menu';
// can we remove this type? it seems to be a subset for ProductClickFrom for no added value

export enum QuickAddState {
  Default = 'default',
  Active = 'active',
  Success = 'success',
}

interface Args {
  // Required arguments
  headingLevel: HeadingLevel;
  product: ProductModel;

  // Optional arguments
  isLandscape?: boolean;
  mode?: ProductCardMode;
  location?: ProductCardLocation;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class ProductCard extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service('-ea-motion') motion!: MotionService;
  @service notifications!: NotificationsService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service globalEvents!: GlobalEventsService;

  // Untracked properties
  style = style;
  transition = fade;

  // Tracked properties
  @tracked basketProduct?: BasketProductModel;
  @tracked showSuccess = false;

  // Getters and setters

  get quickAddState(): QuickAddState {
    if (this.showSuccess) {
      return QuickAddState.Success;
    }

    if (this.basketProduct && this.basketProduct.saveTask.isRunning) {
      return QuickAddState.Active;
    }

    return QuickAddState.Default;
  }

  get isQuickAddInProgress(): boolean {
    return this.quickAddState !== QuickAddState.Default;
  }

  get modeClass(): string {
    return this.style[this.mode];
  }

  get isProductPage(): boolean {
    return this.mode === ProductCardMode.Page || this.mode === ProductCardMode.Modal;
  }

  get showProductName(): boolean {
    return this.mode !== ProductCardMode.Modal;
  }

  get showFeatured(): boolean {
    return this.mode === ProductCardMode.Menu && this.args.product.isFeatured;
  }

  /**
   * Indicates that the quick-add button is disabled and can't quick-add
   * additional items
   */
  get quickAddDisabled(): boolean {
    /**
     * basketMaxQuantity is a required field on admin, because it can be undefined on serve until we merge [OLO-33689]
     * we are defaulting to 8000
     */
    const basketMaxQuantity = this.channel.settings?.basketMaxQuantity ?? 8000;

    const productMaxQuantity = this.args.product.maximumTotalQuantity;

    const productQuantity = this.basket.basketProducts
      .filter(item => item.product.get('id') === this.args.product.id)
      .reduce((sum, product) => sum + product.quantity, 0);

    const basketProductQuantity = this.basket.basketProducts
      .filter(item => item.product.get('isSingleUse') === false)
      .reduce((sum, product) => sum + product.quantity, 0);

    if (this.args.product.isSingleUse) {
      return isSome(productMaxQuantity) ? productQuantity >= productMaxQuantity : false;
    }

    return (
      basketProductQuantity >= basketMaxQuantity ||
      (isSome(productMaxQuantity) ? productQuantity >= productMaxQuantity : false)
    );
  }

  /**
   * Indicates that the quick-add functionality isn't supported for this product
   */
  get quickAddSupported(): boolean {
    return this.mode === ProductCardMode.Menu && this.args.product.quickAddSupported;
  }

  get showAvailabilityDescription(): boolean {
    const showProductAvailability = this.channel.settings?.showProductAvailability ?? true;
    const hasDescription = Boolean(this.args.product.availabilityDescription);
    return this.mode !== ProductCardMode.List && showProductAvailability && hasDescription;
  }

  get showImage(): boolean {
    const hasImage = Boolean(this.args.product.menuImage);
    const singleUse = this.args.product.isSingleUse;
    return hasImage && !singleUse;
  }

  get showPrice(): boolean {
    return (
      this.args.product.baseCost > 0 &&
      (this.fullDescription || (this.channel.settings?.showProductMenuPrices ?? true))
    );
  }

  get showPriceAndCalorieLine(): boolean {
    return !(this.mode === ProductCardMode.List && this.isDisabled);
  }

  get isDisabled(): boolean {
    return this.args.product.isDisabled;
  }

  get showDescription(): boolean {
    return this.mode !== ProductCardMode.List && !isEmpty(this.description);
  }

  get showLabels(): boolean {
    return this.mode !== ProductCardMode.List;
  }

  get description(): string {
    const product = this.args.product;
    if (this.fullDescription) {
      return product.description;
    }
    if (isEmpty(product.shortDescription)) {
      return product.description.length > MAX_DESC_LENGTH
        ? `${product.description.slice(0, MAX_DESC_LENGTH)}...`
        : product.description;
    }
    return product.shortDescription;
  }

  get fullDescription() {
    return this.mode !== ProductCardMode.Menu;
  }

  get isLandscape() {
    return this.args.isLandscape ?? false;
  }

  get mode() {
    return this.args.mode ?? ProductCardMode.Menu;
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    assert('`product` is required', isSome(this.args.product));
  }

  // Other methods

  // Tasks

  // Actions
  @action
  quickAdd(e: Event) {
    e.stopPropagation();

    if (this.quickAddDisabled) {
      return;
    }

    this.basket.quickAddTask.perform(this.args.product);
  }

  @action
  goToProduct(e: Event) {
    if (!this.isProductPage) {
      if (this.mode !== ProductCardMode.List) {
        e.stopPropagation();
      }

      const product = this.args.product;

      if (this.isDisabled) {
        return;
      }

      this.globalEvents.trigger(
        GlobalEventName.ClickProductLink,
        {
          ...product.serializeForGlobalData(),
          hasImages: this.showImage,
          hasPrice: this.showPrice,
        },
        (this.args.location ?? 'vendor-menu') as ProductClickFrom
      );

      this.analytics.trackEvent(AnalyticsEvents.ViewProductCustomization, () => ({
        [AnalyticsProperties.ProductName]: product.name,
        [AnalyticsProperties.ProductCategory]: product.category?.name,
        [AnalyticsProperties.ProductBasePrice]: product.baseCost,
        [AnalyticsProperties.HasVisibleCalories]: !isEmpty(product.calorieLabel),
        [AnalyticsProperties.VisibleLabels]: product.labels.map(l => l.name),
        [AnalyticsProperties.HasProductImages]: this.showImage,
        [AnalyticsProperties.HasCategoryImages]: !isEmpty(product.category?.bannerImage),
        [AnalyticsProperties.HasVisiblePrice]: this.showPrice,
        [AnalyticsProperties.ProductAvailableOptionGroupCount]: product
          .hasMany('optionGroups')
          .ids().length,
        [AnalyticsProperties.Source]: 'Product Card',
      }));

      if (
        product.optionGroups.length === 0 &&
        this.router.currentRouteName !== 'menu.vendor.index'
      ) {
        this.bus.trigger('showProductModal', {
          product,
          onSave: () => {
            this.basket.open();
          },
        });
      } else {
        this.router.transitionTo('menu.vendor.products', product.vendor.slug, product.id);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProductCard: typeof ProductCard;
  }
}
