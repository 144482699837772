import a0 from "../../modal/index.ts";
window.define('mobile-web/components/modal', function () {
  return a0;
});
import a1 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "f8CDsif7",
  "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-exitOnPremiseModal\",\"\"]],[[\"@title\",\"@onClose\",\"@buttons\"],[[28,[37,2],[\"mwc.exitOnPremiseModal.title\"],null],[30,0,[\"continueOnPremise\"]],\"yield\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"body\"]],[[[1,\"      \"],[8,[30,1,[\"section\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,2],[14,\"data-test-exitOnPremiseModal-message\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"mwc.exitOnPremiseModal.message\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"footer\"]],[[[1,\"      \"],[8,[39,3],[[16,0,[30,1,[\"buttonClass\"]]],[24,\"data-test-exitOnPremiseModal-continue-on-premise\",\"\"]],[[\"@onClick\",\"@variant\"],[[30,0,[\"continueOnPremise\"]],\"minor\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"mwc.exitOnPremiseModal.continueOnPremiseButton\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,3],[[16,0,[30,1,[\"buttonClass\"]]],[24,\"data-test-exitOnPremiseModal-switchtoToGo\",\"\"]],[[\"@onClick\"],[[30,0,[\"switchtoToGo\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"mwc.exitOnPremiseModal.switchtoToGoButton\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\"],false,[\"if\",\"modal\",\"t\",\"button\"]]",
  "moduleName": "mobile-web/components/on-premise/exit-on-premise-modal/index.hbs",
  "isStrictMode": false
});