export default {
  "root": "_root_tsoih6",
  "rootCrumb": "_rootCrumb_tsoih6",
  "map": "_map_tsoih6",
  "mapGraphic": "_mapGraphic_tsoih6",
  "mapSvg": "_mapSvg_tsoih6",
  "activeRegion": "_activeRegion_tsoih6",
  "container": "_container_tsoih6",
  "heading": "_heading_tsoih6",
  "intlHeading": "_intlHeading_tsoih6",
  "collapse": "_collapse_tsoih6",
  "listContainer": "_listContainer_tsoih6",
  "link": "_link_tsoih6",
  "content": "_content_tsoih6"
};
