import DS from 'ember-data';

import { UserModelPayload } from 'mobile-web/adapters/account';
import { CustomField } from 'mobile-web/lib/custom-field';
import { UserData } from 'mobile-web/lib/customer';
import Method, { Variant } from 'mobile-web/lib/payment/method';
import isSome from 'mobile-web/lib/utilities/is-some';
import { StoredBasket } from 'mobile-web/models/basket';
import LoyaltyAccountModel from 'mobile-web/models/loyalty-account';

export enum UserType {
  Guest = 'Guest',
  ExistingUser = 'ExistingUser',
  CreateAccount = 'CreateAccount',
}

// eslint-disable-next-line no-use-before-define
export type StoredOrderSubmission = Pick<OrderSubmission, 'selectedBillingMethods'> & {
  basket: StoredBasket;
  loyaltyAccount?: Pick<LoyaltyAccountModel, 'membershipId' | 'schemeProviderName'>;
  memberships?: { id: string; isGiftCard?: boolean; isBrandedCard?: boolean }[];
  createOloAccount?: boolean;
};

/**
 * This is another model that doesn't really "exist" in the sense that you can't
 * get an instance back from the server. It exists purely to integrate with Ember Data.
 * The only thing we do with it is generate an instance and call `save`; this submits
 * an order to the backend. It's needed because the data we send to the server
 * (this model) and the data we get back from the server (`OrderModel`) have
 * completely different shapes.
 */
export default class OrderSubmission extends DS.Model {
  store!: DS.Store;

  // orderId is a property we never actually read or write.
  // We don't use it, but Ember Data expects it to be there
  // so when it saves this object it gets an id back from the server.
  @DS.attr('string')
  orderId!: string;

  @DS.attr('string')
  basketId!: string;
  @DS.attr('string')
  userType!: UserType;
  @DS.attr('boolean')
  createOloAccount?: boolean;
  @DS.attr('boolean')
  saveGuest!: boolean;
  @DS.attr('boolean')
  guestNewsletterOptIn?: boolean;
  @DS.attr('array')
  selectedBillingMethods!: Method[];
  @DS.attr('array')
  customFields?: CustomField[];
  @DS.attr('object')
  bookingUser!: UserData;
  @DS.attr('object')
  receivingUser!: UserData;
  @DS.attr('object')
  user!: UserModelPayload;

  serializeForStorage(): StoredOrderSubmission {
    const basket = this.store.peekRecord('basket', this.basketId)!.serializeForStorage();
    let loyaltyAccount: StoredOrderSubmission['loyaltyAccount'] = undefined;
    if (basket.reward) {
      const match = this.store
        .peekAll('loyalty-account')
        ?.find(a =>
          a.qualifyingLoyaltyRewards.find(
            r => r.externalReference === basket.reward?.externalReference
          )
        );
      if (match) {
        loyaltyAccount = {
          membershipId: match.membershipId,
          schemeProviderName: match.schemeProviderName,
        };
      }
    }
    const selectedBillingMethods = this.selectedBillingMethods.toArray();
    const memberships = selectedBillingMethods
      .map(m => {
        if (m.variant === Variant.Membership) {
          return {
            id: m.membershipId,
            isGiftCard: this.store.peekRecord('billing-scheme', m.schemeId)?.isGiftCard,
            isBrandedCard: this.store.peekRecord('billing-scheme', m.schemeId)?.isBrandedCard,
          };
        }
        return undefined;
      })
      .filter(isSome);
    return {
      basket,
      selectedBillingMethods,
      memberships,
      loyaltyAccount,
      createOloAccount: this.createOloAccount,
    };
  }
}
