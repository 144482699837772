import a0 from "../icons/loading-spinner/index.ts";
window.define('mobile-web/components/icons/loading-spinner', function () {
  return a0;
});
import a1 from "../modal-dialog/index.ts";
window.define('mobile-web/components/modal-dialog', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "TcJ8xn71",
  "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[16,0,[52,[30,2],[30,0,[\"style\",\"loadingIndicator\"]]]],[24,\"data-test-loading-indicator\",\"\"],[17,3],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,0],[15,0,[30,0,[\"style\",\"loadingText\"]]],[14,\"data-focusWhen\",\"loading\"],[12],[1,[30,0,[\"label\"]]],[1,\"…\"],[13],[1,\"\\n      \"],[10,0],[15,0,[30,0,[\"style\",\"spinner\"]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@class\"],[[30,0,[\"style\",\"spinnerIcon\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],[[16,\"aria-labelledby\",[30,0,[\"id\"]]]],[[\"@overlayClass\",\"@containerClass\"],[[30,0,[\"style\",\"overlay\"]],[30,0,[\"style\",\"loadingIndicator\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,1,[30,0,[\"id\"]]],[15,0,[30,0,[\"style\",\"loadingText\"]]],[14,\"data-focusWhen\",\"loading\"],[12],[1,[30,0,[\"label\"]]],[1,\"…\"],[13],[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"spinner\"]]],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n          \"],[8,[39,1],null,[[\"@iconClass\"],[[30,0,[\"style\",\"spinnerIcon\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@display\",\"@inline\",\"&attrs\"],false,[\"if\",\"icons/loading-spinner\",\"modal-dialog\"]]",
  "moduleName": "mobile-web/components/loading-indicator/index.hbs",
  "isStrictMode": false
});