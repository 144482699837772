import { alias } from '@ember/object/computed';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';

import { addScript } from 'mobile-web/lib/utilities/add-script';
import BootstrapService from 'mobile-web/services/bootstrap';

export default class AccessibilityService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service router!: RouterService;

  // Untracked properties
  private initialized = false;

  // Tracked properties

  // Getters and setters
  @alias('bootstrap.data.channel.settings.channelAllyableLicense')
  channelAllyableLicense?: string;

  // Constructor

  // Other methods
  setup(): void {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    if (this.channelAllyableLicense && !this.router.currentRouteName?.includes('secure')) {
      addScript(`https://portal.allyable.com/aweb?license=${this.channelAllyableLicense}`);
    }
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    accessibility: AccessibilityService;
  }
}
