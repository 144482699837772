import { readOnly } from '@ember/object/computed';
import Service from '@ember/service';

import update from 'ember-object-update';

export enum NotificationLevel {
  Success,
  Info,
  Warning,
  Error,
}

export enum NotificationType {
  ProductAdded,
  ProductUpdated,
  ProductUpdatedOutsideBasket,
  ProductRemoved,
  UpsellAdded,
  CartTransferred,
  AccountDeleted,
  ProductQuickAdded,
  OloAuthCodeSent,
}

export interface Notification {
  level: NotificationLevel;
  message: string;
  meta?: Dict<any>;
  type: NotificationType;
}

type Message = Pick<Notification, 'message' | 'meta' | 'type'>;

/**
 * NotificationService will show messages to the user as a toast.
 * Many messages can be show at once, and will dismiss automatically after a timeout.
 * Toasts may show in a variety of locations, including: side of header, bottom of page, and top of cart.
 */
export default class NotificationsService extends Service {
  // Service injections

  // Untracked properties
  _notifications: Notification[] = [];

  // Tracked properties

  // Getters and setters
  @readOnly('_notifications')
  notifications!: Notification[];

  // Constructor

  // Other methods
  show(newNotifications: Notification[]) {
    update(this, '_notifications', notifications => notifications.concat(newNotifications));
  }

  dismiss(notification: Notification) {
    update(this, '_notifications', notifications => notifications.filter(n => n !== notification));
  }

  success({ message, meta, type }: Message) {
    const msg = {
      level: NotificationLevel.Success,
      message,
      meta,
      type,
    };

    update(this, '_notifications', notifications => notifications.concat(msg));

    return msg;
  }

  info({ message, meta, type }: Message) {
    const msg = {
      level: NotificationLevel.Info,
      message,
      meta,
      type,
    };

    update(this, '_notifications', notifications => notifications.concat(msg));

    return msg;
  }

  warning({ message, meta, type }: Message) {
    const msg = {
      level: NotificationLevel.Warning,
      message,
      meta,
      type,
    };

    update(this, '_notifications', notifications => notifications.concat(msg));

    return msg;
  }

  error({ message, meta, type }: Message) {
    const msg = {
      level: NotificationLevel.Error,
      message,
      meta,
      type,
    };

    update(this, '_notifications', notifications => notifications.concat(msg));

    return msg;
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    notifications: NotificationsService;
  }
}
