import { action } from '@ember/object';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  name: string;
  svg: string;

  // Optional arguments
  route?: string;
  onClick?: Action;
}

export default class SideMenuLink extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get translation(): string {
    return 'mwc.sideMenu.' + this.args.name + 'Link';
  }

  get testSelector(): string {
    if (this.args.route) {
      return 'side-menu-' + dasherize(this.args.name);
    }
    return dasherize(this.args.name);
  }
  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  onLinkClick(e: Event) {
    this.args.onClick?.(e);
  }
}
