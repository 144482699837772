import a0 from "../components/routes/dispatch-status-route/index.ts";
window.define('mobile-web/components/routes/dispatch-status-route', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "JwFnwOWX",
  "block": "[[[8,[39,0],null,[[\"@order\",\"@hash\",\"@status\"],[[30,0,[\"model\",\"order\"]],[30,0,[\"hash\"]],[30,0,[\"model\",\"dispatchStatus\"]]]],null]],[],false,[\"routes/dispatch-status-route\"]]",
  "moduleName": "mobile-web/templates/dispatch-status.hbs",
  "isStrictMode": false
});