import a0 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import a1 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a1;
});
import a2 from "../../modifiers/will-destroy.js";
window.define('mobile-web/modifiers/will-destroy', function () {
  return a2;
});
import a3 from "../form-field/index.ts";
window.define('mobile-web/components/form-field', function () {
  return a3;
});
import a4 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a4;
});
import a5 from "../../helpers/or.js";
window.define('mobile-web/helpers/or', function () {
  return a5;
});
import a6 from "../../helpers/and.js";
window.define('mobile-web/helpers/and', function () {
  return a6;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "p7LYNOSx",
  "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"style\",\"root\"]],[52,[30,0,[\"active\"]],[30,0,[\"style\",\"active\"]]]],null]],[24,\"data-test-typeahead\",\"\"],[17,1],[4,[38,2],[[30,0,[\"setupOutsideClick\"]]],null],[4,[38,3],[[30,0,[\"teardownOutsideClick\"]]],null],[12],[1,\"\\n  \"],[8,[39,4],null,[[\"@name\",\"@label\",\"@type\",\"@inputValue\",\"@inlineLabel\",\"@icon\",\"@hideLabel\",\"@placeholder\",\"@size\",\"@onFocus\"],[\"productSearch\",[28,[37,5],[\"mwc.typeahead.searchLabel\"],null],\"text\",[30,0,[\"search\"]],true,\"search\",true,[28,[37,6],[[30,2],[28,[37,5],[\"mwc.typeahead.placeholderText\"],null]],null],\"small\",[30,0,[\"onFocusIn\"]]]],null],[1,\"\\n\"],[41,[28,[37,7],[[30,0,[\"active\"]],[30,0,[\"search\"]]],null],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"resultsContainer\"]]],[12],[1,\"\\n      \"],[10,0],[15,0,[30,0,[\"style\",\"results\"]]],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"matchedItems\"]]],null]],null],null,[[[1,\"          \"],[11,0],[16,0,[30,0,[\"style\",\"result\"]]],[24,\"data-test-typeahead-item\",\"\"],[4,[38,10],[\"click\",[28,[37,11],[[30,0,[\"onSelect\"]],[30,3]],null]],null],[12],[1,\"\\n            \"],[18,4,[[28,[37,13],null,[[\"item\"],[[30,3]]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@placeholderText\",\"item\",\"&default\"],false,[\"classes\",\"if\",\"did-insert\",\"will-destroy\",\"form-field\",\"t\",\"or\",\"and\",\"each\",\"-track-array\",\"on\",\"fn\",\"yield\",\"hash\"]]",
  "moduleName": "mobile-web/components/typeahead/index.hbs",
  "isStrictMode": false
});