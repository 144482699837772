import a0 from "../helpers/trusted-unsafe-html.ts";
window.define('mobile-web/helpers/trusted-unsafe-html', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "qN6y+X9P",
  "block": "[[[10,0],[14,0,\"additional-info\"],[14,\"data-test-customContent\",\"\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"model\",\"htmlContent\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"trusted-unsafe-html\"]]",
  "moduleName": "mobile-web/templates/additional-info.hbs",
  "isStrictMode": false
});