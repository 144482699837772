import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../../../helpers/not-eq.js";
window.define('mobile-web/helpers/not-eq', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "q5bO2D1b",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-routes-openSourceLicensesRoute\",\"\"],[12],[1,\"\\n  \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"header\"]]],[14,\"data-test-routes-openSourceLicensesRoute-header\",\"\"],[12],[1,[28,[35,0],[\"mwc.openSourceLicenses.header\"],null]],[13],[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[10,2],[14,\"data-test-routes-openSourceLicensesRoute-description\",\"\"],[12],[1,[28,[35,0],[\"mwc.openSourceLicenses.description\"],null]],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"      \"],[10,0],[15,0,[30,0,[\"style\",\"licenseDetail\"]]],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,[30,2,[\"packageName\"]]],[13],[1,\"\\n        \"],[10,2],[12],[1,[30,2,[\"license\"]]],[13],[1,\"\\n        \"],[10,2],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,[30,2,[\"text\"]]],[13],[1,\"\\n\"],[41,[28,[37,4],[[30,2],[30,1,[\"lastObject\"]]],null],[[[1,\"          \"],[10,\"hr\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[2]],null]],[]],null],[13]],[\"@licenses\",\"license\"],false,[\"t\",\"if\",\"each\",\"-track-array\",\"not-eq\"]]",
  "moduleName": "mobile-web/components/routes/open-source-licenses-route/index.hbs",
  "isStrictMode": false
});