export default {
  "root": "_root_18ji4f",
  "header": "_header_18ji4f",
  "button": "_button_18ji4f",
  "minimum": "_minimum_18ji4f",
  "tooltip": "_tooltip_18ji4f",
  "icon": "_icon_18ji4f",
  "upIcon": "_upIcon_18ji4f _icon_18ji4f",
  "downIcon": "_downIcon_18ji4f _icon_18ji4f",
  "scheduleDay": "_scheduleDay_18ji4f"
};
