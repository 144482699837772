import Component from '@ember/component';
import { assert } from '@ember/debug';
import { action, computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { SafeString } from '@ember/template/-private/handlebars';

import { tagName } from '@ember-decorators/component';
import IntlService from 'ember-intl/services/intl';
import { MediaService } from 'ember-responsive';

import { OrderCriteria } from 'mobile-web/lib/order-criteria';
import isSome from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import CmsContentService from 'mobile-web/services/cms-content';
import ContentService from 'mobile-web/services/content';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import SideMenuService from 'mobile-web/services/side-menu';

import style from './index.m.scss';

@tagName('')
export default class VendorFrame extends Component {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service cmsContent!: CmsContentService;
  @service content!: ContentService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service media!: MediaService;
  @service onPremise!: OnPremiseService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service sideMenu!: SideMenuService;
  @service analytics!: AnalyticsService;

  // Required arguments
  vendor!: Vendor;

  // Optional arguments
  showInfoModal?: boolean;

  // Class fields
  style = style;

  // Computed properties
  @computed('basket.basket.basketProducts.[]')
  get hasBasketProducts(): boolean {
    return (this.basket.basket?.basketProducts.length ?? 0) > 0;
  }

  @computed('orderCriteria.criteria')
  get criteria(): OrderCriteria {
    return this.orderCriteria.criteria;
  }

  @computed('content', 'vendor.settings.customerFacingMessage')
  get safeCustomerFacingMessage(): SafeString {
    return this.content.dangerouslySanitizeTrustedContent(
      this.vendor.settings.customerFacingMessage
    );
  }

  @computed('cmsContent', 'media.matchers.tablet', 'media.{isMobile,isTablet}')
  get showMenuBanner(): boolean {
    if (this.media.isTablet && this.cmsContent.getContent('menuBanner')?.imageUriTablet) {
      return true;
    } else if (this.media.isMobile && this.cmsContent.getContent('menuBanner')?.imageUriMobile) {
      return true;
    }
    return false;
  }

  @computed('media.isDesktop', 'media.matchers.desktop', 'sideMenu.isEnabled')
  get showRewards(): boolean {
    return this.sideMenu.isEnabled || !this.media.isDesktop;
  }

  get showMultiOrders(): boolean {
    return this.onPremise.isEnabled && !!this.onPremise.multiOrder?.pastOrders?.length;
  }

  get showGroupOrderBanner(): boolean {
    return this.groupOrder.isEnabled && !this.onPremise.multiOrder?.pastOrders?.length;
  }

  get showOrderCriteria(): boolean {
    return this.onPremise.isEnabled && this.showGroupOrderBanner ? false : true;
  }

  // Init
  init() {
    super.init();
    assert('`vendor` is required', isSome(this.vendor));
  }

  // Other methods

  // Actions
  @action
  openOrderCriteriaModal() {
    this.orderCriteria.openModal();
  }

  @action
  toggleFavorite() {
    this.vendor.toggleFavorite();
  }

  @action
  goToMultiOrderConfirmation() {
    if (this.onPremise.multiOrder) {
      this.analytics.trackEvent(AnalyticsEvents.VendorPageOrdersButton);
    }
    this.onPremise.goToMultiOrderConfirmation();
  }
}
