export default {
  "root": "_root_11mcp5",
  "productContainer": "_productContainer_11mcp5",
  "productWithRemove": "_productWithRemove_11mcp5",
  "product": "_product_11mcp5",
  "loadingIcon": "_loadingIcon_11mcp5",
  "removeButton": "_removeButton_11mcp5",
  "removeIcon": "_removeIcon_11mcp5",
  "choiceList": "_choiceList_11mcp5",
  "quantity": "_quantity_11mcp5",
  "name": "_name_11mcp5",
  "toggleChoicesButton": "_toggleChoicesButton_11mcp5",
  "extraInfo": "_extraInfo_11mcp5",
  "editable": "_editable_11mcp5",
  "isCart": "_isCart_11mcp5"
};
