import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import ENV from 'mobile-web/config/environment';
import { addScript } from 'mobile-web/lib/utilities/add-script';
import ChannelService from 'mobile-web/services/channel';

import style from './index.m.scss';

export type MapMarker = {
  name: string;
  lat: number;
  lng: number;
  icon: google.maps.ReadonlySymbol;
  halo?: boolean;
};

/**
 * Icons are sourced from fontawesome, then edited to be centered inside a 20px by 20px canvas.
 * Halo logic only works with 20px by 20px canvas size, so any future icons must conform to that.
 */
export enum MapIcons {
  // https://fontawesome.com/icons/store?style=solid
  Store = 'M16.69 2.93C16.52 2.66 16.22 2.5 15.9 2.5C14.72 2.5 5.28 2.5 4.09 2.5C3.77 2.5 3.47 2.66 3.3 2.93C3.11 3.24 1.6 5.66 1.41 5.96C0.43 7.52 1.29 9.7 3.12 9.95C3.26 9.96 3.39 9.97 3.52 9.97C4.39 9.97 5.16 9.59 5.68 9.01C6.21 9.59 6.98 9.97 7.84 9.97C8.7 9.97 9.47 9.59 9.99 9.01C10.52 9.59 11.29 9.97 12.15 9.97C13.02 9.97 13.78 9.59 14.31 9.01C14.84 9.59 15.6 9.97 16.47 9.97C16.6 9.97 16.73 9.96 16.87 9.95C18.7 9.7 19.57 7.53 18.59 5.96C18.21 5.36 16.88 3.24 16.69 2.93ZM15.61 10.8C15.61 10.99 15.61 11.97 15.61 13.72L4.39 13.72C4.39 11.97 4.39 10.99 4.39 10.8C4.11 10.86 3.82 10.91 3.52 10.91C3.35 10.91 3.17 10.9 3 10.88C2.84 10.85 2.67 10.81 2.52 10.77C2.52 11.35 2.52 15.95 2.52 16.52C2.52 17.04 2.94 17.46 3.45 17.46C4.76 17.46 15.24 17.46 16.55 17.46C17.06 17.46 17.48 17.04 17.48 16.52C17.48 15.95 17.48 11.35 17.48 10.77C17.32 10.82 17.17 10.86 17 10.88C16.82 10.9 16.65 10.91 16.47 10.91C16.18 10.91 15.89 10.87 15.61 10.8Z',
  // https://fontawesome.com/icons/user-circle?style=solid
  Person = 'M2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10ZM12.84 7.94C12.84 9.5 11.57 10.77 10 10.77C8.43 10.77 7.16 9.5 7.16 7.94C7.16 6.37 8.43 5.1 10 5.1C11.57 5.1 12.84 6.37 12.84 7.94ZM5.27 13.99C5.88 12.85 7.07 12.06 8.45 12.06C8.53 12.06 8.61 12.08 8.68 12.1C9.1 12.24 9.54 12.32 10 12.32C10.46 12.32 10.9 12.24 11.32 12.1C11.39 12.08 11.47 12.06 11.55 12.06C12.93 12.06 14.12 12.85 14.73 13.99C13.59 15.34 11.89 16.19 10 16.19C8.11 16.19 6.41 15.34 5.27 13.99Z',
  // https://fontawesome.com/icons/shipping-fast?style=solid
  Car = 'M19 13C19 10.97 19 9.85 19 9.62C19 9.23 18.84 8.84 18.56 8.56C18.25 8.25 15.75 5.75 15.44 5.44C15.16 5.16 14.78 5 14.38 5C14.29 5 13.83 5 13 5C13 4.1 13 3.6 13 3.5C13 2.67 12.33 2 11.5 2C10.7 2 4.3 2 3.5 2C2.67 2 2 2.67 2 3.5C2 3.6 2 4.1 2 5C0.95 5 0.37 5 0.25 5C0.11 5 0 5.11 0 5.25C0 5.3 0 5.7 0 5.75C0 5.89 0.11 6 0.25 6C1.1 6 7.9 6 8.75 6C8.89 6 9 6.11 9 6.25C9 6.3 9 6.7 9 6.75C9 6.89 8.89 7 8.75 7C8 7 2 7 1.25 7C1.11 7 1 7.11 1 7.25C1 7.3 1 7.7 1 7.75C1 7.89 1.11 8 1.25 8C1.9 8 7.1 8 7.75 8C7.89 8 8 8.11 8 8.25C8 8.3 8 8.7 8 8.75C8 8.89 7.89 9 7.75 9C7 9 1 9 0.25 9C0.11 9 0 9.11 0 9.25C0 9.3 0 9.7 0 9.75C0 9.89 0.11 10 0.25 10C0.9 10 6.1 10 6.75 10C6.89 10 7 10.11 7 10.25C7 10.3 7 10.7 7 10.75C7 10.89 6.89 11 6.75 11C6.43 11 4.85 11 2 11C2 13.4 2 14.73 2 15C2 16.66 3.34 18 5 18C6.66 18 8 16.66 8 15C8.4 15 11.6 15 12 15C12 16.66 13.34 18 15 18C16.66 18 18 16.66 18 15C18.15 15 19.35 15 19.5 15C19.77 15 20 14.78 20 14.5C20 14.4 20 13.6 20 13.5C20 13.23 19.77 13 19.5 13C19.43 13 19.27 13 19 13ZM3.5 15C3.5 14.17 4.17 13.5 5 13.5C5.83 13.5 6.5 14.17 6.5 15C6.5 15.83 5.83 16.5 5 16.5C4.17 16.5 3.5 15.83 3.5 15ZM13.5 15C13.5 14.17 14.17 13.5 15 13.5C15.83 13.5 16.5 14.17 16.5 15C16.5 15.83 15.83 16.5 15 16.5C14.17 16.5 13.5 15.83 13.5 15ZM13 10L13 6.5L14.38 6.5L17.5 9.62L17.5 10L13 10Z',
}

interface Args {
  // Required arguments
  // Optional arguments
  markers?: MapMarker[];
  styles?: google.maps.MapTypeStyle[];
}

export const getMapSymbol = (path: MapIcons, color: string): google.maps.ReadonlySymbol => ({
  path,
  fillColor: color,
  fillOpacity: 1,
  strokeColor: color,
  scale: 1,
});

const isSymbol = (
  t: string | google.maps.ReadonlyIcon | google.maps.ReadonlySymbol | null | undefined
): t is google.maps.ReadonlySymbol => t?.hasOwnProperty('fillColor') ?? false;

export default class MapWindow extends Component<Args> {
  // Service injections
  @service channel!: ChannelService;

  // Untracked properties
  map?: google.maps.Map;
  mappedMarkers: Dict<google.maps.Marker[]> = {};
  style = style;

  // Tracked properties
  @tracked scriptLoaded = false;
  @tracked mapElement?: HTMLElement;

  // Getters and setters
  get markers() {
    return this.args.markers ?? [];
  }

  /**
   * Default styles have all roads, road labels, and park backgrounds, nothing else.
   * A clean but still informational map.
   */
  get styles() {
    return (
      this.args.styles ?? [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ]
    );
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    addScript(
      `https://maps.googleapis.com/maps/api/js?key=${
        ENV.DEFAULT_GOOGLE_MAPS_API_KEY
      }&channel=${Math.min(Number(this.channel.current?.id || 0), 999)}`,
      () => {
        this.scriptLoaded = true;
        this.onInit();
      }
    );
  }

  // Other methods
  onInit() {
    if (!this.scriptLoaded || !this.mapElement) {
      return;
    }

    // Simple map with only zoom controls, nothing else
    this.map = new google.maps.Map(this.mapElement, {
      disableDefaultUI: true,
      zoomControl: true,
      styles: this.styles,
    });

    this.updateMarkers();
  }

  updateMarkers() {
    if (this.map) {
      const bounds = new google.maps.LatLngBounds();
      const keysToRemove = Object.keys(this.mappedMarkers);

      this.args.markers?.forEach(m => {
        const position = new google.maps.LatLng(m.lat, m.lng);
        bounds.extend(position);

        if (this.mappedMarkers.hasOwnProperty(m.name)) {
          const drawnObjects = this.mappedMarkers[m.name];
          const marker = drawnObjects[0];
          marker.setIcon(m.icon);
          keysToRemove.removeObject(m.name);
          this.mappedMarkers[m.name].forEach(mm => {
            mm.setPosition(position);
          });
          if (m.halo && drawnObjects.length === 1) {
            // newly halo-ed
            drawnObjects.addObjects(this.addHalo(drawnObjects[0]));
          }
          if (!m.halo && drawnObjects.length > 1) {
            // newly un-halo-ed
            for (let i = 1; i < drawnObjects.length; i++) {
              // eslint-disable-next-line no-null/no-null
              drawnObjects[i].setMap(null);
              delete drawnObjects[i];
            }
          }
        } else {
          const newDrawnObjects = [
            new google.maps.Marker({
              position,
              map: this.map,
              title: m.name,
              icon: m.icon,
            }),
          ];
          if (m.halo) {
            newDrawnObjects.addObjects(this.addHalo(newDrawnObjects[0]));
          }
          this.mappedMarkers[m.name] = newDrawnObjects;
        }
      });

      keysToRemove.forEach(k => {
        // eslint-disable-next-line no-null/no-null
        this.mappedMarkers[k].forEach(m => m.setMap(null));
        delete this.mappedMarkers[k];
      });

      // Automatically center the map fitting all markers on the screen
      this.map.fitBounds(bounds);
    }
  }

  addHalo(marker: google.maps.Marker) {
    const position = marker.getPosition() ?? undefined;
    const icon = marker.getIcon();
    const fillColor = isSymbol(icon) ? icon.fillColor : 'white';
    /**
     * The anchor points of these circles must have a logical calculation, but I don't know it.
     * The given numbers were purely the result of experimentation until it looked good.
     */
    return [
      new google.maps.Marker({
        position,
        map: this.map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor,
          fillOpacity: 0.1,
          scale: 20,
          strokeColor: 'white',
          strokeWeight: 0.5,
          anchor: new google.maps.Point(-0.5, -0.5),
        },
      }),
      new google.maps.Marker({
        position,
        map: this.map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor,
          fillOpacity: 0.2,
          scale: 14,
          strokeColor: 'white',
          strokeWeight: 0.5,
          anchor: new google.maps.Point(-0.7, -0.7),
        },
      }),
    ];
  }

  // Tasks

  // Actions
  @action
  insertElement(e: HTMLElement) {
    this.mapElement = e;
    this.onInit();
  }

  @action
  updateMap() {
    this.updateMarkers();
  }
}
