import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  value?: number;
}

export default class DistanceDisplay extends Component<Args> {
  // Service injections

  // Untracked properties
  ariaUnit = 'miles';
  unit = 'mi';
  style = style;

  get formattedValue() {
    return (this.args.value ?? 0).toFixed(1);
  }

  get ariaLabel(): string {
    return `${this.formattedValue} ${this.ariaUnit}`;
  }

  get label() {
    return `${this.formattedValue} ${this.unit}`;
  }
}
