import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  noRegionResults?: boolean;
  noSearch?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class NoVendorResults extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    NoVendorResults: typeof NoVendorResults;
  }
}
