import a0 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "qfeGa3bv",
  "block": "[[[10,0],[15,0,[28,[37,0],[[30,0,[\"style\",\"container\"]],[52,[51,[30,1]],[30,0,[\"style\",\"noTitle\"]]],[30,2]],null]],[14,\"data-test-modal-section\",\"root\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"h2\"],[14,\"data-test-modal-section\",\"title\"],[15,0,[30,0,[\"style\",\"title\"]]],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,\"data-test-modal-section\",\"content\"],[15,0,[28,[37,0],[[30,0,[\"style\",\"content\"]],[30,3]],null]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"@class\",\"@contentClass\",\"&default\"],false,[\"classes\",\"unless\",\"if\",\"yield\"]]",
  "moduleName": "mobile-web/components/modal-section/index.hbs",
  "isStrictMode": false
});