import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../../custom-icon/index.ts";
window.define('mobile-web/components/custom-icon', function () {
  return a1;
});
import a2 from "../../../helpers/format-currency.ts";
window.define('mobile-web/helpers/format-currency', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "sM6Yq3AB",
  "block": "[[[11,0],[16,\"aria-label\",[28,[37,0],[\"mwc.cart.upsellItemLabel\"],[[\"product\"],[[30,1,[\"name\"]]]]]],[16,\"aria-describedby\",[30,0,[\"describedby\"]]],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"role\",\"button\"],[24,\"tabindex\",\"0\"],[24,\"data-test-cart-upsellItem\",\"\"],[4,[38,1],[\"click\",[30,0,[\"addUpsellItem\"]]],null],[4,[38,1],[\"keypress\",[30,0,[\"handleKeyPress\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"image\"]],[[[1,\"    \"],[10,\"img\"],[14,\"alt\",\"\"],[15,0,[30,0,[\"style\",\"image\"]]],[15,\"src\",[30,1,[\"image\"]]],[14,\"data-test-cart-upsellItem-image\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"h5\"],[15,0,[30,0,[\"style\",\"name\"]]],[14,\"data-test-cart-upsellItem-name\",\"\"],[12],[1,\"\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,1,[\"calorieLabel\"]],[[[1,\"    \"],[10,2],[15,0,[30,0,[\"style\",\"calories\"]]],[14,\"data-test-cart-upsellItem-calories\",\"\"],[15,1,[30,0,[\"ids\",\"calories\"]]],[12],[1,[30,1,[\"calorieLabel\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"labels\"]]],null]],null],null,[[[41,[30,2,[\"icon\"]],[[[1,\"      \"],[10,0],[15,0,[30,0,[\"style\",\"row\"]]],[14,\"data-test-upsellItem-label\",\"\"],[15,1,[28,[37,5],[[30,0,[\"ids\",\"label\"]],\"-\",[30,3]],null]],[12],[1,\"\\n        \"],[8,[39,6],null,[[\"@icon\"],[[30,2,[\"icon\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[2,3]],null],[1,\"\\n  \"],[10,2],[15,0,[30,0,[\"style\",\"cost\"]]],[14,\"data-test-cart-upsellItem-cost\",\"\"],[15,1,[30,0,[\"ids\",\"cost\"]]],[12],[1,\"\\n    \"],[1,[28,[35,7],[[30,1,[\"cost\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@upsellItem\",\"label\",\"index\"],false,[\"t\",\"on\",\"if\",\"each\",\"-track-array\",\"concat\",\"custom-icon\",\"format-currency\"]]",
  "moduleName": "mobile-web/components/cart/upsell-item/index.hbs",
  "isStrictMode": false
});