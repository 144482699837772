import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  isProduct: boolean;
  isViewAll: boolean;

  // Optional arguments
}

export default class ApplicationLoadingMenu extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get eachArray(): number[] {
    return this.args.isViewAll ? [1] : [1, 2];
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
