import a0 from "../modal/index.ts";
window.define('mobile-web/components/modal', function () {
  return a0;
});
import a1 from "../static-page/index.ts";
window.define('mobile-web/components/static-page', function () {
  return a1;
});
import a2 from "../loading-indicator/index.ts";
window.define('mobile-web/components/loading-indicator', function () {
  return a2;
});
import a3 from "../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "8y1wCM7o",
  "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\",\"@onClose\",\"@buttons\"],[[30,0,[\"title\"]],[30,0,[\"close\"]],\"yield\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"body\"]],[[[41,[30,0,[\"content\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"content\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],null,[[\"@display\",\"@inline\"],[true,true]],null],[1,\"\\n\"]],[]]]],[]],[[[41,[30,1,[\"footer\"]],[[[1,\"      \"],[8,[39,4],[[16,0,[30,1,[\"buttonClass\"]]]],[[\"@onClick\"],[[30,0,[\"closeButton\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"buttonLabel\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\"],false,[\"if\",\"modal\",\"static-page\",\"loading-indicator\",\"button\"]]",
  "moduleName": "mobile-web/components/static-content-modal/index.hbs",
  "isStrictMode": false
});