import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/routes/recent-orders-route/index.ts";
window.define('mobile-web/components/routes/recent-orders-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "q63rzx6x",
  "block": "[[[1,[28,[35,0],[\"Recent Orders\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\"]]]],null]],[],false,[\"page-title\",\"routes/recent-orders-route\"]]",
  "moduleName": "mobile-web/templates/recent-orders.hbs",
  "isStrictMode": false
});