import a0 from "../../helpers/eq.js";
window.define('mobile-web/helpers/eq', function () {
  return a0;
});
import a1 from "./content/index.ts";
window.define('mobile-web/components/header-button/content', function () {
  return a1;
});
import a2 from "../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "+wAg78lk",
  "block": "[[[41,[28,[37,1],[[30,0,[\"mode\"]],\"content\"],null],[[[1,\"  \"],[11,1],[16,0,[30,0,[\"buttonStyle\"]]],[16,\"data-test-header-button\",[30,0,[\"testSelector\"]]],[17,1],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@label\",\"@showLabel\"],[[30,2],[30,3],[30,0,[\"showLabel\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"mode\"]],\"link\"],null],[[[1,\"  \"],[8,[39,3],[[16,0,[30,0,[\"buttonStyle\"]]],[16,\"data-test-header-button\",[30,0,[\"testSelector\"]]],[17,1],[4,[38,4],[\"click\",[30,0,[\"onClick\"]]],null]],[[\"@route\"],[[30,0,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@label\",\"@showLabel\"],[[30,2],[30,3],[30,0,[\"showLabel\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[16,\"aria-label\",[52,[51,[30,0,[\"showLabel\"]]],[30,3,[\"text\"]]]],[16,0,[30,0,[\"buttonStyle\"]]],[16,\"data-test-header-button\",[30,0,[\"testSelector\"]]],[24,4,\"button\"],[17,1],[4,[38,4],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\",\"@label\",\"@showLabel\"],[[30,2],[30,3],[30,0,[\"showLabel\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"&attrs\",\"@icon\",\"@label\"],false,[\"if\",\"eq\",\"header-button/content\",\"olo-link-to\",\"on\",\"unless\"]]",
  "moduleName": "mobile-web/components/header-button/index.hbs",
  "isStrictMode": false
});