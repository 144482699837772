import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { WithBoundArgs } from '@glint/template';

import LoyaltyMembership from 'mobile-web/models/loyalty-membership';
import LoyaltyScheme from 'mobile-web/models/loyalty-scheme';
import VendorService from 'mobile-web/services/vendor';

import LinkRewardsButton from './button';
import style from './index.m.scss';
import LinkRewardsSchemes from './schemes';

interface Signature {
  Element: HTMLDivElement;

  Blocks: {
    default: [
      {
        button: WithBoundArgs<
          typeof LinkRewardsButton,
          'isOpen' | 'linkedSchemeNames' | 'memberships' | 'onLink' | 'schemes' | 'vendor'
        >;
        fullyLinked: boolean;
        isOpen: boolean;
        schemes: WithBoundArgs<
          typeof LinkRewardsSchemes,
          'schemes' | 'memberships' | 'linkedSchemeNames' | 'vendor' | 'onLink'
        >;
      }
    ];
  };
}

export default class LinkRewards extends Component<Signature> {
  // Service injections
  @service store!: DS.Store;
  @service('vendor') vendorService!: VendorService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isOpen = false;
  @tracked memberships!: LoyaltyMembership[];

  // Getters and setters

  // Computed properties
  get schemes(): LoyaltyScheme[] {
    return this.vendorService.vendor?.loyaltySchemes?.toArray() ?? [];
  }

  get show(): boolean {
    return !isEmpty(this.schemes);
  }

  /**
   * Returns a list with all scheme names that have been linked.
   * Excludes memberships with isNew because they haven't been
   * saved yet. Does a uniq because you can have more than
   * 1 membership for a given scheme.
   */
  get linkedSchemeNames(): string[] {
    return this.memberships
      .filter(m => !m.isNew)
      .map(m => m.schemeName)
      .uniq();
  }

  get fullyLinked(): boolean {
    return this.linkedSchemeNames.length === this.schemes.length;
  }

  // Constructor
  constructor(owner: unknown, args: AnyObject) {
    super(owner, args);

    this.memberships = this.store.peekAll('loyalty-membership').toArray();
  }

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LinkRewards: typeof LinkRewards;
  }
}
