import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { MediaService } from 'ember-responsive';

import { StaticContentType } from 'mobile-web/components/static-content-modal';
import { appleStoreListingLink, googleStoreListingLink } from 'mobile-web/lib/app-store';
import Data from 'mobile-web/lib/data';
import { classes } from 'mobile-web/lib/utilities/classes';
import { ensureProtocol, displayUrl } from 'mobile-web/lib/utilities/http';
import CustomLinkModel from 'mobile-web/models/custom-link';
import AnalyticsService from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import SideMenuService from 'mobile-web/services/side-menu';

import style from './index.m.scss';

export enum Location {
  SideMenu = 'sideMenu',
  Bottom = 'bottom',
}

export type FooterArguments = {
  location?: Location;
};

export type FooterModel = Data<FooterArguments, string>;

interface Args {
  // Required arguments
  model: FooterModel;

  // Optional arguments
}

export default class Footer extends Component<Args> {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service media!: MediaService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service sideMenu!: SideMenuService;
  @service store!: DS.Store;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked devMenuClickCount = 0;
  @tracked devMenuIsOpen = false;

  // Getters and setters
  get isSideMenu(): boolean {
    return Data.isLoaded(this.args.model) && this.args.model.value.location === Location.SideMenu;
  }

  get isBottom(): boolean {
    return !this.isSideMenu;
  }

  get footerClass(): string {
    return classes(this.style.footer, this.isSideMenu ? this.style.sideMenu : this.style.bottom);
  }

  get orderedCustomLinks(): CustomLinkModel[] {
    return this.store.peekAll('custom-link').sortBy('displayOrder');
  }

  get isLoaded(): boolean {
    return Data.isLoaded(this.args.model);
  }

  get notEmbedded(): boolean {
    return !this.isLoaded || !this.session.isEmbeddedMode;
  }

  get notRestricted(): boolean {
    return !this.isLoaded || !this.session.isRestrictedFlow;
  }

  get showLocations(): boolean {
    return (
      (this.channel.settings?.showParticipatingRestaurants && !this.onPremise.isEnabled) ?? false
    );
  }

  get visitDesktopUrl(): string {
    const url = this.channel.settings?.fullSiteUrl;
    return this.isLoaded && url ? `${ensureProtocol(url)}?stay=y` : '';
  }

  get showParentSiteUrl(): boolean {
    return (
      (this.device.isHybrid || !this.media.isTablet) &&
      this.isLoaded &&
      !isEmpty(this.channel.settings?.parentSiteUrl) &&
      !isEmpty(this.parentSiteDisplayUrl) &&
      !this.session.isEmbeddedMode &&
      !this.session.isRestrictedFlow &&
      !this.basket.basket?.isCallcenterOrder
    );
  }

  get parentSiteDisplayUrl(): string {
    return this.isLoaded ? displayUrl(this.channel.settings?.parentSiteUrl ?? '') : '';
  }

  get reviewInStoreUrl(): string | undefined {
    if (
      !this.isLoaded ||
      !this.sideMenu.isEnabled ||
      !this.device.storeId ||
      this.device.isWebOnly
    ) {
      return undefined;
    }
    return this.device.isAndroid
      ? googleStoreListingLink(this.device.storeId)
      : this.device.isIOS
      ? appleStoreListingLink(this.device.storeId)
      : undefined;
  }

  get showBadgesInTop(): boolean {
    return this.isLoaded && !this.sideMenu.isEnabled;
  }

  get showBadgesInBottom(): boolean {
    return this.isLoaded && this.sideMenu.isEnabled;
  }

  get appVersion(): string {
    const native = this.device.nativeAppVersion ?? '';
    const ember = window.Olo.appVersion?.split('-')[0] ?? '';

    return native + ' (' + ember + ')';
  }

  get serveAppAnalyticsEnabled(): boolean {
    return this.analytics._initialized;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  showModal(type: StaticContentType) {
    this.bus.trigger('showStaticContent', { type });

    this.sideMenu.close();
  }

  @action
  async openAppStoreListing() {
    if (!this.reviewInStoreUrl) {
      return;
    }

    await this.device.launchApp(this.reviewInStoreUrl);
  }

  @action
  async showDevPrompt() {
    this.devMenuClickCount++;
    if (this.devMenuClickCount < 3) {
      return;
    }

    this.devMenuIsOpen = true;
    this.devMenuClickCount = 0;
  }
}
