import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { Mode } from 'mobile-web/components/sign-in-create-account-form';
import { OnPremiseExperience, PastOnPremiseOrder } from 'mobile-web/lib/on-premise';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Args {
  // Required arguments
  currentOrder: OrderModel;

  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class OnPremiseConfirmation extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get showCreateAccountButton() {
    return !this.session.isLoggedIn && this.session.signOnAllowed;
  }

  get allOrders(): Array<PastOnPremiseOrder & { index: number }> {
    // We can assume that `pastOnPremiseOrders` has `currentOrder` because of
    // the thank-you route's afterModel
    const pastOrders = this.onPremise.multiOrder!.pastOrders!;
    return pastOrders.reverse().map((o, i, array) => ({ ...o, index: array.length - i }));
  }

  get allTotal(): number {
    return this.allOrders.reduce((total, o) => total + o.totalCost, 0);
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  orderMore() {
    const slug = this.args.currentOrder.vendor.get('slug');
    if (slug) {
      this.router.transitionTo('menu.vendor', this.args.currentOrder.vendor.get('slug'));
    } else {
      this.router.transitionTo('index');
    }
    if (this.onPremise.experienceType === OnPremiseExperience.MultiOrder) {
      this.analytics.trackEvent(AnalyticsEvents.OrderMore);
    }
  }

  @action
  upgradeAccount() {
    this.router.transitionTo('secure.login', {
      queryParams: { mode: Mode.CreateAccount, upgrade: true },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OnPremiseConfirmation: typeof OnPremiseConfirmation;
  }
}
