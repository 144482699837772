import Component from '@ember/component';
import { assert } from '@ember/debug';
import { action, computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { tagName } from '@ember-decorators/component';

import config from 'mobile-web/config/environment';
import { guids } from 'mobile-web/lib/utilities/guids';
import isSome from 'mobile-web/lib/utilities/is-some';
import DispatchStatusModel, { CourierStatus } from 'mobile-web/models/dispatch-status';
import OrderModel from 'mobile-web/models/order';

import style from './index.m.scss';

const REFRESH_INTERVAL = 60000;

@tagName('')
export default class DispatchSummaryRoute extends Component {
  // Service injections
  @service router!: RouterService;
  @service store!: DS.Store;

  // Required arguments
  order!: OrderModel;
  initialStatuses!: DispatchStatusModel[];

  // Optional arguments
  hash?: string;

  // Class fields
  ids = guids(this, 'heading');
  style = style;
  statuses: DispatchStatusModel[] = [];

  // Computed properties
  @computed('statuses.@each.courierStatus')
  get hasActiveStatus(): boolean {
    return this.statuses.reduce(
      (isActive, status) => isActive || status.courierStatus === CourierStatus.Active,
      false
    );
  }

  // Init
  init() {
    super.init();
    assert('`initialStatuses` is required', isSome(this.initialStatuses));
    assert('`order` is required', isSome(this.order));

    this.statuses.addObjects(this.initialStatuses);

    if (config.environment !== 'test') {
      later(this, this.refresh, REFRESH_INTERVAL);
    }
  }

  // Other methods
  async refresh() {
    if (!this.isDestroying && !this.isDestroyed && this.hasActiveStatus) {
      const newStatuses = await this.store.query('dispatch-status', {
        orderId: this.order.id,
        hash: this.hash,
      });

      newStatuses.forEach(ns => {
        if (!this.statuses.find(s => s.id === ns.id)) {
          this.statuses.addObject(ns);
        }
      });

      later(this, this.refresh, REFRESH_INTERVAL);
    }
  }

  // Actions
  @action
  goToDetails(status: DispatchStatusModel) {
    this.router.transitionTo('dispatch-status', status.order.id, status.id, {
      queryParams: { hash: this.hash },
    });
  }
}
