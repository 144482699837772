import a0 from "../components/application-frame/index.ts";
window.define('mobile-web/components/application-frame', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "DCB1KoQr",
  "block": "[[[8,[39,0],null,[[\"@errorData\"],[[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,\"data-test-app-error\",\"\"],[14,5,\"height: 100%; padding: 100px 20px;\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"friendlyMessage\"]],[[[1,\"      \"],[10,\"h1\"],[14,5,\"padding-bottom: 10px;\"],[12],[1,[30,0,[\"model\",\"messageHeader\"]]],[13],[1,\"\\n      \"],[1,[30,0,[\"model\",\"friendlyMessage\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h1\"],[14,5,\"text-align: center;\"],[12],[1,\"Sorry, something went wrong with your request. Please try again in a few minutes.\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"application-frame\",\"if\"]]",
  "moduleName": "mobile-web/templates/application-error.hbs",
  "isStrictMode": false
});