import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  testSelector?: string;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    buttons: [];
    content: [];
  };
}

export default class Card extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get testSelector() {
    return this.args.testSelector ?? 'root';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Card: typeof Card;
  }
}
