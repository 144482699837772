import a0 from "../../../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../../../components/routes/menu/vendor/rewards-route/index.ts";
window.define('mobile-web/components/routes/menu/vendor/rewards-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "yYeERRm6",
  "block": "[[[1,[28,[35,0],[\"Offers & Rewards\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@vendor\",\"@accounts\",\"@showInfoModal\"],[[30,0,[\"model\",\"vendor\"]],[30,0,[\"model\",\"accounts\"]],[30,0,[\"showInfoModal\"]]]],null]],[],false,[\"page-title\",\"routes/menu/vendor/rewards-route\"]]",
  "moduleName": "mobile-web/templates/menu/vendor/rewards.hbs",
  "isStrictMode": false
});