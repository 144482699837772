import { getOwner } from '@ember/application';
import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';

import { isFunction } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';

export default class RouteAction extends Helper {
  compute([routeName, actionName]: [string, string]): Action {
    const owner = getOwner(this) as AnyObject;
    const route = owner.lookup(`route:${routeName}`);
    assert(`first parameter should be a route`, isSome(route));
    const action = route[actionName];
    assert(`second parameter should be a function on the route`, isFunction(action));
    return action;
  }
}
