import a0 from "../../helpers/not-eq.js";
window.define('mobile-web/helpers/not-eq', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "5cFHCX42",
  "block": "[[[10,0],[15,1,[30,0,[\"uniqueId\"]]],[15,0,[28,[37,0],[[30,0,[\"style\",\"placeholder\"]],\" \",[30,1]],null]],[14,\"data-test-stickyElement-placeholder\",\"\"],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-stickyElement\",\"\"],[15,0,[30,0,[\"stickyElementClass\"]]],[15,5,[30,0,[\"stickyStyle\"]]],[12],[1,\"\\n    \"],[18,2,[[28,[37,2],null,[[\"isSticky\"],[[28,[37,3],[[30,0,[\"isSticky\"]],false],null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@placeholderClass\",\"&default\"],false,[\"concat\",\"yield\",\"hash\",\"not-eq\"]]",
  "moduleName": "mobile-web/components/sticky-element/index.hbs",
  "isStrictMode": false
});