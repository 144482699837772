import { action } from '@ember/object';
import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  url: string;

  // Optional arguments
}

interface Signature {
  Element: HTMLElement;

  Args: Args;
}

export default class FeedbackSection extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  giveFeedback() {
    window.location.href = this.args.url;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::FeedbackSection': typeof FeedbackSection;
  }
}
