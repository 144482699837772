export default {
  "imageCard": "_imageCard_1u53aq",
  "header": "_header_1u53aq",
  "heading": "_heading_1u53aq",
  "vendorProperties": "_vendorProperties_1u53aq",
  "crossStreet": "_crossStreet_1u53aq",
  "vendorLinks": "_vendorLinks_1u53aq",
  "vendorDistance": "_vendorDistance_1u53aq",
  "inlineLinkContainer": "_inlineLinkContainer_1u53aq",
  "placeIcon": "_placeIcon_1u53aq",
  "mapLink": "_mapLink_1u53aq",
  "info": "_info_1u53aq",
  "infoButton": "_infoButton_1u53aq",
  "outerLinkContainer": "_outerLinkContainer_1u53aq",
  "availability": "_availability_1u53aq",
  "timezoneWarning": "_timezoneWarning_1u53aq",
  "favoriteButton": "_favoriteButton_1u53aq",
  "vendorMinTags": "_vendorMinTags_1u53aq",
  "vendorMinTag": "_vendorMinTag_1u53aq"
};
