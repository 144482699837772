import a0 from "../subsection/index.ts";
window.define('mobile-web/components/post-checkout/subsection', function () {
  return a0;
});
import a1 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a1;
});
import a2 from "../../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a2;
});
import a3 from "../../address-display/index.ts";
window.define('mobile-web/components/address-display', function () {
  return a3;
});
import a4 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a4;
});
import a5 from "../../../helpers/format-phone.ts";
window.define('mobile-web/helpers/format-phone', function () {
  return a5;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "nQwjCZhL",
  "block": "[[[10,0],[14,\"data-test-post-checkout-order-location\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@heading\",\"@icon\"],[[30,0,[\"heading\"]],[50,\"svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"store\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@route\",\"@model\",\"@testSelector\"],[\"menu.vendor\",[30,1,[\"slug\"]],\"vendorLink\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n\"],[41,[51,[30,2]],[[[1,\"          \"],[10,\"br\"],[12],[13],[1,\"\\n          \"],[8,[39,4],null,[[\"@model\"],[[30,1,[\"address\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[30,3,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,3],[15,0,[28,[37,5],[[30,0,[\"style\",\"button\"]],[30,3,[\"buttonClass\"]]],null]],[15,6,[29,[\"tel:\",[30,1,[\"phoneNumber\"]]]]],[14,\"data-test-link\",\"vendorPhoneNumber\"],[12],[1,\"\\n          \"],[1,[28,[35,6],[[30,1,[\"phoneNumber\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@vendor\",\"@isDelivery\",\"s\"],false,[\"post-checkout/subsection\",\"component\",\"olo-link-to\",\"unless\",\"address-display\",\"classes\",\"format-phone\"]]",
  "moduleName": "mobile-web/components/post-checkout/order-location/index.hbs",
  "isStrictMode": false
});