import Component from '@glimmer/component';

import { License } from 'mobile-web/lib/license';

import style from './index.m.scss';

export default class OpenSourceLicensesRoute extends Component {
  // Service injections

  // Required arguments
  licenses!: License[];

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
