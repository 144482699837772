import a0 from "../card/index.ts";
window.define('mobile-web/components/card', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../../helpers/format-relative-date.ts";
window.define('mobile-web/helpers/format-relative-date', function () {
  return a2;
});
import a3 from "../../helpers/and.js";
window.define('mobile-web/helpers/and', function () {
  return a3;
});
import a4 from "../tag-list/index.ts";
window.define('mobile-web/components/tag-list', function () {
  return a4;
});
import a5 from "../info-tag/index.ts";
window.define('mobile-web/components/info-tag', function () {
  return a5;
});
import a6 from "../loading-indicator/index.ts";
window.define('mobile-web/components/loading-indicator', function () {
  return a6;
});
import a7 from "../../helpers/or.js";
window.define('mobile-web/helpers/or', function () {
  return a7;
});
import a8 from "../card/button/index.ts";
window.define('mobile-web/components/card/button', function () {
  return a8;
});
import a9 from "../../helpers/perform.js";
window.define('mobile-web/helpers/perform', function () {
  return a9;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Ci8sWamM",
  "block": "[[[8,[39,0],[[16,\"aria-labelledby\",[30,0,[\"ids\",\"label\"]]],[17,1]],[[\"@testSelector\"],[\"reward\"]],[[\"content\",\"buttons\"],[[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"style\",\"card\"]]],[12],[1,\"\\n      \"],[10,2],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-rewardCard-label\",\"\"],[15,1,[30,0,[\"ids\",\"label\"]]],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n\"],[41,[30,2,[\"expiresOn\"]],[[[1,\"        \"],[10,2],[15,0,[30,0,[\"style\",\"exp\"]]],[14,\"data-test-rewardCard-expDate\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"mwc.rewardCard.expirationText\"],[[\"exp\"],[[28,[37,3],[[30,2,[\"expiresOn\"]]],null]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,4],[[30,3],[30,2,[\"schemeName\"]]],null],[[[1,\"        \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[39,6],[[16,0,[30,4,[\"tagClass\"]]]],[[\"@type\",\"@label\"],[\"neutral\",[30,2,[\"schemeName\"]]]],null],[1,\"\\n        \"]],[4]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[8,[39,7],null,[[\"@display\"],[[28,[37,8],[[30,0,[\"applyRewardTask\",\"isRunning\"]],[30,0,[\"removeRewardTask\",\"isRunning\"]]],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n\"],[41,[30,2,[\"isActiveReward\"]],[[[1,\"      \"],[8,[39,9],null,[[\"@testSelector\",\"@variant\",\"@label\",\"@onClick\"],[\"removeReward\",\"destructive\",[28,[37,2],[\"mwc.rewardCard.removeButton\"],null],[28,[37,10],[[30,0,[\"removeRewardTask\"]]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,9],null,[[\"@testSelector\",\"@label\",\"@onClick\"],[\"applyReward\",[28,[37,2],[\"mwc.rewardCard.applyButton\"],null],[30,0,[\"applyReward\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@reward\",\"@showScheme\",\"list\"],false,[\"card\",\"if\",\"t\",\"format-relative-date\",\"and\",\"tag-list\",\"info-tag\",\"loading-indicator\",\"or\",\"card/button\",\"perform\"]]",
  "moduleName": "mobile-web/components/reward-card/index.hbs",
  "isStrictMode": false
});