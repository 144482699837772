import DS from 'ember-data';
import RSVP from 'rsvp';

import ApplicationAdapter from './application';

export type ChangePasswordData = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type ResetPasswordData = {
  id: EmberDataId;
  newPassword: string;
  confirmNewPassword: string;
};

// TODO: handle response *here* intead of making caller do it.

export default class UserAdapter extends ApplicationAdapter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login(email: string, password: string): RSVP.Promise<any> {
    const baseUrl = this.buildURL('user'),
      url = `${baseUrl}/login`;

    return this.ajax(url, 'POST', { data: { email, password } });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalLogin(
    this: UserAdapter,
    nextUrl: string,
    providerSlug: string,
    nativeUrlScheme?: string
  ): RSVP.Promise<{ success: boolean; data: { url: string; state: string } }> {
    const baseUrl = this.buildURL('user'),
      url = `${baseUrl}/oauthlogin?provider=${providerSlug}&url=${nextUrl}${
        nativeUrlScheme ? `&nativeUrlScheme=${nativeUrlScheme}` : ''
      }`;

    return this.ajax(url, 'GET');
  }

  logout(): RSVP.Promise<any> {
    const baseUrl = this.buildURL('user'),
      url = `${baseUrl}/logout`;

    return this.ajax(url, 'POST');
  }

  refreshToken(serveAppToken: string): RSVP.Promise<{ serveAppToken: string }> {
    const baseUrl = this.buildURL('user'),
      url = `${baseUrl}/refreshToken`;

    return this.ajax(url, 'POST', { data: { serveAppToken } });
  }

  updateRecord(store: DS.Store, type: DS.Model, snapshot: DS.Snapshot): RSVP.Promise<unknown> {
    const data: { user?: UnknownObject } = {};

    const serializer = store.serializerFor('user');

    serializer.serializeIntoHash(data, type, snapshot);

    const id = snapshot.id;
    const url = this.buildURL('user', id, snapshot, 'updateRecord');

    return this.ajax(url, 'PUT', { data: data.user });
  }

  deleteRecord(
    _store: DS.Store,
    _type: DS.Model,
    snapshot: DS.Snapshot
  ): RSVP.Promise<{ success: boolean }> {
    const url = this.buildURL('user', snapshot.id);

    return this.ajax(url, 'DELETE');
  }

  buildURL(
    _modelName?: unknown,
    _id?: string,
    snapshot?: unknown,
    _requestType?: string,
    _query?: unknown
  ): string {
    const url = super.buildURL(...arguments);
    const code =
      typeof snapshot === 'object' &&
      !Array.isArray(snapshot) &&
      snapshot?.hasOwnProperty('adapterOptions')
        ? ((snapshot as UnknownObject).adapterOptions as UnknownObject)?.token
        : '';
    const query = code ? `?code=${code}` : '';
    return `${url}${query}`;
  }
}
