import Component from '@ember/component';
import { assert } from '@ember/debug';
import { set, computed, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import { tagName } from '@ember-decorators/component';
import IntlService from 'ember-intl/services/intl';

import dayjs from 'mobile-web/lib/dayjs';
import Address, { Coords, distanceBetweenCoords } from 'mobile-web/lib/location/address';
import { getHandoffLabel, HandoffMode, isDelivery } from 'mobile-web/lib/order-criteria';
import { Calendar, CalendarType, Weekday } from 'mobile-web/lib/time/calendar';
import { hoursForDay, ScheduleAvailabilityKeywords } from 'mobile-web/lib/time/weekly-schedule';
import { isFunction } from 'mobile-web/lib/utilities/_';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import Vendor, { getShortOverrideDesc } from 'mobile-web/models/vendor';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import GroupOrderService from 'mobile-web/services/group-order';
import OrderCriteriaService from 'mobile-web/services/order-criteria';

import style from './index.m.scss';

const days = Object.values(Weekday);
const schedulePrefix = 'mwc.vendorInfo.scheduleAvailability.';

function convertToTime(currentTimeWithTZ: string, targetTime: string) {
  //currentTimeWithTZ format: 2020-01-31T11:30:20-04:00
  //                                    |replacing|
  // Dayjs does not have good timezone support and cannot get the start of a day
  // in a certain timezone. It also requires extra plugins to support parsing.
  // So, this returns the Dayjs format of ?X?X:(?xx?)(am/pm) on the day passed in.
  let newTime;
  if (targetTime === ScheduleAvailabilityKeywords.Midnight) {
    newTime = '00:00:00';
  } else {
    const hasMins = targetTime.includes(':');
    const isPm = targetTime.includes('pm');

    // Strips postfix and converts hours to XX format
    const hours = ((Number(targetTime.split(':')[0].replace(/\D/g, '')) % 12) + (isPm ? 12 : 0))
      .toString()
      .padStart(2, '0');
    const mins = hasMins ? targetTime.split(':')[1].substring(0, 2) : '00';
    newTime = hours + ':' + mins + ':00';
  }
  return currentTimeWithTZ.substring(0, 11) + newTime + currentTimeWithTZ.substring(19);
}

function midnightToLowerCase(time: string) {
  return time.replace('Midnight', 'midnight');
}

@tagName('')
export default class VendorInfo extends Component {
  @service router!: RouterService;
  @service orderCriteria!: OrderCriteriaService;
  @service intl!: IntlService;
  @service groupOrder!: GroupOrderService;
  @service store!: DS.Store;
  @service basket!: BasketService;
  @service error!: ErrorService;
  @service channel!: ChannelService;
  @service device!: DeviceService;

  vendor!: Vendor;
  showInfoModal?: boolean;
  onToggleFavorite?: () => void;

  showMenuLink = false;

  style = style;

  @alias('channel.settings.favorites')
  allowFavorites!: boolean;

  @computed('device.isIOS', 'vendor.address')
  get mapsUrl() {
    return Address.toMapsApplicationLink(this.vendor.address, this.device.isIOS);
  }

  @computed('(this.vendor.address).crossStreet', 'vendor.address.crossStreet')
  get crossStreet() {
    const crossStreet = this.vendor.address.crossStreet;
    return isEmpty(crossStreet) ? '' : `(${crossStreet})`;
  }

  @computed('vendor.name')
  get title() {
    return `${this.vendor.name} Menu`;
  }

  @computed('vendor.utcOffset')
  get showTimezoneWarning(): boolean {
    return dayjs().utcOffset() !== this.vendor.utcOffset * 60;
  }

  @computed('orderCriteria.criteria.handoffMode', 'store')
  get handoffMinTagDescription(): string | undefined {
    // minimumDeliveryOrder and minimumPickupOrder are only enforced
    // by the supportedModes below
    const supportedModes: HandoffMode[] = ['CounterPickup', 'CurbsidePickup', 'Delivery'];

    if (this.orderCriteria.criteria) {
      const handoff = this.orderCriteria.criteria.handoffMode;
      return supportedModes.includes(handoff) ? getHandoffLabel(handoff, this.store) : undefined;
    }
    return undefined;
  }

  @computed(
    'orderCriteria.criteria.handoffMode',
    'vendor.{minimumDeliveryOrder,minimumPickupOrder}'
  )
  get handoffMinTagAmount(): number | undefined {
    if (this.orderCriteria.criteria) {
      const handoff = this.orderCriteria.criteria.handoffMode;
      return handoff === 'Delivery'
        ? this.vendor.minimumDeliveryOrder
        : this.vendor.minimumPickupOrder;
    }
    return undefined;
  }

  @computed('router.currentRouteName')
  get showInfoAsLink() {
    return this.router.currentRouteName === 'menu.vendor.index';
  }

  @computed(
    'error',
    'orderCriteria.{basketOrderCriteria,criteria,searchOrderCriteria}',
    'store',
    'vendor'
  )
  get vendorDistance() {
    if (this.orderCriteria.criteria) {
      const oc = this.orderCriteria.criteria;
      if (isDelivery(oc) && oc.deliveryAddress?.id) {
        const address = this.store.peekRecord('address', oc.deliveryAddress.id);
        if (isSome(address)) {
          try {
            return distanceBetweenCoords(address as Coords, this.vendor);
          } catch (e) {
            this.error.sendExternalError(e);
          }
        }
      }
    }
    return undefined;
  }

  @computed(
    'error',
    'intl',
    'orderCriteria.criteria.isDefault',
    'store',
    'vendor.{overrideReason,utcOffset,weeklySchedule}'
  )
  get availabilityText(): string {
    if (this.vendor.overrideReason) {
      return getShortOverrideDesc(this.vendor.overrideReason, this.intl);
    }
    const schedule = this.vendor.weeklySchedule;
    let weekSchedule =
      schedule.calendars.find(c => c.scheduleDescription === CalendarType.Business) ||
      schedule.calendars[0];
    let mode: string | undefined;
    if (!this.orderCriteria.criteria.isDefault) {
      const oc = this.orderCriteria.criteria;
      mode = getHandoffLabel(oc.handoffMode, this.store);
      const ocWeekSchedule = schedule.calendars.find(c => c.scheduleDescription === mode);
      if (ocWeekSchedule) {
        weekSchedule = ocWeekSchedule;
      } else if (oc.handoffMode !== 'CounterPickup') {
        // When not using a handoff specific schedule, do not display
        // handoff specific message - except with Counter Pickup,
        // as it derives from the Business schedule
        mode = undefined;
      }
    }

    // All times based around vendor time zone
    const userTimeAtStore = dayjs().utcOffset(this.vendor.utcOffset);
    let currentWeekDay = schedule.currentWeekDay;
    if (userTimeAtStore.day() !== days.indexOf(currentWeekDay)) {
      // This should never happen. The day at the store must be the day for the user
      // at the store in the store's timezone.
      // Could send at around midnight for user if not refreshed,
      // so want to restore to "fresh" value if required
      this.error.sendExternalError(new Error('Vendor current weekday incongruent with user time'));
      currentWeekDay = days[userTimeAtStore.day()];
    }

    // currentWeekDay is already relative to vendor time zone
    const hours = hoursForDay(currentWeekDay, weekSchedule);

    if (hours === ScheduleAvailabilityKeywords.Closed) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayClosed', { mode })
        : this.intl.t(schedulePrefix + 'todayClosed');
    }

    let start = '',
      end = '',
      startTime = '',
      endTime = '';
    if (hours !== ScheduleAvailabilityKeywords.AllDay) {
      start = hours.split('-')[0];
      end = hours.split('-')[1];
      startTime = convertToTime(userTimeAtStore.format(), start);
      endTime =
        // Ending at midnight is actually 12am on the next day
        end === ScheduleAvailabilityKeywords.Midnight
          ? convertToTime(userTimeAtStore.add(1, 'd').format(), end)
          : convertToTime(userTimeAtStore.format(), end);
    }

    // Workaround for MWC-3921
    // to support multi-day availability spans
    if (hours.includes(',')) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayMultiRange', { mode, range: hours })
        : this.intl.t(schedulePrefix + 'todayMultiRange', { range: hours });
    } else if (hours.includes('(') || dayjs(startTime).unix() > dayjs(endTime).unix()) {
      // Two day spans wrapped in parentheses when hour overlap
      // Otherwise, end hour must be < start hour
      start = midnightToLowerCase(start);
      end = midnightToLowerCase(end);
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayOpenNowVerbose', { mode, start, end })
        : this.intl.t(schedulePrefix + 'todayOpenNow', { start, end });
    }

    if (
      hours === ScheduleAvailabilityKeywords.AllDay ||
      (dayjs().isBetween(startTime, endTime) && end === ScheduleAvailabilityKeywords.Midnight)
    ) {
      return this.consecutiveDayAvailabilityMessaging(
        weekSchedule,
        mode,
        currentWeekDay,
        start,
        end
      );
    }

    start = midnightToLowerCase(start);
    end = midnightToLowerCase(end);
    if (dayjs().isBetween(startTime, endTime)) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayOpenNow', { mode, end })
        : this.intl.t(schedulePrefix + 'todayOpenNow', { start, end });
    } else if (dayjs().isBefore(startTime)) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffBeforeOpening', { mode, start })
        : this.intl.t(schedulePrefix + 'beforeOpening', { start });
    }
    return mode
      ? this.intl.t(schedulePrefix + 'handoffNowTodayClosed', { mode })
      : this.intl.t(schedulePrefix + 'nowTodayClosed');
  }

  init() {
    super.init();

    assert('VendorInfo: `vendor` is required', isSome(this.vendor));
    assert(
      'VendorInfo: `onToggleFavorite` function is required if `allowFavorites` is true',
      this.allowFavorites ? isFunction(this.onToggleFavorite) : true
    );
  }

  @action
  closeInfoModal() {
    set(this, 'showInfoModal', undefined);
  }

  @action
  viewMenu() {
    this.router.transitionTo('menu.vendor', this.vendor.slug);
  }

  consecutiveDayAvailabilityMessaging(
    weekSchedule: Calendar,
    mode: string | undefined,
    currentWeekDay: Weekday,
    start: string,
    end: string
  ) {
    // Checking for 24/7 availability
    if (
      weekSchedule.schedule.filterBy('description', ScheduleAvailabilityKeywords.AllDay).length ===
      days.length
    ) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffOpen24/7', { mode })
        : this.intl.t(schedulePrefix + 'open24/7');
    }

    const index = days.indexOf(currentWeekDay);
    let pointer = -1;
    for (let i = 1; i < days.length; i++) {
      pointer = (i + index) % days.length;
      const dayHours = hoursForDay(days[pointer], weekSchedule);
      if (
        dayHours !== ScheduleAvailabilityKeywords.AllDay &&
        (dayHours === ScheduleAvailabilityKeywords.Closed ||
          dayHours.split('-')[0] !== ScheduleAvailabilityKeywords.Midnight)
      ) {
        pointer = (i - 1 + index) % days.length;
        break;
      } else if (dayHours !== ScheduleAvailabilityKeywords.AllDay) {
        break;
      }
    }

    start = midnightToLowerCase(start);
    end = midnightToLowerCase(end);
    const day = days[pointer];
    const lastConsecutiveOpenDayHours = hoursForDay(day, weekSchedule);
    if (day === currentWeekDay) {
      if (lastConsecutiveOpenDayHours === ScheduleAvailabilityKeywords.AllDay) {
        return mode
          ? this.intl.t(schedulePrefix + 'handoffTodayOpenAllDay', { mode })
          : this.intl.t(schedulePrefix + 'todayOpenAllDay');
      }
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayOpenNow', { mode, end })
        : this.intl.t(schedulePrefix + 'todayOpenNow', { start, end });
    }
    if (lastConsecutiveOpenDayHours === ScheduleAvailabilityKeywords.AllDay) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffConsecutiveAllDay', { mode, day })
        : this.intl.t(schedulePrefix + 'consecutiveAllDay', { day });
    }
    end = lastConsecutiveOpenDayHours.split('-')[1];
    return mode
      ? this.intl.t(schedulePrefix + 'handoffConsecutiveTime', { mode, end, day })
      : this.intl.t(schedulePrefix + 'consecutiveTime', { end, day });
  }
}
