import Component from '@ember/component';
import { assert } from '@ember/debug';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

import { tagName } from '@ember-decorators/component';

import isSome from 'mobile-web/lib/utilities/is-some';
import LoyaltyAccountModel from 'mobile-web/models/loyalty-account';
import QualifyingLoyaltyRewardModel from 'mobile-web/models/qualifying-loyalty-reward';
import Vendor from 'mobile-web/models/vendor';
import ChannelService from 'mobile-web/services/channel';

import style from './index.m.scss';

@tagName('')
export default class MenuVendorRewardsRoute extends Component {
  // Service injections
  @service channel!: ChannelService;

  // Required arguments
  vendor!: Vendor;
  accounts!: LoyaltyAccountModel[];

  // Optional arguments
  showInfoModal?: boolean;

  // Class fields
  style = style;

  // Computed properties
  @computed('accounts.[]')
  get allRewards(): QualifyingLoyaltyRewardModel[] {
    return this.accounts.map(account => account.qualifyingLoyaltyRewards.toArray()).flat();
  }

  @computed('accounts.[]')
  get managedReward(): LoyaltyAccountModel | undefined {
    return this.accounts.find(
      r => r.showManageRewardsLink && r.manageRewardsUrl && r.manageRewardsText
    );
  }

  // Init
  init() {
    super.init();

    assert('`vendor` is required', isSome(this.vendor));
    assert('`accounts` is required', isSome(this.accounts));
  }

  // Other methods

  // Actions
  @action
  toggleFavorite() {
    this.vendor.toggleFavorite();
  }
}
