import a0 from "./auth/index.ts";
window.define('mobile-web/components/application-loading/auth', function () {
  return a0;
});
import a1 from "./checkout/index.ts";
window.define('mobile-web/components/application-loading/checkout', function () {
  return a1;
});
import a2 from "./locations/index.ts";
window.define('mobile-web/components/application-loading/locations', function () {
  return a2;
});
import a3 from "./menu/index.ts";
window.define('mobile-web/components/application-loading/menu', function () {
  return a3;
});
import a4 from "./my-account/index.ts";
window.define('mobile-web/components/application-loading/my-account', function () {
  return a4;
});
import a5 from "./password/index.ts";
window.define('mobile-web/components/application-loading/password', function () {
  return a5;
});
import a6 from "./search/index.ts";
window.define('mobile-web/components/application-loading/search', function () {
  return a6;
});
import a7 from "./splash/index.ts";
window.define('mobile-web/components/application-loading/splash', function () {
  return a7;
});
import a8 from "./thank-you/index.ts";
window.define('mobile-web/components/application-loading/thank-you', function () {
  return a8;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "83XwS4vg",
  "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-applicationLoading\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"loading\",\"isAuth\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@isCheckoutAuth\",\"@isCreateAccount\"],[[30,0,[\"loading\",\"isAuthCheckout\"]],[30,0,[\"loading\",\"isAuthCreateAccount\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isCheckout\"]],[[[1,\"    \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isLocations\"]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isMenu\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@isProduct\",\"@isViewAll\"],[[30,0,[\"loading\",\"isMenuProduct\"]],[30,0,[\"loading\",\"isMenuViewAll\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isMyAccount\"]],[[[1,\"    \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isPassword\"]],[[[1,\"    \"],[8,[39,6],null,[[\"@isChange\",\"@isForgot\",\"@isReset\"],[[30,0,[\"loading\",\"isPasswordChange\"]],[30,0,[\"loading\",\"isPasswordForgot\"]],[30,0,[\"loading\",\"isPasswordReset\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isSearch\"]],[[[1,\"    \"],[8,[39,7],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isSplash\"]],[[[1,\"    \"],[8,[39,8],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"loading\",\"isThankYou\"]],[[[1,\"    \"],[8,[39,9],null,null,null],[1,\"\\n  \"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]]],[]]],[13]],[\"&attrs\"],false,[\"if\",\"application-loading/auth\",\"application-loading/checkout\",\"application-loading/locations\",\"application-loading/menu\",\"application-loading/my-account\",\"application-loading/password\",\"application-loading/search\",\"application-loading/splash\",\"application-loading/thank-you\"]]",
  "moduleName": "mobile-web/components/application-loading/index.hbs",
  "isStrictMode": false
});