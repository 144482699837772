import a0 from "../heading/index.ts";
window.define('mobile-web/components/heading', function () {
  return a0;
});
import a1 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "aStYBstG",
  "block": "[[[11,\"section\"],[16,0,[30,0,[\"style\",\"root\"]]],[16,\"data-test-heading-section\",[30,1]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],[[16,0,[28,[37,1],[[30,0,[\"style\",\"heading\"]],[30,3]],null]],[16,\"data-test-heading-section-heading\",[30,1]]],[[\"@level\"],[[30,0,[\"headingLevel\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@heading\",\"&attrs\",\"@headingClass\",\"&default\"],false,[\"heading\",\"classes\",\"yield\"]]",
  "moduleName": "mobile-web/components/heading-section/index.hbs",
  "isStrictMode": false
});