import a0 from "../components/static-page/index.ts";
window.define('mobile-web/components/static-page', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "kEFLokYy",
  "block": "[[[8,[39,0],null,[[\"@model\"],[[30,1]]],null]],[\"@model\"],false,[\"static-page\"]]",
  "moduleName": "mobile-web/templates/accessibility.hbs",
  "isStrictMode": false
});