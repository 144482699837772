import Component from '@glimmer/component';

import style from './index.m.scss';

export enum Variant {
  Normal = 'normal',
  Destructive = 'destructive',
}

interface Args {
  // Required arguments
  label: string;
  onClick: Action;

  // Optional arguments
  testSelector?: string;
  variant?: EnumOrValue<Variant>;
}

interface Signature {
  Args: Args;
  Element: HTMLButtonElement;
}

export default class CardButton extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get baseStyle(): string {
    return style[this.args.variant ?? Variant.Normal];
  }

  get testSelector() {
    return this.args.testSelector ?? '';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Button': typeof CardButton;
  }
}
