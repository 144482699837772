import a0 from "../../card/grid/index.ts";
window.define('mobile-web/components/card/grid', function () {
  return a0;
});
import a1 from "../../card/index.ts";
window.define('mobile-web/components/card', function () {
  return a1;
});
import a2 from "../../card/button/index.ts";
window.define('mobile-web/components/card/button', function () {
  return a2;
});
import a3 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "8nG99qoa",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-routes-dispatchSummaryRoute\",\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"statuses\",\"length\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@models\"],[[30,0,[\"statuses\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],[[16,\"aria-labelledby\",[28,[37,3],[[30,0,[\"ids\",\"heading\"]],\"-\",[30,2]],null]]],[[\"@testSelector\"],[\"status\"]],[[\"content\",\"buttons\"],[[[[1,\"\\n          \"],[10,0],[15,\"data-test-routes-dispatchSummaryRoute-card\",[30,1,[\"id\"]]],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n            \"],[10,0],[12],[1,\"\\n              \"],[10,2],[15,0,[30,0,[\"style\",\"deliveryService\"]]],[15,\"data-test-routes-dispatchSummaryRoute-deliveryService\",[30,1,[\"id\"]]],[15,1,[28,[37,3],[[30,0,[\"ids\",\"heading\"]],\"-\",[30,2]],null]],[12],[1,\"\\n                \"],[1,[30,1,[\"deliveryService\"]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,2],[15,\"data-test-routes-dispatchSummaryRoute-description\",[30,1,[\"id\"]]],[12],[1,\"\\n                \"],[1,[30,1,[\"statusDescription\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[39,4],null,[[\"@testSelector\",\"@label\",\"@onClick\"],[\"statusDetails\",[28,[37,5],[\"mwc.dispatchStatus.detailsButton\"],null],[28,[37,6],[[30,0,[\"goToDetails\"]],[30,1]],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[1,2]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"status\",\"index\"],false,[\"if\",\"card/grid\",\"card\",\"concat\",\"card/button\",\"t\",\"fn\"]]",
  "moduleName": "mobile-web/components/routes/dispatch-summary-route/index.hbs",
  "isStrictMode": false
});