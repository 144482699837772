import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import Address from 'mobile-web/lib/location/address';
import { classes } from 'mobile-web/lib/utilities/classes';
import { Address as VendorAddress } from 'mobile-web/models/vendor';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import DeviceService from 'mobile-web/services/device';

import style from './index.m.scss';

interface Args {
  // Required arguments
  address: VendorAddress;

  // Optional arguments
  isMinor?: boolean;
}

interface Signature {
  Args: Args;
}

export default class GetDirectionsButton extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service device!: DeviceService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get rootClass(): string {
    return classes(style.root, { [style.minor]: this.args.isMinor });
  }

  get href(): string {
    return Address.toMapsApplicationLink(this.args.address, this.device.isIOS);
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  track() {
    scheduleOnce('afterRender', this, this._track);
  }

  @action
  _track() {
    this.analytics.trackEvent(AnalyticsEvents.GetDirections, () => ({}), {
      isLink: true,
      domQuery: '.get-directions-button',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GetDirectionsButton: typeof GetDirectionsButton;
  }
}
