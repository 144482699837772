import a0 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a1;
});
import a2 from "../../helpers/track-event.ts";
window.define('mobile-web/helpers/track-event', function () {
  return a2;
});
import a3 from "../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "g0TJUiw2",
  "block": "[[[11,0],[24,\"data-test-vendor-search\",\"no-results\"],[16,0,[30,0,[\"style\",\"wrapper\"]]],[17,1],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,2],[14,\"data-test-vendor-search\",\"no-search\"],[12],[1,\"\\n     \"],[1,[28,[35,1],[\"mwc.vendorCard.enterAddressMessage\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"    \"],[10,2],[14,\"data-test-vendor-search\",\"no-search\"],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"mwc.locations.noResults\"],null]],[1,\"\\n      \"],[8,[39,2],[[24,\"data-test-link-to-locations\",\"\"],[4,[38,3],[\"click\",[28,[37,4],[\"View All Locations?\",[28,[37,5],null,[[\"Link Location\"],[\"No Results Page\"]]]],null]],null]],[[\"@route\"],[\"locations\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"mwc.locations.noResultsReturnLink\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[14,\"data-test-vendor-search\",\"no-search\"],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n      \"],[8,[39,6],null,[[\"@type\",\"@key\",\"@defaultMessage\"],[\"html\",\"NO_VENDORS_NEAR_USER\",\"Either edit your search or broaden your search radius to find locations that work for you.\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]],[13]],[\"&attrs\",\"@noSearch\",\"@noRegionResults\"],false,[\"if\",\"t\",\"olo-link-to\",\"on\",\"track-event\",\"hash\",\"x-content\"]]",
  "moduleName": "mobile-web/components/no-vendor-results/index.hbs",
  "isStrictMode": false
});