import a0 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a0;
});
import a1 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a1;
});
import a2 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "nXhenPp+",
  "block": "[[[11,0],[16,\"aria-labelledby\",[30,0,[\"messageId\"]]],[24,\"aria-modal\",\"false\"],[16,0,[30,0,[\"notificationClass\"]]],[24,\"data-test-notification\",\"\"],[24,\"role\",\"dialog\"],[4,[38,0],[[30,0,[\"open\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"    \"],[8,[39,2],[[16,0,[30,0,[\"style\",\"icon\"]]]],[[\"@ariaLabel\",\"@halo\",\"@icon\"],[[30,0,[\"iconLabel\"]],true,[30,0,[\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"message\"]]],[14,\"data-test-notification\",\"message\"],[15,1,[30,0,[\"messageId\"]]],[14,\"role\",\"alert\"],[12],[1,[30,0,[\"message\"]]],[13],[1,\"\\n\\n  \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"closeButton\"]]],[24,\"data-test-notification\",\"close\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"close\"]]],null],[12],[1,\"\\n    \"],[8,[39,2],[[16,0,[30,0,[\"style\",\"closeIcon\"]]]],[[\"@ariaLabel\",\"@icon\"],[[28,[37,4],[\"mwc.notifications.close\"],null],\"x\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"did-insert\",\"if\",\"svg\",\"on\",\"t\"]]",
  "moduleName": "mobile-web/components/notification/index.hbs",
  "isStrictMode": false
});