import { dasherize } from '@ember/string';
import DS from 'ember-data';

import { pluralize } from 'ember-inflector';

import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';

type DispatchQueryParams = { orderId: EmberDataId; hash?: string };

export default class DispatchStatusAdapter extends ApplicationAdapter.extend({
  pathForType(modelName: string) {
    return dasherize(pluralize(modelName));
  },

  sortQueryParams(params: DispatchQueryParams) {
    if (params.hasOwnProperty('hash')) {
      return { hash: params.hash };
    }
    return undefined;
  },

  urlForQuery(query: DispatchQueryParams, modelName: string) {
    const path = this.pathForType(modelName);
    return `${host()}/${this.namespace}/orders/${query.orderId}/${path}`;
  },

  urlForFindAll(modelName: string, snapshot: DS.Snapshot) {
    const {
      // @ts-ignore -- it exists (this works at runtime) but we don't have enough
      //               info to meaningfully inform TS about DS.Snapshots.
      adapterOptions: { orderId, hash },
    } = snapshot;
    const path = this.pathForType(modelName);
    const query = hash === undefined ? '' : `?hash=${encodeURIComponent(hash)}`;
    return `${host()}/${this.namespace}/orders/${orderId}/${path}${query}`;
  },

  urlForFindRecord(id: EmberDataId, modelName: string, snapshot: DS.Snapshot) {
    const {
      // @ts-ignore -- it exists (this works at runtime) but we don't have enough
      //               info to meaningfully inform TS about DS.Snapshots.
      adapterOptions: { orderId, hash },
    } = snapshot;
    const path = this.pathForType(modelName);
    const query = hash === undefined ? '' : `?hash=${encodeURIComponent(hash)}`;
    return `${host()}/${this.namespace}/orders/${orderId}/${path}/${id}${query}`;
  },
}) {}
