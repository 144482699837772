/* eslint-disable no-use-before-define */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import pick from 'lodash.pick';

import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import { CustomField } from 'mobile-web/lib/custom-field';
import { UserData } from 'mobile-web/lib/customer';
import { ErrorCategory } from 'mobile-web/lib/errors';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import { HandoffMode, HandoffModeKey, TimeWantedType } from 'mobile-web/lib/order-criteria';
import isSome from 'mobile-web/lib/utilities/is-some';
import { roundDecimals } from 'mobile-web/lib/utilities/math';
import UpsellGroupModel from 'mobile-web/models/upsell-group';
import UpsellItemModel from 'mobile-web/models/upsell-item';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';

import SavedAddressModel from './address';
import BasketProduct, { GlobalBasketProduct } from './basket-product';
import BillingDetails from './billing-details';
import ProductModel from './product';
import { LoyaltyRewardRule } from './qualifying-loyalty-reward';
import Vendor from './vendor';

export type Tax = {
  totalTax: number;
  label: string;
  rate?: number;
};

export type Coupon = {
  code?: string;
  description: string;
};

export type CustomFee = {
  description: string;
  amount: number;
  name: string;
};

export type BasketFailure = {
  productId: number;
  productName: string;
  choiceId?: number;
  choiceName?: string;
  category: ErrorCategory;
  message: string;
};

type Reward = {
  id: number;
  externalReference: string;
  label: string;
  loyaltyRewardRule: LoyaltyRewardRule;
};

export type StoredBasket = Pick<
  BasketModel,
  | 'coupon'
  | 'deliveryCharge'
  | 'handoffMode'
  | 'guid'
  | 'reward'
  | 'subTotal'
  | 'taxes'
  | 'timeWantedType'
  | 'total'
  | 'vendorDiscount'
  | 'containsSingleUseItems'
> & {
  basketProducts: Array<Pick<BasketProduct, 'productName' | 'quantity'>>;
  vendor?: Pick<Vendor, 'address' | 'externalReference' | 'name' | 'settings'>;
};

export type CompCardPayload = {
  cardNumber: string;
  pinNumber: string;
};

export type RewardPayload = {
  membershipId: EmberDataId;
  reference: EmberDataId;
};

export type UpdateTipPayload = {
  tip: number;
};

export type GetOrderDaysPayload = {
  handoffModeChar: HandoffModeKey;
};

export type GetTimeSlotsPayload = {
  date: string;
  handoffModeChar: HandoffModeKey;
};

export type GetTimeSlotsResponse = {
  leadTimeEstimate: number;
  /** An array of ISO 8601 UTC datetime strings */
  timeSlots: string[];
  vendorUtcOffset: number;
};

export type TransferPayload = {
  vendorId: EmberDataId;
};

export type ValidateResponse = { success: boolean };

export type OnPremiseDetailsPayload = {
  tablePosReference?: string;
  experienceType: OnPremiseExperience;
};

export enum DiscountType {
  COUPON = 'Coupon',
  LOYALTY = 'Loyalty',
  BRAND_DISCOUNT = 'BrandDiscount',
  SCHEDULED_PRICE = 'ScheduledPrice',
  EXTERNAL = 'External',
  COMP_CARD = 'CompCard',
}

export type Discount = {
  description: string;
  amount: number;
  type: DiscountType;
};

export interface GlobalBasket
  extends Pick<
    BasketModel,
    | 'id'
    | 'basketType'
    | 'coupon'
    | 'couponDiscount'
    | 'handoffMode'
    | 'fees'
    | 'feesTotal'
    | 'guid'
    | 'hasReward'
    | 'isAdvance'
    | 'isCallcenterOrder'
    | 'isImmediate'
    | 'isManualFire'
    | 'isUpsellEnabled'
    | 'leadTimeEstimate'
    | 'receivingUser'
    | 'reward'
    | 'subTotal'
    | 'suggestedTip'
    | 'taxes'
    | 'timeWanted'
    | 'timeWantedUtc'
    | 'tip'
    | 'total'
    | 'vendorDiscount'
    | 'wasUpsold'
  > {
  basketProducts: GlobalBasketProduct[];
}

export function pushBasketPayload(
  this: DS.Model & { store: DS.Store; bus: BusService },
  response: AnyObject
): BasketModel {
  const payload = isSome(response.basket) ? response : { basket: response };
  this.store.pushPayload('basket', payload);
  const basket = this.store.peekRecord('basket', payload.basket.guid)!;
  this.bus.trigger('adjustAmounts');
  return basket;
}

const pushPayload = pushBasketPayload;

export default class BasketModel extends DS.Model {
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service store!: DS.Store;

  @DS.attr('string')
  guid!: string;
  @DS.attr('string')
  basketType!: string;
  @DS.attr()
  coupon?: Coupon;
  @DS.attr('number')
  couponDiscount!: number;
  @DS.attr('array')
  customFieldValues!: CustomField[];
  @DS.attr('number')
  deliveryCharge!: number; // TODO: -> `handoffCharge` (fees may be for other modes)
  @DS.attr('string')
  handoffMode!: HandoffMode;
  @DS.attr('array')
  discounts?: Discount[];
  @DS.attr('string')
  handoffLabel!: string;
  @DS.attr('boolean')
  hasReward!: boolean;
  @DS.attr('boolean')
  isUpsellEnabled!: boolean;
  @DS.attr('boolean')
  wasUpsold!: boolean;
  @DS.attr('number')
  leadTimeEstimate?: number;
  @DS.attr()
  reward?: Reward;
  @DS.attr('number')
  subTotal!: number;
  @DS.attr('number')
  suggestedTip!: number;
  @DS.attr('array')
  taxes!: Tax[];
  @DS.attr()
  onPremiseDetails?: OnPremiseDetailsPayload;
  /**
   * @summary
   * This is the time at the vendor using the vendor's current UTC Offset,
   * which may not actually be the same offset that the vendor would have at
   * the desired time. For example, if it is currently January, and you are
   * placing an order for July, the DST change in March may mean that this time
   * is off by an hour.
   *
   * @deprecated
   */
  @DS.attr('string')
  timeWanted?: string;
  /**
   * @summary
   * This is the user's desired time for their order, it has been corrected on
   * the server so that it refers to the appropriate moment in time for their
   * order.
   *
   * Use the vendor's TimeZoneId to format this time in the context of the
   * vendor.
   */
  @DS.attr('date')
  timeWantedUtc?: Date; // This is in the utc with the offset specified
  @DS.attr('number', { defaultValue: 0 })
  tip!: number;
  @DS.attr('number')
  total!: number;
  @DS.attr('number')
  vendorDiscount!: number;
  @DS.attr('boolean')
  isAdvance?: boolean;
  @DS.attr('boolean')
  isCallcenterOrder?: boolean;
  @DS.attr('boolean')
  isImmediate?: boolean;
  @DS.attr('boolean')
  isManualFire?: boolean;
  @DS.attr('boolean')
  doCreditCardStreetAddressValidation!: boolean;
  @DS.attr('boolean')
  isPosValidated!: boolean;
  @DS.attr('array')
  unavailableItems!: string[];
  @DS.attr('array')
  warnings!: string[];
  @DS.attr('array')
  failures!: BasketFailure[];
  @DS.attr('array')
  fees!: CustomFee[];
  @DS.attr('number')
  feesTotal!: number;
  @DS.attr('string')
  groupOrderId?: string;
  @DS.attr('object')
  receivingUser?: UserData;

  @DS.hasMany('basket-product')
  basketProducts!: DS.PromiseManyArray<BasketProduct>;
  @DS.belongsTo('address', { async: false })
  deliveryAddress?: SavedAddressModel;

  // most places this is always present, but on the checkout page if you transfer
  // to a new vendor this relationship won't be present and needs to be queried.
  @DS.belongsTo('vendor')
  vendor!: DS.PromiseObject<Vendor>;
  @DS.belongsTo('billing-details')
  billingDetails!: DS.PromiseObject<BillingDetails>;

  @DS.hasMany('upsell-group', { async: false })
  upsellGroups!: DS.ManyArray<UpsellGroupModel>;

  @memberAction<void, ValidateResponse>({
    type: 'post',
    path: 'validate',
    after(this: BasketModel, response) {
      if (isSome(response.basket)) {
        this.store.pushPayload('basket', response);
      }
      return response;
    },
  })
  validate!: MemberAction<void, ValidateResponse>;

  @memberAction({ type: 'post', path: 'updatecriteria', after: pushPayload })
  updateCriteria!: MemberAction<void, BasketModel>;

  @memberAction({ type: 'post', path: 'compcard', after: pushPayload })
  applyCompCard!: MemberAction<CompCardPayload, BasketModel>;

  @memberAction({ type: 'delete', path: 'compcard', after: pushPayload })
  removeCompCard!: MemberAction<void, BasketModel>;

  @memberAction<RewardPayload, BasketModel>({ type: 'post', path: 'rewards', after: pushPayload })
  applyReward!: MemberAction<RewardPayload, BasketModel>;

  @memberAction<RewardPayload, BasketModel>({
    type: 'post',
    path: 'removeRewards',
    after: pushPayload,
  })
  removeReward!: MemberAction<RewardPayload, BasketModel>;

  @memberAction<string, BasketModel>({
    type: 'post',
    path: 'coupon',
    before: (code: string) => JSON.stringify(code),
    after: pushPayload,
  })
  applyCoupon!: MemberAction<string, BasketModel>;

  @memberAction<void, BasketModel>({ type: 'delete', path: 'coupon', after: pushPayload })
  removeCoupon!: MemberAction<void, BasketModel>;

  @memberAction<UpdateTipPayload, BasketModel>({
    type: 'post',
    path: 'updatetip',
    after: pushPayload,
  })
  updateTip!: MemberAction<UpdateTipPayload, BasketModel>;

  /** Returns strings in the format 'YYYY-MM-DDTHH:mm:ss */
  @memberAction<GetOrderDaysPayload, string[]>({ type: 'get', path: 'getorderdays' })
  getOrderDays!: MemberAction<GetOrderDaysPayload, string[]>;

  @memberAction<GetTimeSlotsPayload, GetTimeSlotsResponse>({ type: 'get', path: 'gettimeslots' })
  getTimeSlots!: MemberAction<GetTimeSlotsPayload, GetTimeSlotsResponse>;

  @memberAction<void, void>({ type: 'post', path: 'activate' })
  activate!: MemberAction<void, void>;

  @memberAction<TransferPayload, BasketModel>({
    // @ts-ignore
    type: 'transfer',
    path: 'transfer',
    after: pushPayload,
  })
  transfer!: MemberAction<TransferPayload, BasketModel>;

  @memberAction<UpsellItemModel, BasketModel>({
    type: 'post',
    path: 'upsellitems',
    before: (upsellItem: UpsellItemModel) => {
      const upsellData = upsellItem.serialize({ includeId: true }) as PureModel<UpsellItemModel>;
      upsellData.quantity = 1;
      return JSON.stringify([upsellData]);
    },
    after: pushPayload,
  })
  addUpsellItem!: MemberAction<UpsellItemModel, BasketModel>;

  @memberAction<OnPremiseDetailsPayload, BasketModel>({
    type: 'post',
    path: 'onpremise',
    after: pushPayload,
  })
  setOnPremiseDetails!: MemberAction<OnPremiseDetailsPayload, BasketModel>;

  @memberAction<void, void>({
    type: 'post',
    path: 'startover',
  })
  startOver!: MemberAction<void, void>;

  @memberAction<void, boolean>({
    type: 'post',
    path: 'grouporderupdate',
  })
  checkGroupOrderUpdateStatus!: MemberAction<void, any>;

  // The `total` value will include the `tip` after it is set, which can lead to
  // an endless loop in contexts which require the user to interact with the
  // total, e.g. splitting payments.
  @computed('total', 'tip')
  get preTipTotal(): number {
    return roundDecimals(this.total - this.tip);
  }

  @computed('discounts')
  get compCardDiscount(): number {
    return this.discounts?.find(d => d.type === DiscountType.COMP_CARD)?.amount ?? 0;
  }

  @computed('isImmediate', 'isAdvance', 'isManualFire')
  get timeWantedType(): TimeWantedType {
    if (this.isAdvance) {
      return 'Advance';
    }
    if (this.isManualFire) {
      return 'ManualFire';
    }
    return 'Immediate';
  }

  @computed('coupon.code')
  get hasCoupon(): boolean {
    return isSome(this.coupon) && isSome(this.coupon.code);
  }

  @computed(
    'channel.settings.showSmsOptIn',
    'features.flags',
    'handoffMode',
    'isImmediate',
    'vendor'
  )
  get showSmsOptIn(): boolean {
    const vendorSlug = this.vendor.get('slug') ?? 'NONE';
    const pilotVendorSlugs: string = this.features.flags['sms-pilot-vendors'];
    const vendorIncluded =
      pilotVendorSlugs === '*' || pilotVendorSlugs.split(',').includes(vendorSlug);
    return (
      !!this.channel.settings?.showSmsOptIn &&
      vendorIncluded &&
      this.handoffMode === 'CurbsidePickup' &&
      !!this.isImmediate
    );
  }

  /**
   * This intermediatary computed getter is required to find the nested `.isSingleUse`
   * property on each basket product's product.
   *
   * Link to documentation:
   * https://github.com/ember-cli/eslint-plugin-ember/blob/master/docs/rules/no-deeply-nested-dependent-keys-with-each.md
   */
  @computed('basketProducts.@each.product')
  private get products(): ProductModel[] {
    return this.basketProducts
      .map(bp => bp.product.content)
      .filter<ProductModel>((p): p is ProductModel => !!p);
  }

  @computed('products.@each.isSingleUse')
  get containsSingleUseItems(): boolean {
    return this.products.any(p => p.get('isSingleUse') ?? false);
  }

  serializeForStorage(this: BasketModel): StoredBasket {
    const vendor = this.belongsTo('vendor').value() as Vendor | undefined;
    return {
      containsSingleUseItems: this.containsSingleUseItems,
      coupon: this.coupon,
      deliveryCharge: this.deliveryCharge,
      handoffMode: this.handoffMode,
      guid: this.guid,
      reward: this.reward,
      subTotal: this.subTotal,
      taxes: this.taxes,
      timeWantedType: this.timeWantedType,
      total: this.total,
      vendorDiscount: this.vendorDiscount,
      basketProducts: this.basketProducts.map(bp => ({
        productName: bp.productName,
        quantity: bp.quantity,
      })),
      vendor: vendor
        ? {
            externalReference: vendor.externalReference,
            name: vendor.name,
            address: vendor.address,
            settings: vendor.settings,
          }
        : undefined,
    };
  }

  serializeForGlobalData(): GlobalBasket {
    return {
      ...pick(
        this,
        'id',
        'basketType',
        'coupon',
        'couponDiscount',
        'handoffMode',
        'fees',
        'feesTotal',
        'guid',
        'hasReward',
        'isAdvance',
        'isCallcenterOrder',
        'isImmediate',
        'isManualFire',
        'isUpsellEnabled',
        'leadTimeEstimate',
        'reward',
        'subTotal',
        'suggestedTip',
        'taxes',
        'timeWanted',
        'timeWantedUtc',
        'tip',
        'total',
        'vendorDiscount',
        'wasUpsold'
      ),
      /**
       * Let me explain.
       * Ember 3.26 introduced a deprecation for calling store methods when the
       * store is destroy(ing/ed): ember-data:method-calls-on-destroyed-store.
       * After upgrading, we got these deprecation warnings in tests.
       * After much debugging, we found the source of the deprecation here.
       * The issue seems to be that this serialization code runs async,
       * but happens outside Ember's runloop (it's written to work with GA).
       * Because it's outside the runloop, tests don't know to wait for it.
       * So sometimes when this code runs, the store is destroy(ing/ed).
       * This is a problem because `isLoaded` internally calls store methods.
       * A check here was enough to fix all instances of the deprecation.
       */
      basketProducts:
        // @ts-ignore
        !this.store.isDestroying &&
        // @ts-ignore
        !this.store.isDestroyed &&
        // eslint-disable-next-line ember/no-get, ember/classic-decorator-no-classic-methods
        this.get('isLoaded')
          ? this.basketProducts?.map(bp => bp.serializeForGlobalData())
          : [],
    };
  }
}
