import a0 from "../../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../../components/routes/menu/category-route/index.ts";
window.define('mobile-web/components/routes/menu/category-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "oxMGYlJC",
  "block": "[[[1,[28,[35,0],[[30,1,[\"name\"]]],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@category\"],[[30,1]]],null]],[\"@model\"],false,[\"page-title\",\"routes/menu/category-route\"]]",
  "moduleName": "mobile-web/templates/menu/category.hbs",
  "isStrictMode": false
});