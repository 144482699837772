import Component from '@ember/component';
import { assert } from '@ember/debug';
import { get, computed } from '@ember/object';

import { tagName } from '@ember-decorators/component';

import { isString } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

@tagName('')
export default class TrackOrderButton extends Component {
  orderId!: string;

  class = '';

  style = style;

  @computed('class')
  get rootClass(): string {
    return [style.root, get(this, 'class')].join(' ');
  }

  init() {
    super.init();

    assert('`orderId` is required', isString(this.orderId));
  }
}
