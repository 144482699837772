/* eslint-disable ember/avoid-leaking-state-in-ember-objects */
import ArrayProxy from '@ember/array/proxy';
import Service, { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { enqueueTask, TaskGenerator } from 'ember-concurrency';
import update from 'ember-object-update';

import { errResult, isOk, okResult, Result, ResultErr } from 'mobile-web/lib/result';
import { unionBy } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';
import LoyaltyAccount from 'mobile-web/models/loyalty-account';
import LoyaltyMembership from 'mobile-web/models/loyalty-membership';
import LoyaltyScheme from 'mobile-web/models/loyalty-scheme';
import { errorForUser, extractErrorDetail, MOBO_ERR } from 'mobile-web/services/error';

export type LoyaltyAccountResult = Result<LoyaltyAccount | undefined, string>;

export type LoadedLoyaltyData = { membership: LoyaltyMembership; account?: LoyaltyAccount };

export type LoyaltyResults = Result<LoadedLoyaltyData, string>[];

/**
 * Throw an error (to catch in the global error logger) if a user-facing error
 * cannot be constructed for use with `Result`.
 *
 * This is useful for cases where we expect a user-facing error to be available:
 * if it is not, we want to take full advantage of the top-level error handling.
 */
const throwOrResultErr = (error: unknown): ResultErr<string> => {
  if (!errorForUser(error)) {
    throw error;
  }

  return errResult(extractErrorDetail(error) ?? MOBO_ERR);
};

const loadAccount = (
  store: DS.Store,
  vendorId: EmberDataId,
  membershipId: number
): RSVP.Promise<LoyaltyAccountResult> => {
  const existingAccount = store
    .peekAll('loyalty-account')
    .find(la => la.membershipId === membershipId && la.vendorId === vendorId);

  const account = isSome(existingAccount)
    ? RSVP.resolve(existingAccount)
    : store.queryRecord('loyalty-account', {
        membershipId,
        vendorId,
      });

  return account.then(okResult).catch(throwOrResultErr);
};

const toLoyaltyModelResults = (
  store: DS.Store,
  vendorId: EmberDataId,
  memberships: LoyaltyMembership[]
): RSVP.Promise<LoyaltyResults> =>
  RSVP.all(
    memberships.map(m =>
      RSVP.hash({
        membership: m,
        accountResult: loadAccount(store, vendorId, m.membershipId),
      }).then(({ membership, accountResult }) =>
        isOk(accountResult)
          ? okResult<LoadedLoyaltyData>({ membership, account: accountResult.value })
          : errResult<string>(accountResult.err)
      )
    )
  );

type RewardsLinkStatus = {
  schemeProviderName: EmberDataId;
  membershipId: string | undefined;
};

const fullyLoadedDataToStatus = (fld: LoadedLoyaltyData): RewardsLinkStatus => ({
  schemeProviderName: fld.membership.schemeProviderName,
  membershipId: fld.membership.membershipId.toString(),
});

export default class LoyaltyService extends Service {
  // Service injections
  @service store!: DS.Store;

  // Untracked properties
  /**
   * Think of this as something like a this-service-only immutable state store.
   * The point is that there's a single source of truth for this state which is
   * updated whenever the relevant underlying states change. And it doesn't
   * track history, sadly.
   */
  rewardsLinkStatuses: RewardsLinkStatus[] = [];

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods
  linkMembershipAccount(
    memberNumber: string,
    scheme: LoyaltyScheme
  ): RSVP.Promise<LoyaltyMembership> {
    const membership = this.store.createRecord('loyalty-membership', {
      membershipId: memberNumber,
      scheme,
      schemeName: scheme.schemeName,
    });
    return membership.save().catch(e => {
      membership.destroyRecord();
      throw e;
    });
  }

  loadMemberships(): RSVP.Promise<ArrayProxy<LoyaltyMembership>> {
    const loyaltyMemberships = this.store.peekAll('loyalty-membership');
    const updates = loyaltyMemberships.map(membership => ({
      schemeProviderName: membership.schemeProviderName,
      membershipId: membership.membershipId.toString(),
    }));

    this._updateRewardsLinkStatus(updates);
    return RSVP.resolve(loyaltyMemberships);
  }

  unlinkMembership(membershipId: EmberDataId): RewardsLinkStatus[] {
    const match = (status: RewardsLinkStatus) => status.membershipId === membershipId.toString();

    const status = this.rewardsLinkStatuses.find(match);
    if (status) {
      const updatedStatus: RewardsLinkStatus = Object.assign({}, status, {
        membershipId: undefined,
      });
      return this._updateRewardsLinkStatus([updatedStatus]);
    }
    return this.rewardsLinkStatuses;
  }

  /**
   * @private
   * Our typings don't currently allow for truly private properties on classes
   * which extend `EmberObject`.
   */
  _updateRewardsLinkStatus(change: RewardsLinkStatus[]): RewardsLinkStatus[] {
    const result = update(this, 'rewardsLinkStatuses', rls =>
      unionBy(change, rls, 'schemeProviderName')
    );
    return result;
  }

  // Tasks
  @enqueueTask *loadModel(vendorId: EmberDataId): TaskGenerator<LoyaltyResults> {
    const store = this.store;
    const memberships = store.peekAll('loyalty-membership');
    return yield toLoyaltyModelResults(store, vendorId, memberships.toArray()).then(results => {
      const updates = results
        .filter(isOk)
        .map(r => r.value)
        .map(fullyLoadedDataToStatus);

      this._updateRewardsLinkStatus(updates);

      return results;
    });
  }

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    loyalty: LoyaltyService;
  }
}
