import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import GroupOrderService, { GroupOrderParticipantStatus } from 'mobile-web/services/group-order';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';
interface Args {
  // Required arguments
  location: 'cart' | 'vendor';
  // Optional arguments
  currentVendorSlug?: string;
}
interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [unknown];
  };
}

export default class GroupOrderBanner extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bus!: BusService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service channel!: ChannelService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isModalOpen = false;

  // Getters and setters
  get hasGroupOrder(): boolean {
    return this.groupOrder.hasGroupOrder;
  }

  get isHostMode(): boolean {
    return this.groupOrder.isHostMode;
  }

  get isParticipantMode(): boolean {
    return this.groupOrder.isParticipantMode;
  }

  get currentUserName(): string {
    return this.groupOrder.currentUserName === '' ? 'no_name' : this.groupOrder.currentUserName;
  }

  get statusesToShow(): { status: GroupOrderParticipantStatus; statusCount: number }[] {
    const statusArray = [
      GroupOrderParticipantStatus.InProgress,
      GroupOrderParticipantStatus.Submitted,
    ];
    return statusArray.map(status => {
      const statusCount = this.groupOrder.statusCount(status);
      return { status, statusCount };
    });
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  leaveGroupOrder() {
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.groupOrder.leaveGroupTitle'),
      content: this.intl.t('mwc.groupOrder.leaveGroupConfirmationMessage'),
      buttonLabel: this.intl.t('mwc.groupOrder.leaveButton', {
        hostName: this.groupOrder.hostDisplayNamePossessive,
      }),
      onConfirm: () => this.endGroupOrderConfirmed(),
      testSelector: 'leave-group-order-confirmation-modal',
      buttonTestSelector: 'leave-group-order-confirm-button',
      cancelTestSelector: 'leave-group-order-cancel-button',
    });
  }

  @action
  cancelGroupOrder() {
    this.bus.trigger('confirm', {
      title: this.intl.t('mwc.groupOrder.cancelGroupTitle'),
      content: this.intl.t('mwc.groupOrder.cancelGroupConfirmationMessage'),
      buttonLabel: this.intl.t('mwc.groupOrder.confirmCancelGroupButton'),
      onConfirm: () => this.cancelGroupOrderConfirmed(),
      testSelector: 'cancel-group-order-confirmation-modal',
      buttonTestSelector: 'cancel-group-order-confirm-button',
      cancelTestSelector: 'cancel-group-order-cancel-button',
    });
  }

  @action
  endGroupOrderConfirmed() {
    this.analytics.trackEvent(AnalyticsEvents.LeaveGroupOrder);
    this.groupOrder.endGroupOrder();
  }

  @action
  cancelGroupOrderConfirmed() {
    this.analytics.trackEvent(AnalyticsEvents.CancelGroupOrder);
    this.groupOrder.cancelGroupOrder();
  }

  @action
  startGroupOrder() {
    this.analytics.trackEvent(AnalyticsEvents.StartGroupOrder, () => ({
      [AnalyticsProperties.LinkLocation]: 'Banner Link',
    }));
    this.router.transitionTo('secure.group-order.start', {
      queryParams: { slug: this.args.currentVendorSlug },
    });
  }

  @action
  inviteToGroupOrder() {
    this.openModal();
    this.analytics.trackEvent(AnalyticsEvents.GroupOrderInviteOthersButton);
  }

  @action
  openModal() {
    this.isModalOpen = true;
  }

  @action
  closeModal() {
    this.isModalOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GroupOrder::Banner': typeof GroupOrderBanner;
  }
}
