import Service from '@ember/service';

import { local, session } from 'mobile-web/decorators/storage';
import { computedLocal, computedSession } from 'mobile-web/lib/computed';
import { UserData } from 'mobile-web/lib/customer';
import { StoredOrderSubmission } from 'mobile-web/models/order-submission';
import { Notification } from 'mobile-web/services/notifications';

export default class StorageService extends Service {
  // Service injections

  // Untracked properties
  @computedLocal hybridEverOpened?: boolean;

  @computedSession forceChallengePattern?: string;
  @computedSession orderSubmission?: StoredOrderSubmission;
  @computedSession ignorePrecheckError?: boolean;
  @computedSession lastPreCheckValid?: boolean;
  @computedSession lastVendorSlug?: string;
  @computedSession showFullMenu!: boolean;
  @computedSession usedAppBackButton?: boolean;
  @computedSession firstRouteName?: string;
  @computedSession startupNotifications?: Notification[];
  @computedSession showOnPremiseContinueModal?: boolean;
  @computedSession showExitOnPremiseModal?: boolean;
  @computedSession createOloAccount?: boolean;
  @computedSession signedInViaOloAuthOverlay?: boolean;
  @computedSession trackedThankYouOrderId?: string;
  @session lastShownAppRatingPromptDate?: string;

  // Tracked properties
  @session receivingCustomer?: UserData;
  @local shownOneTimeNotifications?: AnyObject;

  // Getters and setters

  // Constructor

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    storage: StorageService;
  }
}
