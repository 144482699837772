import a0 from "./line-item-container/index.ts";
window.define('mobile-web/components/post-checkout/subsection/line-item-container', function () {
  return a0;
});
import a1 from "./line-item/index.ts";
window.define('mobile-web/components/post-checkout/subsection/line-item', function () {
  return a1;
});
import a2 from "./button-container/index.ts";
window.define('mobile-web/components/post-checkout/subsection/button-container', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "jo7oRB4F",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-post-checkout-subsection\",\"\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[30,1],null,[[\"@class\"],[[30,0,[\"style\",\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[10,\"h3\"],[15,0,[30,0,[\"style\",\"heading\"]]],[15,\"data-test-subsection-heading\",[30,2]],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,3,[[28,[37,2],null,[[\"lineItemContainer\",\"lineItem\"],[[50,\"post-checkout/subsection/line-item-container\",0,null,null],[50,\"post-checkout/subsection/line-item\",0,null,null]]]]]],[1,\"\\n    \"],[18,4,[[28,[37,2],null,[[\"buttonContainer\",\"buttonClass\"],[[50,\"post-checkout/subsection/button-container\",0,null,null],[30,0,[\"style\",\"button\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"@heading\",\"&content\",\"&buttons\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
  "moduleName": "mobile-web/components/post-checkout/subsection/index.hbs",
  "isStrictMode": false
});