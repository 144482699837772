import a0 from "./button/index.ts";
window.define('mobile-web/components/link-rewards/button', function () {
  return a0;
});
import a1 from "./schemes/index.ts";
window.define('mobile-web/components/link-rewards/schemes', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "73Z649h6",
  "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,0],[24,\"data-test-linkRewards\",\"\"],[17,1],[12],[1,\"\\n    \"],[18,2,[[28,[37,2],null,[[\"button\",\"schemes\",\"isOpen\",\"fullyLinked\"],[[50,\"link-rewards/button\",0,null,[[\"schemes\",\"memberships\",\"linkedSchemeNames\",\"vendor\",\"isOpen\",\"onLink\"],[[30,0,[\"schemes\"]],[30,0,[\"memberships\"]],[30,0,[\"linkedSchemeNames\"]],[30,0,[\"vendorService\",\"vendor\"]],[30,0,[\"isOpen\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"isOpen\"]]],null]],null]]]],[50,\"link-rewards/schemes\",0,null,[[\"schemes\",\"memberships\",\"linkedSchemeNames\",\"vendor\",\"onLink\"],[[30,0,[\"schemes\"]],[30,0,[\"memberships\"]],[30,0,[\"linkedSchemeNames\"]],[30,0,[\"vendorService\",\"vendor\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"isOpen\"]]],null],false],null]]]],[30,0,[\"isOpen\"]],[30,0,[\"fullyLinked\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"fn\",\"mut\"]]",
  "moduleName": "mobile-web/components/link-rewards/index.hbs",
  "isStrictMode": false
});