import a0 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import a1 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a1;
});
import a2 from "../expandable-text/index.ts";
window.define('mobile-web/components/expandable-text', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Z0rsRMS9",
  "block": "[[[10,0],[15,0,[28,[37,0],[[30,0,[\"style\",\"root\"]],[30,1]],null]],[14,\"data-test-inlineAlert\",\"\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"hideIcon\"]]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"iconContainer\"]]],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[1,[30,2]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],null,[[\"@class\",\"@icon\"],[[28,[37,0],[[30,0,[\"style\",\"icon\"]],[30,3]],null],\"info\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@collapsible\",\"@maxHeight\"],[[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@icon\",\"@iconClass\",\"@collapsible\",\"@maxHeight\",\"&default\"],false,[\"classes\",\"unless\",\"if\",\"svg\",\"expandable-text\",\"yield\"]]",
  "moduleName": "mobile-web/components/inline-alert/index.hbs",
  "isStrictMode": false
});