import a0 from "../../content-placeholders/index.ts";
window.define('mobile-web/components/content-placeholders', function () {
  return a0;
});
import a1 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "JOz4czWB",
  "block": "[[[8,[39,0],[[16,0,[28,[37,1],[[30,0,[\"style\",\"root\"]],[52,[30,1],[30,0,[\"style\",\"isCheckoutAuth\"]]],[52,[30,2],[30,0,[\"style\",\"isCreateAccount\"]]]],null]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"    \"],[8,[30,3,[\"text\"]],[[16,0,[30,0,[\"style\",\"mainHeader\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[30,3,[\"text\"]],[[16,0,[30,0,[\"style\",\"subHeader\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"form\"]]],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"inputEachArray\"]]],null]],null],null,[[[1,\"      \"],[8,[30,3,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[8,[30,3,[\"text\"]],[[16,0,[30,0,[\"style\",\"button\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"]],[\"@isCheckoutAuth\",\"@isCreateAccount\",\"placeholder\"],false,[\"content-placeholders\",\"classes\",\"if\",\"unless\",\"each\",\"-track-array\"]]",
  "moduleName": "mobile-web/components/application-loading/auth/index.hbs",
  "isStrictMode": false
});