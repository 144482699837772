import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { PreviousRoute } from 'mobile-web/lib/routing';
import BasketProductModel from 'mobile-web/models/basket-product';
import ProductModel from 'mobile-web/models/product';
import BusService from 'mobile-web/services/bus';

import style from './index.m.scss';

export type ShowProductModalArgs = {
  product: ProductModel;
  onClose?: Action;
  onSave?: Action;
  basketProduct?: BasketProductModel;
  previousRoute?: PreviousRoute;
};

declare module 'mobile-web/services/bus' {
  interface EventRegistry {
    showProductModal: ShowProductModalArgs;
  }
}

export default class ProductModal extends Component {
  // Service injections
  @service bus!: BusService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked eventArgs?: ShowProductModalArgs;
  @tracked isOpen = false;

  // Getters and setters

  // Constructor
  constructor(owner: unknown, args: {}) {
    super(owner, args);

    this.bus.on('showProductModal', eventArgs => {
      this.eventArgs = eventArgs;
      this.isOpen = true;
    });
  }

  // Other methods

  // Tasks

  // Actions
  @action
  close() {
    this.isOpen = false;
  }

  @action
  onCloseComplete() {
    this.eventArgs?.onClose?.();
    this.eventArgs = undefined;
  }

  @action
  onSave() {
    this.close();
    this.eventArgs?.onSave?.();
  }
}
