import Component from '@ember/component';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

import { tagName } from '@ember-decorators/component';

import { UserOrderEvent } from 'mobile-web/components/post-checkout';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService from 'mobile-web/services/analytics';
import FavoriteService from 'mobile-web/services/favorite';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

@tagName('')
export default class OrderSummaryRoute extends Component {
  // Service injections
  @service favorite!: FavoriteService;
  @service router!: RouterService;
  @service userFeedback!: UserFeedback;
  @service vendor!: VendorService;
  @service analytics!: AnalyticsService;

  // Required arguments
  order!: OrderModel;

  // Optional arguments

  // Class fields
  style = style;

  // Computed properties

  // Init

  // Other methods

  // Actions
  @action
  onUserOrderEvent(id: EmberDataId, hash: object, event: UserOrderEvent) {
    this.router.transitionTo(event, id, { queryParams: { hash } });
  }

  @action
  onCancelOrder() {
    this.userFeedback.add({
      type: Type.Neutral,
      title: 'Cancellation Complete',
      message: 'Your order has been successfully cancelled.',
    });
    this.router.transitionTo('application');
  }

  @action
  saveFavorite(orderId: EmberDataId, name: string) {
    return this.favorite.saveFavorite(orderId, name).then(() => {
      this.order.set('isFavorite', true);
      this.userFeedback.add({
        message: `Your order has been saved as ${name}!`,
        title: 'Success!',
        type: Type.Positive,
      });
    });
  }
}
