import a0 from "../../animated-container.js";
window.define('mobile-web/components/animated-container', function () {
  return a0;
});
import a1 from "../../group-order/name/index.ts";
window.define('mobile-web/components/group-order/name', function () {
  return a1;
});
import a2 from "../../animated-each.js";
window.define('mobile-web/components/animated-each', function () {
  return a2;
});
import a3 from "../product/index.ts";
window.define('mobile-web/components/cart/product', function () {
  return a3;
});
import a4 from "../../animated-if.js";
window.define('mobile-web/components/animated-if', function () {
  return a4;
});
import a5 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a5;
});
import a6 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a6;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "9jm/ZNgz",
  "block": "[[[10,0],[14,\"data-test-cart-productList\",\"\"],[15,0,[52,[30,1],[30,0,[\"style\",\"isCart\"]]]],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,2],null,[[\"@participantName\"],[[30,3]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"ul\"],[15,0,[30,0,[\"style\",\"productList\"]]],[12],[1,\"\\n\"],[6,[39,3],[[30,0,[\"shownProducts\"]]],[[\"use\",\"duration\"],[[30,0,[\"transition\"]],[30,0,[\"animationDuration\"]]]],[[\"default\"],[[[[1,\"        \"],[10,\"li\"],[15,0,[30,0,[\"style\",\"product\"]]],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@product\",\"@currency\",\"@editable\",\"@onEdit\",\"@onClose\"],[[30,4],[30,5],[30,1],[30,6],[30,7]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]]]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,5],[[30,0,[\"showExpandButton\"]]],[[\"use\",\"duration\"],[[30,0,[\"transition\"]],[30,0,[\"animationDuration\"]]]],[[\"default\"],[[[[1,\"      \"],[8,[39,6],[[16,0,[30,0,[\"style\",\"expandButton\"]]],[24,\"data-test-cart-productList-showMore\",\"\"]],[[\"@variant\",\"@onClick\"],[\"minor\",[30,0,[\"expandProducts\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,7],[\"mwc.cart.showMoreItemsLabel\"],[[\"count\"],[[30,0,[\"hiddenProductCount\"]]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@editable\",\"@showGroupOrderName\",\"@participantName\",\"product\",\"@currency\",\"@onEdit\",\"@onClose\"],false,[\"if\",\"animated-container\",\"group-order/name\",\"animated-each\",\"cart/product\",\"animated-if\",\"button\",\"t\"]]",
  "moduleName": "mobile-web/components/cart/product-list/index.hbs",
  "isStrictMode": false
});