import a0 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "JuqwmI6k",
  "block": "[[[11,0],[16,0,[28,[37,0],[\"ember-content-placeholders-text\",[30,0,[\"rootClass\"]]],null]],[17,1],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"linesArray\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"ember-content-placeholders-text__line\"],[14,\"data-test-ember-content-placeholders-text-line\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"classes\",\"each\",\"-track-array\"]]",
  "moduleName": "mobile-web/components/content-placeholders/text/index.hbs",
  "isStrictMode": false
});