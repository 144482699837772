import { noop } from 'mobile-web/lib/utilities/_';
import { bindActionToKeyEvent, Key } from 'mobile-web/lib/utilities/keys';

export type PageDownHandler = (keyEvent: KeyboardEvent) => void | KeyboardEvent;

export function setUp(): PageDownHandler {
  const selector = '[data-focusWhen="PageDown"]';
  const el = document.querySelector<HTMLElement>(selector);
  const focusMainAction = el ? () => el.focus() : noop;

  const pageDownHandler: PageDownHandler = bindActionToKeyEvent(Key.PageDown, focusMainAction);

  document.addEventListener('keydown', pageDownHandler);
  return pageDownHandler;
}

export function tearDown(handler: PageDownHandler): void {
  document.removeEventListener('keydown', handler);
}

const PageDown = {
  setUp,
  tearDown,
};
export default PageDown;
