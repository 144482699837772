import a0 from "../../content-placeholders/index.ts";
window.define('mobile-web/components/content-placeholders', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "0LYjCv+r",
  "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"root\"]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"header\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"content\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"content\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"button\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"header\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"content\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"grid\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[1,2],null]],null]],null],null,[[[1,\"      \"],[10,0],[12],[1,\"\\n        \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"header\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[1,2,3],null]],null]],null],null,[[[1,\"          \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"gridContent\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"gridButton\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[1,2,3],null]],null]],null],null,[[[1,\"          \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"gridContent\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"placeholder\"],false,[\"content-placeholders\",\"each\",\"-track-array\",\"array\"]]",
  "moduleName": "mobile-web/components/application-loading/thank-you/index.hbs",
  "isStrictMode": false
});