import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import IntlService from 'ember-intl/services/intl';

import DispatchStatusModel, { State } from 'mobile-web/models/dispatch-status';

import style from './index.m.scss';

enum StepKey {
  OrderReceived = 'orderReceived',
  PickupInProgress = 'pickupInProgress',
  OutForDelivery = 'outForDelivery',
  Delivered = 'delivered',
}

enum StepStatus {
  Pending = 'pending',
  Active = 'active',
  Complete = 'complete',
  Error = 'error',
  Info = 'info',
}

type Step = {
  key: StepKey;
  label: string;
  status: StepStatus;
};

const PICKUP_IN_PROGRESS_MAP = {
  [State.OrderReceived]: StepStatus.Pending,
  [State.OrderError]: StepStatus.Pending,
  [State.PickupInProgress]: StepStatus.Active,
  [State.PickupError]: StepStatus.Error,
  [State.PickupSucceeded]: StepStatus.Complete,
  [State.DeliveryError]: StepStatus.Complete,
  [State.DeliverySucceeded]: StepStatus.Complete,
  [State.ReturnInProgress]: StepStatus.Complete,
  [State.ReturnCompleted]: StepStatus.Complete,
};

const OUT_FOR_DELIVERY_MAP = {
  [State.OrderReceived]: StepStatus.Pending,
  [State.OrderError]: StepStatus.Pending,
  [State.PickupInProgress]: StepStatus.Pending,
  [State.PickupError]: StepStatus.Pending,
  [State.PickupSucceeded]: StepStatus.Active,
  [State.DeliveryError]: StepStatus.Error,
  [State.DeliverySucceeded]: StepStatus.Complete,
  [State.ReturnInProgress]: StepStatus.Complete,
  [State.ReturnCompleted]: StepStatus.Complete,
};

const DELIVERED_MAP = {
  [State.OrderReceived]: StepStatus.Pending,
  [State.OrderError]: StepStatus.Pending,
  [State.PickupInProgress]: StepStatus.Pending,
  [State.PickupError]: StepStatus.Pending,
  [State.PickupSucceeded]: StepStatus.Pending,
  [State.DeliveryError]: StepStatus.Pending,
  [State.DeliverySucceeded]: StepStatus.Complete,
  [State.ReturnInProgress]: StepStatus.Info,
  [State.ReturnCompleted]: StepStatus.Info,
};

interface Args {
  // Required arguments
  status: DispatchStatusModel;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class DispatchTracker extends Component<Signature> {
  // Service injections
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get steps(): Step[] {
    const state = this.args.status.status;
    return [
      {
        key: StepKey.OrderReceived,
        label: this.intl.t('mwc.dispatchTracker.orderReceivedLabel'),
        status: state === State.OrderError ? StepStatus.Error : StepStatus.Complete,
      },
      {
        key: StepKey.PickupInProgress,
        label: this.intl.t('mwc.dispatchTracker.pickupInProgressLabel'),
        status: PICKUP_IN_PROGRESS_MAP[state],
      },
      {
        key: StepKey.OutForDelivery,
        label: this.intl.t('mwc.dispatchTracker.outForDeliveryLabel'),
        status: OUT_FOR_DELIVERY_MAP[state],
      },
      {
        key: StepKey.Delivered,
        label:
          state === State.ReturnInProgress || state === State.ReturnCompleted
            ? this.intl.t('mwc.dispatchTracker.returnLabel')
            : this.intl.t('mwc.dispatchTracker.deliveredLabel'),
        status: DELIVERED_MAP[state],
      },
    ];
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DispatchTracker: typeof DispatchTracker;
  }
}
