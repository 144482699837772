import a0 from "../components/routes/index-route/index.ts";
window.define('mobile-web/components/routes/index-route', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "rLshHeR/",
  "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null]],[],false,[\"routes/index-route\"]]",
  "moduleName": "mobile-web/templates/index.hbs",
  "isStrictMode": false
});