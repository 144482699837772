import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/on-premise/confirmation/index.ts";
window.define('mobile-web/components/on-premise/confirmation', function () {
  return a1;
});
import a2 from "../components/post-checkout/index.ts";
window.define('mobile-web/components/post-checkout', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "9wLBdrBz",
  "block": "[[[1,[28,[35,0],[\"Order Placed\"],null]],[1,\"\\n\\n\"],[41,[30,0,[\"onPremise\",\"isEnabled\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@currentOrder\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@order\",\"@onUserOrderEvent\",\"@onCancelOrder\",\"@saveFavorite\",\"@showSocialIntegration\"],[[30,0,[\"model\"]],[30,0,[\"onUserOrderEvent\"]],[30,0,[\"onCancelOrder\"]],[30,0,[\"saveFavorite\"]],[30,0,[\"showSocialIntegration\"]]]],null],[1,\"\\n\"]],[]]]],[],false,[\"page-title\",\"if\",\"on-premise/confirmation\",\"post-checkout\"]]",
  "moduleName": "mobile-web/templates/thank-you.hbs",
  "isStrictMode": false
});