import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  socialLink: string;
  vendorName: string;

  // Optional arguments
}

interface Signature {
  Element: HTMLElement;

  Args: Args;
}

export default class ShareSection extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get substitutions() {
    return {
      Vendor: this.args.vendorName,
    };
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::ShareSection': typeof ShareSection;
  }
}
