import a0 from "../ember-tooltip.js";
window.define('mobile-web/components/ember-tooltip', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "iBWB1gnk",
  "block": "[[[11,1],[24,\"data-test-tooltip\",\"\"],[17,1],[12],[1,\"\\n  \"],[11,\"button\"],[24,\"data-test-tooltip-trigger\",\"\"],[16,0,[30,0,[\"style\",\"trigger\"]]],[16,1,[30,0,[\"ids\",\"trigger\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"onShow\"]]],null],[4,[38,0],[\"mouseenter\",[30,0,[\"onShow\"]]],null],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[1,\"  \"],[8,[39,2],null,[[\"@arrowClass\",\"@event\",\"@side\",\"@tooltipClass\",\"@keepInWindow\",\"@targetId\",\"@popperOptions\"],[[30,0,[\"arrowClasses\"]],[30,0,[\"event\"]],[30,0,[\"side\"]],[30,0,[\"tooltipClasses\"]],true,[30,0,[\"ids\",\"trigger\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@options\",\"&trigger\",\"&content\"],false,[\"on\",\"yield\",\"ember-tooltip\"]]",
  "moduleName": "mobile-web/components/tooltip/index.hbs",
  "isStrictMode": false
});