import Component from '@glimmer/component';

import style from './index.m.scss';

export default class ApplicationLoadingSplash extends Component {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
