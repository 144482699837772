/* eslint-disable @typescript-eslint/no-explicit-any */
import { helper } from '@ember/component/helper';

import { DirectInvokable } from '@glint/template/-private/integration';

export default helper(
  ([object, key]: [unknown, string]) => typeof object === 'object' && !!object && key in object
);

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'has-key': DirectInvokable<
      <T, K extends string>(
        named: AnyObject,
        object: T,
        key: K
      ) => object is Extract<T, Record<K, any>>
    >;
  }
}
