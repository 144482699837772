import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../subsection/index.ts";
window.define('mobile-web/components/post-checkout/subsection', function () {
  return a1;
});
import a2 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a2;
});
import a3 from "../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a3;
});
import a4 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a4;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "rfrwL4Hd",
  "block": "[[[11,\"aside\"],[16,\"title\",[28,[37,0],[\"mwc.postCheckout.feedbackLabel\"],null]],[16,0,[30,0,[\"style\",\"root\"]]],[16,\"data-test-post-checkout-feedback-section\",[30,1]],[17,2],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@icon\"],[[50,\"svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"very-satisfied\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_POSTCHECKOUT_FEEDBACK_PROMPT\",[28,[37,0],[\"mwc.postCheckout.feedbackPrompt\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[30,3,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],[[16,0,[30,3,[\"buttonClass\"]]]],[[\"@onClick\",\"@variant\"],[[30,0,[\"giveFeedback\"]],\"minor\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,3],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_POSTCHECKOUT_FEEDBACK_BUTTON\",[28,[37,0],[\"mwc.postCheckout.feedbackLabel\"],null]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@url\",\"&attrs\",\"s\"],false,[\"t\",\"post-checkout/subsection\",\"component\",\"x-content\",\"button\"]]",
  "moduleName": "mobile-web/components/post-checkout/feedback-section/index.hbs",
  "isStrictMode": false
});