import a0 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a1;
});
import a2 from "../../helpers/not.js";
window.define('mobile-web/helpers/not', function () {
  return a2;
});
import a3 from "../../helpers/media.js";
window.define('mobile-web/helpers/media', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "SwppFkfB",
  "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-openCheck\",\"\"],[17,1],[12],[1,\"\\n  \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"title\"]]],[14,\"data-test-openCheck-title\",\"\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.openCheck.title\"],[[\"name\"],[[30,0,[\"session\",\"user\",\"firstName\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[14,\"data-test-openCheck-subtitle\",\"\"],[15,0,[30,0,[\"style\",\"subtitle\"]]],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.openCheck.subtitle\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,2],[14,\"data-test-openCheck-instructions\",\"\"],[15,0,[30,0,[\"style\",\"instructions\"]]],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.openCheck.instructions\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"button\"]]],[24,\"data-test-openCheck-viewTab\",\"\"],[4,[38,4],[\"click\",[30,0,[\"viewTab\"]]],null]],[[\"@fullWidth\",\"@variant\"],[[28,[37,2],[[28,[37,3],[\"isTablet\"],null]],null],\"minor\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.openCheck.viewTabButton\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"button\"]]],[24,\"data-test-openCheck-orderMore\",\"\"],[4,[38,4],[\"click\",[30,0,[\"orderMore\"]]],null]],[[\"@fullWidth\"],[[28,[37,2],[[28,[37,3],[\"isTablet\"],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.openCheck.orderMoreButton\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[10,2],[15,0,[30,0,[\"style\",\"orderMoreInfo\"]]],[14,\"data-test-openCheck-orderMoreInfo\",\"\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"mwc.openCheck.orderMoreInfo\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"t\",\"button\",\"not\",\"media\",\"on\"]]",
  "moduleName": "mobile-web/components/open-check/index.hbs",
  "isStrictMode": false
});