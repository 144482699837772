import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import ChannelService from 'mobile-web/services/channel';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
}

export default class HeaderHome extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service router!: RouterService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  onClick() {
    return this.router.transitionTo('index');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HeaderHome: typeof HeaderHome;
  }
}
