/* eslint-disable ember/classic-decorator-no-classic-methods */
import EmberArray from '@ember/array';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { tagName } from '@ember-decorators/component';

import { computedLocal } from 'mobile-web/lib/computed';
import dayjs from 'mobile-web/lib/dayjs';
import {
  OrderCriteria,
  isDelivery,
  isAtStore,
  getTimeWantedTypeLabel,
  getHandoffLabel,
  isAdvance,
} from 'mobile-web/lib/order-criteria';
import { toString } from 'mobile-web/lib/utilities/_';
import { VendorResultRoutes } from 'mobile-web/lib/vendor';
import VendorSearchResultModel, { FailureCategory } from 'mobile-web/models/vendor-search-result';
import { VendorSearchParams } from 'mobile-web/routes/vendor-search-results';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
  TIME_WANTED_ANALYTICS_LABELS,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import MwcIntl from 'mobile-web/services/mwc-intl';
import * as OrderCriteriaService from 'mobile-web/services/order-criteria';
import { VendorSearchResult } from 'mobile-web/services/vendor-search';

import style from './index.m.scss';

@tagName('')
export default class VendorSearchResultsRoute extends Component {
  // Service injections
  @service('order-criteria') orderCriteriaService!: OrderCriteriaService.default;
  @service basket!: BasketService;
  @service router!: RouterService;
  @service analytics!: AnalyticsService;
  @service store!: DS.Store;
  @service mwcIntl!: MwcIntl;

  // Required arguments
  model!: VendorSearchResult;

  // Optional arguments
  isRewardSearch = false;
  onSearchChange?: Action;

  // Class fields
  @computedLocal
  localVendorSearchParams?: VendorSearchParams;
  style = style;

  // Computed properties
  @computed('isRewardSearch')
  get destRoute(): VendorResultRoutes {
    return this.isRewardSearch ? VendorResultRoutes.Rewards : VendorResultRoutes.Menu;
  }

  @computed('orderCriteriaService.criteria')
  get orderCriteria(): OrderCriteria {
    return this.orderCriteriaService.criteria;
  }

  @computed('orderCriteria.handoffMode', 'store')
  get handoffLabel(): string {
    return getHandoffLabel(this.orderCriteria.handoffMode, this.store);
  }

  @computed(
    'handoffLabel',
    'mwcIntl',
    'noHandoffResults',
    'noTimeWantedResults',
    'orderCriteria.{timeWanted,timeWantedType}',
    'store'
  )
  get noResultsLabel(): string {
    if (this.noHandoffResults) {
      return this.handoffLabel;
    } else if (this.noTimeWantedResults) {
      return isAdvance(this.orderCriteria) && this.orderCriteria.timeWanted
        ? this.mwcIntl.relativeDateTime(this.orderCriteria.timeWanted)
        : getTimeWantedTypeLabel(this.orderCriteria.timeWantedType, this.store);
    }
    return '';
  }

  @computed('isRewardSearch', 'model.results')
  get vendors(): EmberArray<VendorSearchResultModel> {
    if (this.isRewardSearch) {
      return this.model.results;
    }

    return this.model.results.filter(
      ({ hasOnlineOrdering, displayNationalMenu }) => hasOnlineOrdering || displayNationalMenu
    );
  }

  @computed('vendors')
  get availableVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => v.available);
  }

  @computed('vendors')
  get unavailableVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => !v.available);
  }

  @computed('vendors')
  get handoffFailedVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => v.preCheckCategory === FailureCategory.HandoffMode);
  }

  @computed('vendors')
  get timeModeFailedVendors(): VendorSearchResultModel[] {
    return this.vendors.filter(v => v.preCheckCategory === FailureCategory.TimeWantedMode);
  }

  @computed('handoffFailedVendors.length', 'vendors.length')
  get noHandoffResults(): boolean {
    return this.vendors.length === this.handoffFailedVendors.length;
  }

  @computed('timeModeFailedVendors.length', 'vendors.length')
  get noTimeWantedResults(): boolean {
    return this.vendors.length === this.timeModeFailedVendors.length;
  }

  @computed('orderCriteria.{timeWanted,timeWantedType}')
  get vendorScheduleDay() {
    return isAdvance(this.orderCriteria) && this.orderCriteria.timeWanted
      ? this.orderCriteria.timeWanted
      : dayjs();
  }

  // Init

  // Other methods

  // Actions
  @action
  showCriteria() {
    this.orderCriteriaService.openModal({ onChange: this.updateSearch, searchOnly: true });
  }

  @action
  showVendorResults(address: string) {
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.set('localVendorSearchParams', { address });
    this.onSearchChange?.();
  }

  @action
  updateSearch(model: OrderCriteria) {
    // We call `toString` in the `model.vendorAddress|deliveryAddress.*` case so that we do not
    // end up binding the query params to the same string *reference* as the
    // order criteria address. If we do *not* do this, we'll end up with very
    // confusing double-render bugs from any `link-to` which has to reference
    // this controller to make sure any query params are resolved -- as happens
    // when the router looks up vendor routes!
    let address: string | undefined;
    let addressId: string | undefined;
    if (isDelivery(model) && model.deliveryAddress) {
      address = model.deliveryAddress.streetAddress.toString();
      const id = model.deliveryAddress.id;
      if (typeof id === 'string') {
        addressId = id.toString();
      }
    } else if (isAtStore(model)) {
      address = model.searchAddress;
    }

    const { latitude, longitude } =
      isAtStore(model) && model.searchCoords
        ? model.searchCoords
        : { latitude: undefined, longitude: undefined };

    const [building, city, zipCode] = isDelivery(model)
      ? [
          model.deliveryAddress?.building,
          model.deliveryAddress?.city,
          model.deliveryAddress?.zipCode,
        ].map(toString)
      : ['', '', ''];

    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    this.set('localVendorSearchParams', {
      addressId,
      address,
      building,
      city,
      zipCode,
      latitude,
      longitude,
      handoffMode: model.handoffMode,
      timeWantedType: model.timeWantedType,
      timeWanted: isAdvance(model) && model.timeWanted ? model.timeWanted.toISOString() : undefined,
    } as VendorSearchParams);

    this.onSearchChange?.();
  }

  getChosenVendorIndex(chosenVendor: VendorSearchResultModel): number {
    const index = this.availableVendors.findIndex(vendor => vendor.id === chosenVendor.id);

    return index > -1 ? index + 1 : 0;
  }

  @action
  goToVendor(newVendor: VendorSearchResultModel) {
    const search = this.orderCriteria;
    const handoffMode = search.handoffMode;
    const timeWantedMode = TIME_WANTED_ANALYTICS_LABELS[search.timeWantedType];
    this.analytics.trackEvent(AnalyticsEvents.BeginOrder, () => ({
      [AnalyticsProperties.StoreName]: newVendor.name,
      [AnalyticsProperties.StoreCity]: newVendor.address.city,
      [AnalyticsProperties.StoreState]: newVendor.address.state,
      [AnalyticsProperties.StorePostalCode]: newVendor.address.postalCode,
      [AnalyticsProperties.SelectedHandoffMode]: handoffMode,
      [AnalyticsProperties.SelectedTimeWantedMode]: timeWantedMode,
      [AnalyticsProperties.LocationSearchIndex]: this.getChosenVendorIndex(newVendor),
    }));

    this.router.transitionTo(this.destRoute, newVendor.slug);
  }
}
