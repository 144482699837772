/**
 * Adapted from https://github.com/ember-a11y/a11y-announcer/blob/v2.0.0/addon/services/announcer.js
 */

import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export type AnnounceTone = 'off' | 'polite' | 'assertive';

export default class AnnouncerService extends Service {
  // Service injections

  // Untracked properties

  // Tracked properties
  @tracked message: string | undefined;
  @tracked tone: AnnounceTone = 'polite';

  // Getters and setters

  // Constructor

  // Other methods
  announce(message: string, tone: AnnounceTone): void {
    this.message = message;
    this.tone = tone;
  }

  announceRouteChange(): void {
    const pageTitle = document.title.trim();
    const message = `${pageTitle} has loaded`;
    this.announce(message, 'polite');
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    announcer: AnnouncerService;
  }
}
