import Component from '@ember/component';
import { assert } from '@ember/debug';
import { get, action, computed } from '@ember/object';

import { tagName } from '@ember-decorators/component';

import { POPUP_OPTIONS } from 'mobile-web/lib/popup';
import { isString } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';

@tagName('')
export default class TwitterShareButton extends Component {
  link!: string;

  class = '';
  quote = '';

  style = style;

  @computed('class')
  get rootClass(): string {
    return [get(this, 'class'), style.root].join(' ');
  }

  init() {
    super.init();

    assert('`link` is required in `twitter-share-button`', isString(this.link));
  }

  @action
  share() {
    const link = get(this, 'link');
    const quote = encodeURIComponent(get(this, 'quote'));
    const url = `${TWITTER_SHARE_URL}?url=${link}&text=${quote}`;

    window.open(url, 'twitter', POPUP_OPTIONS);
  }
}
