import Component from '@ember/component';
import { assert } from '@ember/debug';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';

import { tagName } from '@ember-decorators/component';

import { isArray, isString, isFunction } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

export type Segment<T> = {
  label: string;
  value: T;
};

export type OnChange<T> = (segment: Segment<T>) => any;

@tagName('')
export default class SegmentedControl<T> extends Component {
  // Required arguments
  name!: string;
  onChange!: OnChange<T>;
  segments!: Segment<T>[];

  // Optional arguments
  @alias('rootClass') class?: string;
  itemClass?: string;
  label?: string;
  value?: T;

  // Class properties
  style = style;

  @computed('segments.length')
  get hasSingleSegment() {
    return this.segments.length === 1;
  }

  @computed('itemClass', 'segments', 'value')
  get buttonClasses() {
    const value = this.value;
    return this.segments.map(s =>
      [style.button, this.itemClass || '', s.value === value ? style.selected : ''].join(' ')
    );
  }

  @computed('label', 'name')
  get groupLabel(): string {
    return this.label || this.name;
  }

  @computed('name', 'segments')
  get inputIds() {
    const name = this.name;
    return this.segments.map((_s, i) => `${name}-${i}`);
  }

  @action
  handleChange(segment: Segment<T>) {
    this.onChange(segment);
  }

  init() {
    super.init();

    assert('`name` is required', isString(this.name));
    assert('`segments` is required', isArray(this.segments));
    assert('`onChange` must be a `function`', isFunction(this.onChange));
  }
}
