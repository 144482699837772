import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import Order from 'mobile-web/models/order';
import BasketService from 'mobile-web/services/basket';

interface Args {
  // Required arguments
  order: Order;
  onCancelOrder: Action;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class PostCheckoutChangeOrder extends Component<Signature> {
  // Service injections
  @service store!: DS.Store;
  @service basket!: BasketService;

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  cancelOrder() {
    this.store.adapterFor('order').cancel(this.args.order.id).then(this.args.onCancelOrder);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::ChangeOrder': typeof PostCheckoutChangeOrder;
  }
}
