/* eslint-disable ember/no-computed-properties-in-native-classes */
import { action, computed } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { next } from 'mobile-web/lib/runloop';
import { classes } from 'mobile-web/lib/utilities/classes';
import type CategoryModel from 'mobile-web/models/category';
import { GlobalProduct } from 'mobile-web/models/product';
import type ChannelService from 'mobile-web/services/channel';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import MenuCategoryActivationService from 'mobile-web/services/menu-category-activation';
import ScrollService from 'mobile-web/services/scroll';
import type StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

const PRODUCT_VISIBLE_THRESHOLD = 0.5;

interface Args {
  // Required arguments
  category: CategoryModel;

  // Optional arguments
  /**
   * An optional argument to control whether the category starts in an active
   * state. This setting is primarily used for testing purposes.
   */
  isActive?: boolean;
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;
}

export default class MenuCategory extends Component<Signature> {
  // Service injections
  @service channel!: ChannelService;
  @service globalEvents!: GlobalEventsService;
  @service menuCategoryActivation!: MenuCategoryActivationService;
  @service scroll!: ScrollService;
  @service storage!: StorageService;

  // Untracked properties
  height?: number;
  style = style;
  productIntersectionObserver = new IntersectionObserver(this.trackVisibleProducts, {
    threshold: [PRODUCT_VISIBLE_THRESHOLD],
  });
  viewedProducts = new Set();
  carouselElement: HTMLElement | undefined;
  categoryElement: HTMLDivElement | undefined;

  // Tracked properties
  @tracked isActive = this.args.isActive ?? false;

  // Getters and setters
  /**
   * All of the products in the category.
   */
  @dependentKeyCompat
  get products() {
    return this.args.category.products;
  }

  /**
   * All of the products to be shown in the category. This filters out the
   * unavailable products when the "View Full Menu" toggle is off.
   */
  @computed('products.@each.isAvailable', 'storage.showFullMenu')
  get shownProducts() {
    return this.products
      .filter(product => this.storage.showFullMenu || product.isAvailable)
      .sortBy('isDisabled');
  }

  /**
   * The total number of products to show in the category.
   */
  @computed('shownProducts.[]')
  get productCount() {
    return this.shownProducts.length;
  }

  get showCategoryName(): boolean {
    return (
      isEmpty(this.args.category.images) || this.channel.settings?.showMenuCategoryNames || false
    );
  }

  get rootClass(): string {
    return classes(this.style.root, {
      [this.style.active]: this.isActive,
    });
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  /**
   * Activates the menu category and returns a promise that resolves when all
   * the images have finished loading (or erroring).
   *
   * @returns a promise that indicates that the category has finished rendering
   */
  @action
  async activate() {
    if (!this.isActive) {
      this.isActive = true;
    }

    try {
      while (!this.carouselElement) {
        await next(this);
      }

      await Promise.allSettled(
        [...(this.categoryElement?.querySelectorAll('img') ?? [])].map(img => img.decode())
      );
    } catch {
      // bail out if `next` throws due to the component being destroyed
    }
  }

  /**
   * Registers the category with the activation service and queues its
   * activation.
   *
   * @param category
   */
  @action
  setupCategory(category: HTMLDivElement) {
    this.categoryElement = category;
    this.menuCategoryActivation.register(category, this.activate);
    this.menuCategoryActivation.activateMenuCategoryTask.perform(this.activate);
  }

  /**
   * Tracks products that are visible on screen.
   *
   * TODO: migrate this to tracking service
   *
   * @param entries
   * @returns
   */
  @action
  trackVisibleProducts(entries: IntersectionObserverEntry[]) {
    const products = entries
      .filter(entry => entry.intersectionRatio > PRODUCT_VISIBLE_THRESHOLD)
      .map(entry => entry.target as HTMLElement)
      .map(target => +(target.dataset.index ?? ''))
      .map(index => this.shownProducts.objectAt(index)?.serializeForGlobalData())
      .filter<GlobalProduct>(
        (product): product is GlobalProduct =>
          (product && !this.viewedProducts.has(product.id)) ?? false
      );

    if (!products.length) {
      return;
    }

    products.forEach(product => this.viewedProducts.add(product.id));
    this.globalEvents.trigger(GlobalEventName.ProductsVisible, products);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MenuCategory: typeof MenuCategory;
  }
}
