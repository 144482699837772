import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../subsection/index.ts";
window.define('mobile-web/components/post-checkout/subsection', function () {
  return a1;
});
import a2 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a2;
});
import a3 from "../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a3;
});
import a4 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a4;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "yppw9Y3V",
  "block": "[[[11,\"aside\"],[16,\"title\",[28,[37,0],[\"mwc.postCheckout.createAccountLabel\"],null]],[24,\"data-test-post-checkout-create-account\",\"\"],[17,1],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@heading\",\"@icon\"],[[30,0,[\"header\"]],[50,\"svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"account-circle-legacy\",\"\"]]]]],[[\"content\",\"buttons\"],[[[[1,\"\\n      \"],[10,2],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_POSTCHECKOUT_CREATEACCOUNT_PROMPT\",[28,[37,0],[\"mwc.postCheckout.createAccountMessage\"],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[8,[30,2,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],[[16,0,[30,2,[\"buttonClass\"]]]],[[\"@label\",\"@onClick\",\"@variant\",\"@testSelector\"],[[28,[37,0],[\"mwc.postCheckout.createAccountLabel\"],null],[30,3],\"minor\",\"create-account-button\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"s\",\"@upgradeAccount\"],false,[\"t\",\"post-checkout/subsection\",\"component\",\"x-content\",\"button\"]]",
  "moduleName": "mobile-web/components/post-checkout/create-account/index.hbs",
  "isStrictMode": false
});