import { assert } from '@ember/debug';

import Modifier from 'ember-modifier';

import { noop } from 'mobile-web/lib/utilities/_';

export type HasVisibleCallback = (visible: Element[], hidden: Element[]) => void;
export type HasVisibleArgs = {
  positional: [HasVisibleCallback, Element[]];
  named: { threshold: number; rootMargin?: string; root?: string };
};

export default class HasVisibleModifier extends Modifier<HasVisibleArgs> {
  private obs!: IntersectionObserver;
  private priorChildren: Element[] = [];
  private rootElement?: Element;

  /**
   * Callback to run when an item becomes visible.
   */
  private get onItemsVisible(): HasVisibleCallback {
    const fn = this.args.positional[0];
    const fnType = typeof fn;
    assert(
      `first positional argument must be 'function' but ${fnType} was provided`,
      fnType === 'function'
    );
    return fn;
  }

  /**
   * Get the list of children to observe.
   */
  private get children(): Element[] {
    const children = this.args.positional[1];
    assert(
      `second positional argument must be 'Element[]' but an array was not provided`,
      Array.isArray(children)
    );
    return children;
  }

  /**
   * Runs on install and update.
   */
  didReceiveArguments(): void {
    this.setupObserver();
    this.bindObservers();
  }

  /**
   * Stop observing.
   */
  willDestroy(): void {
    this.obs.disconnect();
  }

  /**
   * Initialize the intersection observer if we haven't already.
   */
  setupObserver(): void {
    if (!this.obs) {
      const { threshold = 0.5, rootMargin, root } = this.args.named;
      const opts: IntersectionObserverInit = { threshold, rootMargin };
      this.rootElement = (root && document.querySelector<Element>(root)) || undefined;
      if (this.rootElement) {
        opts.root = this.rootElement;
      }
      this.obs = new IntersectionObserver(this.onIntersection.bind(this), opts);
    }
  }

  /**
   * Stop listening to children that no longer exist, start listening to
   * children that were added.
   */
  bindObservers(): void {
    this.children.concat(this.priorChildren).forEach(child => {
      const inChildren = this.children.includes(child);
      const inPrior = this.priorChildren.includes(child);
      if (inChildren && !inPrior) {
        this.obs.observe(child);
      } else if (!inChildren && inPrior) {
        this.obs.unobserve(child);
      }
    });
    this.priorChildren = [...this.children];
  }

  /**
   * Determine if an entry should be considered visible. For all items that become visible,
   * run the callback.
   * Don't use `isIntersecting` because Firefox does NOT round well and it isn't reliable.
   */
  onIntersection(entries: IntersectionObserverEntry[]): void {
    const [visible, hidden] = entries.reduce<[Element[], Element[]]>(
      (acc, e) => {
        const isVisible = e.intersectionRatio >= (this.args.named.threshold || 0.5);
        acc[isVisible ? 0 : 1].push(e.target);
        return acc;
      },
      [[], []]
    );
    (this.onItemsVisible || noop)(visible, hidden);
  }
}
