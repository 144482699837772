import { action } from '@ember/object';
import Evented from '@ember/object/evented';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import DeviceService from 'mobile-web/services/device';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

interface Signature {
  Element: HTMLElement;
}

export default class BackButton extends Component<Signature> {
  // Service injections
  @service device!: DeviceService;
  @service router!: RouterService & Evented;
  @service storage!: StorageService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get icon(): string {
    return this.device.isHybridAndroid ? 'android-back' : 'ios-back';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  back() {
    this.storage.usedAppBackButton = true;
    this.router.one('routeDidChange', () => {
      this.storage.usedAppBackButton = false;
    });

    history.back();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BackButton: typeof BackButton;
  }
}
