import a0 from "../modal/index.ts";
window.define('mobile-web/components/modal', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../form-field/index.ts";
window.define('mobile-web/components/form-field', function () {
  return a2;
});
import a3 from "../cart/product-list/index.ts";
window.define('mobile-web/components/cart/product-list', function () {
  return a3;
});
import a4 from "../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a4;
});
import a5 from "../loading-indicator/index.ts";
window.define('mobile-web/components/loading-indicator', function () {
  return a5;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "4SBMSgrq",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@onClose\",\"@buttons\"],[[28,[37,1],[\"mwc.saveFavorite.saveOrder\"],null],[30,1],\"yield\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"body\"]],[[[1,\"\\n    \"],[8,[30,2,[\"section\"]],null,[[\"@title\"],[[28,[37,1],[\"mwc.saveFavorite.favoriteName\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@name\",\"@label\",\"@hideLabel\",\"@maxlength\",\"@inputValue\"],[\"save-favorite-modal-name\",[28,[37,1],[\"mwc.saveFavorite.favoriteName\"],null],true,32,[30,0,[\"name\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"section\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@products\",\"@currency\",\"@initialCount\"],[[30,3,[\"basketProducts\"]],[30,3,[\"currency\"]],3]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],[[[41,[30,2,[\"footer\"]],[[[1,\"    \"],[8,[39,5],[[16,0,[30,2,[\"buttonClass\"]]]],[[\"@onClick\",\"@variant\"],[[30,1],\"minor\"]],[[\"default\"],[[[[1,\"\\n      Cancel\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,5],[[16,0,[30,2,[\"buttonClass\"]]],[16,\"disabled\",[30,0,[\"isSubmitDisabled\"]]],[24,\"data-test-save-favorite-modal\",\"save-button\"]],[[\"@onClick\"],[[28,[37,6],[[30,0],[30,0,[\"doSubmit\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"mwc.saveFavorite.saveLabel\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]],null]],[]]]],[2]]]]],[1,\"\\n\\n\"],[1,[28,[35,7],null,[[\"label\",\"display\"],[[28,[37,1],[\"mwc.saveFavorite.savingLabel\"],null],[30,0,[\"submit\",\"isRunning\"]]]]]],[1,\"\\n\"]],[\"@onClose\",\"modal\",\"@order\"],false,[\"modal\",\"t\",\"if\",\"form-field\",\"cart/product-list\",\"button\",\"action\",\"loading-indicator\"]]",
  "moduleName": "mobile-web/components/save-favorite-modal/index.hbs",
  "isStrictMode": false
});