export default {
  "root": "_root_yxgqv7",
  "content": "_content_yxgqv7",
  "delete": "_delete_yxgqv7",
  "deleteIcon": "_deleteIcon_yxgqv7",
  "description": "_description_yxgqv7",
  "summary": "_summary_yxgqv7",
  "empty": "_empty_yxgqv7",
  "icon": "_icon_yxgqv7",
  "emptyText": "_emptyText_yxgqv7",
  "emptyButton": "_emptyButton_yxgqv7",
  "heading": "_heading_yxgqv7",
  "modalHeaderDescription": "_modalHeaderDescription_yxgqv7",
  "vendorLink": "_vendorLink_yxgqv7",
  "buttonContainer": "_buttonContainer_yxgqv7",
  "expandButton": "_expandButton_yxgqv7"
};
