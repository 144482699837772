import Component from '@glimmer/component';

import style from './index.m.scss';

export type Model = {
  label: string;
  icon?: string; // name of component
  testSelector?: string;
  class?: string;
} & ({ route: string; isBypass?: boolean } | { action: Action });

interface Args {
  // Required arguments
  model: Model;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class PopoverItem extends Component<Signature> {
  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PopoverItem: typeof PopoverItem;
  }
}
