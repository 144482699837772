import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "6QMBULet",
  "block": "[[[10,1],[15,0,[30,0,[\"rootStyle\"]]],[15,\"data-test-productCustomization-requirement\",[30,0,[\"testState\"]]],[12],[1,\"\\n  \"],[1,[52,[30,1],[28,[37,1],[\"mwc.productCustomization.completedText\"],null]]],[1,\"\\n  \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],[30,0,[\"icon\"]]]],null],[1,\"\\n  \"],[1,[52,[51,[30,1]],[28,[37,1],[\"mwc.productCustomization.requiredText\"],null]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isCompleted\"],false,[\"if\",\"t\",\"svg\",\"unless\"]]",
  "moduleName": "mobile-web/components/product-customization/requirement/index.hbs",
  "isStrictMode": false
});