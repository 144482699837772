export default {
  "modal": "_modal_10cnyz",
  "header": "_header_10cnyz",
  "contactInfo": "_contactInfo_10cnyz",
  "addressWrapper": "_addressWrapper_10cnyz",
  "mapLink": "_mapLink_10cnyz",
  "phoneWrapper": "_phoneWrapper_10cnyz",
  "phoneHeader": "_phoneHeader_10cnyz",
  "phoneButton": "_phoneButton_10cnyz",
  "calendarWrapper": "_calendarWrapper_10cnyz",
  "cardWrapper": "_cardWrapper_10cnyz",
  "cards": "_cards_10cnyz"
};
