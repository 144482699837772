import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import Verify, { VerifyAttachProps, VerifyCallbacks } from '@olo/auth-verify';

import ENV from 'mobile-web/config/environment';
import { host, nativeFetch } from 'mobile-web/lib/hybrid-util';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import SessionService from 'mobile-web/services/session';
import WindowService from 'mobile-web/services/window';

export default class OloAuthService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service session!: SessionService;
  @service window!: WindowService;

  // Untracked properties
  verify: Verify;

  // Tracked properties
  @tracked userVerified = false;

  // Constructor
  constructor() {
    super(...arguments);
    this.verify = new Verify();
  }

  // Other methods
  public async attachEmailListener({
    callbacks,
    ...props
  }: Pick<VerifyAttachProps, 'el' | 'initialValue' | 'callbacks'>) {
    const apiUrl = `${this.bootstrap.data?.oloAuthApiUrl}/api`;
    const channelId = this.channel.current?.guid;
    const loginProviderSlug = this.session.oloAuthProviderSlug;

    let loginUrl = '/login/otp';
    if (loginUrl[0] === '/') {
      const urlBase = host() || `${window.location.protocol}//${window.location.hostname}`;
      loginUrl = `${urlBase}${loginUrl}`;
    }

    this.verify.attachEmailListener({
      callbacks: {
        ...callbacks,
        onVerifyDone: this.createVerifyDoneCallback(callbacks?.onVerifyDone),
      },
      ...props,
      apiUrl,
      channelId,
      loginProviderSlug,
      loginUrl,
      fetchMethod: ENV.isHybrid ? nativeFetch : undefined,
    });
  }

  public detachEmailListener() {
    this.verify.detachEmailListener();
  }

  private createVerifyDoneCallback(onVerifyDone: VerifyCallbacks['onVerifyDone']) {
    return () => {
      this.userVerified = true;
      onVerifyDone?.();
      this.window.location().reload();
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'olo-auth': OloAuthService;
  }
}
