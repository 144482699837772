import Controller from '@ember/controller';
import { action } from '@ember/object';
import Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { Storage } from '@capacitor/storage';
import IntlService from 'ember-intl/services/intl';
import { MediaService } from 'ember-responsive';

import { StaticContentType } from 'mobile-web/components/static-content-modal';
import { fetchOAuthCallback } from 'mobile-web/lib/hybrid-util';
import Keyboard from 'mobile-web/lib/plugins/keyboard';
import { PreviousRoute } from 'mobile-web/lib/routing';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import DeviceService, { LAST_BACKGROUNDED_KEY } from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import LoyaltyService from 'mobile-web/services/loyalty';
import SessionService from 'mobile-web/services/session';
import SideMenuService from 'mobile-web/services/side-menu';
import VendorService from 'mobile-web/services/vendor';
import WindowService from 'mobile-web/services/window';
import style from 'mobile-web/styles/routes/application';

export default class ApplicationController extends Controller {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service loyalty!: LoyaltyService;
  @service media!: MediaService;
  @service router!: Router;
  @service session!: SessionService;
  @service sideMenu!: SideMenuService;
  @service vendor!: VendorService;
  @service window!: WindowService;
  @service intl!: IntlService;
  @service error!: ErrorService;

  // Untracked properties
  backButtonListener?: PluginListenerHandle;
  keyboardWillShowListener?: PluginListenerHandle;
  keyboardWillHideListener?: PluginListenerHandle;
  appStateChangeListener?: PluginListenerHandle;
  fontSizeDidChangeListener?: PluginListenerHandle;
  previousRoute!: PreviousRoute;
  queryParams = ['openBasket', 'restrictedFlow', 'src', 'open'];
  style = style;

  // Tracked properties
  @tracked openBasket?: string;
  @tracked restrictedFlow?: string;
  @tracked src?: string;
  @tracked open?: string;

  // Constructor
  constructor() {
    super(...arguments);
  }

  // Init
  init() {
    super.init();
    if (this.device.isHybrid) {
      App.addListener('backButton', () => this.handleBackButton()).then(
        listener => (this.backButtonListener = listener)
      );

      if (this.device.isHybridIOS) {
        Keyboard.addListener('keyboardWillShow', async () => await StatusBar.hide()).then(
          listener => (this.keyboardWillShowListener = listener)
        );
        Keyboard.addListener('keyboardWillHide', async () => await StatusBar.show()).then(
          listener => (this.keyboardWillHideListener = listener)
        );
        Keyboard.addListener('fontSizeDidChange', () => this.setPreferredFontSize()).then(
          listener => (this.fontSizeDidChangeListener = listener)
        );

        this.setPreferredFontSize();
      }
      App.addListener('appStateChange', async state => await this.handleAppStateChange(state)).then(
        listener => (this.appStateChangeListener = listener)
      );
      App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
        // Navigate directly to whatever the app URL was, for example, a vendor
        if (event.url.startsWith('https')) {
          const path = /https:\/\/[\w.\-_]*\/(.*)/.exec(event.url);

          if (path && path[1]) {
            // viewing vendor menus
            this.window.location().assign('/' + path[1]);
          }
        } else {
          let oauthCallbackParts: { state: string; code: string } | undefined = undefined;
          try {
            let url = event.url.split(':')[1];
            const facebookRedirectUriSecurityToken = '#_=_';
            if (url.endsWith(facebookRedirectUriSecurityToken)) {
              url = url.substring(0, url.length - facebookRedirectUriSecurityToken.length);
            }
            oauthCallbackParts = JSON.parse(atob(url));
          } catch (e) {
            this.error.reportError(e);
          }

          if (oauthCallbackParts?.state && oauthCallbackParts.code) {
            try {
              const oauthResult = await fetchOAuthCallback(
                `${window.Olo.hybridAppHost}/user/oauthcallback?state=${oauthCallbackParts.state}&code=${oauthCallbackParts.code}&hybridCallbackComplete=true`,
                false
              );

              this.session.handleCompletedOauthFlow(oauthResult, '/');
            } catch (e) {
              this.error.reportError(e);
            }
          }
        }
      });
    }
  }

  // WillDestroy
  willDestroy() {
    super.willDestroy();
    this.backButtonListener?.remove();
    this.keyboardWillShowListener?.remove();
    this.keyboardWillHideListener?.remove();
    this.appStateChangeListener?.remove();
    this.fontSizeDidChangeListener?.remove();
  }

  // Other methods
  handleCartState() {
    if (this.openBasket === 'true') {
      this.basket.open();
      this.openBasket = undefined;
    }
  }

  handleOpenState() {
    switch (this.open) {
      case StaticContentType.CONTACT_US:
      case StaticContentType.OPT_OUT_GUIDE:
      case StaticContentType.PRIVACY_POLICY:
      case StaticContentType.USER_AGREEMENT:
        this.bus.trigger('showStaticContent', { type: this.open });
        this.open = undefined;
        break;
      default:
        // Clear the query param and then do nothing
        this.open = undefined;
        break;
    }
  }

  handleBackButton(): void {
    if (this.sideMenu.isActive) {
      this.sideMenu.close();
    } else if (this.basket.isOpen) {
      this.basket.close();
    } else if (this.router.currentRouteName === 'index') {
      this.device.exitApp();
    } else {
      history.back();
    }
  }

  async handleAppStateChange(state: AppState) {
    if (state.isActive) {
      await this.handleAppForegrounded();
    } else {
      await this.handleAppBackgrounded();
    }
  }

  async handleAppForegrounded() {
    await this.device.checkForNativeUpdate();
  }

  async handleAppBackgrounded() {
    await Storage.set({ key: LAST_BACKGROUNDED_KEY, value: Date.now().toString() });
  }

  async setPreferredFontSize(): Promise<void> {
    if (Capacitor.isPluginAvailable('TextZoom')) {
      const { TextZoom } = await import('@capacitor/text-zoom');
      TextZoom.getPreferred().then(result => TextZoom.set({ value: result.value }));
    }
  }

  // Tasks

  // Actions

  @action
  logout() {
    return this.session.logout().then(redirectUrl => {
      this.basket.clear();
      this.sideMenu.close();

      this.window.location().assign(redirectUrl);
    });
  }
}
