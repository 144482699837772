import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Qetx3jU1",
  "block": "[[[1,[28,[35,0],[\"Feedback\"],null]],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"page-title\",\"component\",\"-outlet\"]]",
  "moduleName": "mobile-web/templates/feedback.hbs",
  "isStrictMode": false
});