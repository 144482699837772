import a0 from "../../helpers/svg-jar.js";
window.define('mobile-web/helpers/svg-jar', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "cQr3Rr/c",
  "block": "[[[11,1],[16,0,[30,0,[\"containerClass\"]]],[16,\"data-test-svg\",[30,1]],[17,2],[12],[1,\"\\n\"],[1,\"  \"],[1,[28,[35,0],[[30,0,[\"iconName\"]]],[[\"aria-label\",\"class\",\"role\"],[[30,0,[\"ariaLabel\"]],[30,0,[\"iconClass\"]],[30,0,[\"role\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"&attrs\"],false,[\"svg-jar\"]]",
  "moduleName": "mobile-web/components/svg/index.hbs",
  "isStrictMode": false
});