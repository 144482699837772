import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import DeviceService from 'mobile-web/services/device';

/**
 * Use this to reference the getters on the device service in templates,
 * e.g. `{{device 'isHybrid'}} to check DeviceService#isHybrid
 */
export default class extends Helper {
  @service device!: DeviceService;

  compute(
    params: Array<
      'isAndroid' | 'isHybrid' | 'isHybridIOS' | 'isIOS' | 'isWeb' | 'isWebAndroid' | 'isWebIOS'
    >
  ): boolean {
    return this.device[params[0]];
  }
}
