import a0 from "../../post-checkout/index.ts";
window.define('mobile-web/components/post-checkout', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "bu/nTeuY",
  "block": "[[[10,0],[14,\"data-test-routes-orderSummaryRoute\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@order\",\"@showSocialIntegration\",\"@allowReorder\",\"@onUserOrderEvent\",\"@onCancelOrder\",\"@saveFavorite\"],[[30,1],false,true,[28,[37,1],[[30,0],[30,0,[\"onUserOrderEvent\"]]],null],[28,[37,1],[[30,0],[30,0,[\"onCancelOrder\"]]],null],[30,0,[\"saveFavorite\"]]]],null],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"@order\"],false,[\"post-checkout\",\"action\"]]",
  "moduleName": "mobile-web/components/routes/order-summary-route/index.hbs",
  "isStrictMode": false
});