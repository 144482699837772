import a0 from "../../helpers/or.js";
window.define('mobile-web/helpers/or', function () {
  return a0;
});
import a1 from "../../modifiers/ref.ts";
window.define('mobile-web/modifiers/ref', function () {
  return a1;
});
import a2 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a2;
});
import a3 from "../../modifiers/will-destroy.js";
window.define('mobile-web/modifiers/will-destroy', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "aPvKUuku",
  "block": "[[[41,[28,[37,1],[[30,1],[30,0,[\"hasOpened\"]]],null],[[[1,\"  \"],[11,0],[16,0,[30,0,[\"overlayClass\"]]],[4,[38,2],[[30,0],\"overlayElement\"],null],[4,[38,3],[\"click\",[30,0,[\"overlayClick\"]]],null],[12],[1,\"\\n    \"],[11,0],[16,\"aria-labelledby\",[30,0,[\"ids\",\"heading\"]]],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"role\",\"dialog\"],[24,\"aria-modal\",\"true\"],[4,[38,2],[[30,0],\"rootElement\"],null],[12],[1,\"\\n      \"],[11,0],[16,0,[30,0,[\"style\",\"tag\"]]],[17,2],[12],[1,\"\\n        \"],[18,3,[[28,[37,5],null,[[\"close\",\"closeId\",\"headingId\"],[[30,0,[\"close\"]],[30,0,[\"ids\",\"close\"]],[30,0,[\"ids\",\"heading\"]]]]]]],[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n          \"],[18,4,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[11,0],[4,[38,6],[[30,0,[\"open\"]]],null],[4,[38,7],[[30,0,[\"close\"]]],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@isOpen\",\"&attrs\",\"&header\",\"&content\"],false,[\"if\",\"or\",\"ref\",\"on\",\"yield\",\"hash\",\"did-insert\",\"will-destroy\"]]",
  "moduleName": "mobile-web/components/flyout/index.hbs",
  "isStrictMode": false
});