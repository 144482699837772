export default {
  "root": "_root_akx4b6",
  "activeGoContainer": "_activeGoContainer_akx4b6",
  "headerText": "_headerText_akx4b6",
  "descriptionText": "_descriptionText_akx4b6",
  "buttonContainer": "_buttonContainer_akx4b6",
  "cancelLink": "_cancelLink_akx4b6",
  "inviteButtonContent": "_inviteButtonContent_akx4b6",
  "inviteButtonIcon": "_inviteButtonIcon_akx4b6",
  "bannerButton": "_bannerButton_akx4b6",
  "start": "_start_akx4b6",
  "startContainer": "_startContainer_akx4b6",
  "startLabel": "_startLabel_akx4b6",
  "startIcon": "_startIcon_akx4b6",
  "yourGroupContainer": "_yourGroupContainer_akx4b6",
  "participantContainer": "_participantContainer_akx4b6",
  "participantStatus": "_participantStatus_akx4b6",
  "iconClassClock": "_iconClassClock_akx4b6",
  "iconClassCheck": "_iconClassCheck_akx4b6",
  "startLink": "_startLink_akx4b6"
};
