export default {
  "placeholder": "_placeholder_jsbuvk",
  "sideMenu": "_sideMenu_jsbuvk",
  "footer": "_footer_jsbuvk",
  "bottom": "_bottom_jsbuvk",
  "appStoreBadge": "_appStoreBadge_jsbuvk",
  "footerLinks": "_footerLinks_jsbuvk",
  "contactUsLink": "_contactUsLink_jsbuvk",
  "contentInfo": "_contentInfo_jsbuvk",
  "appVersion": "_appVersion_jsbuvk"
};
