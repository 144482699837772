import a0 from "../components/routes/dispatch-summary-route/index.ts";
window.define('mobile-web/components/routes/dispatch-summary-route', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "qzLxQHl3",
  "block": "[[[8,[39,0],null,[[\"@order\",\"@initialStatuses\",\"@hash\"],[[30,0,[\"model\",\"o\"]],[30,0,[\"model\",\"statuses\"]],[30,0,[\"hash\"]]]],null],[1,\"\\n\"]],[],false,[\"routes/dispatch-summary-route\"]]",
  "moduleName": "mobile-web/templates/dispatch-summary.hbs",
  "isStrictMode": false
});