import Component from '@glimmer/component';

import { guids } from 'mobile-web/lib/utilities/guids';

import style from '../index.m.scss';

interface Args {
  // Required arguments
  label: string;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class HeaderLogoContent extends Component<Signature> {
  // Service injections

  // Untracked properties
  ids = guids(this, 'label');
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HeaderLogo::Content': typeof HeaderLogoContent;
  }
}
