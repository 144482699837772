import a0 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "58BUdfrq",
  "block": "[[[10,1],[15,0,[30,0,[\"style\",\"buttonContent\"]]],[14,\"data-test-header-button\",\"content\"],[12],[1,\"\\n  \"],[10,1],[14,\"data-test-header-button\",\"icon\"],[15,0,[30,0,[\"iconWrapperStyle\"]]],[12],[1,\"\\n\"],[1,\"    \"],[8,[39,0],[[16,0,[30,0,[\"style\",\"icon\"]]]],[[\"@icon\",\"@ariaLabel\",\"@iconClass\"],[[30,1,[\"svg\"]],[30,1,[\"ariaLabel\"]],[30,1,[\"iconClass\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,2],[[[1,\"    \"],[10,1],[14,\"data-test-header-button\",\"label\"],[15,0,[30,0,[\"labelStyle\"]]],[12],[1,[30,3,[\"text\"]]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@icon\",\"@showLabel\",\"@label\"],false,[\"svg\",\"if\"]]",
  "moduleName": "mobile-web/components/header-button/content/index.hbs",
  "isStrictMode": false
});