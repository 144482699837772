import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import { Code } from 'mobile-web/lib/country';
import { stateFullName, allStatesWithCountry } from 'mobile-web/lib/state';
import RegionModel from 'mobile-web/models/region';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';

import style from './index.m.scss';

interface ME<CurrentTarget extends EventTarget, Target extends EventTarget = CurrentTarget>
  extends MouseEvent {
  currentTarget: CurrentTarget | null;
  target: Target | null;
}

interface Args {
  // Required arguments
  model: { regions: RegionModel[]; map: string };

  // Optional arguments
}

export default class LocationsRoute extends Component<Args> {
  // Service injections
  @service intl!: IntlService;
  @service router!: RouterService;
  @service analytics!: AnalyticsService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isShown: Record<string, boolean> = {};
  @tracked mapElement?: HTMLElement;

  // Getters and setters
  get locationsByCountry() {
    const locationsByCountry: { [k in Code]?: RegionModel[] } = {};
    const allStates = allStatesWithCountry();
    this.args.model.regions.sortBy('name').forEach(region => {
      const code = allStates[region.code].countryCode;
      (locationsByCountry[code] = locationsByCountry[code] ?? []).push(region);
    });
    return Object.entries(locationsByCountry).sort();
  }

  get hasIntlLocations() {
    return Object.keys(this.locationsByCountry).length > 1;
  }

  get spansMultipleRegions() {
    return this.args.model.regions.length > 1;
  }

  get mapSvg() {
    return htmlSafe(this.args.model.map);
  }

  get mapGraphicRegions() {
    if (this.mapElement) {
      return Array.from(this.mapElement.querySelectorAll('[id^="map"]').values()).map(ele => ({
        element: ele,
        isShown: this.isShown[ele.id],
        name: stateFullName(this.stripMapIdPrefix(ele.id)),
      }));
    }
    return [];
  }

  get popperOptions() {
    return {
      modifiers: {
        flip: {
          boundariesElement: this.mapElement,
        },
      },
    };
  }

  // Constructor

  // Other methods
  stripMapIdPrefix(id: string) {
    return id.split('-').pop()!;
  }

  // Tasks

  // Actions
  @action
  renderMap(element: HTMLElement) {
    this.mapElement = element;
    this.mapElement?.firstElementChild?.classList.add(this.style.mapSvg);
    const modelRegions = this.args.model.regions.map(region => region.id);
    this.mapGraphicRegions
      .filter(region => modelRegions.includes(this.stripMapIdPrefix(region.element.id)))
      .forEach(region => {
        region.element.addEventListener('click', this.redirectToRegion);
        region.element.classList.add(this.style.activeRegion);
      });
  }

  @action
  redirectToRegion(event: ME<Element>) {
    if (!event.currentTarget?.id) {
      return;
    }

    this.analytics.trackEvent(AnalyticsEvents.ViewRegionsLocations, () => ({
      [AnalyticsProperties.LinkLocation]: 'Map',
    }));

    this.router.transitionTo('region-results', this.stripMapIdPrefix(event.currentTarget.id));
  }

  @action
  onMouseOver(event: ME<Element>) {
    this.isShown = {
      ...this.isShown,
      [event.target?.id ?? '']: true,
    };
  }

  @action
  onMouseOut(event: ME<Element>) {
    this.isShown = {
      ...this.isShown,
      [event.target?.id ?? '']: false,
    };
  }
}
