import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { tagName } from '@ember-decorators/component';
import fade from 'ember-animated/transitions/fade';

import { getDuration } from 'mobile-web/lib/animation';
import { HandoffMode, isAtStoreMode, isDeliveryMode } from 'mobile-web/lib/order-criteria';
import { Calendar, Weekday, CalendarType, DailySchedule } from 'mobile-web/lib/time/calendar';
import { hoursForDay } from 'mobile-web/lib/time/weekly-schedule';
import isSome from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';

import style from './index.m.scss';

@tagName('')
export default class VendorInfoModalCalendar extends Component {
  // Service injections
  @service store!: DS.Store;

  // Required arguments
  calendar!: Calendar;
  vendor!: Vendor;

  // Optional arguments

  // Class fields
  animationDuration = getDuration(400);
  transition = fade;
  style = style;

  // Computed properties
  @alias('vendor.weeklySchedule.currentWeekDay')
  weekDay!: Weekday;

  @computed('calendar.schedule.@each.weekDay', 'weekDay')
  get currentSchedule(): string {
    return hoursForDay(this.weekDay, this.calendar);
  }

  @computed('calendar.scheduleDescription')
  get handoffMode(): HandoffMode | undefined {
    if (this.calendar.scheduleDescription === CalendarType.Business) {
      return 'CounterPickup';
    }
    return this.store
      .peekAll('handoff-mode')
      .find(h => h.get('label') === this.calendar.scheduleDescription)
      ?.get('type');
  }

  @computed('handoffMode', 'vendor.{minimumPickupOrder,minimumDeliveryOrder}')
  get handoffMinimum(): number {
    if (isSome(this.handoffMode)) {
      const minimumDelivery = this.vendor.minimumDeliveryOrder;
      const minimumPickup = this.vendor.minimumPickupOrder;
      if (isDeliveryMode(this.handoffMode)) {
        return minimumDelivery;
      }
      if (isAtStoreMode(this.handoffMode)) {
        return minimumPickup;
      }
    }
    return 0;
  }

  @computed('calendar.schedule.@each.weekDay', 'weekDay')
  get sortedSchedule(): DailySchedule[] {
    const currentIdx = this.calendar.schedule.findIndex(s => s.weekDay === this.weekDay);

    return [
      ...this.calendar.schedule.slice(currentIdx),
      ...this.calendar.schedule.slice(0, currentIdx),
    ];
  }

  // Init
  init() {
    super.init();

    assert('`calendar` is required', isSome(this.calendar));
    assert('`vendor` is required', isSome(this.vendor));
  }

  // Other methods

  // Action
}
