import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import UpsellGroupModel from 'mobile-web/models/upsell-group';
import BasketService from 'mobile-web/services/basket';

import style from './index.m.scss';

export default class CartUpsells extends Component {
  // Service injections
  @service basket!: BasketService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get availableUpsellGroups(): UpsellGroupModel[] {
    return this.basket.basket?.upsellGroups.filter(ug => ug.availableItems.length > 0) ?? [];
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::Upsells': typeof CartUpsells;
  }
}
