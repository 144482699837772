import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import fade from 'ember-animated/transitions/fade';

import { HeadingLevel } from 'mobile-web/components/heading';
import { getDuration } from 'mobile-web/lib/animation';
import { ContentString } from 'mobile-web/services/content';
import FocusManagerService, { CategoryName, FocusData } from 'mobile-web/services/focus-manager';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  allowFocus?: () => boolean;
  canToggle?: boolean;
  contentClass?: string;
  expanded?: boolean;
  focusCategory?: CategoryName;
  headingLevel?: HeadingLevel;
  hideToggle?: boolean;
  onFocus?: () => void;
  onToggle?: (expanded: boolean) => void;
  startExpanded?: boolean;
  testSelector?: string;
  title?: ContentString;
  toggleClass?: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
    toggle: [];
  };
}

export default class Collapse extends Component<Signature> {
  // Service injections
  @service focusManager!: FocusManagerService;

  // Untracked properties
  animationDuration = getDuration(200);
  private button?: HTMLButtonElement;
  private focusData?: FocusData;
  private heading?: HTMLHeadingElement;
  style = style;
  transition = fade;

  // Tracked properties
  @tracked localExpanded = false;

  // Getters and setters
  get canToggle() {
    return this.args.canToggle ?? true;
  }

  get expanded() {
    return this.args.expanded ?? this.localExpanded;
  }

  get headingLevel(): HeadingLevel {
    return this.args.headingLevel ?? 2;
  }

  get hideToggle() {
    return this.args.hideToggle ?? false;
  }

  get testSelector() {
    return this.args.testSelector ?? '';
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.localExpanded = this.args.startExpanded ?? false;
  }

  // Other methods

  // Tasks

  // Actions
  @action
  deregister() {
    if (this.args.focusCategory && this.focusData) {
      this.focusManager.deregister(this.args.focusCategory, this.focusData);
    }
  }

  @action
  register() {
    if (this.args.focusCategory) {
      // Disabled buttons can't receive focus, so focus the heading instead
      const getTarget = () => (this.button?.disabled ? this.heading : this.button);
      this.focusData = {
        allowFocus: this.args.allowFocus,
        onFocus: this.args.onFocus,
        get target() {
          return getTarget();
        },
      };
      this.focusManager.register(this.args.focusCategory, this.focusData);
    }
  }

  @action
  setButton(button: HTMLButtonElement) {
    this.button = button;
  }

  @action
  setHeading(heading: HTMLHeadingElement) {
    this.heading = heading;
  }

  @action
  toggle() {
    if (this.args.onToggle) {
      this.args.onToggle(!this.args.expanded);
    } else {
      this.localExpanded = !this.localExpanded;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Collapse: typeof Collapse;
  }
}
