import Component from '@glimmer/component';

import style from './index.m.scss';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface Args {
  // Required arguments
  level: HeadingLevel;

  // Optional arguments
}

interface Signature {
  Element: HTMLHeadingElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

/**
 * @summary Dynamically creates an h1-6 element.
 *
 * Only use this if you need to switch between <h#> elements.
 *
 * @example <caption>Create a dynamic heading</caption>
 *
 * <Heading
 *   @level={{this.level}}
 *   class={{this.style.heading}}
 * >
 *   Example heading
 * </Heading>
 */
export default class Heading extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Heading: typeof Heading;
  }
}
