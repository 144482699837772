export default {
  "button": "_button_1xtxml _container_tuesss _standard_tuesss",
  "labelInputContainer": "_labelInputContainer_1xtxml _inlineLabelInputContainer_tuesss _labelInputContainerBase_tuesss",
  "label": "_label_1xtxml _inlineLabel_tuesss",
  "labelIcon": "_labelIcon_1xtxml _icon_tuesss",
  "labelText": "_labelText_1xtxml _inlineLabelText_tuesss",
  "input": "_input_1xtxml _inlineSelectInput_tuesss _selectInputBase_tuesss _inputBase_tuesss _inlineInputBase_tuesss",
  "placeholder": "_placeholder_1xtxml _inlineSelectInput_tuesss _selectInputBase_tuesss _inputBase_tuesss _inlineInputBase_tuesss",
  "icon": "_icon_1xtxml",
  "responsive": "_responsive_1xtxml _responsive_tuesss"
};
