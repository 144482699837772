import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import OnPremiseService from 'mobile-web/services/on-premise';
import StorageService from 'mobile-web/services/storage';

export default class OnPremiseExitOnPremiseModal extends Component {
  // Service injections
  @service onPremise!: OnPremiseService;
  @service storage!: StorageService;

  // Untracked properties

  // Tracked properties
  @tracked isOpen = false;

  // Getters and setters

  // Constructor
  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    if (this.storage.showExitOnPremiseModal) {
      this.storage.showExitOnPremiseModal = false;
      this.isOpen = true;
    }
  }

  // Other methods

  // Tasks

  // Actions
  @action
  continueOnPremise() {
    this.isOpen = false;
    this.onPremise.goToStoreVendorPage();
  }

  @action
  switchtoToGo() {
    this.isOpen = false;
    this.onPremise.endMultiOrder();
    this.onPremise.basketStartOver();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OnPremiseExitOnPremiseModal: typeof OnPremiseExitOnPremiseModal;
  }
}
