import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'mobile-web/lib/dayjs';
import FeaturesService from 'mobile-web/services/features';
import MwcIntlService from 'mobile-web/services/mwc-intl';

import style from './index.m.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Args {
  // Required arguments
  // Optional arguments
}

interface Signature {
  Element: HTMLDivElement;

  Args: Args;

  Blocks: {
    default: [];
  };
}

interface PrivacyPolicyNotificationFlag {
  effectiveDate?: string;
  startNotificationDate?: string;
}

/**
 * Display a notification that the privacy policy has been updated.
 *
 * The privacy policy notification will display when the
 * `updated-privacy-policy-notification` feature flag in launch darkly is set to
 * an object with `startNotificationDate`, and `effectiveDate` properties, and
 * the current time is between those timestamps.
 */
export default class PrivacyPolicyNotification extends Component<Signature> {
  // Service injections
  @service features!: FeaturesService;
  @service mwcIntl!: MwcIntlService;
  @service router!: RouterService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get effectiveDate() {
    const { effectiveDate } = this.flag;
    return effectiveDate ? dayjs(effectiveDate) : undefined;
  }

  get flag(): PrivacyPolicyNotificationFlag {
    return this.features.flags['updated-privacy-policy-notification'] ?? {};
  }

  get hidden() {
    const now = dayjs(this.mwcIntl.now);

    return !(
      this.startNotificationDate &&
      this.effectiveDate &&
      this.startNotificationDate <= now &&
      now < this.effectiveDate
    );
  }

  get startNotificationDate() {
    const { startNotificationDate } = this.flag;
    return startNotificationDate ? dayjs(startNotificationDate) : undefined;
  }

  get type() {
    if (!this.effectiveDate || !this.startNotificationDate) {
      return undefined;
    }

    const start = dayjs(this.startNotificationDate).format('YYYY-MM-DD');
    const end = dayjs(this.effectiveDate).format('YYYY-MM-DD');

    return `privacy-policy-${start}-${end}`;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PrivacyPolicyNotification: typeof PrivacyPolicyNotification;
  }
}
