export default {
  "root": "_root_1u4pxu",
  "infoContainer": "_infoContainer_1u4pxu",
  "img": "_img_1u4pxu",
  "info": "_info_1u4pxu",
  "header": "_header_1u4pxu",
  "text": "_text_1u4pxu",
  "nonProductText": "_nonProductText_1u4pxu",
  "productText": "_productText_1u4pxu",
  "optionGroups": "_optionGroups_1u4pxu",
  "menuToggle": "_menuToggle_1u4pxu",
  "category": "_category_1u4pxu",
  "categoryImg": "_categoryImg_1u4pxu",
  "categoryTitle": "_categoryTitle_1u4pxu",
  "categoryDescription": "_categoryDescription_1u4pxu",
  "viewAllGrid": "_viewAllGrid_1u4pxu",
  "viewAllItem": "_viewAllItem_1u4pxu",
  "menu": "_menu_1u4pxu",
  "menuItem": "_menuItem_1u4pxu"
};
