import a0 from "./badge/index.ts";
window.define('mobile-web/components/app-store-badges/badge', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "ii/s1rzZ",
  "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"showAndroidBadge\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@appIdentifier\",\"@type\"],[[30,0,[\"channel\",\"androidAppIdentifier\"]],\"android\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showiOSBadge\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@appIdentifier\",\"@type\"],[[30,0,[\"channel\",\"iOSAppIdentifier\"]],\"ios\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"if\",\"app-store-badges/badge\"]]",
  "moduleName": "mobile-web/components/app-store-badges/index.hbs",
  "isStrictMode": false
});