import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import BasketProductModel from 'mobile-web/models/basket-product';
import Order from 'mobile-web/models/order';

import PostCheckoutSubsectionLineItem from '../subsection/line-item';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  order?: Order;
}

interface Signature {
  Args: Args;

  Blocks: {
    default: [
      {
        lineItem: typeof PostCheckoutSubsectionLineItem;
      }
    ];
  };
}

export default class PostCheckoutOrderContents extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked showItemCustomizations = false;

  // Getters and setters
  get basketProducts(): BasketProductModel[] {
    return this.args.order?.basketProducts?.toArray() ?? [];
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  viewItemCustomizations() {
    this.showItemCustomizations = true;
  }

  @action
  hideItemCustomizations() {
    this.showItemCustomizations = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PostCheckout::OrderContents': typeof PostCheckoutOrderContents;
  }
}
