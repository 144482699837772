import a0 from "../../content-placeholders/index.ts";
window.define('mobile-web/components/content-placeholders', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Jfzrl8i2",
  "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"header\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"grid\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[1,2,3,4,5,6],null]],null]],null],null,[[[1,\"      \"],[10,0],[12],[1,\"\\n        \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"cardTop\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n        \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"cardBottom\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"placeholder\"],false,[\"content-placeholders\",\"each\",\"-track-array\",\"array\"]]",
  "moduleName": "mobile-web/components/application-loading/search/index.hbs",
  "isStrictMode": false
});