import a0 from "../status/index.ts";
window.define('mobile-web/components/group-order/status', function () {
  return a0;
});
import a1 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Zz9dNjaQ",
  "block": "[[[41,[30,0,[\"groupOrderName\"]],[[[1,\"  \"],[10,0],[14,\"data-test-cart-group-order-name\",\"\"],[15,0,[30,0,[\"style\",\"participantStatus\"]]],[12],[1,\"\\n    \"],[10,0],[15,\"data-test-group-order-role\",[30,0,[\"groupOrderNameLabel\"]]],[15,0,[30,0,[\"style\",\"groupOrderName\"]]],[12],[1,[30,0,[\"groupOrderName\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@status\",\"@hideLabel\"],[[30,0,[\"status\"]],true]],null],[1,\"\\n\"],[41,[30,0,[\"groupOrderLeftTheGroup\"]],[[[1,\"    \"],[10,0],[12],[1,[28,[35,2],[\"mwc.groupOrder.leftTheGroup\"],null]],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,3],[[16,0,[30,0,[\"style\",\"statusIcon\"]]]],[[\"@ariaLabel\",\"@icon\"],[\"Participant Status\",\"sign-out\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"group-order/status\",\"t\",\"svg\"]]",
  "moduleName": "mobile-web/components/group-order/name/index.hbs",
  "isStrictMode": false
});