import a0 from "../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "d0pus0hQ",
  "block": "[[[10,0],[14,\"data-test-no-valid-vendor-results\",\"\"],[15,0,[30,0,[\"style\",\"wrapper\"]]],[12],[1,\"\\n  \"],[10,2],[14,\"data-test-no-valid-vendor-message\",\"\"],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"message\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@fullWidth\",\"@size\",\"@onClick\"],[true,400,[30,0,[\"clickEdit\"]]]],[[\"default\"],[[[[1,\"\\n    Edit Your Search\\n  \"]],[]]]]],[1,\"\\n\\n\"],[13]],[],false,[\"button\"]]",
  "moduleName": "mobile-web/components/no-valid-vendor-results/index.hbs",
  "isStrictMode": false
});