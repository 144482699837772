import { inject as service } from '@ember/service';

// @ts-ignore
import PageTitleHelper from 'ember-page-title/helpers/page-title';

import ChannelService from 'mobile-web/services/channel';
import { SEPARATOR, SUFFIX } from 'mobile-web/services/page-title';

export default class OloPageTitleHelper extends PageTitleHelper {
  @service channel!: ChannelService;

  compute(params: string[], hash: AnyObject) {
    let newParams = hash.top
      ? [this.channel.current!.name, SUFFIX]
      : [this.channel.current!.name, SEPARATOR, ...params];

    if (!hash.top && !hash.noSuffix) {
      newParams.push(SUFFIX);
    }

    newParams = newParams.filter(p => !!p).map(p => p.replace(/%separator%/gi, SEPARATOR.trim()));

    return super.compute(newParams, {
      ...hash,
      prepend: false,
      replace: true,
      separator: SEPARATOR,
    });
  }
}
