import DS from 'ember-data';

export default class UpsellGroupSerializer extends DS.JSONSerializer.extend(
  DS.EmbeddedRecordsMixin
) {
  attrs = {
    items: { embedded: 'always' },
  };

  normalize(modelClass: DS.Model, resourceHash: any) {
    return super.normalize(modelClass, resourceHash);
  }
}
