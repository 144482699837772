import a0 from "../../../helpers/and.js";
window.define('mobile-web/helpers/and', function () {
  return a0;
});
import a1 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a2;
});
import a3 from "../../collapse/index.ts";
window.define('mobile-web/components/collapse', function () {
  return a3;
});
import a4 from "../upsell-group/index.ts";
window.define('mobile-web/components/cart/upsell-group', function () {
  return a4;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "QM7+GFbA",
  "block": "[[[41,[28,[37,1],[[30,0,[\"basket\",\"basket\",\"isUpsellEnabled\"]],[30,0,[\"availableUpsellGroups\"]]],null],[[[1,\"  \"],[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-cart-upsells\",\"\"],[14,\"tabindex\",\"0\"],[15,\"aria-label\",[28,[37,2],[\"mwc.cart.upsellSectionLabel\"],null]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@key\",\"@defaultMessage\"],[\"RESP_UPSELL_HEADER\",\"mwc.cart.singleUpsellGroupTitle\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@headingLevel\",\"@startExpanded\",\"@testSelector\",\"@title\",\"@toggleClass\"],[3,true,\"upsells\",[30,1,[\"value\"]],[30,0,[\"style\",\"toggle\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"ul\"],[15,0,[30,0,[\"style\",\"list\"]]],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"availableUpsellGroups\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[15,0,[30,0,[\"style\",\"listItem\"]]],[12],[1,\"\\n              \"],[8,[39,7],null,[[\"@upsellGroup\"],[[30,2]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"x\",\"upsellGroup\"],false,[\"if\",\"and\",\"t\",\"x-content\",\"collapse\",\"each\",\"-track-array\",\"cart/upsell-group\"]]",
  "moduleName": "mobile-web/components/cart/upsells/index.hbs",
  "isStrictMode": false
});