import a0 from "../form-field/index.ts";
window.define('mobile-web/components/form-field', function () {
  return a0;
});
import a1 from "../../helpers/and.js";
window.define('mobile-web/helpers/and', function () {
  return a1;
});
import a2 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a2;
});
import a3 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a3;
});
import a4 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a4;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "+sIxkTpT",
  "block": "[[[8,[39,0],[[24,\"data-test-password\",\"\"],[17,1]],[[\"@autocomplete\",\"@buttonData\",\"@class\",\"@hasRequirement\",\"@inputValue\",\"@label\",\"@name\",\"@trimOnChange\",\"@type\",\"@validationMessages\"],[[30,2],[28,[37,1],null,[[\"icon\",\"label\",\"onClick\"],[[30,0,[\"icon\"]],[30,0,[\"iconLabel\"]],[30,0,[\"toggleVisibility\"]]]]],[30,0,[\"class\"]],[30,3],[30,0,[\"value\"]],[30,0,[\"label\"]],[30,0,[\"name\"]],false,[30,0,[\"type\"]],[30,4]]],[[\"requirement\"],[[[[1,\"\\n\"],[41,[28,[37,3],[[30,5,[\"focused\"]],[30,0,[\"minlength\"]]],null],[[[1,\"      \"],[10,2],[15,1,[30,5,[\"id\"]]],[15,0,[28,[37,4],[[30,5,[\"messageClass\"]],[52,[30,0,[\"minlengthReached\"]],[30,5,[\"validClass\"]]]],null]],[14,\"data-test-password-minlengthRequirement\",\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"minlengthReached\"]],[[[1,\"          \"],[8,[39,5],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,5,[\"iconClass\"]],\"checkmark\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,1],[15,0,[30,5,[\"textClass\"]]],[12],[1,[28,[35,6],[\"mwc.password.minLength\"],[[\"length\"],[[30,0,[\"minlength\"]]]]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[5]]]]],[1,\"\\n\"]],[\"&attrs\",\"@autocomplete\",\"@requireMinlength\",\"@validationMessages\",\"r\"],false,[\"form-field\",\"hash\",\"if\",\"and\",\"classes\",\"svg\",\"t\"]]",
  "moduleName": "mobile-web/components/password/index.hbs",
  "isStrictMode": false
});