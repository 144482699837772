import a0 from "../privacy-policy-notification/index.ts";
window.define('mobile-web/components/privacy-policy-notification', function () {
  return a0;
});
import a1 from "../route-announcer/index.ts";
window.define('mobile-web/components/route-announcer', function () {
  return a1;
});
import a2 from "../side-menu/index.ts";
window.define('mobile-web/components/side-menu', function () {
  return a2;
});
import a3 from "../cart/index.ts";
window.define('mobile-web/components/cart', function () {
  return a3;
});
import a4 from "../header/index.ts";
window.define('mobile-web/components/header', function () {
  return a4;
});
import a5 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a5;
});
import a6 from "../footer/index.ts";
window.define('mobile-web/components/footer', function () {
  return a6;
});
import a7 from "../feedback-banners/index.ts";
window.define('mobile-web/components/feedback-banners', function () {
  return a7;
});
import a8 from "../edit-order-criteria-modal/index.ts";
window.define('mobile-web/components/edit-order-criteria-modal', function () {
  return a8;
});
import a9 from "../basket-transfer-modal/index.ts";
window.define('mobile-web/components/basket-transfer-modal', function () {
  return a9;
});
import a10 from "../challenge-modal/index.ts";
window.define('mobile-web/components/challenge-modal', function () {
  return a10;
});
import a11 from "../confirm-modal/index.ts";
window.define('mobile-web/components/confirm-modal', function () {
  return a11;
});
import a12 from "../product-modal/index.ts";
window.define('mobile-web/components/product-modal', function () {
  return a12;
});
import a13 from "../on-premise/continue-modal/index.ts";
window.define('mobile-web/components/on-premise/continue-modal', function () {
  return a13;
});
import a14 from "../on-premise/exit-on-premise-modal/index.ts";
window.define('mobile-web/components/on-premise/exit-on-premise-modal', function () {
  return a14;
});
import a15 from "../static-content-modal/index.ts";
window.define('mobile-web/components/static-content-modal', function () {
  return a15;
});
import a16 from "../../helpers/media.js";
window.define('mobile-web/helpers/media', function () {
  return a16;
});
import a17 from "../notifications/index.ts";
window.define('mobile-web/components/notifications', function () {
  return a17;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "zd6Xeh4q",
  "block": "[[[41,[51,[30,0,[\"isLoading\"]]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[10,0],[15,1,[30,0,[\"announcerContainerId\"]]],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],null,[[\"@onLogout\"],[[30,1]]],null],[1,\"\\n  \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[15,1,[30,0,[\"uniqueId\"]]],[15,0,[30,0,[\"style\",\"root\"]]],[12],[1,\"\\n  \"],[8,[39,5],null,[[\"@model\"],[[30,0,[\"headerModel\"]]]],null],[1,\"\\n  \"],[10,0],[15,0,[28,[37,6],[[30,0,[\"style\",\"body\"]],[30,0,[\"routeClass\"]]],null]],[12],[18,2,null],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isLoading\"]]],[[[1,\"    \"],[8,[39,8],[[16,0,[30,0,[\"style\",\"footer\"]]]],[[\"@model\"],[[30,0,[\"footerModel\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isLoading\"]]],[[[1,\"  \"],[8,[39,9],null,null,null],[1,\"\\n\\n  \"],[8,[39,10],null,null,null],[1,\"\\n  \"],[8,[39,11],null,null,null],[1,\"\\n  \"],[8,[39,12],null,null,null],[1,\"\\n  \"],[8,[39,13],null,null,null],[1,\"\\n  \"],[8,[39,14],null,null,null],[1,\"\\n  \"],[8,[39,15],null,null,null],[1,\"\\n  \"],[8,[39,16],null,null,null],[1,\"\\n  \"],[8,[39,17],null,null,null],[1,\"\\n\"],[41,[28,[37,19],[\"isMobile\"],null],[[[1,\"    \"],[8,[39,20],[[24,\"data-test-application-frame-notifications\",\"\"],[16,0,[30,0,[\"style\",\"notifications\"]]]],[[\"@filter\"],[[30,0,[\"notificationsFilter\"]]]],null],[1,\"\\n\"]],[]],null]],[]],null]],[\"@onLogout\",\"&default\"],false,[\"unless\",\"privacy-policy-notification\",\"route-announcer\",\"side-menu\",\"cart\",\"header\",\"classes\",\"yield\",\"footer\",\"feedback-banners\",\"edit-order-criteria-modal\",\"basket-transfer-modal\",\"challenge-modal\",\"confirm-modal\",\"product-modal\",\"on-premise/continue-modal\",\"on-premise/exit-on-premise-modal\",\"static-content-modal\",\"if\",\"media\",\"notifications\"]]",
  "moduleName": "mobile-web/components/application-frame/index.hbs",
  "isStrictMode": false
});