import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import TransitionContext from 'ember-animated/-private/transition-context';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';

import { getDuration } from 'mobile-web/lib/animation';
import { CustomizedProductModel } from 'mobile-web/lib/menu';
import isSome from 'mobile-web/lib/utilities/is-some';
import GroupOrderService from 'mobile-web/services/group-order';

import style from './index.m.scss';

interface Args {
  // Required arguments
  products: CustomizedProductModel[];

  // Optional arguments
  currency?: string;
  editable?: boolean;
  initialCount?: number;
  onEdit?: Action;
  onClose?: Action;
  participantName?: string;
  showGroupOrderName?: boolean;
}

interface Signature {
  Args: Args;
}

export default class CartProductList extends Component<Signature> {
  // Service injections
  @service groupOrder!: GroupOrderService;

  // Untracked properties
  animationDuration = getDuration(40);
  style = style;

  // Tracked properties
  @tracked isExpanded = false;

  // Getters and setters
  get shownProducts() {
    return this.isExpanded || isNone(this.args.initialCount)
      ? this.args.products
      : this.args.products.slice(0, this.args.initialCount);
  }

  get hiddenProductCount() {
    return isNone(this.args.initialCount)
      ? this.args.products.length
      : this.args.products.length - this.args.initialCount;
  }

  get showExpandButton() {
    return (
      !this.isExpanded &&
      isSome(this.args.initialCount) &&
      this.args.products.length > this.args.initialCount
    );
  }

  // Constructor

  // Other methods
  *transition({ insertedSprites, removedSprites }: TransitionContext): Generator {
    for (const sprite of insertedSprites) {
      sprite.startTranslatedBy(0, 30);
      fadeIn(sprite, { duration: 200 });
      move(sprite, { duration: 0 });
    }
    for (const sprite of removedSprites) {
      sprite.endTranslatedBy(sprite.initialBounds!.width, 0);
      move(sprite, { duration: 200 });
      fadeOut(sprite, { duration: 200 });
    }
  }

  // Tasks

  // Actions
  @action
  expandProducts() {
    this.isExpanded = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::ProductList': typeof CartProductList;
  }
}
