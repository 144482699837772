import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/routes/check-in-route/index.ts";
window.define('mobile-web/components/routes/check-in-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "D9hCWqFw",
  "block": "[[[1,[28,[35,0],[\"Successfully Checked In\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@order\"],[[30,0,[\"model\",\"order\"]]]],null]],[],false,[\"page-title\",\"routes/check-in-route\"]]",
  "moduleName": "mobile-web/templates/checkin.hbs",
  "isStrictMode": false
});