import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import { host } from 'mobile-web/lib/hybrid-util';
import {
  asPerimeterXResponse,
  PerimeterXResponse,
  PerimeterXInstance,
} from 'mobile-web/lib/perimeterx';
import { isFunction, noop } from 'mobile-web/lib/utilities/_';
import BootstrapService from 'mobile-web/services/bootstrap';
import DeviceService from 'mobile-web/services/device';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorHandler = (error: any) => void;

type ArgsBase = {
  response: PerimeterXResponse;
  onCancel?: Action;
};

type RequestArgs = ArgsBase & {
  retry: Action;
  onError?: ErrorHandler;
};

type ModelHookArgs = ArgsBase & {
  transition: Transition;
};

export default class ChallengeService extends Service {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service device!: DeviceService;
  @service router!: RouterService;

  // Tracked properties
  @tracked private args?: RequestArgs | ModelHookArgs;

  // Getters and setters
  get isOpen(): boolean {
    return !!this.args;
  }

  /**
   * We are only expecting this to be true when the endpoints that load user data,
   * which are called as part of bootrap init, are challenged.
   * If the bootstrap data endpoint itself is challenged, we can't recover.
   */
  get initBootsrapChallenged(): boolean {
    return this.bootstrap.initBootstrapFailed && this.isOpen;
  }

  // Constructor

  // Other methods

  // Tasks
  @task *request(request: Action, onError?: ErrorHandler): TaskGenerator<void> {
    try {
      yield request();
    } catch (e) {
      const response = asPerimeterXResponse(e);
      if (response) {
        this.args = { response, retry: request, onError };
      } else {
        if (onError) {
          onError(e);
        } else {
          throw e;
        }
      }
    }
  }

  // Actions
  @action
  routeError(transition: Transition, response: PerimeterXResponse): void {
    this.args = {
      response,
      transition,
      onCancel: () => {
        this.router.transitionTo('index');
      },
    };
    if (transition.to.name.includes('secure')) {
      this.router.transitionTo('secure.challenge');
    } else {
      this.router.transitionTo('challenge');
    }
  }

  // `| HTMLElement` is because we call `setup` in {{did-insert}}, which passes an element.
  // `appendBlockScript` is really only for testing convenience
  @action
  setup(appendBlockScript?: ((e: HTMLScriptElement) => void) | HTMLElement): void {
    const r = this.args!.response;
    const w = window as AnyObject;
    w._pxAppId = r.appId;
    w._pxJsClientSrc = `${this.device.isHybrid ? host() : ''}${r.jsClientSrc}`;
    w._pxFirstPartyEnabled = r.firstPartyEnabled;
    w._pxVid = r.vid;
    w._pxUuid = r.uuid;
    w._pxHostUrl = `${this.device.isHybrid ? host() : ''}${r.hostUrl}`;
    w._pxOnCaptchaSuccess = () => {
      // @todo: this could get `false` as an argument if the captcha was not passed
      this.close(true);
    };

    // If we're on hybrid, set the pxcookie so we can pick it up in hybrid-util and send it with requests
    if (this.device.isHybrid) {
      w[`${r.appId}_asyncInit`] = (px: PerimeterXInstance) => {
        px.Events.on('risk', (risk, name) => {
          localStorage.setItem('pxcookie', `${name}=${risk}`);
        });
      };
    }

    const script = document.createElement('script');
    if (this.device.isHybrid) {
      script.src = `${host()}${r.blockScript}`;
    } else {
      script.src = r.blockScript;
    }
    if (isFunction(appendBlockScript)) {
      appendBlockScript(script);
    } else {
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  @action
  open(response: PerimeterXResponse): void {
    this.args = {
      response,
      retry: noop,
    };
  }

  @action
  async close(retry?: boolean): Promise<void> {
    if (!this.args) {
      return;
    }

    if (!retry) {
      this.args.onCancel?.();
      this.args = undefined;
      return;
    }

    if ('retry' in this.args) {
      taskFor(this.request).perform(this.args.retry, this.args.onError);
    } else {
      if (this.initBootsrapChallenged) {
        await this.bootstrap.initBootstrap();
      }
      this.args.transition.retry();
    }
    this.args = undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    challenge: ChallengeService;
  }
}
