import Route from '@ember/routing/route';
import RSVP from 'rsvp';

import OrderModel from 'mobile-web/models/order';

export default class CheckInRoute extends Route {
  model(params: { id: EmberDataId; hash: unknown }) {
    const adapter = this.store.adapterFor('order');

    const checkIn = adapter.checkIn(params.id, params.hash);

    return this.store
      .findRecord('order', params.id, { adapterOptions: { hash: params.hash } })
      .then((order: OrderModel) =>
        RSVP.hash({
          order,
          checkIn,
        })
      );
  }
}
