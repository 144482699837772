import a0 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "bXVz49Px",
  "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"aria-label\",\"Share on Facebook\"],[24,\"data-test-facebookShareButton\",\"\"],[17,1],[4,[38,0],[\"click\",[30,0,[\"share\"]]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"Share\"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"Facebook\",[30,0,[\"style\",\"icon\"]],\"facebook-logo\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"on\",\"svg\"]]",
  "moduleName": "mobile-web/components/facebook-share-button/index.hbs",
  "isStrictMode": false
});