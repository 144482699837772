import a0 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "FjlAU/2m",
  "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[30,0,[\"style\",\"button\"]]],[16,\"data-test-favoriteButton\",[52,[30,1],\"filled\",\"unfilled\"]],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[[52,[30,1],[28,[37,3],[\"mwc.saveFavorite.unsetFavorite\"],null],[28,[37,3],[\"mwc.saveFavorite.setFavorite\"],null]],[30,0,[\"style\",\"icon\"]],[52,[30,1],\"favorite-filled\",\"favorite\"]]],null],[1,\"\\n\"],[13]],[\"@isFavorite\",\"&attrs\",\"@onClick\"],false,[\"if\",\"on\",\"svg\",\"t\"]]",
  "moduleName": "mobile-web/components/favorite-button/index.hbs",
  "isStrictMode": false
});