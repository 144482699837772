import a0 from "./content/index.ts";
window.define('mobile-web/components/header-logo/content', function () {
  return a0;
});
import a1 from "../../helpers/or.js";
window.define('mobile-web/helpers/or', function () {
  return a1;
});
import a2 from "../href/index.ts";
window.define('mobile-web/components/href', function () {
  return a2;
});
import a3 from "../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "uZvyuqLS",
  "block": "[[[10,\"style\"],[12],[1,\"\\n  \"],[1,[30,0,[\"componentStyles\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"disabled\"]],[[[1,\"  \"],[10,1],[14,\"data-test-header-logo\",\"root\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@label\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"useHref\"]],[30,0,[\"channel\",\"settings\",\"parentSiteUrl\"]]],null],[[[1,\"    \"],[8,[39,3],[[16,6,[30,0,[\"href\"]]],[16,\"target\",[52,[30,0,[\"channel\",\"settings\",\"parentSiteUrl\"]],\"_blank\"]],[24,\"data-test-header-logo\",\"root\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@label\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],[[24,\"data-test-header-logo\",\"root\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@label\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]]],[\"@label\"],false,[\"if\",\"header-logo/content\",\"or\",\"href\",\"olo-link-to\"]]",
  "moduleName": "mobile-web/components/header-logo/index.hbs",
  "isStrictMode": false
});