import a0 from "../modal/index.ts";
window.define('mobile-web/components/modal', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "UXr/XVuR",
  "block": "[[[41,[30,0,[\"challenge\",\"isOpen\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-challengeModal\",\"\"]],[[\"@title\",\"@onClose\"],[[28,[37,2],[\"mwc.challenge.title\"],null],[30,0,[\"challenge\",\"close\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"section\"]],null,[[\"@class\"],[[30,0,[\"style\",\"container\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,0],[24,1,\"px-captcha\"],[4,[38,3],[[30,0,[\"challenge\",\"setup\"]]],null],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\"],false,[\"if\",\"modal\",\"t\",\"did-insert\"]]",
  "moduleName": "mobile-web/components/challenge-modal/index.hbs",
  "isStrictMode": false
});