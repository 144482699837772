import a0 from "../../../../vendor-info/index.ts";
window.define('mobile-web/components/vendor-info', function () {
  return a0;
});
import a1 from "../../../../href/index.ts";
window.define('mobile-web/components/href', function () {
  return a1;
});
import a2 from "../../../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a2;
});
import a3 from "../../../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a3;
});
import a4 from "../../../../card/grid/index.ts";
window.define('mobile-web/components/card/grid', function () {
  return a4;
});
import a5 from "../../../../reward-card/index.ts";
window.define('mobile-web/components/reward-card', function () {
  return a5;
});
import a6 from "../../../../../helpers/gt.js";
window.define('mobile-web/helpers/gt', function () {
  return a6;
});
import { createTemplateFactory } from "../..\\..\\..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "GYi4qVZP",
  "block": "[[[10,0],[14,\"data-test-routes-menu-vendor-rewardsRoute\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@vendor\",\"@showMenuLink\",\"@showVendorPhoneNumber\",\"@showInfoModal\",\"@onToggleFavorite\"],[[30,1],true,[30,0,[\"channel\",\"settings\",\"showVendorPhoneNumber\"]],[30,2],[30,0,[\"toggleFavorite\"]]]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"descriptionContainer\"]]],[14,\"data-test-routes-menu-vendor-rewardsRoute-rewardDescription\",\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"allRewards\"]],[[[41,[30,0,[\"managedReward\"]],[[[1,\"        \"],[8,[39,2],[[16,6,[30,0,[\"managedReward\",\"manageRewardsUrl\"]]],[24,\"target\",\"_blank\"]],[[\"@testSelector\"],[\"manage-rewards-link\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"managedReward\",\"manageRewardsText\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[15,0,[30,0,[\"style\",\"rewardDescription\"]]],[12],[1,[28,[35,3],[\"mwc.rewards.availableRewardsText\"],null]],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"      \"],[10,2],[15,0,[30,0,[\"style\",\"noOffers\"]]],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@key\",\"@defaultMessage\"],[\"OFFERS_NOT_AVAILABLE\",[28,[37,3],[\"mwc.rewards.offersNotAvailableText\"],[[\"channelName\"],[[30,0,[\"channel\",\"current\",\"name\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,5],null,[[\"@models\"],[[30,0,[\"allRewards\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@reward\",\"@showScheme\",\"@vendor\"],[[30,3],[28,[37,7],[[30,0,[\"accounts\",\"length\"]],1],null],[30,1]]],null],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@vendor\",\"@showInfoModal\",\"reward\"],false,[\"vendor-info\",\"if\",\"href\",\"t\",\"x-content\",\"card/grid\",\"reward-card\",\"gt\"]]",
  "moduleName": "mobile-web/components/routes/menu/vendor/rewards-route/index.hbs",
  "isStrictMode": false
});