import a0 from "../olo-link-to/index.ts";
window.define('mobile-web/components/olo-link-to', function () {
  return a0;
});
import a1 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a1;
});
import a2 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "9yDroXoL",
  "block": "[[[11,0],[16,\"data-test-sideMenuLink\",[30,1]],[17,2],[12],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,1],[[16,0,[30,0,[\"style\",\"link\"]]],[4,[38,2],[\"click\",[30,0,[\"onLinkClick\"]]],null]],[[\"@route\",\"@testSelector\"],[[30,3],[30,0,[\"testSelector\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@icon\",\"@class\",\"@ariaLabel\"],[[30,4],[30,0,[\"style\",\"icon\"]],\"\"]],null],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"\\n        \"],[1,[28,[35,4],[[30,0,[\"translation\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"]],[]],[[[41,[30,5],[[[1,\"    \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"link\"]]],[16,\"data-test-sideMenu\",[30,0,[\"testSelector\"]]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,5]],null],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@icon\",\"@class\",\"@ariaLabel\"],[[30,4],[30,0,[\"style\",\"icon\"]],\"\"]],null],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[12],[1,\"\\n        \"],[1,[28,[35,4],[[30,0,[\"translation\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\\n    \"]],[]],null]],[]]],[1,\"\\n\"],[13]],[\"@testSelector\",\"&attrs\",\"@route\",\"@svg\",\"@onClick\"],false,[\"if\",\"olo-link-to\",\"on\",\"svg\",\"t\"]]",
  "moduleName": "mobile-web/components/side-menu-link/index.hbs",
  "isStrictMode": false
});