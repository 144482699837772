import { action } from '@ember/object';
import { cancel, later, next } from '@ember/runloop';
import { EmberRunTimer } from '@ember/runloop/types';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import config from 'mobile-web/config/environment';
import { getDuration } from 'mobile-web/lib/animation';
import { noop } from 'mobile-web/lib/utilities/_';
import { bindActionToKeyEvent, Key } from 'mobile-web/lib/utilities/keys';
import AnnouncerService from 'mobile-web/services/announcer';
import FocusService from 'mobile-web/services/focus';
import UserFeedback, { Message, ICONS } from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

const FOCUS_ROOT = '[data-focus-root]';

interface Args {
  // Required arguments
  model: Message;

  // Optional arguments
}

export default class FeedbackBanner extends Component<Args> {
  // Service injections
  @service announcer!: AnnouncerService;
  @service focus!: FocusService;
  @service userFeedback!: UserFeedback;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked escapeHandler!: (keyEvent: KeyboardEvent) => void;
  @tracked rootElement?: HTMLElement;
  @tracked runLater!: EmberRunTimer;

  // Getters and setters
  get feedbackActions() {
    return this.args.model.actions.map(({ label, fn: original, variant }) => ({
      label,
      fn: () => {
        this.userFeedback.clearAll();
        return original();
      },
      variant,
    }));
  }

  get cancelAction() {
    return this.args.model.actions.find(a => a.cancelAction);
  }

  get variantStyle() {
    return style[this.args.model.type];
  }

  get icon(): string {
    return ICONS[this.args.model.type];
  }

  get titleId(): string {
    return camelize(this.args.model.title);
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.model.actions.length === 0 && config.environment !== 'test') {
      this.runLater = later(
        this,
        () => {
          this.close();
        },
        getDuration(8000)
      );
    }

    next(this, () => {
      document.addEventListener('click', this.onClick, true);
    });
  }

  // Other methods

  // Tasks

  // Actions
  @action
  onClick(e: Event) {
    if (!this.rootElement?.contains(e.target as HTMLElement)) {
      this.close(this.cancelAction?.fn);
    }
  }

  @action
  didInsert() {
    this.announcer.announce(`${this.args.model.title} ${this.args.model.message}`, 'polite');

    this.escapeHandler = bindActionToKeyEvent(Key.Escape, () => this.close(this.cancelAction?.fn));
    document.addEventListener('keydown', this.escapeHandler, true);

    const firstFocusSelector =
      this.args.model.actions.length > 0 ? '[data-button="0"]' : '[data-close]';
    const firstFocusEl = document.querySelector<HTMLElement>(firstFocusSelector);

    const focusRootEl = document.querySelector<HTMLElement>(FOCUS_ROOT);
    if (focusRootEl) {
      this.focus.addTrap(focusRootEl, firstFocusEl ?? focusRootEl);
    }

    const focuser = firstFocusEl
      ? () => {
          if (!this.isDestroying && !this.isDestroyed) {
            this.focus.setLastFocus();
            firstFocusEl.focus();
          }
        }
      : noop;

    // Schedule setting focus to run *after* animation executes.
    later(this, focuser, 1);
  }

  @action
  willDestroy() {
    super.willDestroy();
    const el = document.querySelector<HTMLElement>(FOCUS_ROOT);
    if (el) {
      this.focus.removeTrap(el);
    }

    document.removeEventListener('keydown', this.escapeHandler, true);
    document.removeEventListener('click', this.onClick, true);
    cancel(this.runLater);
    this.focus.focusLast();
  }

  @action
  close(callback?: Action) {
    this.rootElement?.classList.add(style.closeAnimation);
    later(
      this,
      () => {
        callback?.();
        this.userFeedback.clear(this.args.model);
      },
      //wait for closing animation to end
      getDuration(200)
    );
  }
}
