import a0 from "../subsection/index.ts";
window.define('mobile-web/components/post-checkout/subsection', function () {
  return a0;
});
import a1 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a1;
});
import a2 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a2;
});
import a3 from "../../../helpers/perform.js";
window.define('mobile-web/helpers/perform', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "WPizbeKl",
  "block": "[[[10,0],[14,\"data-test-post-checkout-change-order\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@heading\",\"@icon\"],[\"Need to Change Something?\",[50,\"svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"pencil\",\"\"]]]]],[[\"buttons\"],[[[[1,\"\\n      \"],[8,[30,1,[\"buttonContainer\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"canModify\"]],[[[1,\"          \"],[8,[39,3],[[16,0,[30,1,[\"buttonClass\"]]],[24,\"data-test-editOrder\",\"\"]],[[\"@label\",\"@onClick\",\"@variant\"],[\"Edit Order\",[28,[37,4],[[30,0,[\"basket\",\"editOrderTask\"]],[30,2]],null],\"minor\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"canCancel\"]],[[[1,\"          \"],[8,[39,3],[[16,0,[30,1,[\"buttonClass\"]]],[24,\"data-test-cancelOrder\",\"\"]],[[\"@label\",\"@onClick\",\"@variant\"],[\"Cancel Order\",[30,0,[\"cancelOrder\"]],\"minor\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"s\",\"@order\"],false,[\"post-checkout/subsection\",\"component\",\"if\",\"button\",\"perform\"]]",
  "moduleName": "mobile-web/components/post-checkout/change-order/index.hbs",
  "isStrictMode": false
});