import a0 from "../segmented-control/index.ts";
window.define('mobile-web/components/segmented-control', function () {
  return a0;
});
import a1 from "../sign-in-form/index.ts";
window.define('mobile-web/components/sign-in-form', function () {
  return a1;
});
import a2 from "../create-account-form/index.ts";
window.define('mobile-web/components/create-account-form', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "rb5HZYGz",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-sign-in-create-account-form\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@class\",\"@label\",\"@name\",\"@onChange\",\"@segments\",\"@value\"],[[30,0,[\"style\",\"modeSwitcher\"]],\"Sign in mode\",\"sign-in-mode\",[28,[37,1],[[30,0],\"switchModes\"],[[\"value\"],[\"value\"]]],[30,0,[\"modes\"]],[30,0,[\"mode\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isSignInMode\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@headingLevel\",\"@onSubmit\"],[[30,0,[\"headingLevel\"]],[30,0,[\"onSignIn\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@guestUser\",\"@headingLevel\",\"@isUpgrade\",\"@lastOrderId\",\"@previousOrderIds\",\"@onSubmit\",\"@onSubmitError\",\"@optIn\"],[[30,0,[\"guestUser\"]],[30,0,[\"headingLevel\"]],[30,0,[\"isUpgrade\"]],[30,0,[\"lastOrderId\"]],[30,0,[\"previousOrderIds\"]],[30,0,[\"onCreateAccount\"]],[30,0,[\"onCreateAccountError\"]],[30,0,[\"optIn\"]]]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"segmented-control\",\"action\",\"if\",\"sign-in-form\",\"create-account-form\"]]",
  "moduleName": "mobile-web/components/sign-in-create-account-form/index.hbs",
  "isStrictMode": false
});