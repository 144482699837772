import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { tagName } from '@ember-decorators/component';

import { Breadcrumb } from 'mobile-web/components/breadcrumbs';
import isSome from 'mobile-web/lib/utilities/is-some';
import CategoryModel from 'mobile-web/models/category';
import ProductModel, { GlobalProduct } from 'mobile-web/models/product';
import ChannelService from 'mobile-web/services/channel';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

@tagName('')
export default class MenuCategoryRoute extends Component {
  // Service injections
  @service channel!: ChannelService;
  @service storage!: StorageService;
  @service globalEvents!: GlobalEventsService;

  // Required arguments
  category!: CategoryModel;

  // Optional arguments

  // Class fields
  style = style;
  @tracked productCards: Element[] = [];
  previouslyVisibleProductIds: string[] = [];

  // Computed properties
  @computed('category.products.@each.isLoaded')
  get isLoaded(): boolean {
    return this.category.products.every(p => p.isLoaded);
  }

  @computed('category.products.@each.isAvailable', 'storage.showFullMenu')
  get visibleProducts(): ProductModel[] {
    return this.category.products
      .filter(p => this.storage.showFullMenu || p.isAvailable)
      .sortBy('isDisabled');
  }

  @computed('category.name', 'category.vendor.{name,slug}')
  get breadcrumbs(): Breadcrumb[] {
    const vendor = this.category.vendor;
    return [
      { label: `${vendor.name} Menu`, route: 'menu.vendor', models: [vendor.slug] },
      { label: this.category.name },
    ];
  }

  // Init
  init() {
    super.init();
    assert('`category` is required', isSome(this.category));
  }

  // Other methods

  // Actions
  @action
  handleProductsVisible(visible: Element[]) {
    const [products, ids] = visible.reduce<[GlobalProduct[], string[]]>(
      (acc, el) => {
        const idx = el.getAttribute('data-idx')!;
        const product =
          isSome(idx) && this.visibleProducts.objectAt(+idx)?.serializeForGlobalData();
        if (product && !this.previouslyVisibleProductIds.includes(product.id)) {
          acc[0].push(product);
          acc[1].push(product.id);
        }
        return acc;
      },
      [[], []]
    );
    this.previouslyVisibleProductIds = this.previouslyVisibleProductIds.concat(ids);
    if (products.length) {
      this.globalEvents.trigger(GlobalEventName.ProductsVisible, products);
    }
  }
}
