import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { APPLICATION_FRAME_ID } from 'mobile-web/components/application-frame';
import { ANIMATION_GRADUAL_IN_TIME } from 'mobile-web/lib/animation';
import { safeLater } from 'mobile-web/lib/runloop';
import { classes } from 'mobile-web/lib/utilities/classes';
import { guids } from 'mobile-web/lib/utilities/guids';
import { bindActionToKeyEvent, Key } from 'mobile-web/lib/utilities/keys';
import FocusService from 'mobile-web/services/focus';
import ScrollService from 'mobile-web/services/scroll';

import style from './index.m.scss';

interface Args {
  // Required arguments
  isOpen: boolean;
  location: 'left' | 'right';
  onClose: Action;

  // Optional arguments
  allowBackgroundScroll?: boolean;
}

export default class Flyout extends Component<Args> {
  // Service injections
  @service focus!: FocusService;
  @service scroll!: ScrollService;

  // Untracked properties
  ids = guids(this, 'close', 'heading');
  style = style;

  // Tracked properties
  @tracked escapeHandler?: Action;
  @tracked hasOpened = false;
  @tracked overlayElement?: HTMLDivElement;
  @tracked rootElement!: HTMLDivElement;

  // Getters and setters
  get overlayClass(): string {
    return classes(this.style.overlay, this.style[this.args.location], {
      [this.style.hidden]: !(this.args.isOpen && this.hasOpened),
    });
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  open() {
    if (!this.hasOpened) {
      this.hasOpened = true;
    }

    if (!this.args.allowBackgroundScroll) {
      this.scroll.disableDocumentScroll();
    }

    this.focus.setLastFocus();
    safeLater(
      this,
      () => {
        const closeButton = document.getElementById(this.ids.close) as HTMLElement;
        this.focus.addTrap(this.rootElement, closeButton);
        closeButton.focus();

        const escapeHandler = bindActionToKeyEvent(Key.Escape, this.close);
        document.addEventListener('keydown', escapeHandler);
        this.escapeHandler = escapeHandler;

        document.getElementById(APPLICATION_FRAME_ID)?.setAttribute('aria-hidden', 'true');
      },
      ANIMATION_GRADUAL_IN_TIME
    );
  }

  @action
  close() {
    document.getElementById(APPLICATION_FRAME_ID)?.removeAttribute('aria-hidden');

    document.removeEventListener('keydown', this.escapeHandler!);
    this.escapeHandler = undefined;

    this.focus.removeTrap(this.rootElement);
    this.focus.focusLast();

    this.scroll.enableDocumentScroll();

    this.args.onClose();
  }

  @action
  overlayClick(e: Event) {
    if (e.target === this.overlayElement) {
      this.close();
    }
  }
}
