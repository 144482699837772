import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import SessionService from 'mobile-web/services/session';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;
}

export default class OpenCheck extends Component<Signature> {
  // Service injections
  @service router!: RouterService;
  @service session!: SessionService;
  @service vendor!: VendorService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  orderMore() {
    this.router.transitionTo('menu.vendor', this.vendor.vendorSlug!);
  }

  @action
  viewTab() {
    this.router.transitionTo('close-check');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OpenCheck: typeof OpenCheck;
  }
}
