export default {
  "root": "_root_1tmvze",
  "isSelected": "_isSelected_1tmvze",
  "isDisabled": "_isDisabled_1tmvze",
  "nameRow": "_nameRow_1tmvze",
  "name": "_name_1tmvze",
  "check": "_check_1tmvze",
  "content": "_content_1tmvze",
  "labels": "_labels_1tmvze",
  "hasOneLabel": "_hasOneLabel_1tmvze",
  "overrideLabel": "_overrideLabel_1tmvze",
  "price": "_price_1tmvze",
  "calories": "_calories_1tmvze",
  "hasPrice": "_hasPrice_1tmvze",
  "hasOverride": "_hasOverride_1tmvze",
  "yieldedContent": "_yieldedContent_1tmvze",
  "quantity": "_quantity_1tmvze",
  "break": "_break_1tmvze",
  "isDisabledDescription": "_isDisabledDescription_1tmvze"
};
