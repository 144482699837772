export default {
  "root": "_root_x2mwpo",
  "filterContainer": "_filterContainer_x2mwpo",
  "filter": "_filter_x2mwpo",
  "typeahead": "_typeahead_x2mwpo",
  "active": "_active_x2mwpo",
  "searchResult": "_searchResult_x2mwpo",
  "recentOrders": "_recentOrders_x2mwpo",
  "recentOrderCard": "_recentOrderCard_x2mwpo",
  "recentOrderBody": "_recentOrderBody_x2mwpo",
  "title": "_title_x2mwpo",
  "categoryContainer": "_categoryContainer_x2mwpo"
};
