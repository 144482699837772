import DS from 'ember-data';

import { HandoffMode } from 'mobile-web/lib/order-criteria';
import ChannelModel from 'mobile-web/models/channel';
import TimeWantedMode from 'mobile-web/models/time-wanted-mode';

export default class HandoffModeModel extends DS.Model {
  @DS.attr('string')
  label!: string;
  @DS.attr('string')
  type!: HandoffMode;
  @DS.attr('boolean')
  isPrivate?: boolean;

  @DS.belongsTo('channel', { async: false })
  channel!: ChannelModel;
  @DS.hasMany('time-wanted-mode', { async: false })
  timeWantedModes!: DS.ManyArray<TimeWantedMode>;
}
