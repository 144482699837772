export default {
  "root": "_root_1bw4cr",
  "headerWrapper": "_headerWrapper_1bw4cr",
  "mainHeader": "_mainHeader_1bw4cr",
  "subHeader": "_subHeader_1bw4cr",
  "formContainer": "_formContainer_1bw4cr",
  "form": "_form_1bw4cr",
  "linkContainer": "_linkContainer_1bw4cr",
  "link": "_link_1bw4cr"
};
