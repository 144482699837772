import Component from '@ember/component';
import { action, computed } from '@ember/object';

import { tagName } from '@ember-decorators/component';

import { HeadingLevel } from 'mobile-web/components/heading';
import { Segment } from 'mobile-web/components/segmented-control';
import { Customer } from 'mobile-web/lib/customer';

import style from './index.m.scss';

const LABEL_SIGN_IN = 'Sign In';
const LABEL_CREATE_ACCOUNT = 'Create Account';

export enum Mode {
  SignIn,
  CreateAccount,
}

const MODES: Segment<Mode>[] = [
  {
    label: LABEL_SIGN_IN,
    value: Mode.SignIn,
  },
  {
    label: LABEL_CREATE_ACCOUNT,
    value: Mode.CreateAccount,
  },
];

@tagName('div') // Needed because we can't handle DOM events in a tagless component
export default class SignInCreateAccountForm extends Component {
  initialMode?: string | number;
  mode = Mode.SignIn;
  onModeChange?: (mode: Mode) => void;

  // Sign in
  onSignIn?: (email: string, password: string) => void;

  // Create account
  guestUser?: Customer;
  headingLevel: HeadingLevel = 1;
  isUpgrade?: boolean;
  lastOrderId?: EmberDataId;
  previousOrderIds?: EmberDataId[];
  optIn?: boolean;
  onCreateAccount?: () => void;
  onCreateAccountError?: (error: any) => void;

  // Class properties
  modes = MODES;
  style = style;

  // Computeds
  @computed('mode')
  get isSignInMode(): boolean {
    return this.mode === Mode.SignIn;
  }

  @action
  switchModes(mode: Mode) {
    const onModeChange = this.onModeChange;
    if (onModeChange) {
      onModeChange(mode);
    }
  }
}
