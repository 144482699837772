import a0 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../toggle/index.ts";
window.define('mobile-web/components/toggle', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "2YhrbiJv",
  "block": "[[[41,[51,[30,0,[\"hideMenuToggle\"]]],[[[1,\"  \"],[11,0],[17,1],[12],[1,\"\\n    \"],[10,\"label\"],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-menuFilter\",\"\"],[12],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"restaurant-menu\"]],null],[1,\"\\n        \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[14,\"data-test-menuFilter-label\",\"\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"mwc.menuFilter.fullMenuLabel\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[1,\"      \"],[8,[39,3],[[16,\"aria-label\",[28,[37,2],[\"mwc.menuFilter.fullMenuLabel\"],null]]],[[\"@value\",\"@onChange\"],[[30,0,[\"storage\",\"showFullMenu\"]],[30,0,[\"onChange\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"unless\",\"svg\",\"t\",\"toggle\"]]",
  "moduleName": "mobile-web/components/menu-filter/index.hbs",
  "isStrictMode": false
});