import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsPropertiesPartial,
} from 'mobile-web/services/analytics';

// Usage:
// <div {{on 'click' (track-event 'event name here' (hash some='data' more='data'))}}>
//    <span>Existing nodes to wrap</span>
// </div>

// For components that already have actions and don't have elements that an
// onclick listener can be added to, it's better to call the analytics service directly.

export interface Signature {
  Args: {
    Positional: [EnumOrValue<AnalyticsEvents>, AnalyticsPropertiesPartial | undefined];
  };
  Return: () => void;
}

export default class TrackEvent extends Helper<Signature> {
  @service analytics!: AnalyticsService;

  compute(params: Signature['Args']['Positional']) {
    const eventName = params[0] as AnalyticsEvents;
    const customProperties = params[1] || {};

    const { analytics } = this;

    return () => {
      analytics.trackEvent(eventName, () => customProperties);
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'track-event': typeof TrackEvent;
  }
}
