import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';

import { MediaService } from 'ember-responsive';

import { TABLET } from 'mobile-web/breakpoints';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import CmsContentService from 'mobile-web/services/cms-content';
import DeviceService from 'mobile-web/services/device';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  // Required arguments
  label: string;

  // Optional arguments
  /**
   * Used to use the href instead of the olo-link-to component
   * so that Ember does not eagerly instantiate the controller
   */
  useHref?: boolean;
}

interface Signature {
  Args: Args;
}

export default class HeaderLogo extends Component<Signature> {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service cmsContent!: CmsContentService;
  @service device!: DeviceService;
  @service media!: MediaService;
  @service session!: SessionService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get useHref(): boolean {
    return this.args.useHref ?? false;
  }

  get componentStyles(): SafeString {
    const pixels = `${TABLET}px`;
    const cmsImageUrls = this.getCmsImage();

    const regImageUrl =
      cmsImageUrls[0] ||
      [
        this.buildImageUrl('/logo/logo-narrow.svg'),
        this.buildImageUrl('/logo/logo-narrow.png'),
      ].join(', ');

    const tabletImageUrl =
      cmsImageUrls[1] ||
      [
        this.buildImageUrl('/logo/logo-wide.svg'),
        this.buildImageUrl('/logo/logo-wide.png'),
        this.buildImageUrl('/logo/logo-wide-fallback.png'),
      ].join(', ');

    const logoStyles = `
      .${this.style.logo} {
        background-image: ${regImageUrl};
        max-width: 320px;
      }
      @media (min-width: ${pixels}) {
        .${this.style.logo} {
          background-image: ${tabletImageUrl};
        }
      }
      @media (max-width: ${pixels}) {
        .${this.style.logo} {
          max-width: 210px;
        }
      }
    `;

    return htmlSafe(logoStyles);
  }

  get href(): string {
    return this.channel.settings?.parentSiteUrl ?? '/';
  }

  // Used to display the logo but disable the linking functionality
  get disabled(): boolean {
    return (
      !(this.device.isWeb && this.media.isDesktop) ||
      this.session.isRestrictedFlow ||
      !!this.basket.basket?.isCallcenterOrder
    );
  }

  // Constructor

  // Other methods
  getCmsImage() {
    const mobileUri = this.cmsContent.getContent('logoImage')?.imageUriMobile;
    const tabletUri = this.cmsContent.getContent('logoImage')?.imageUriTablet;

    if (mobileUri || tabletUri) {
      return [`url(${mobileUri ?? tabletUri})`, `url(${tabletUri ?? mobileUri})`];
    }
    return '';
  }

  buildImageUrl(path: string) {
    if (this.channel.current) {
      return `url(${this.channel.buildCdnImageUrl(path)})`;
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HeaderLogo: typeof HeaderLogo;
  }
}
