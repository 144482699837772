import a0 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import a1 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a1;
});
import a2 from "../../modifiers/on-window.ts";
window.define('mobile-web/modifiers/on-window', function () {
  return a2;
});
import a3 from "../../modifiers/observe-mutation.js";
window.define('mobile-web/modifiers/observe-mutation', function () {
  return a3;
});
import a4 from "../../helpers/not.js";
window.define('mobile-web/helpers/not', function () {
  return a4;
});
import a5 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a5;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "qQuCdKO3",
  "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"style\",\"root\"]],[52,[30,0,[\"isCollapsed\"]],[30,0,[\"style\",\"isCollapsed\"]]]],null]],[17,1],[12],[1,\"\\n  \"],[11,0],[16,0,[30,0,[\"style\",\"content\"]]],[16,5,[30,0,[\"contentStyle\"]]],[4,[38,2],[[30,0,[\"setup\"]]],null],[4,[38,3],[\"resize\",[30,0,[\"resize\"]]],null],[12],[1,\"\\n\"],[1,\"    \"],[11,0],[16,0,[30,0,[\"style\",\"innerContent\"]]],[4,[38,4],[[30,0,[\"update\"]]],[[\"subtree\",\"characterData\"],[true,true]]],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showExpand\"]],[[[1,\"    \"],[11,\"button\"],[16,0,[30,0,[\"style\",\"showMore\"]]],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[28,[37,8],[[30,0,[\"isCollapsed\"]]],null],[28,[37,9],[[30,0,[\"isCollapsed\"]]],null]],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,10],[\"mwc.expandableText.collapseButton\"],[[\"isCollapsed\"],[[30,0,[\"isCollapsed\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"classes\",\"if\",\"did-insert\",\"on-window\",\"observe-mutation\",\"yield\",\"on\",\"fn\",\"mut\",\"not\",\"t\"]]",
  "moduleName": "mobile-web/components/expandable-text/index.hbs",
  "isStrictMode": false
});