import { modifier } from 'ember-modifier';

type Observer = {
  observe(element: Element): void;
  unobserve?(element: Element): void;
};

const observe = modifier((element: Element, [observer]: [Observer?]) => {
  observer?.observe(element);

  return () => {
    observer?.unobserve?.(element);
  };
});

export default observe;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    observe: typeof observe;
  }
}
