import { alias, readOnly } from '@ember/object/computed';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { PreviousRoute } from 'mobile-web/lib/routing';
import Channel from 'mobile-web/models/channel';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import ChannelService from 'mobile-web/services/channel';
import NotificationsService from 'mobile-web/services/notifications';
import { Notification } from 'mobile-web/services/notifications';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

export default class IndexRoute extends Route {
  previousRoute?: PreviousRoute = undefined;

  @service session!: SessionService;
  @service channel!: ChannelService;
  @service storage!: StorageService;
  @service notifications!: NotificationsService;

  @alias('channel.current')
  currentChannel?: Channel;
  @readOnly('session.isLoggedIn')
  isLoggedIn!: boolean;

  model(): OrderSearchResultModel[] | PromiseLike<OrderSearchResultModel[]> {
    if (this.isLoggedIn) {
      return this.store
        .findAll('order-search-result')
        .then(orders => orders.filterBy('canUserCheckin', true));
    }

    return [];
  }

  afterModel() {
    const startupNotifications = this.storage.get('startupNotifications') ?? [];
    if (
      !Array.isArray(startupNotifications) ||
      !this.validStartupNotifications(startupNotifications)
    ) {
      return;
    }
    this.notifications.show(startupNotifications);
    this.storage.set('startupNotifications', []);
  }

  validStartupNotifications(notifications: Notification[]) {
    for (const notification of notifications) {
      const validNotificationObject =
        typeof notification === 'object' &&
        'level' in notification &&
        'message' in notification &&
        'type' in notification &&
        typeof notification.level === 'number' &&
        typeof notification.message === 'string' &&
        typeof notification.type === 'number';

      if (!validNotificationObject) {
        return validNotificationObject;
      }
    }

    return true;
  }
}
