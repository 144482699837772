import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments
  isCompleted: boolean;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class ProductCustomizationRequirement extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get icon() {
    return this.args.isCompleted ? 'check' : 'exclamation';
  }

  get rootStyle() {
    return this.args.isCompleted ? this.style.completed : this.style.required;
  }

  get testState() {
    return this.args.isCompleted ? 'completed' : 'required';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProductCustomization::Requirement': typeof ProductCustomizationRequirement;
  }
}
