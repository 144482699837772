import a0 from "../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a0;
});
import a1 from "../form-field/index.ts";
window.define('mobile-web/components/form-field', function () {
  return a1;
});
import a2 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a2;
});
import a3 from "../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "U2KNrI/W",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-rewardsSearchForm\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@defaultMessage\",\"@key\"],[\"mwc.rewardsSearch.addressPlaceholder\",\"RESP_SPLASH_INPUT_TEXT_NON_DELIVERY\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@name\",\"@class\",\"@labelClass\",\"@label\",\"@autocomplete\",\"@icon\",\"@inputValue\",\"@placeholder\",\"@responsiveLabel\"],[\"rewardsSearch\",[30,0,[\"style\",\"row\"]],[30,0,[\"style\",\"label\"]],[28,[37,2],[\"mwc.rewardsSearch.nearbyLabel\"],null],\"postal-code\",\"place\",[30,0,[\"_address\"]],[30,1,[\"value\"]],true]],null],[1,\"\\n    \"],[8,[39,3],[[16,0,[30,0,[\"style\",\"submit\"]]]],[[\"@testSelector\",\"@onClick\"],[\"rewardsSearchSubmit\",[28,[37,4],[[30,2],[30,0,[\"_address\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"mwc.rewardsSearch.searchButton\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"x\",\"@onSubmit\"],false,[\"x-content\",\"form-field\",\"t\",\"button\",\"fn\"]]",
  "moduleName": "mobile-web/components/rewards-search-form/index.hbs",
  "isStrictMode": false
});