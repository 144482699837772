import Component from '@ember/component';
import { assert } from '@ember/debug';
import { action, computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import { tagName } from '@ember-decorators/component';
import { initializeOloPay } from '@olo/pay';
import dayjs from 'dayjs';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';
import { Searcher } from 'fast-fuzzy';

import { Variant } from 'mobile-web/components/button';
import { isForToday } from 'mobile-web/lib/order-criteria';
import isSome from 'mobile-web/lib/utilities/is-some';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import Vendor from 'mobile-web/models/vendor';
import { FailureCategory } from 'mobile-web/models/vendor-search-result';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChallengeService from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';
import ContentService, { ContentData } from 'mobile-web/services/content';
import FeaturesService from 'mobile-web/services/features';
import MwcIntl from 'mobile-web/services/mwc-intl';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import ReorderService from 'mobile-web/services/reorder';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

import style from './index.m.scss';

@tagName('')
export default class MenuVendorRoute extends Component {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service challenge!: ChallengeService;
  @service channel!: ChannelService;
  @service content!: ContentService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service mwcIntl!: MwcIntl;
  @service reorder!: ReorderService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service orderCriteria!: OrderCriteriaService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;
  @service onPremise!: OnPremiseService;

  // Required arguments
  vendor!: Vendor;

  // Optional arguments
  recentOrders?: OrderSearchResultModel[];
  showInfoModal?: boolean;
  skipPreCheck?: boolean;

  // Class fields
  observer?: IntersectionObserver;
  searchActive = false;
  style = style;

  // Computed properties
  @computed('storage.showFullMenu', 'store', 'vendor')
  get products() {
    return this.store
      .peekAll('product')
      .filter(p => p.vendor === this.vendor && (this.storage.showFullMenu || p.isAvailable));
  }

  @computed('products.[]')
  get productSearcher() {
    return new Searcher(this.products, { keySelector: obj => obj.name });
  }

  // Init
  init() {
    super.init();
    assert('`vendor` is required', isSome(this.vendor));

    if (this.vendor.settings.isOloPay) {
      initializeOloPay();
    }
  }

  // Other methods
  async showPreCheckBanner(
    titleCommand: Promise<ContentData>,
    messageCommand: Promise<ContentData>
  ) {
    const changeLocationLabel = this.intl.t('mwc.vendor.changeLocationButton');
    const proceedToMenuLabel = this.intl.t('mwc.vendor.proceedToMenuButton');

    const [titleData, messageData] = await Promise.all([titleCommand, messageCommand]);

    const title = titleData.toString();
    const message = messageData.toString();

    const trackAnalyticsEvent = (locationSelection: string) => {
      const titleTemplate = titleData.toTemplateString();
      const messageTemplate = messageData.toTemplateString();

      this.analytics.trackEvent(AnalyticsEvents.AnsweredChangeLocation, () => ({
        [AnalyticsProperties.AnsweredChangeLocationDialogueText]: `${title} ${message}`,
        [AnalyticsProperties.AnsweredChangeLocationSelection]: locationSelection,
        [AnalyticsProperties.AnsweredChangeLocationTemplateString]: `${titleTemplate} ${messageTemplate}`,
      }));
    };

    this.userFeedback.add({
      type: Type.Negative,
      title,
      message,
      actions: [
        {
          label: changeLocationLabel,
          fn: () => {
            trackAnalyticsEvent(changeLocationLabel);
            this.router.transitionTo('vendor-search-results');
          },
          variant: Variant.Main,
        },
        {
          label: proceedToMenuLabel,
          fn: () => {
            trackAnalyticsEvent(proceedToMenuLabel);
            this.storage.ignorePrecheckError = true;
          },
          variant: Variant.Minor,
          cancelAction: true,
        },
      ],
    });
  }

  getBusinessHoursMessage(): Promise<ContentData> {
    // Alert the user when the next available hours are if available
    let dateTime: string;

    const timeZone = this.vendor.timeZoneId;
    if (timeZone) {
      dateTime = this.mwcIntl.vendorRelativeDateTime(
        new Date(this.vendor.nextOpenTime!),
        timeZone,
        { style: 'sentenceCase' }
      );
    } else {
      dateTime = this.mwcIntl.relativeDateTime(dayjs(this.vendor.nextOpenTime));
    }

    return this.content.getData(
      'RESP_BUSINESS_HOURS_UNAVAILABLE',
      'mwc.vendor.businessHoursUnavailable',
      {
        vendorName: this.vendor.name,
        dateTime,
      }
    );
  }

  // Actions
  @action
  doReorder(order: OrderSearchResultModel, index: number) {
    this.reorder.reorder(order, { index });
  }

  @action
  goToOrderDetails(orderId: string) {
    this.router.transitionTo('order-summary', orderId);
  }

  @action
  onInsert() {
    const scheduledForToday =
      !isSome(this.orderCriteria.criteria) || isForToday(this.orderCriteria.criteria);

    if (this.vendor.overrideReason && scheduledForToday) {
      this.showPreCheckBanner(
        this.content.getStringData(''),
        this.content.getStringData(this.vendor.overrideReason.reason)
      );
    } else if (!this.skipPreCheck) {
      this.preCheck();
    }
  }

  @action
  async preCheck() {
    const shouldDisplayBusinessHoursMsg = !this.vendor.isOpen && this.vendor.nextOpenTime;
    const criteria = this.orderCriteria.searchOrderCriteria;
    const basket = this.basket.basket;
    if (criteria) {
      taskFor(this.challenge.request).perform(async () => {
        const response = await this.vendor.preCheck(criteria);
        if (!response.isValid && !this.storage.ignorePrecheckError) {
          const isTimeWantedError = response.failureCategory === FailureCategory.TimeWanted;
          this.showPreCheckBanner(
            this.content.getTranslationEntry('mwc.vendor.preCheckWarningTitle'),
            shouldDisplayBusinessHoursMsg && isTimeWantedError
              ? this.getBusinessHoursMessage()
              : this.content.getStringData(response.errorMessage)
          );
        } else if (response.isValid) {
          if (this.basket.basket) {
            this.orderCriteria.updateBasket();
          }
        }
      });
    } else if (
      !this.storage.ignorePrecheckError &&
      (!basket || !basket.isAdvance) &&
      !isEmpty(this.vendor.unavailableMessage)
    ) {
      if (shouldDisplayBusinessHoursMsg) {
        this.showPreCheckBanner(
          this.content.getTranslationEntry('mwc.vendor.preCheckWarningTitle'),
          this.getBusinessHoursMessage()
        );
      } else {
        this.showPreCheckBanner(
          this.content.getStringData(this.vendor.unavailableMessage!),
          this.content.getTranslationEntry('mwc.vendor.unavailableMessage')
        );
      }
    }
  }
}
