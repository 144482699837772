import a0 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "msWPkfje",
  "block": "[[[8,[39,0],[[16,0,[30,0,[\"rootClass\"]]]],[[\"@route\",\"@model\"],[\"dispatch-summary\",[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"text\"]]],[14,\"data-test-trackDispatch\",\"\"],[12],[1,\"Track Order Progress\"],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"track-changes\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@orderId\"],false,[\"link-to\",\"svg\"]]",
  "moduleName": "mobile-web/components/track-order-button/index.hbs",
  "isStrictMode": false
});