import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/routes/region-results-route/index.ts";
window.define('mobile-web/components/routes/region-results-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Evxi4MS2",
  "block": "[[[1,[28,[35,0],[[30,1,[\"pageTitle\"]]],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@results\"],[[30,1,[\"result\"]]]],null]],[\"@model\"],false,[\"page-title\",\"routes/region-results-route\"]]",
  "moduleName": "mobile-web/templates/region-results.hbs",
  "isStrictMode": false
});