import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ensureSafeComponent } from '@embroider/util';
import { WithBoundArgs } from '@glint/template';

import Svg from 'mobile-web/components/svg';
import { classes } from 'mobile-web/lib/utilities/classes';

import style from './index.m.scss';

export type CloseEditor = () => void;
export type OpenEditor = () => void;

interface Args {
  // Required arguments
  /** The label of the wrapped form field. */
  label: string;
  /** The name of the wrapped form field. */
  name: string;
  /** The value to display in the form field. */
  value: string;

  // Optional arguments
  /** Whether or not to hide the label text. */
  hideLabel?: boolean;
  /** Extra classes for the label */
  labelClass?: string;
  /** The icon to display by the label. */
  icon?: WithBoundArgs<typeof Svg, 'icon'>;
  /** Use if you want to override default click behavior. */
  onClick?: (openEditor: OpenEditor) => void;
  /** Placeholder text. */
  placeholder?: string;
  /**
   * Whether or not to make the label responsive, i.e. hidden in small viewports
   * but displayed in larger ones.
   */
  responsiveLabel?: boolean;
}

export default class FormEditButton extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  /** Whether or not the editor is open. */
  @tracked isEditorOpen = false;

  // Getters and setters
  get icon() {
    return this.args.icon ? ensureSafeComponent(this.args.icon, this) : undefined;
  }

  get placeholder(): string {
    return this.args.placeholder ?? '';
  }

  get ariaLabel(): string {
    return `Edit ${this.args.label}, ${this.args.value}`;
  }

  get labelClassName(): string {
    return classes(style.label, this.args.labelClass);
  }

  get labelTextClass(): string {
    return classes(style.labelText, this.args.responsiveLabel ? style.responsive : '');
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  handleClick() {
    const onClick = this.args.onClick;
    if (onClick) {
      onClick(this.openEditor);
    } else {
      this.openEditor();
    }
  }

  @action
  openEditor() {
    this.isEditorOpen = true;
  }

  @action
  closeEditor() {
    this.isEditorOpen = false;
  }
}
