export default {
  "modal": "_modal_1hukeq",
  "modalHeader": "_modalHeader_1hukeq",
  "modalHeaderIcon": "_modalHeaderIcon_1hukeq",
  "modalBody": "_modalBody_1hukeq",
  "descriptionText": "_descriptionText_1hukeq",
  "qrCodeButtonContainer": "_qrCodeButtonContainer_1hukeq",
  "shareLinkButton": "_shareLinkButton_1hukeq",
  "shareLinkButtonContent": "_shareLinkButtonContent_1hukeq",
  "shareLinkButtonIcon": "_shareLinkButtonIcon_1hukeq",
  "shareableUrlText": "_shareableUrlText_1hukeq",
  "qrCodeLinkContainer": "_qrCodeLinkContainer_1hukeq",
  "copyLinkButton": "_copyLinkButton_1hukeq",
  "copyLinkButtonIcon": "_copyLinkButtonIcon_1hukeq",
  "copyLinkButtonContent": "_copyLinkButtonContent_1hukeq"
};
