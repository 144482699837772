import a0 from "../icons/loading-spinner/index.ts";
window.define('mobile-web/components/icons/loading-spinner', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a2;
});
import a3 from "../animated-container.js";
window.define('mobile-web/components/animated-container', function () {
  return a3;
});
import a4 from "../animated-value.js";
window.define('mobile-web/components/animated-value', function () {
  return a4;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "KD7yIj5X",
  "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[8,[39,1],[[16,\"aria-label\",[28,[37,2],[\"mwc.header.cartLoadingText\"],null]],[24,\"tabindex\",\"0\"]],[[\"@class\"],[[30,0,[\"style\",\"loading\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,0,[30,0,[\"buttonStyle\"]]],[24,\"data-test-cartButton\",\"button\"],[24,4,\"button\"],[17,1],[4,[38,3],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n    \"],[10,1],[15,0,[30,0,[\"style\",\"content\"]]],[14,\"data-test-cartButton\",\"content\"],[12],[1,\"\\n      \"],[10,1],[15,0,[30,0,[\"style\",\"iconWrapper\"]]],[14,\"data-test-cartButton\",\"icon-wrapper\"],[12],[1,\"\\n        \"],[8,[39,4],[[16,0,[30,0,[\"style\",\"icon\"]]],[24,\"data-test-cartButton\",\"icon\"]],[[\"@ariaLabel\",\"@icon\"],[\"\",\"shopping-cart\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasProducts\"]],[[[1,\"        \"],[8,[39,5],[[16,0,[30,0,[\"style\",\"animatedContainer\"]]]],[[\"@tag\",\"@onInitialRender\"],[\"span\",false]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,6],[[30,0,[\"numBasketProducts\"]]],[[\"rules\",\"duration\"],[[30,0,[\"rules\"]],[30,0,[\"duration\"]]]],[[\"default\"],[[[[1,\"            \"],[10,1],[15,0,[30,0,[\"style\",\"label\"]]],[14,\"data-test-cartButton\",\"label\"],[12],[1,\"\\n              \"],[1,[30,2]],[1,\" \"],[10,1],[15,0,[30,0,[\"style\",\"labelText\"]]],[12],[1,[28,[35,2],[\"mwc.header.cartLabel\"],[[\"count\"],[[30,2]]]]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]]]]],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&attrs\",\"v\"],false,[\"if\",\"icons/loading-spinner\",\"t\",\"on\",\"svg\",\"animated-container\",\"animated-value\"]]",
  "moduleName": "mobile-web/components/cart-button/index.hbs",
  "isStrictMode": false
});