import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export type ModalConfig = {
  element: HTMLElement;
  lastFocus: typeof document.activeElement;
  onClose?: Action;
};

export default class ModalStackService extends Service {
  // Service injections

  // Untracked properties

  // Tracked properties
  @tracked private stack: ModalConfig[] = [];

  // Getters and setters
  get length(): number {
    return this.stack.length;
  }

  get top(): ModalConfig | undefined {
    return this.stack[this.stack.length - 1];
  }

  get previous(): ModalConfig | undefined {
    return this.stack[this.stack.length - 2];
  }

  // Constructor

  // Other methods
  push(modal: ModalConfig): void {
    this.stack = [...this.stack, modal];
  }

  pop(): ModalConfig | undefined {
    const last = this.stack.pop();
    // Intentional because .pop() mutates the array, but @tracked only picks up assignments.
    // eslint-disable-next-line no-self-assign
    this.stack = this.stack;
    return last;
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    'modal-stack': ModalStackService;
  }
}
