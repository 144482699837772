import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import { ProductsRouteModel } from 'mobile-web/routes/menu/vendor/products';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';

import style from './index.m.scss';

interface Args {
  // Required arguments
  model: ProductsRouteModel;

  // Optional arguments
}

export default class MenuVendorProductsRoute extends Component<Args> {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service router!: RouterService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get isExistingBasketProduct(): boolean {
    return this.args.model.basketProduct?.get('isNew') === false;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  transitionToVendor(slug?: string, isExistingBasketProduct?: boolean) {
    /**
     * You might be wondering why we have this if-else.
     * Well, it seems that, in some rare cases when we render the product modal,
     * some of the properties on `this.args.model` become undefined.
     * My guess is that there is some race condition where the modal closes,
     * then this method runs, but some component state is already destroyed.
     * We can get around this by creating a closure with the values pre-calculated.
     * See further down in `openModal`.
     */
    if (typeof slug === 'string' && typeof isExistingBasketProduct === 'boolean') {
      this.router.transitionTo('menu.vendor', slug, {
        queryParams: { openBasket: isExistingBasketProduct || undefined },
      });
    } else {
      this.router.transitionTo('menu.vendor', this.args.model.product.vendor.slug, {
        queryParams: { openBasket: this.isExistingBasketProduct || undefined },
      });
    }
  }

  @action
  openModal() {
    const { displayInModal, product, basketProduct, previousRoute } = this.args.model;
    if (displayInModal) {
      const slug = product.vendor.slug;
      const isExistingBasketProduct = this.isExistingBasketProduct;
      this.bus.trigger('showProductModal', {
        product,
        basketProduct,
        previousRoute,
        onClose: () => this.transitionToVendor(slug, isExistingBasketProduct),
      });
    }
  }
}
