import { set, action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import RSVP from 'rsvp';

import IntlService from 'ember-intl/services/intl';
import cloneDeep from 'lodash.clonedeep';

import { toPastOnPremiseOrder } from 'mobile-web/lib/on-premise';
import { getPaymentMethod } from 'mobile-web/lib/payment';
import { Variant } from 'mobile-web/lib/payment/method';
import OrderModel, { EcommerceOrderModel } from 'mobile-web/models/order';
import { StoredOrderSubmission } from 'mobile-web/models/order-submission';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import AppStoreReviewService from 'mobile-web/services/app-store-review';
import BasketService from 'mobile-web/services/basket';
import DeviceService from 'mobile-web/services/device';
import FeaturesService from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';
import WindowService from 'mobile-web/services/window';

export default class ThankYouRoute extends Route {
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service globalEvents!: GlobalEventsService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service vendor!: VendorService;
  @service window!: WindowService;
  @service device!: DeviceService;
  @service features!: FeaturesService;
  @service appStoreReview!: AppStoreReviewService;

  @readOnly('session.isLoggedIn')
  isLoggedIn!: boolean;

  beforeModel(transition: Transition) {
    if (transition.from?.localName === 'checkout') {
      this.basket.clear();
    }
  }

  model(params: { order_id: EmberDataId }): RSVP.Promise<OrderModel> {
    return this.store.findRecord('order', params.order_id).then(order => {
      this.vendor.set('vendor', order.vendor);
      return order;
    });
  }

  /**
   * @deprecated since 10/2020 in favor of global events
   */
  setEcomTrackingObj(this: ThankYouRoute, trackingObj: EcommerceOrderModel) {
    const OLO = this.window.OLO;
    set(OLO, 'ecomTrackingObj', [cloneDeep(trackingObj)]); // Clone this to prevent it being exposed globally and accidentally mutated
  }

  trackEcommerce(trackingObj: EcommerceOrderModel) {
    this.analytics.trackEcommerce(trackingObj);
  }

  trackTransaction(this: ThankYouRoute, order: OrderModel) {
    const ecomModel = order.serializeForEcommerce();
    const orderSubmission = this.storage.orderSubmission;

    this.setEcomTrackingObj(ecomModel);

    if (
      orderSubmission &&
      orderSubmission.basket &&
      this.session.lastOrderId !== this.storage.trackedThankYouOrderId
    ) {
      this.analytics.trackEcommerce(ecomModel);
      this.globalEvents.trigger(
        GlobalEventName.Transaction,
        order.serializeForGlobalData(),
        cloneDeep(orderSubmission),
        this.session.serializeUserForGlobalData()
      );
      this.trackMixpanel(order, orderSubmission);
      this.storage.trackedThankYouOrderId = this.session.lastOrderId;
    }
  }

  trackMixpanel(
    model: Resolved<ReturnType<ThankYouRoute['model']>>,
    submission: StoredOrderSubmission
  ) {
    const properties = this.buildMixpanelProperties(model, submission);
    this.analytics.trackEvent(AnalyticsEvents.OrderPlaced, () => properties);
    this.analytics.trackEvent(
      AnalyticsEvents.OrderPlaced,
      () => ({
        ...properties,
        ...this.buildOloAuthMixpanelProperties(
          !!this.storage.signedInViaOloAuthOverlay,
          submission.createOloAccount
        ),
      }),
      { bucket: 'olo-auth' }
    );
    this.storage.signedInViaOloAuthOverlay = undefined;
  }

  buildMixpanelProperties(
    model: Resolved<ReturnType<ThankYouRoute['model']>>,
    { basket, memberships, selectedBillingMethods, loyaltyAccount }: StoredOrderSubmission
  ) {
    const properties: Dict<unknown> = {
      [AnalyticsProperties.OrderID]: model.id,
      [AnalyticsProperties.SavedANewCard]:
        (this.session.savedNewCcCard || this.session.savedNewBrandedCard) ?? false,
      [AnalyticsProperties.EditedOrderFromCheckout]: this.session.editedOrderFromCheckout ?? false,
      [AnalyticsProperties.UsedASavedCard]: this.session.usedSavedCard ?? false,
      [AnalyticsProperties.ViewedCustomFeesTooltip]: this.session.viewedCustomFeesTooltip ?? false,
      [AnalyticsProperties.NumberOfPaymentTypesUsed]: selectedBillingMethods.length,
      [AnalyticsProperties.SupportsParkingLocation]: model.requireArrivalMessage,
      [AnalyticsProperties.SupportsArrivalNotifications]: model.canSupportArrival,
      [AnalyticsProperties.ToGoOrder]: this.isToGoOrder(model),
    };

    properties[AnalyticsProperties.PaymentTypes] = selectedBillingMethods
      .map(method => {
        if (method.variant === Variant.OloPay) {
          if (method.paymentCard.isDigitalWallet) {
            /**
            Split pay is not supported for Google Pay or Apple Pay,
            so we know we are safe to use the first and only billingMethod here to determine the Digital Wallet type
          */
            const description = model?.billingComponents?.firstObject?.description
              .toString()
              .split(' ')
              .slice(0, 2) // Grab the first two words separated by a space (i.e. Google Pay, Apple Pay)
              .join(' ');

            return `Olo Pay Digital Wallet - ${description}`;
          }
          return 'Olo Pay Card';
        }

        return getPaymentMethod(method, memberships);
      })
      .sort();

    if (basket.coupon) {
      properties[AnalyticsProperties.CouponCode] = basket.coupon.code;
    }

    if (basket.reward && loyaltyAccount) {
      properties[AnalyticsProperties.LoyaltyRedemption] = true;
      properties[AnalyticsProperties.LoyaltyProvider] = loyaltyAccount.schemeProviderName;
      properties[AnalyticsProperties.LoyaltyMembershipID] = loyaltyAccount.membershipId;
      properties[AnalyticsProperties.LoyaltyRewardID] = basket.reward.externalReference;
      properties[AnalyticsProperties.LoyaltyRewardDiscountAmount] =
        basket.vendorDiscount.toFixed(2);
    } else {
      properties[AnalyticsProperties.LoyaltyRedemption] = false;
    }
    return properties;
  }

  buildOloAuthMixpanelProperties(signedInViaOloAuthOverlay = false, createOloAccount = false) {
    return {
      [AnalyticsProperties.SignedInViaOloAuthOverlay]: signedInViaOloAuthOverlay,
      [AnalyticsProperties.CreateOloAuthAccount]: createOloAccount,
    };
  }

  afterModel(this: ThankYouRoute, model: Resolved<ReturnType<ThankYouRoute['model']>>) {
    this.session.lastOrderId = model.id;
    scheduleOnce('afterRender', this, 'trackTransaction', model);

    if (!this.isLoggedIn) {
      this.session.loadGuestUser();
    }

    const dispatchIds = model.hasMany('dispatchStatuses').ids();
    if (dispatchIds.length === 1) {
      this.router.replaceWith('dispatch-status', model.id, dispatchIds[0]);
    }

    if (this.onPremise.multiOrder) {
      // Since this is multi-order, we can assume that we have a currentUser.
      // Either the user logged in, or we created a saved guest user for them.
      const order = toPastOnPremiseOrder(this.session.currentUser!, model);
      const pastOrders = this.onPremise.multiOrder.pastOrders;
      if (pastOrders.length === 0) {
        this.onPremise.multiOrder = { ...this.onPremise.multiOrder, pastOrders: [order] };
      } else if (!pastOrders.find(o => o.displayId === order.displayId)) {
        this.onPremise.multiOrder = {
          ...this.onPremise.multiOrder,
          pastOrders: [...pastOrders, order],
        };
      }
    }

    if (
      this.device.isHybrid &&
      this.features.flags['app-rating-olo-37375'] &&
      this.device.isCapacitorPluginAvailable('AppStoreReview')
    ) {
      const lastShownDate = this.storage.get('lastShownAppRatingPromptDate');
      const day = 24 * 60 * 60 * 1000;
      const minimumNumberOfDaysBetweenPrompts = 7;
      if (
        !lastShownDate ||
        Math.round(
          Math.abs((new Date().getTime() - new Date(Date.parse(lastShownDate)).getTime()) / day)
        ) >= minimumNumberOfDaysBetweenPrompts
      ) {
        this.appStoreReview.requestReview();
        this.storage.set('lastShownAppRatingPromptDate', new Date().toISOString());
      }
    }
  }

  @action
  willTransition() {
    // Clean up Mixpanel session properties
    this.session.savedNewBrandedCard = undefined;
    this.session.savedNewCcCard = undefined;
    this.session.editedOrderFromCheckout = undefined;
    this.session.usedSavedCard = undefined;
    this.session.viewedCustomFeesTooltip = undefined;
  }

  deactivate() {
    this.storage.orderSubmission = undefined;
  }

  isToGoOrder(model: OrderModel): boolean {
    if (model.customFieldValues) {
      const takeoutIndex = model.customFieldValues.findIndex(cf => cf.label === 'ToGoOrder');
      if (
        takeoutIndex > -1 &&
        model.customFieldValues[takeoutIndex].value ===
          this.intl.t('mwc.checkout.toGoCustomFieldValue')
      ) {
        return true;
      }
    }
    return false;
  }
}
