import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/routes/order-summary-route/index.ts";
window.define('mobile-web/components/routes/order-summary-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "gerHftzZ",
  "block": "[[[1,[28,[35,0],[\"Order \",[30,1,[\"order\",\"displayId\"]],\" Summary\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@order\"],[[30,1,[\"order\"]]]],null]],[\"@model\"],false,[\"page-title\",\"routes/order-summary-route\"]]",
  "moduleName": "mobile-web/templates/order-summary.hbs",
  "isStrictMode": false
});