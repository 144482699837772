import a0 from "../../content-placeholders/index.ts";
window.define('mobile-web/components/content-placeholders', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "sW96DAF3",
  "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"root\"]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"header\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"textEachArray\"]]],null]],null],null,[[[1,\"    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"text\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"inputEachArray\"]]],null]],null],null,[[[1,\"    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"placeholder\"],false,[\"content-placeholders\",\"each\",\"-track-array\"]]",
  "moduleName": "mobile-web/components/application-loading/password/index.hbs",
  "isStrictMode": false
});