import a0 from "../components/routes/outage-route/index.ts";
window.define('mobile-web/components/routes/outage-route', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "MDWTz26p",
  "block": "[[[8,[39,0],null,null,null]],[],false,[\"routes/outage-route\"]]",
  "moduleName": "mobile-web/templates/outage.hbs",
  "isStrictMode": false
});