import a0 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import a1 from "../../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "AFe2kBNQ",
  "block": "[[[11,0],[16,\"data-test-groupOrder-status\",[30,1]],[16,0,[30,0,[\"style\",\"root\"]]],[17,2],[12],[1,\"\\n\\n\"],[41,[51,[30,3]],[[[1,\"  \"],[8,[39,1],[[16,0,[28,[37,2],[[30,0,[\"style\",\"icon\"]],[30,0,[\"iconStyle\"]]],null]]],[[\"@ariaLabel\",\"@icon\"],[\"\",[30,0,[\"statusDisplay\",\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[51,[30,4]],[[[1,\"  \"],[10,1],[14,\"data-test-status-label\",\"\"],[15,0,[30,0,[\"style\",\"label\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"statusDisplay\",\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"displayCount\"]],[[[1,\"  \"],[10,1],[14,\"data-test-status-count\",\"\"],[15,0,[30,0,[\"style\",\"count\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"displayCount\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[13]],[\"@status\",\"&attrs\",\"@hideIcon\",\"@hideLabel\"],false,[\"unless\",\"svg\",\"classes\",\"if\"]]",
  "moduleName": "mobile-web/components/group-order/status/index.hbs",
  "isStrictMode": false
});