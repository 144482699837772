import Component from '@ember/component';

import { tagName } from '@ember-decorators/component';

import style from './index.m.scss';

@tagName('')
export default class ModalSection extends Component {
  style = style;
}
