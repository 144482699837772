import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import isSome from 'mobile-web/lib/utilities/is-some';
import { Address } from 'mobile-web/models/vendor';
import FeaturesService from 'mobile-web/services/features';

export function uniqueCategoryId(categoryId: EmberDataId, vendorId: EmberDataId): string {
  return `${categoryId}_${vendorId}`;
}

export default class VendorSerializer extends DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin) {
  @service features!: FeaturesService;
  attrs = {
    embeddedProductGroups: { embedded: 'always' },
  };

  normalizeResponse(
    store: DS.Store,
    primaryModelClass: DS.Model,
    payload: {
      vendor: {
        id: EmberDataId;
        categories: EmberDataId[];
      };
      categories?: { id: EmberDataId }[];
      products?: { category: EmberDataId }[];
    },
    id: EmberDataId,
    requestType: string
  ): UnknownObject {
    const vendorId = payload.vendor.id;
    payload.vendor.categories = payload.vendor.categories.map(cId =>
      uniqueCategoryId(cId, vendorId)
    );
    payload.categories?.forEach(c => {
      c.id = uniqueCategoryId(c.id, vendorId);
    });
    payload.products?.forEach(p => {
      p.category = uniqueCategoryId(p.category, vendorId);
    });

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  normalize(
    modelClass: DS.Model,
    resourceHash: {
      address?: Address;
      embeddedProductGroups?: Array<{ items: Array<{ cost?: number }> }>;
      settings?: { acceptedCreditCardTypes: string };
    }
  ): UnknownObject {
    if (isSome(resourceHash.settings)) {
      // acceptedCreditCardTypes comes to us as a single comma separated string, like so:
      // 'Amex, Discover, Mastercard, Visa'
      // This logic splits it into an array and matches case to the enum in lib/payment/card.ts
      const acceptedCreditCardTypes = isEmpty(resourceHash.settings.acceptedCreditCardTypes)
        ? []
        : resourceHash.settings.acceptedCreditCardTypes.split(', ');
      const strCardTypes = acceptedCreditCardTypes.map(cardType => {
        if (cardType === 'Amex' || cardType === 'Visa') {
          return cardType.toUpperCase();
        }
        return cardType;
      });
      // We're purposefully transforming from a string to a string[]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resourceHash.settings.acceptedCreditCardTypes = strCardTypes as any;
    }

    if (isSome(resourceHash.embeddedProductGroups)) {
      resourceHash.embeddedProductGroups.forEach(upsellGroup => {
        upsellGroup.items.forEach(upsellItem => {
          delete upsellItem.cost;
        });
      });
    }

    if (resourceHash.address) {
      resourceHash.address.crossStreet = resourceHash.address.crossStreet?.trim();
    }

    return super.normalize(modelClass, resourceHash);
  }
}
