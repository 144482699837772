import UserModel from 'mobile-web/models/user';

export const MAX_FIRST_NAME_LENGTH = 36;
export const MAX_LAST_NAME_LENGTH = 36;
export const MAX_EMAIL_LENGTH = 128;

export type UserData = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  contactNumber: string;
  optInToSms?: boolean;
};
export type GuestUser = UserData & {
  optIn: boolean;
};

export type Customer = GuestUser | UserModel;
