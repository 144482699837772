import Component from '@glimmer/component';

import { HeadingLevel } from 'mobile-web/components/heading';
import { ContentString } from 'mobile-web/services/content';

import style from './index.m.scss';

interface Args {
  // Required arguments
  heading: ContentString;

  // Optional arguments
  headingClass?: string;
  headingLevel?: HeadingLevel;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class HeadingSection extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get headingLevel(): HeadingLevel {
    return this.args.headingLevel ?? 2;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HeadingSection: typeof HeadingSection;
  }
}
