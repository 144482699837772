import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { groupOptionGroups } from 'mobile-web/lib/menu';
import OptionGroupModel from 'mobile-web/models/option-group';

import style from './index.m.scss';

interface Args {
  // Required arguments
  optionGroups: OptionGroupModel[];

  // Optional arguments
  inModal?: boolean;
}

export default class ProductCustomizationTopLevelOptionGroups extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked openFormationGroup = false;

  // Getters and setters
  get inModal() {
    return this.args.inModal ?? false;
  }

  get groupedOptionGroups() {
    return groupOptionGroups(this.args.optionGroups);
  }

  get hasFormationGroups() {
    return this.args.optionGroups.find(og => !isEmpty(og.formationGroup));
  }

  get isLoaded() {
    return this.args.optionGroups.every(og => og.isLoaded);
  }

  get useStickyNav() {
    return (
      this.args.optionGroups.length > 1 &&
      !this.inModal &&
      !this.hasFormationGroups &&
      this.isLoaded
    );
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
