import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import authenticated from 'mobile-web/decorators/authenticated';
import { PreviousRoute, vendorToMenuRoute } from 'mobile-web/lib/routing';
import isSome from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';
import VendorService from 'mobile-web/services/vendor';

@authenticated
export default class RewardsRoute extends Route {
  @service vendor!: VendorService;

  previousRoute!: PreviousRoute;

  model(params: { vendor_slug: string }) {
    const vendorService = this.vendor;

    return vendorService.ensureVendorLoaded(params.vendor_slug).then((vendor: Vendor) => {
      set(this, 'previousRoute', vendorToMenuRoute(vendor));

      const accounts = RSVP.all(
        this.store.peekAll('loyalty-membership').map(m =>
          this.store.queryRecord('loyalty-account', {
            membershipId: m.membershipId,
            vendorId: vendor.id,
          })
        )
      );
      return RSVP.hash({
        accounts,
        vendor,
      }).then(model => ({
        accounts: model.accounts.filter(isSome),
        vendor: model.vendor,
      }));
    });
  }
}
