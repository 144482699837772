export default {
  "row": "_row_dwjr7y",
  "shortRow": "_shortRow_dwjr7y _row_dwjr7y",
  "breadcrumbs": "_breadcrumbs_dwjr7y",
  "root": "_root_dwjr7y",
  "stickyElement": "_stickyElement_dwjr7y",
  "column": "_column_dwjr7y",
  "grid": "_grid_dwjr7y",
  "invalidOptionGroup": "_invalidOptionGroup_dwjr7y",
  "border-fade": "_border-fade_dwjr7y"
};
