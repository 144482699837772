export default {
  "list": "_list_upr86",
  "item": "_item_upr86",
  "columns-1": "_columns-1_upr86",
  "columns-2": "_columns-2_upr86",
  "columns-3": "_columns-3_upr86",
  "columns-4": "_columns-4_upr86",
  "columns-5": "_columns-5_upr86",
  "hidden": "_hidden_upr86"
};
