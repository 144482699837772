import a0 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a0;
});
import a1 from "../../modifiers/did-update.js";
window.define('mobile-web/modifiers/did-update', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "wxexq0DD",
  "block": "[[[11,0],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-mapWindow\",\"\"],[17,1],[12],[1,\"\\n  \"],[11,0],[16,0,[30,0,[\"style\",\"map\"]]],[4,[38,0],[[30,0,[\"insertElement\"]]],null],[4,[38,1],[[30,0,[\"updateMap\"]],[30,2]],null],[12],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@markers\"],false,[\"did-insert\",\"did-update\"]]",
  "moduleName": "mobile-web/components/map-window/index.hbs",
  "isStrictMode": false
});