import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { tagName } from '@ember-decorators/component';

import { UserOrderEvent } from 'mobile-web/components/post-checkout';
import { getHandoffMode } from 'mobile-web/lib/order-criteria';
import OrderModel from 'mobile-web/models/order';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';

import style from './index.m.scss';

interface Args {
  order: OrderModel;
}

@tagName('')
export default class CheckInRoute extends Component<Args> {
  // Service injections
  @service analytics!: AnalyticsService;

  // Required arguments

  // Optional arguments

  // Class fields
  style = style;

  // Computed properties
  get analyticsProperties() {
    return {
      [AnalyticsProperties.BasketHandoffMode]: getHandoffMode(this.args.order.deliveryMode),
    };
  }

  // Init

  // Other methods

  // Actions
  @action
  onInsert() {
    this.analytics.trackEvent(AnalyticsEvents.UserFiredOrderEvent, () => ({
      [AnalyticsProperties.OrderEvent]: UserOrderEvent.CheckIn,
      ...this.analyticsProperties,
    }));
  }
}
