import a0 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a0;
});
import a1 from "../../order-search-result-list/index.ts";
window.define('mobile-web/components/order-search-result-list', function () {
  return a1;
});
import a2 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a2;
});
import a3 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "zhsvUSHq",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-routes-recentOrdersRoute\",\"\"],[12],[1,\"\\n  \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"heading\"]]],[12],[1,[28,[35,0],[\"mwc.accountHeading.recentOrders\"],null]],[13],[1,\"\\n\\n\"],[41,[30,1,[\"length\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@results\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"style\",\"empty\"]]],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"History\",[30,0,[\"style\",\"icon\"]],\"history\"]],null],[1,\"\\n      \"],[10,2],[14,\"data-test-routes-recentOrdersRoute-emptyText\",\"\"],[15,0,[30,0,[\"style\",\"emptyText\"]]],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,[28,[35,0],[\"mwc.recentOrders.emptyText\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"onPremise\",\"isEnabled\"]]],[[[1,\"        \"],[8,[39,5],null,[[\"@testSelector\",\"@class\",\"@onClick\"],[\"emptyButton\",[30,0,[\"style\",\"emptyButton\"]],[30,0,[\"startOrder\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,0],[\"mwc.recentOrders.emptyButton\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@model\"],false,[\"t\",\"if\",\"order-search-result-list\",\"svg\",\"unless\",\"button\"]]",
  "moduleName": "mobile-web/components/routes/recent-orders-route/index.hbs",
  "isStrictMode": false
});