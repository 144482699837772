import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { isString, isFunction } from 'mobile-web/lib/utilities/_';

import style from './index.m.scss';

interface Args {
  // Required arguments
  onEdit: Action;
  nearbyVendorCount: number;

  // Optional arguments
  label?: string;
  isAdditional?: boolean;
}

export default class NoValidVendorResults extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get isAdditional() {
    return this.args.isAdditional ?? false;
  }

  get message(): string {
    const label = this.args.label;
    const nearbyVendorCount = this.args.nearbyVendorCount;
    if (this.isAdditional) {
      if (nearbyVendorCount > 1) {
        return `There are ${nearbyVendorCount} additional locations that are similar to your search.`;
      }
      return `There is ${nearbyVendorCount} additional location that is similar to your search.`;
    }
    if (nearbyVendorCount > 1) {
      return `There are ${nearbyVendorCount} locations near you, but none of them support ordering ${label}!`;
    }
    return `There is ${nearbyVendorCount} location near you, but it does not support ordering ${label}!`;
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    assert('`label` is required if not `isAdditional`', args.isAdditional || isString(args.label));
    assert('`nearbyVendorCount` is required and > 0', args.nearbyVendorCount > 0);
    assert('`onEdit` action is required', isFunction(args.onEdit));
  }

  // Other methods

  // Tasks

  // Actions
  @action
  clickEdit() {
    this.args.onEdit();
  }
}
