import Component from '@ember/component';
import { assert } from '@ember/debug';
import { next } from '@ember/runloop';
import GlimmerComponent from '@glimmer/component';

import Modifier from 'ember-modifier';

export type RefArgs = {
  Args: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Positional: [Component | GlimmerComponent<any>, string];
    Named: Record<string, unknown>;
  };
};

export default class RefModifier extends Modifier<RefArgs> {
  private get context(): UnknownObject {
    return this.args.positional[0] as unknown as UnknownObject;
  }

  private get name(): string {
    const name = this.args.positional[1];
    assert(
      `second positional argument must be a valid property name of ${this.context}`,
      typeof name === 'string' && name in this.context
    );
    return name;
  }

  private get isArray(): boolean {
    return Array.isArray(this.value);
  }

  private get value() {
    return this.context[this.name];
  }

  private set value(el: unknown) {
    if (!this.isArray) {
      this.context[this.name] = el;
    } else {
      let val = this.context[this.name] as unknown[];
      if (el) {
        val.push(el);
      } else {
        val = val.filter(e => e !== el);
      }
      this.context[this.name] = val;
    }
  }

  /**
   * Store the element.
   */
  didInstall(): void {
    next(this, () => {
      this.value = this.element;
    });
  }

  /**
   * Stop storing the element.
   */
  willDestroy(): void {
    next(this, () => {
      this.value = undefined;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ref: typeof RefModifier;
  }
}
