import Component from '@glimmer/component';

import style from './index.m.scss';

interface Signature {
  Element: HTMLDivElement;

  Blocks: {
    default: [];
  };
}

export default class PostCheckoutSubsectionButtonContainer extends Component<Signature> {
  style = style;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'post-checkout/subsection/button-container': typeof PostCheckoutSubsectionButtonContainer;
  }
}
