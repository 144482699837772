import { inject as service } from '@ember/service';
import DS from 'ember-data';

import collectionAction, { CollectionAction } from 'mobile-web/decorators/collection-action';

export type CheckStatus = 'open' | 'closed';

export type CreateCheckRequest = {
  tablePosRef: string;
};

export type CreateCheckResponse = {
  createdDate: string;
  openCheckGuid: string;
  posTableNumber: string;
  status: CheckStatus;
};

export type SendBasketResponse = {
  basketGuids: string[];
  createdDate: string;
  openCheckGuid: string;
  posTableNumber: string;
  sentBasketProducts: number[];
  status: CheckStatus;
};

export type CalculatedTotals = {
  salesTax: number;
  subTotal: number;
  totalCost: number;
};

export default class CheckModel extends DS.Model {
  @service store!: DS.Store;

  @DS.attr('array')
  basketGuids!: string[];

  @DS.attr()
  calculatedTotals?: CalculatedTotals | null;

  @DS.attr()
  posTableNumber!: string;

  @DS.attr('array')
  sentBasketProducts!: number[];

  @DS.attr()
  status!: CheckStatus;

  @collectionAction<CreateCheckRequest, CheckModel>({
    type: 'post',
    path: 'create',
    after: pushPayload,
  })
  createCheck!: CollectionAction<CreateCheckRequest, CheckModel>;

  // Idempotent
  @collectionAction({
    type: 'post',
    path: 'sendBasket',
    after: pushPayload,
  })
  sendBasket!: CollectionAction;
}

function pushPayload(this: CheckModel, response: CreateCheckResponse | SendBasketResponse) {
  this.store.pushPayload({
    check: {
      basketGuids: [],
      sentBasketProducts: [],
      ...response,
    },
  });
  return this.store.peekRecord('check', response.openCheckGuid)!;
}
