import a0 from "../inline-alert/index.ts";
window.define('mobile-web/components/inline-alert', function () {
  return a0;
});
import a1 from "../custom-icon/index.ts";
window.define('mobile-web/components/custom-icon', function () {
  return a1;
});
import a2 from "../../helpers/html-safe.js";
window.define('mobile-web/helpers/html-safe', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "TMVL5Gam",
  "block": "[[[11,\"aside\"],[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-disclaimers\",\"\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@class\",\"@collapsible\"],[[30,0,[\"style\",\"disclaimer\"]],true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3,[\"icon\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@icon\"],[[30,3,[\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[28,[35,5],[[30,3,[\"content\"]]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@disclaimers\",\"disclaimer\"],false,[\"each\",\"-track-array\",\"inline-alert\",\"if\",\"custom-icon\",\"html-safe\"]]",
  "moduleName": "mobile-web/components/disclaimers/index.hbs",
  "isStrictMode": false
});