import Controller from '@ember/controller';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import Router from '@embroider/router';

import config from 'mobile-web/config/environment';
import ApplicationController from 'mobile-web/controllers/application';
import { homeRoute, PreviousRoute } from 'mobile-web/lib/routing';
import { isString } from 'mobile-web/lib/utilities/_';
import WindowService from 'mobile-web/services/window';

const NO_REDIRECT_ROUTES = ['thank-you', 'secure.reset-password', 'forgot-password'];

const SECURE_ROUTE_REGEX = new RegExp(
  ['/signup', '/login', '/resetpassword', '/my-account/*', '/checkout/*'].join('|')
);

export const isNoRedirectRoute = (route: string | string[]): boolean =>
  (isString(route) && NO_REDIRECT_ROUTES.includes(route)) ||
  (route.length > 0 && NO_REDIRECT_ROUTES.includes(route[0]));

const isSecure = (input?: string) =>
  input ? input.includes('secure') || SECURE_ROUTE_REGEX.test(input) : false;

const shouldHardReload = (currentRoute?: string, newRoute?: string): boolean =>
  !config.preventReload && isSecure(currentRoute) !== isSecure(newRoute);

interface RouterCustomAPI {
  window: WindowService;
}
interface RouterPrivateAPI {
  currentURL: string;

  generate(...args: unknown[]): string;
}

// eslint-disable-next-line ember/no-classic-classes
class CustomRouter extends Router.extend({
  window: service(),

  location: config.locationType,

  _doTransition(
    this: Router & RouterPrivateAPI & RouterCustomAPI,
    targetRouteName: string | UnknownObject,
    models: UnknownObject[],
    queryParams: UnknownObject
  ) {
    const currentSearchParams = new URLSearchParams(this.window.location().search);
    if (currentSearchParams.has('src')) {
      queryParams = { ...queryParams, src: currentSearchParams.get('src') };
    }

    // The router has a property `currentPath` that we could use here to
    // avoid having to store the secure URL pattern, but `currentPath` isn't
    // set if we transition from a route's beforeModel hook. `currentURL` is
    // always set, so we have to use that instead.
    if (isString(targetRouteName) && shouldHardReload(this.currentURL, targetRouteName)) {
      const newUrl = this.generate(targetRouteName, ...models, { queryParams });
      this.window.location().assign(newUrl);

      // This method returns a Transition, but we don't have a Transition object.
      // The window location assign above means what we return doesn't matter, but
      // we need to return an object of some sort to avoid error noise, so return
      // an empty object.
      return {};
    }

    return this._super(targetRouteName, models, queryParams);
  },

  rootURL: config.rootURL,
}) {}

Route.reopen({
  previousRoute: homeRoute,

  setupController(
    this: Route & { previousRoute: PreviousRoute },
    controller: Controller,
    model: UnknownObject
  ) {
    this._super(controller, model);

    // eslint-disable-next-line ember/no-controller-access-in-routes
    (this.controllerFor('application') as ApplicationController).set(
      'previousRoute',
      this.previousRoute
    );
  },
});

/**
 * The routes listed here are documented in `/docs/pages`.
 * Please be sure to update the documentation when making changes.
 */
Router.map(function () {
  /* Menu Routes */
  this.route('menu', function () {
    this.route('index', { path: '/' });
    this.route('vendor', { path: '/:vendor_slug' }, function () {
      this.route('products', { path: '/products/:product_id' });
    });

    // Intentionally not nested under `vendor` route above
    // because we don't want them to be rendered as child routes
    this.route('category', { path: '/:vendor_slug/categories/:category_id' });
    this.route('vendor.single-use', { path: '/:vendor_slug/single-use' });
    this.route('vendor.info', { path: '/:vendor_slug/info' });
    this.route('vendor.rewards', { path: '/:vendor_slug/rewards' });
    this.route('vendor.reward', { path: '/:vendor_slug/rewards/:reward_reference' });
  });

  /* Basket Routes */
  this.route('basket', function () {
    this.route('basket-products.edit', { path: '/products/:basket_product_id/edit' });
  });

  /* Participating Locations */
  this.route('vendor-search-results', { path: 'search' });
  this.route('rewards-search', { path: 'rewards/search' });

  this.route('feedback');
  this.route('contact-us');
  this.route('user-agreement');
  this.route('open-source-licenses');
  this.route('privacy-policy');
  this.route('opt-out-guide', { path: '/online-tracking-opt-out-guide' });
  this.route('locations');
  this.route('region-results', { path: 'locations/:region_code' });

  this.route('thank-you', { path: 'thank-you/:order_id' });
  this.route('order-summary', { path: 'orders/:order_id' });

  this.route('additional-info', { path: 'additional-info/:linkTitle' }); // eslint-disable-line ember/routes-segments-snake-case

  /* Group Order Routes */

  /* Error Routes */
  this.route('outage');
  // If we don't resolve the route for some reason, render a 404.
  this.route('fourOhFour', { path: '*path' }); // eslint-disable-line ember/no-capital-letters-in-routes

  this.route('frequently-asked-questions');
  this.route('forgot-password');
  this.route('favorites');
  this.route('recent-orders');
  this.route('checkin', { path: 'order/checkin/:id' });
  this.route('arrival', { path: 'order/arrival/:id' });

  this.route('dispatch-summary', { path: 'orders/:order_id/dispatch-statuses' });
  this.route('dispatch-status', { path: 'orders/:order_id/dispatch-statuses/:status_id' });

  this.route('open-check');
  this.route('close-check');

  this.route('challenge');

  // secure routes
  this.route('secure.signup', { path: 'signup' });
  this.route('secure.login', { path: 'login' });
  this.route('secure.reset-password', { path: 'resetpassword' });

  this.route('secure.my-account', { path: 'my-account' });
  this.route('secure.my-account.change-password', { path: 'my-account/change-password' });
  this.route('secure.my-account.contact-info', { path: 'my-account/contact-info' });

  this.route('secure.checkout', { path: 'checkout' });
  this.route('secure.checkout.auth', { path: 'checkout/auth' });

  this.route('secure.challenge', { path: 'secure-challenge' });

  this.route('secure.group-order.start', { path: 'group-order/start' });
  this.route('secure.group-order.join', { path: 'group-order/join' });
  this.route('secure.group-order.cancel', { path: 'group-order/cancel' });
  this.route('secure.group-order.update-participant-status', {
    path: 'group-order/update-participant-status',
  });
  this.route('secure.group-order.participant-confirmation', {
    path: 'group-order/participant-confirmation',
  });

  // compatability routes (for parity with Ghost)
  this.route('compat.reset-password', { path: 'user/resetpassword' });
});

/**
 * Maps a route name to a friendly page name. Primarily used for analytics.
 * @param route the route name to map
 */
export function routeToPageName(route: string): string | undefined {
  switch (route) {
    case 'index':
      return 'Home Page';
    case 'application_loading':
      return 'Loading Page';
    case 'menu.vendor':
    case 'menu.vendor.index':
      return 'View Vendor Menu Page';
    case 'menu.vendor.products':
      return 'View Product Customization';
    case 'menu.category':
      return 'View All Category Page';
    case 'menu.vendor.rewards':
      return 'View Rewards';
    case 'basket.basket-products.edit':
      return 'Edit Product';
    case 'vendor-search-results':
      return 'Search Locations Page';
    case 'rewards-search':
      return 'Search Rewards Page';
    case 'feedback':
      return 'Feedback Page';
    case 'contact-us':
      return 'Contact Us Page';
    case 'open-source-licenses':
      return 'Open Source Licenses Page';
    case 'user-agreement':
      return 'User Agreement Page';
    case 'privacy-policy':
      return 'Privacy Policy Page';
    case 'opt-out-guide':
      return 'Online Tracking Opt Out Guide';
    case 'locations':
      return 'View Locations Page';
    case 'region-results':
      return 'View Locations in Region Page';
    case 'thank-you':
      return 'Thank You Page';
    case 'order-summary':
      return 'Order Details Page';
    case 'additional-info':
      return 'Custom Link Page';
    case 'outage':
      return 'Outage';
    case 'frequently-asked-questions':
      return 'FAQs Page';
    case 'forgot-password':
      return 'Forgot Password Page';
    case 'favorites':
      return 'Favorite Orders Page';
    case 'recent-orders':
      return 'Recent Orders Page';
    case 'checkin':
      return 'Manual Fire Check-In Page';
    case 'arrival':
      return 'Customer Arrivals Success Page';
    case 'dispatch-summary':
      return 'Dispatch Multiple Deliveries Summary Page';
    case 'dispatch-status':
      return 'Dispatch Tracker Page';
    case 'secure.login':
      return 'Log In Page';
    case 'secure.reset-password':
      return 'Reset Password Page';
    case 'secure.my-account':
      return 'My Accounts Page';
    case 'secure.my-account.change-password':
      return 'Change Password Page';
    case 'secure.checkout':
      return 'Checkout Page';
    case 'secure.checkout.auth':
      return 'Checkout Log In Page';
    case 'open-check':
      return 'Open Check Page';
    case 'close-check':
      return 'Close Check Page';
    case 'secure.group-order.start':
      return 'Start Group Order Page';
    case 'secure.group-order.join':
      return 'Join Group Order Page';
    case 'secure.group-order.participant-confirmation':
      return 'Group Order Participant Confirmation Page';
    case 'secure.group-order.cancel':
      return 'Group Order Cancelled Page';
    default:
      return undefined;
  }
}

export default CustomRouter;
