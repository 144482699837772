import Component from '@glimmer/component';

import { splitNumbers } from 'mobile-web/lib/a11y';
import { Address, isAddress, isVendorAddress } from 'mobile-web/lib/location/address';
import { Address as VendorAddress } from 'mobile-web/models/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  model: Address | VendorAddress;

  // Optional arguments
  noCrossStreet?: boolean;
}

interface Signature {
  Args: Args;
}

export default class AddressDisplay extends Component<Signature> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get streetAddress2(): string | undefined {
    const m = this.args.model;
    return isAddress(m) ? m.building : m.streetAddress2;
  }

  get crossStreet(): string | undefined {
    const m = this.args.model;
    return isVendorAddress(m) && !this.args.noCrossStreet ? m.crossStreet : undefined;
  }

  get state(): string | undefined {
    const m = this.args.model;
    return isVendorAddress(m) ? m.state : undefined;
  }

  get zip(): string {
    const m = this.args.model;
    return isAddress(m) ? m.zipCode : m.postalCode;
  }

  get zipSR() {
    return splitNumbers(this.zip);
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AddressDisplay: typeof AddressDisplay;
  }
}
