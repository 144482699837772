import a0 from "../../heading-section/index.ts";
window.define('mobile-web/components/heading-section', function () {
  return a0;
});
import a1 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../../heading-section/subsection/index.ts";
window.define('mobile-web/components/heading-section/subsection', function () {
  return a2;
});
import a3 from "../subsection/index.ts";
window.define('mobile-web/components/post-checkout/subsection', function () {
  return a3;
});
import a4 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a4;
});
import a5 from "../../../helpers/trusted-unsafe-html.ts";
window.define('mobile-web/helpers/trusted-unsafe-html', function () {
  return a5;
});
import a6 from "../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a6;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "6szp3rLR",
  "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"section\"]]]],[[\"@heading\",\"@headingLevel\"],[[28,[37,1],[\"mwc.postCheckout.thankyouHeader\"],null],[30,0,[\"headingLevel\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@icon\"],[[50,\"svg\",0,null,[[\"icon\",\"ariaLabel\"],[\"comment\",\"Comment\"]]]]],[[\"content\"],[[[[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"thanksMessage\"]]],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[10,2],[14,\"data-test-post-checkout-thanks-handoff-instructions\",\"\"],[12],[1,\"\\n              \"],[1,[28,[35,6],[[30,2]],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[10,2],[15,0,[52,[30,1],[30,0,[\"style\",\"dispatchThanks\"]],\"\"]],[14,\"data-test-post-checkout-thanks-message-text\",\"\"],[12],[1,\"\\n            \"],[8,[39,7],null,[[\"@type\",\"@key\"],[\"html\",\"THANKS_ALLORDERMESSAGE\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@isDispatch\",\"@handoffInstructions\"],false,[\"heading-section\",\"t\",\"heading-section/subsection\",\"post-checkout/subsection\",\"component\",\"if\",\"trusted-unsafe-html\",\"x-content\"]]",
  "moduleName": "mobile-web/components/post-checkout/thanks-message/index.hbs",
  "isStrictMode": false
});