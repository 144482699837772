import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';

import { safeNext } from 'mobile-web/lib/runloop';
import { isFunction } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';
import ScrollService from 'mobile-web/services/scroll';

import style from './index.m.scss';

// eslint-disable-next-line ember/require-tagless-components
export default class Modal extends Component {
  @service scroll!: ScrollService;

  onClose!: () => void;
  title!: string;

  buttons?: 'yield';
  error?: string;
  errorAction?: Component;
  testSelector?: string;
  wrapperClass = '';
  bodyDidInsert?: Action<HTMLElement>;

  escapeHandler!: (keyEvent: KeyboardEvent) => void | KeyboardEvent; // Set in `didInsertElement`

  style = style;

  @computed('elementId', 'testSelector')
  get dataTestTag(): string {
    return this.testSelector || this.elementId;
  }

  get headingId() {
    return `${guidFor(this)}-heading`;
  }

  init() {
    super.init();

    assert('`onClose` is required', isFunction(this.onClose));
    assert('`title` is required', isSome(this.title));
  }

  // eslint-disable-next-line ember/no-actions-hash
  actions = {
    close(this: Modal) {
      this.onClose();
    },
    bodyDidInsert(this: Modal, el: HTMLElement) {
      if (this.bodyDidInsert) {
        safeNext(this, () => {
          this.bodyDidInsert?.(el);
        });
      }
      this.scroll.disableDocumentScroll();
    },
    bodyWillDestroy(this: Modal) {
      this.scroll.enableDocumentScroll();
    },
  };
}
