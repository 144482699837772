import { isNone } from '@ember/utils';
import { trackedData } from '@glimmer/validator';

import config from 'mobile-web/config/environment';
import { propertyDecoratorFactory } from 'mobile-web/lib/decorator-factory';
import * as json from 'mobile-web/lib/utilities/json';
import { MemoryStorage } from 'mobile-web/lib/utilities/memory-storage';

const ns = config.localStorageNameSpace;

interface Options {
  // eslint-disable-next-line no-null/no-null
  normalize?(data: string | null | undefined): unknown;
  serialize?(data: unknown): string | undefined;
}

const storageDecoratorFactory = (storage: Storage) =>
  propertyDecoratorFactory<Options | undefined>(
    ({ normalize = json.deserialize, serialize = json.serialize } = {}, target, propertyKey) => {
      const key = `${ns}${String(propertyKey)}`;

      const { getter, setter } = trackedData<typeof target, typeof propertyKey>(propertyKey, () =>
        normalize(storage.getItem(key) ?? undefined)
      );

      return {
        get() {
          return getter(this);
        },
        set(value) {
          const item = serialize(value);
          if (isNone(item)) {
            storage.removeItem(key);
          } else {
            storage.setItem(key, item);
          }
          setter(this, value);
        },
      };
    }
  );

export const local = storageDecoratorFactory(localStorage ?? new MemoryStorage());

export const session = storageDecoratorFactory(sessionStorage ?? new MemoryStorage());
