import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import fade from 'ember-animated/transitions/fade';
import { task, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { UPSELL_ITEM_BLOCK } from 'mobile-web/components/cart/upsell-item';
import isSome from 'mobile-web/lib/utilities/is-some';
import UpsellGroup from 'mobile-web/models/upsell-group';
import UpsellItem from 'mobile-web/models/upsell-item';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import NotificationsService, { NotificationType } from 'mobile-web/services/notifications';

import style from './index.m.scss';

interface Args {
  // Required arguments
  upsellGroup: UpsellGroup;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class CartUpsellGroup extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service store!: DS.Store;
  @service notifications!: NotificationsService;
  @service globalEvents!: GlobalEventsService;

  // Untracked properties
  transition = fade;
  style = style;

  // Tracked properties
  @tracked rootElement!: HTMLElement;
  @tracked listElement?: HTMLElement;

  // Getters and setters
  get isScrollable(): boolean {
    return isSome(this.listElement) && this.listElement.scrollWidth > this.listElement.clientWidth;
  }

  // Constructor

  // Other methods

  // Tasks
  addUpsellItemTask = taskFor(this.addUpsellItemTaskInstance);
  @task *addUpsellItemTaskInstance(upsellItem: UpsellItem): TaskGenerator<void> {
    yield this.basket.basket!.addUpsellItem(upsellItem);

    const product = this.store.peekRecord('product', upsellItem.id);
    if (product) {
      this.globalEvents.trigger(
        GlobalEventName.ClickProductLink,
        {
          ...product.serializeForGlobalData(),
          hasImages: isSome(upsellItem.image),
          hasPrice: true,
        },
        ProductClickFrom.CartUpsell
      );
    }

    this.analytics.trackEvent(AnalyticsEvents.AddToCart, () => ({
      [AnalyticsProperties.ProductName]: upsellItem.name,
      [AnalyticsProperties.ProductCategory]: product?.category?.name,
      [AnalyticsProperties.ProductQuantity]: 1,
      [AnalyticsProperties.ProductBasePrice]: upsellItem.cost,
      [AnalyticsProperties.AddToCartMethod]: 'Upsell',
      [AnalyticsProperties.HasVisibleCalories]: isSome(upsellItem.calorieLabel),
      [AnalyticsProperties.VisibleLabels]: upsellItem.labels?.map(l => l.name),
      [AnalyticsProperties.HasProductImages]: isSome(upsellItem.image),
      [AnalyticsProperties.HasCategoryImages]: !isEmpty(product?.category?.images),
      [AnalyticsProperties.IsFeatured]: product?.isFeatured ?? false,
    }));

    const message = this.intl.t('mwc.notifications.added', {
      quantity: upsellItem.quantity,
    });

    this.notifications.success({
      message,
      type: NotificationType.UpsellAdded,
    });
  }

  // Actions
  @action
  scroll(direction: 'left' | 'right') {
    if (isSome(this.listElement)) {
      let newScrollLeft = this.listElement.scrollLeft;
      if (direction === 'left') {
        newScrollLeft -= UPSELL_ITEM_BLOCK;
        const mod = newScrollLeft % UPSELL_ITEM_BLOCK;
        if (mod > 0) {
          newScrollLeft += UPSELL_ITEM_BLOCK - mod;
        }
      } else {
        newScrollLeft += UPSELL_ITEM_BLOCK;
        const mod = newScrollLeft % UPSELL_ITEM_BLOCK;
        if (mod > 0) {
          newScrollLeft -= mod;
        }
      }
      this.listElement.scrollLeft = newScrollLeft;
    }
  }

  @action
  addUpsellItem(upsellItem: UpsellItem) {
    this.addUpsellItemTask.perform(upsellItem);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::UpsellGroup': typeof CartUpsellGroup;
  }
}
