import Component from '@glimmer/component';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  class?: string;
  iconClass?: string;
  icon?: Component;
  hideIcon?: boolean;
  collapsible?: boolean;
  maxHeight?: number;
}

export default class InlineAlert extends Component<Args> {
  // Service injections

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get hideIcon(): boolean {
    return this.args.hideIcon ?? false;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
