export default {
  "root": "_root_ra5mvk",
  "steps": "_steps_ra5mvk",
  "step": "_step_ra5mvk",
  "activeStep": "_activeStep_ra5mvk",
  "completedStep": "_completedStep_ra5mvk",
  "errorStep": "_errorStep_ra5mvk",
  "infoStep": "_infoStep_ra5mvk",
  "stepIcon": "_stepIcon_ra5mvk",
  "iconSvg": "_iconSvg_ra5mvk",
  "stepLabel": "_stepLabel_ra5mvk"
};
