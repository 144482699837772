import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import UrlTemplates from 'ember-data-url-templates';

import { host } from 'mobile-web/lib/hybrid-util';
import VendorService from 'mobile-web/services/vendor';

import ApplicationAdapter from './application';

export default class ChoiceAdapter extends ApplicationAdapter.extend(UrlTemplates) {
  @service vendor!: VendorService;

  coalesceFindRequests = true;
  urlTemplate = host() + '/{+namespace}/vendors/{vendorId}/choices/{choiceId}';
  urlSegments = {
    vendorId(this: ChoiceAdapter): string {
      const vendor = this.vendor.vendor;
      if (isNone(vendor)) {
        throw new Error('Vendor Id for Choice Not Found');
      }

      return vendor.id;
    },

    choiceId(_type: unknown, _id: unknown, snapshot: DS.Snapshot<'choice'>): string {
      return snapshot.id;
    },

    namespace(this: ChoiceAdapter): string {
      return this.namespace;
    },
  };
}
