export default {
  "root": "_root_iddz0t",
  "dispatchInfo": "_dispatchInfo_iddz0t",
  "description": "_description_iddz0t",
  "estimate": "_estimate_iddz0t",
  "estimateText": "_estimateText_iddz0t",
  "icon": "_icon_iddz0t",
  "iconLabel": "_iconLabel_iddz0t",
  "updates": "_updates_iddz0t",
  "updatesInactive": "_updatesInactive_iddz0t _updates_iddz0t",
  "updatesActive": "_updatesActive_iddz0t _updates_iddz0t",
  "pulse": "_pulse_iddz0t",
  "updatesActiveNoLocation": "_updatesActiveNoLocation_iddz0t _updates_iddz0t"
};
