import a0 from "../../templates/components/basic-dropdown.js";
window.define('mobile-web/templates/components/basic-dropdown', function () {
  return a0;
});
import a1 from "../basic-dropdown.js";
window.define('mobile-web/components/basic-dropdown', function () {
  return a1;
});
import a2 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a2;
});
import a3 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a3;
});
import a4 from "../popover-content/index.ts";
window.define('mobile-web/components/popover-content', function () {
  return a4;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "UxxFiXf8",
  "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"didInsert\"]]],null]],[[\"@renderInPlace\",\"@horizontalPosition\",\"@verticalPosition\"],[true,\"right\",\"auto\"]],[[\"default\"],[[[[1,\"\\n\"],[1,\"  \"],[8,[30,1,[\"Trigger\"]],[[16,0,[28,[37,2],[[30,0,[\"triggerSelectorClass\"]],[30,2]],null]],[17,3]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,6,[[28,[37,4],null,[[\"isOpen\"],[[30,1,[\"isOpen\"]]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[1,\"  \"],[8,[30,1,[\"Content\"]],[[16,0,[30,0,[\"style\",\"content\"]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[8,[39,6],null,[[\"@model\",\"@target\",\"@onClick\",\"@isHeader\"],[[30,4],[30,0,[\"triggerEl\"]],[30,1,[\"actions\",\"close\"]],[30,5]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],null,[[\"@target\",\"@isHeader\"],[[30,0,[\"triggerEl\"]],[30,5]]],[[\"default\"],[[[[1,\"\\n        \"],[18,7,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"dd\",\"@triggerClass\",\"&attrs\",\"@model\",\"@isHeader\",\"&default\",\"&content\"],false,[\"basic-dropdown\",\"did-insert\",\"classes\",\"yield\",\"hash\",\"if\",\"popover-content\"]]",
  "moduleName": "mobile-web/components/popover/index.hbs",
  "isStrictMode": false
});