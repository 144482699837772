// Need @computed because of dependency on other @computed properties
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { computedSession } from 'mobile-web/lib/computed';
import dayjs, { roundUpTime } from 'mobile-web/lib/dayjs';
import { EMPTY } from 'mobile-web/lib/location/address';
import {
  HandoffCriteria,
  handoffMatches,
  HandoffMode,
  isAdvance,
  isAtStoreMode,
  isDelivery,
  isDeliveryMode,
  mapPostalCode,
  OrderCriteria,
  TimeWantedCriteria,
  TimeWantedType,
} from 'mobile-web/lib/order-criteria';
import { noop } from 'mobile-web/lib/utilities/_';
import HandoffModeModel from 'mobile-web/models/handoff-mode';
import TimeWantedMode from 'mobile-web/models/time-wanted-mode';
import { VendorStatus } from 'mobile-web/models/vendor';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import VendorService from 'mobile-web/services/vendor';

function normalizeOrderCriteria(input: string): OrderCriteria {
  const jsonData = JSON.parse(input);
  if (jsonData.timeWanted) {
    jsonData.timeWanted = dayjs(jsonData.timeWanted);
  }
  return jsonData;
}

export default class OrderCriteriaService extends Service {
  // Service injections
  @service basket!: BasketService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service vendor!: VendorService;

  // Untracked properties
  @computedSession({ normalize: normalizeOrderCriteria })
  private storedOrderCriteria?: OrderCriteria;

  // Tracked properties
  @tracked private onOrderCriteriaChange?: (model: OrderCriteria) => void;
  @tracked searchOnly = false;
  @tracked orderCriteriaError = '';
  @tracked showAddressModal = false;

  /**
   * Search order criteria is a bit complicated. There are three parts to it.
   *
   * `storedOrderCriteria` is the session-storage part of it. We use this to actually
   * store the search in session storage. However, `session` annotated variables
   * cannot be annotated with `tracked`.
   *
   * `_searchOrderCriteria` is the tracked part of it. We use this to drive the dynamic
   * updating of all the things when search criteria changes. We set this private variable
   * in the constructor from `storedOrderCriteria`, loading up saved session searches.
   *
   * `searchOrderCriteria` is the third part of it, and it is a getter/setter pair. The
   * getter is simply a wrapper for `_searchOrderCriteria`, but the setter is the magic.
   * It sets both `_searchOrderCriteria` and `storedOrderCriteria`, keeping them both
   * in sync after the constructor has run.
   *
   * Eventually we probably want to add similar two-variable tracking to the `session`
   * decorator directly so we don't need all this wiring to make a session variable
   * tracked, but that is a project for another 10% time day.
   */
  @tracked private _searchOrderCriteria?: OrderCriteria;

  // Getters and setters
  get searchOrderCriteria(): OrderCriteria | undefined {
    return this._searchOrderCriteria;
  }

  set searchOrderCriteria(c: OrderCriteria | undefined) {
    this._searchOrderCriteria = c;
    this.storedOrderCriteria = c;
    if (isDelivery(c) && c.deliveryAddress) {
      this.channel.savedCurrentCountry = mapPostalCode(c.deliveryAddress.zipCode);
    }
  }

  get showModal(): boolean {
    return !this.basket.onPremise.multiOrder && this.onOrderCriteriaChange !== undefined;
  }

  get criteria(): OrderCriteria {
    return this.basketOrderCriteria ?? this.searchOrderCriteria ?? this.defaultOrderCriteria;
  }

  get defaultOrderCriteria(): OrderCriteria {
    const channel = this.channel.current;
    const allChannelModes = this.channel.handoffModes.filter(h => h.isPrivate === false);
    const defaultHandoffMode =
      allChannelModes?.find(m => handoffMatches(m.type, channel?.defaultHandoffMode)) ??
      allChannelModes?.[0];
    const defaultHandoffType = defaultHandoffMode?.type;
    const publicTimeWantedModes = defaultHandoffMode?.timeWantedModes?.filter(
      t => t.isPrivate === false
    );
    const defaultTimeWantedMode = publicTimeWantedModes?.firstObject;

    let timeWanted: TimeWantedCriteria;
    if (defaultTimeWantedMode?.type === 'Advance') {
      timeWanted = {
        timeWantedType: 'Advance',
        timeWanted: roundUpTime(dayjs()),
      };
    } else {
      timeWanted = {
        timeWantedType: defaultTimeWantedMode?.type ?? 'Immediate',
      };
    }

    let handoffMode: HandoffCriteria;
    if (isDeliveryMode(defaultHandoffType)) {
      handoffMode = {
        handoffMode: defaultHandoffType,
        deliveryAddress: EMPTY,
      };
    } else if (isAtStoreMode(defaultHandoffType)) {
      handoffMode = {
        handoffMode: defaultHandoffType,
        searchAddress: '',
      };
    } else {
      handoffMode = {
        handoffMode: 'Unspecified',
      };
    }

    return {
      ...handoffMode,
      ...timeWanted,
      isDefault: true,
    };
  }

  // Migrated verbatim from `edit-order-criteria-modal`, untested
  get isSearch(): boolean {
    return this.searchOnly || !this.basketOrderCriteria;
  }

  // Migrated verbatim from `edit-order-criteria-modal`, untested
  get showVendorModes(): boolean {
    return this.router.isActive('menu') || !this.isSearch;
  }

  // Migrated verbatim from `edit-order-criteria-modal`, untested
  get showPrivateModes(): boolean {
    return this.showVendorModes && this.vendor.vendor?.status === VendorStatus.Private;
  }

  get selectableHandoffModes(): HandoffModeModel[] {
    let validModes!: HandoffModeModel[];
    if (this.showVendorModes) {
      validModes = this.store.peekAll('handoff-mode').filter(h => this.isValidHandoffMode(h.type));
    } else {
      validModes = this.channel.handoffModes.filter(h => !h.isPrivate);
    }

    const hiddenModes = this.channel.current?.settings.handoffModesHiddenFromSelection ?? [];

    return validModes.filter(h => !hiddenModes.includes(h.type));
  }

  // We still need `computed` here because the basket dependencies are not `tracked`
  @computed(
    'basket.basket.{deliveryAddress,handoffLabel,handoffMode,isAdvance,isImmediate,isManualFire,timeWanted,vendor}',
    'features.flags'
  )
  get basketOrderCriteria(): OrderCriteria | undefined {
    const basket = this.basket.basket;
    if (!basket) {
      return undefined;
    }

    let timeWanted: TimeWantedCriteria;
    if (basket.isAdvance) {
      if (basket.timeWantedUtc) {
        timeWanted = {
          timeWantedType: 'Advance',
          timeWanted: basket.timeWantedUtc ? dayjs(basket.timeWantedUtc) : undefined,
        };
      } else {
        timeWanted = {
          timeWantedType: 'Advance',
          timeWanted: basket.timeWanted ? dayjs(basket.timeWanted) : undefined,
        };
      }
    } else {
      timeWanted = {
        timeWantedType: basket.isManualFire ? 'ManualFire' : 'Immediate',
      };
    }

    let handoffMode: HandoffCriteria;
    if (isDeliveryMode(basket.handoffMode)) {
      handoffMode = {
        handoffMode: basket.handoffMode,
        deliveryAddress: basket.deliveryAddress
          ? {
              id: basket.deliveryAddress.id,
              streetAddress: basket.deliveryAddress.streetAddress,
              building: basket.deliveryAddress.building,
              city: basket.deliveryAddress.city,
              zipCode: basket.deliveryAddress.zipCode,
            }
          : undefined,
      };
    } else {
      handoffMode = {
        handoffMode: basket.handoffMode,
        searchAddress: basket.vendor.get('name')!,
      };
    }

    return {
      ...handoffMode,
      ...timeWanted,
    };
  }

  // Constructor
  constructor() {
    super(...arguments);
    this._searchOrderCriteria = this.storedOrderCriteria;
  }

  // Other methods
  async updateBasket(): Promise<void> {
    if (this.basketOrderCriteria) {
      await this.basket.basket?.updateCriteria();
      this.searchOrderCriteria = undefined;
    }
  }

  openModal(
    args: {
      error?: string;
      onChange?: (model: OrderCriteria) => void;
      searchOnly?: boolean;
    } = {}
  ): void {
    this.onOrderCriteriaChange = args.onChange ?? this.onOrderCriteriaChange ?? noop;
    this.searchOnly = args.searchOnly ?? this.searchOnly ?? false;
    this.orderCriteriaError = args.error ?? '';
  }

  closeModal(orderCriteria?: OrderCriteria): void {
    if (orderCriteria) this.onOrderCriteriaChange?.(orderCriteria);
    this.onOrderCriteriaChange = undefined;
    this.searchOnly = false;
    this.orderCriteriaError = '';
  }

  /**
   * Updates the search order criteria to the given handoff mode and
   * the first time wanted supported for the given handoff mode.
   *
   * Opens the order criteria modal if given a valid handoff mode,
   * but not all required information is available, such as:
   * - Delivery handoff mode (no delivery address)
   * - Advance time mode (no time wanted)
   *
   * @param handoffMode
   * @returns boolean indicating if we were able to set a valid search criteria
   */
  updateSearchOrderCriteria(handoffMode: HandoffMode): boolean {
    if (!this.isValidHandoffMode(handoffMode)) {
      return false;
    }

    const timeWantedType = this.getFirstValidTimeWantedType(handoffMode);

    if (!timeWantedType) {
      // In theory this should never be hit because `isValidHandoffMode` should
      // have already checked that at least one valid timeWantedType exists
      return false;
    }

    switch (handoffMode) {
      case 'Delivery':
      case 'Dispatch':
      case 'MultiDelivery':
        this.searchOrderCriteria = {
          handoffMode: 'MultiDelivery',
          isDefault: true,
          timeWantedType,
        };
        break;
      case 'CounterPickup':
      case 'CurbsidePickup':
      case 'DriveThru':
      case 'DineIn':
        this.searchOrderCriteria = {
          handoffMode,
          searchAddress: this.vendor.vendor?.address.postalCode ?? 'auto',
          timeWantedType,
        };
        break;
      default:
        break;
    }

    // Open the modal when:
    if (
      // a delivery handoff mode is used, because we need an address
      isDeliveryMode(handoffMode) ||
      // there is a basket with a handoff mode that doesn't match the specified
      // handoff mode, so the guest can confirm how they want their order
      (this.basket.basket?.handoffMode && this.basket.basket.handoffMode !== handoffMode) ||
      // the timeWantedType is advance but timeWanted isn't provided--such as
      // when ASAP isn't available--so the guest can confirm when they want
      // their order
      (isAdvance(this.searchOrderCriteria) && !this.searchOrderCriteria.timeWanted)
    ) {
      this.openModal({
        searchOnly: true,
        onChange: () => {
          this.updateBasket();
        },
      });

      return false;
    }

    return true;
  }

  /**
   * Determine if a handoff mode is valid for the current vendor
   * @param handoffMode the handoff mode to check
   * @returns whether the handoff is valid
   */
  isValidHandoffMode(handoffMode: HandoffMode): boolean {
    // Unspecified handoff modes are never valid
    if (handoffMode === 'Unspecified') {
      return false;
    }

    // The handoff mode must be included in the vendor's supported handoff modes
    const supportedHandoffModes = this.vendor.vendor?.settings.supportedHandoffModes ?? [];
    if (!supportedHandoffModes.some(hm => handoffMatches(hm, handoffMode))) {
      return false;
    }

    const handoffModeModel = this.store.peekAll('handoff-mode').find(
      hm =>
        handoffMatches(hm.type, handoffMode) &&
        // If the handoff mode is private, it is only valid if the vendor is
        // also private. There may be a second matching handoff mode that is not
        // private--such as when MultiDelivery matches Delivery and then
        // Dispatch--so continue looking for a matching handoff mode.
        (!hm.isPrivate || this.showPrivateModes)
    );

    if (!handoffModeModel) {
      return false;
    }

    // The vendor must have at least one valid time wanted mode
    if (
      !handoffModeModel.timeWantedModes
        .toArray()
        .some((timeWantedMode: TimeWantedMode) => this.isValidTimeWantedType(timeWantedMode.type))
    ) {
      return false;
    }

    return true;
  }

  /**
   * Determine if a time wanted type is valid for the current vendor
   * @param timeWantedType the time wanted type to check
   * @returns whether the time wanted type is valid
   */
  isValidTimeWantedType(timeWantedType: TimeWantedType): boolean {
    const timeWantedMode = this.store
      .peekAll('time-wanted-mode')
      .find(twm => twm.type === timeWantedType);

    // If the time wanted type is private, it is only valid if the vendor is
    // also private
    if (timeWantedMode?.isPrivate && !this.showPrivateModes) {
      return false;
    }

    // The time wanted type must be allowed by the vendor
    switch (timeWantedType) {
      case 'Advance':
        return this.vendor.vendor?.allowAdvanceOrders ?? false;
      case 'Immediate':
        return this.vendor.vendor?.allowImmediateOrders ?? false;
      case 'ManualFire':
        return this.vendor.vendor?.allowManualFireOrders ?? false;
      default:
        return false;
    }
  }

  /**
   * Gets the first timeWantedType for a handoffMode that's valid for the
   * current vendor if one exists.
   * @param handoffMode the handoff mode to access
   * @returns the time wanted mode if a valid one exists, otherwise `undefined`
   */
  getFirstValidTimeWantedType(handoffMode: HandoffMode): TimeWantedType | undefined {
    const handoffModeModel = this.store
      .peekAll('handoff-mode')
      .find(hm => handoffMatches(hm.type, handoffMode));

    if (!handoffModeModel) {
      return undefined;
    }

    const timeWantedMode = handoffModeModel.timeWantedModes
      .toArray()
      .find(twm => this.isValidTimeWantedType(twm.type));

    return timeWantedMode?.type;
  }

  getSelectableTimeWantedModes(handoffMode?: HandoffModeModel): TimeWantedMode[] {
    const timeWantedModes = handoffMode?.timeWantedModes ?? [];
    const validModes = timeWantedModes.filter(t =>
      this.showVendorModes ? this.isValidTimeWantedType(t.type) : !t.isPrivate
    );
    return validModes;
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    'order-criteria': OrderCriteriaService;
  }
}
