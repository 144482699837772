import Service from '@ember/service';

import { AppStoreReview } from 'mobile-web/lib/plugins/app-store-review';

export default class AppStoreReviewService extends Service {
  // Service injections

  // Untracked properties

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods
  requestReview(): Promise<void> {
    return AppStoreReview.requestReview();
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    appStoreReview: AppStoreReviewService;
  }
}
