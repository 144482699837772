export default {
  "notification": "_notification_4t0izc",
  "expand": "_expand_4t0izc",
  "success": "_success_4t0izc",
  "info": "_info_4t0izc",
  "warning": "_warning_4t0izc",
  "error": "_error_4t0izc",
  "hybrid": "_hybrid_4t0izc",
  "opening": "_opening_4t0izc",
  "idling": "_idling_4t0izc",
  "closing": "_closing_4t0izc",
  "icon": "_icon_4t0izc",
  "message": "_message_4t0izc",
  "closeButton": "_closeButton_4t0izc",
  "closeIcon": "_closeIcon_4t0izc"
};
