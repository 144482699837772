import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/routes/vendor-search-results-route/index.ts";
window.define('mobile-web/components/routes/vendor-search-results-route', function () {
  return a1;
});
import a2 from "../helpers/route-action.ts";
window.define('mobile-web/helpers/route-action', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "1HSYnusP",
  "block": "[[[1,[28,[35,0],[\"Location Results\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@model\",\"@onSearchChange\"],[[30,0,[\"model\"]],[28,[37,2],[\"vendor-search-results\",\"searchDidChange\"],null]]],null]],[],false,[\"page-title\",\"routes/vendor-search-results-route\",\"route-action\"]]",
  "moduleName": "mobile-web/templates/vendor-search-results.hbs",
  "isStrictMode": false
});