import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
// import { tracked } from '@glimmer/tracking';

import ChannelService from 'mobile-web/services/channel';
import CmsContentService from 'mobile-web/services/cms-content';

import style from './index.m.scss';

interface Args {
  // Required arguments
  altText: string;

  // Optional arguments
}

export default class HeroImage extends Component<Args> {
  // Service injections
  @service channel!: ChannelService;
  @service cmsContent!: CmsContentService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get heroImage(): string {
    return this.cmsContent.getContent('heroImage')?.imageUri ?? this.channel.heroImageUrl;
  }

  get heroImageAlt(): string | undefined {
    return this.cmsContent.getContent('heroImage')?.altText ?? this.args.altText;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
