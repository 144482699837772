import a0 from "../../content-placeholders/index.ts";
window.define('mobile-web/components/content-placeholders', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "p+KNVxlM",
  "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"root\"]]]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"img\"]],[[16,0,[30,0,[\"style\",\"image\"]]]],null,null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"controls\"]]],[12],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"mainHeader\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"subHeader\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"input\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n    \"],[8,[30,1,[\"text\"]],[[16,0,[30,0,[\"style\",\"button\"]]]],[[\"@lines\"],[1]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"placeholder\"],false,[\"content-placeholders\"]]",
  "moduleName": "mobile-web/components/application-loading/splash/index.hbs",
  "isStrictMode": false
});