import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import { host } from 'mobile-web/lib/hybrid-util';

import ApplicationAdapter from './application';

export default class VendorAdapter extends ApplicationAdapter {
  shouldBackgroundReloadRecord(_store: unknown, snapshot: DS.Snapshot<'vendor'>): boolean {
    /* Reload if there is a vendor record but not associated Menu Data */
    return isEmpty(snapshot.record.categories);
  }

  urlForQueryRecord(query: { slug: string }): string {
    return `${host()}/${this.namespace}/vendors/${query.slug}`;
  }

  // Strips out the slug from the query params
  sortQueryParams(): unknown[] {
    return [];
  }
}
