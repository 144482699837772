import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { taskFor } from 'ember-concurrency-ts';

import { isDeliveryMode } from 'mobile-web/lib/order-criteria';
import BasketModel from 'mobile-web/models/basket';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

interface Args {
  // Required arguments
  basket: BasketModel;

  // Optional arguments
}

interface Signature {
  Args: Args;
}

export default class CartTotals extends Component<Signature> {
  // Service injections
  @service('basket') basketService!: BasketService;
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get isProcessing(): boolean {
    return taskFor(this.basketService.updateTipTask).isRunning;
  }

  get isDelivery(): boolean {
    return isDeliveryMode(this.args.basket.handoffMode);
  }

  /**
   * Normally vendorDiscount is a positive number (e.g. 5 off),
   * this method makes it a negative number (e.g. -5 to the total)
   */
  get vendorDiscount(): number {
    // comp card discounts are included in vendorDiscount, but we pretend it is a payment method not a discount
    // so in this context we have to remove any comp card discount from the vendorDiscount
    return this.args.basket.compCardDiscount - this.args.basket.vendorDiscount;
  }

  get discountLabel(): string | number {
    return (
      (this.args.basket.hasCoupon
        ? this.args.basket.coupon?.code
        : this.args.basket.reward?.label) ?? ''
    );
  }

  get feesTotal(): number {
    const fees = this.args.basket.fees.map(fee => fee.amount ?? 0);
    const taxes = this.args.basket.taxes.map(tax => tax.totalTax ?? 0);
    return fees.concat(taxes).reduce((acc, cur) => acc + cur);
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  onTooltipHover(): void {
    if (this.session.viewedCustomFeesTooltip === true) return;
    this.session.viewedCustomFeesTooltip = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cart::Totals': typeof CartTotals;
  }
}
