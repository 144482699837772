import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/routes/open-source-licenses-route/index.ts";
window.define('mobile-web/components/routes/open-source-licenses-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "OC9qp5ZY",
  "block": "[[[1,[28,[35,0],[\"Open Source Licenses\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@licenses\"],[[30,0,[\"model\"]]]],null]],[],false,[\"page-title\",\"routes/open-source-licenses-route\"]]",
  "moduleName": "mobile-web/templates/open-source-licenses.hbs",
  "isStrictMode": false
});