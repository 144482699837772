import a0 from "../../helpers/classes.ts";
window.define('mobile-web/helpers/classes', function () {
  return a0;
});
import a1 from "../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a1;
});
import a2 from "../href/index.ts";
window.define('mobile-web/components/href', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "IZjAoEyL",
  "block": "[[[11,0],[16,0,[28,[37,0],[[30,0,[\"style\",\"container\"]],[52,[51,[30,0,[\"loadedImage\"]]],[30,0,[\"style\",\"placeholder\"]]]],null]],[24,\"data-test-menuBanner\",\"\"],[17,1],[4,[38,2],[[30,0,[\"handleImageLoad\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"urlDestination\"]],[[[1,\"    \"],[8,[39,4],[[16,6,[30,0,[\"urlDestination\"]]],[16,\"target\",[52,[30,0,[\"openUrlNewWindow\"]],\"_blank\",\"_self\"]],[16,0,[30,0,[\"style\",\"link\"]]],[24,\"data-test-menuBanner-link\",\"\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[10,\"img\"],[15,0,[30,0,[\"style\",\"image\"]]],[15,\"src\",[30,0,[\"imageSrc\"]]],[15,\"alt\",[30,0,[\"altText\"]]],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"img\"],[15,0,[30,0,[\"style\",\"image\"]]],[15,\"src\",[30,0,[\"imageSrc\"]]],[15,\"alt\",[30,0,[\"altText\"]]],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"classes\",\"unless\",\"did-insert\",\"if\",\"href\"]]",
  "moduleName": "mobile-web/components/menu-banner/index.hbs",
  "isStrictMode": false
});