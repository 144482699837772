import Helper from '@ember/component/helper';
import { later } from '@ember/runloop';

import ENV from 'mobile-web/config/environment';
import dayjs from 'mobile-web/lib/dayjs';

type Hash = {
  interval?: number;
};

export default class LiveUtc extends Helper {
  compute(_params: unknown[], hash: Hash) {
    if (ENV.environment !== 'test') {
      later(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.recompute();
        }
      }, hash.interval ?? 1000);
    }
    return dayjs.utc();
  }
}
