import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { dropTask, timeout } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import BasketProductModel from 'mobile-web/models/basket-product';
import GroupOrderModel from 'mobile-web/models/group-order';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import OnPremiseService, { ON_PREMISE_JUNK_LAST_NAME } from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

import AnalyticsService, { AnalyticsEvents } from './analytics';
import ErrorService from './error';
import SessionService from './session';

export function groupOrderDisplayName(firstName?: string, lastName?: string) {
  const names = [firstName, lastName === ON_PREMISE_JUNK_LAST_NAME ? undefined : lastName];
  return names.join(' ').trim();
}

export const GROUP_ORDER_BASKET_REFRESH_INTERVAL = 10000;

export enum GroupOrderParticipantStatus {
  Unknown = 'Unknown',
  Joined = 'Joined',
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Removed = 'Removed',
}

export default class GroupOrderService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service vendor!: VendorService;
  @service router!: RouterService;
  @service error!: ErrorService;

  // Untracked properties
  timeout = timeout; // aids testing of ember timeouts
  private lastOrderUpdateTimestamp?: string;

  // Tracked properties
  @tracked private _groupOrderId?: string;

  // Getters and setters
  get isEnabled(): boolean {
    return (
      ((this.features.flags['on-premise-group-ordering'] && this.onPremise.isEnabled) ||
        this.features.flags['off-premise-group-ordering']) &&
      this.channel.current?.settings.groupOrdering === true
    );
  }

  get groupOrder(): GroupOrderModel | undefined {
    if (this.groupOrderId) {
      return this.store.peekRecord('group-order', this.groupOrderId) ?? undefined;
    }
    return undefined;
  }

  get hasGroupOrder(): boolean {
    return !!this.groupOrder;
  }

  get isGroupOrderStarted(): boolean {
    return this.hasGroupOrder;
  }

  get isHostMode(): boolean {
    return this.hasGroupOrder && !!this.groupOrder?.currentUserIsHost;
  }

  get isParticipantMode(): boolean {
    return this.hasGroupOrder && !this.isHostMode;
  }

  get groupOrderId(): string | undefined {
    return this._groupOrderId ?? this.basket.basket?.groupOrderId;
  }

  set groupOrderToJoin(value: string | undefined) {
    this._groupOrderId = value;
  }

  get currentUserName(): string {
    const user = this.session.user;
    return groupOrderDisplayName(user?.firstName, user?.lastName);
  }

  get currentUserProducts(): BasketProductModel[] {
    const allProducts = this.basket.basket?.basketProducts?.toArray() ?? [];
    return allProducts.filter(bp => bp.recipientName === this.currentUserName);
  }

  get hostDisplayName(): string {
    const host = this.groupOrder?.host;
    return groupOrderDisplayName(host?.firstName, host?.lastName);
  }

  get hostDisplayNamePossessive(): string {
    return !this.hostDisplayName ? '' : `${this.hostDisplayName}'s`;
  }

  get canEditGroupOrder(): boolean {
    return this.groupOrder?.status.toLowerCase() === 'created';
  }

  get isGroupOrderCancelled(): boolean {
    return this.groupOrder?.status.toLowerCase() === 'cancelled';
  }

  get participantStatusSettings(): {
    icon: string;
    status: GroupOrderParticipantStatus;
    statusCount: number;
  }[] {
    const statusArray = [
      //GroupOrderParticipantStatus.Joined,
      GroupOrderParticipantStatus.InProgress,
      GroupOrderParticipantStatus.Submitted,
    ];

    const statuses = statusArray.map(status => {
      const statusCount =
        this.groupOrder!.participants?.filter(part => status === part.status).length ?? 0;

      const icon = this.participantStatusIcon(status);

      return { icon, status, statusCount };
    });

    return statuses;
  }

  get hasParticipantsInProgress(): boolean {
    return (
      (this.groupOrder!.participants?.filter(
        p => p.status === GroupOrderParticipantStatus.InProgress
      ).length ?? 0) > 0
    );
  }

  // Other methods
  participantStatus(name: string): GroupOrderParticipantStatus {
    return (
      this.groupOrder!.participants?.find(x => x.participantName === name)?.status ??
      GroupOrderParticipantStatus.Unknown
    );
  }

  statusCount(status: GroupOrderParticipantStatus): number {
    return this.groupOrder!.participants?.filter(part => status === part.status).length ?? 0;
  }

  public participantStatusIcon(status: string) {
    let icon = '';
    if (status === GroupOrderParticipantStatus.Submitted) {
      icon = 'check';
    } else if (status === GroupOrderParticipantStatus.InProgress) {
      icon = 'clock';
    }

    return icon;
  }

  async loadGroupOrder(): Promise<GroupOrderModel | undefined> {
    if (
      !(
        this.features.flags['on-premise-group-ordering'] ||
        this.features.flags['off-premise-group-ordering']
      ) ||
      this.channel.current?.settings.groupOrdering !== true
    ) {
      return undefined;
    }

    const groupOrderId = this.basket.basket?.groupOrderId;
    if (!groupOrderId) {
      return undefined;
    }

    const groupOrder = await this.store.findRecord('group-order', groupOrderId);

    this.refreshBasketTask.perform();
    return groupOrder;
  }

  async startGroupOrder(): Promise<GroupOrderModel | undefined> {
    const groupOrder = this.store.createRecord('group-order');
    groupOrder.basketId = this.basket.basket!.id;
    this.refreshBasketTask.perform();
    const createdGroupOrder = await groupOrder.save();
    await this.basket.refreshBasket();
    return createdGroupOrder;
  }

  async endGroupOrder(): Promise<void> {
    await this.groupOrder?.updateParticipantStatus({
      participantName: this.currentUserName,
      status: GroupOrderParticipantStatus.Removed,
    });
    this.cleanupGroupOrder();
  }

  async cancelGroupOrder(): Promise<void> {
    await this.groupOrder?.cancelGroupOrder();
    this.cleanupGroupOrder();
  }

  async cleanupGroupOrder(): Promise<void> {
    this._groupOrderId = undefined;
    this.refreshBasketTask.cancelAll();

    // TODO: This will eventually get moved to a common service so that we don't need to depend on on-premise.
    await this.onPremise.endMultiOrder();
    this.onPremise.basketStartOver();
    this.lastOrderUpdateTimestamp = undefined;
  }

  async joinGroupOrder(vendorSlug: string, firstName: string, lastName: string): Promise<void> {
    if (!this.groupOrder) {
      this.error.reportError('Could not load the group order.');
      return;
    }
    const response = await this.groupOrder.joinGroupOrder({
      participantName: firstName + ' ' + lastName,
    });
    await this.basket.loadBasket(response.basket.guid);
    // this must be in sync with app/services/bootstrap initBootstrap.
    await this.loadGroupOrder();
    await this.vendor.ensureVendorLoaded(vendorSlug);
    this.onPremise.onBootstrapInit();
  }

  // Private methods
  private async checkForGroupOrderBasketUpdate(): Promise<boolean> {
    const result: { lastUpdated: string } = await this.basket.basket?.checkGroupOrderUpdateStatus();
    if (result.lastUpdated !== this.lastOrderUpdateTimestamp) {
      this.lastOrderUpdateTimestamp = result.lastUpdated;
      return true;
    }

    return false;
  }

  private async orderMore(): Promise<void> {
    await this.cleanupGroupOrder();

    const slug = this.vendor.vendor?.get('slug');

    if (slug) {
      this.router.transitionTo('menu.vendor', slug);
    } else {
      this.router.transitionTo('index');
    }
    if (this.onPremise.experienceType === OnPremiseExperience.MultiOrder) {
      this.analytics.trackEvent(AnalyticsEvents.OrderMore);
    }
  }

  // Tasks
  refreshBasketTask = taskFor(this.refreshBasketInstance);
  @dropTask *refreshBasketInstance(): Generator<any, any, any> {
    while (!this.isDestroyed) {
      if (
        this.groupOrderId &&
        this.isParticipantMode &&
        !this.canEditGroupOrder &&
        !this.router.currentURL.endsWith('/group-order/participant-confirmation')
      ) {
        if (this.isGroupOrderCancelled) {
          this.bus.trigger('confirm', {
            title: this.intl.t('mwc.groupOrder.closedGroupTitle'),
            content: this.intl.t('mwc.groupOrder.closedGroupDescription'),
            buttonLabel: this.intl.t('mwc.groupOrder.closedGroupStartNew'),
            hideCancelButton: true,
            onConfirm: async () => await this.orderMore(),
            testSelector: 'closed-group-order-confirmation-modal',
            buttonTestSelector: 'closed-group-order-confirmation-button',
          });
        } else {
          yield this.router.transitionTo('secure.group-order.participant-confirmation');
        }
      }
      yield this.timeout(GROUP_ORDER_BASKET_REFRESH_INTERVAL);
      if (this.basket.basket && this.groupOrderId) {
        const shoudUpdate = yield this.checkForGroupOrderBasketUpdate();
        if (shoudUpdate) {
          yield this.basket.refreshBasket();
          yield this.store.findRecord('group-order', this.groupOrderId, { reload: true });
        }
      }
    }
  }
}

// Actions

declare module '@ember/service' {
  interface Registry {
    'group-order': GroupOrderService;
  }
}
