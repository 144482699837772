import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

import { tagName } from '@ember-decorators/component';
import IntlService from 'ember-intl/services/intl';

import { vendorTime } from 'mobile-web/helpers/vendor-time';
import dayjs from 'mobile-web/lib/dayjs';
import Address from 'mobile-web/lib/location/address';
import { isFunction } from 'mobile-web/lib/utilities/_';
import isSome from 'mobile-web/lib/utilities/is-some';
import Vendor from 'mobile-web/models/vendor';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';

import style from './index.m.scss';

@tagName('')
export default class VendorInfoModal extends Component {
  // Service injections
  @service intl!: IntlService;
  @service channel!: ChannelService;
  @service device!: DeviceService;

  // Required arguments
  onClose!: Action;
  vendor!: Vendor;

  // Optional arguments

  // Class fields
  style = style;

  // Computed properties
  @computed('vendor')
  get currentDate(): string {
    return vendorTime(dayjs().utc(), this.vendor).format(
      this.intl.t('mwc.vendorInfoModal.currentDayFormat')
    );
  }

  @computed('device.isIOS', 'vendor.address')
  get mapsUrl() {
    return Address.toMapsApplicationLink(this.vendor.address, this.device.isIOS);
  }

  // Init
  init() {
    super.init();
    assert('`onClose` is required', isFunction(this.onClose));
    assert('`vendor` is required', isSome(this.vendor));
  }

  // Other methods

  // Actions
}
