import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import { cancel, later } from '@ember/runloop';
import { EmberRunTimer } from '@ember/runloop/types';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { getDuration } from 'mobile-web/lib/animation';
import { MILLISECONDS, SECONDS } from 'mobile-web/lib/time/durations';
import FeaturesService from 'mobile-web/services/features';
import NotificationsService, {
  Notification as NotificationMessage,
  NotificationLevel,
} from 'mobile-web/services/notifications';

import style from './index.m.scss';

const OPEN_DURATION = getDuration(400 * MILLISECONDS);
const CLOSE_DURATION = getDuration(400 * MILLISECONDS);

enum NotificationState {
  Opening = 'opening',
  Idling = 'idling',
  Closing = 'closing',
}

const ICONS: Dict<string> = {
  [NotificationLevel.Success]: 'checkmark',
  [NotificationLevel.Info]: 'info',
  [NotificationLevel.Warning]: 'exclamation',
  [NotificationLevel.Error]: 'flag',
};

const ICON_LABELS: Dict<string> = {
  [NotificationLevel.Success]: 'Success',
  [NotificationLevel.Info]: 'Info',
  [NotificationLevel.Warning]: 'Warning',
  [NotificationLevel.Error]: 'Error',
};

const VARIANTS: Dict<string> = {
  [NotificationLevel.Success]: style.success,
  [NotificationLevel.Info]: style.info,
  [NotificationLevel.Warning]: style.warning,
  [NotificationLevel.Error]: style.error,
};

const STATES: Dict<string> = {
  [NotificationState.Opening]: style.opening,
  [NotificationState.Idling]: style.idling,
  [NotificationState.Closing]: style.closing,
};

interface Args {
  // Required arguments
  notification: NotificationMessage;

  // Optional arguments
  testMode?: boolean;
}

interface Signature {
  Args: Args;
}

export default class Notification extends Component<Signature> {
  // Service injections
  @service features!: FeaturesService;
  @service notifications!: NotificationsService;

  // Untracked properties
  style = style;
  timer?: EmberRunTimer;

  // Tracked properties
  @tracked state = NotificationState.Opening;

  // Getters and setters
  get icon() {
    const level = this.args.notification.level;

    return ICONS.hasOwnProperty(level) ? ICONS[level] : '';
  }

  get iconLabel() {
    const level = this.args.notification.level;

    return ICON_LABELS.hasOwnProperty(level) ? ICON_LABELS[level] : '';
  }

  @alias('args.notification.message') message!: string;

  get messageId() {
    return `notification-message-${this.uniqueId}`;
  }

  get notificationClass() {
    const classes = [style.notification];

    classes.push(this.style.hybrid);

    const level = this.args.notification.level;
    if (VARIANTS.hasOwnProperty(level)) {
      classes.push(VARIANTS[level]);
    }

    const state = this.state;
    if (STATES.hasOwnProperty(state)) {
      classes.push(STATES[state]);
    }

    return classes.join(' ');
  }

  get uniqueId() {
    return guidFor(this);
  }

  get idleDuration(): number {
    return getDuration(5 * SECONDS);
  }

  // Constructor

  // Other methods
  next(method: () => void, wait: number) {
    if (this.timer) {
      cancel(this.timer);
    }

    this.timer = later(method, wait);
  }

  // Tasks

  // Actions
  @action
  open() {
    this.state = NotificationState.Opening;

    this.next(this.idle, OPEN_DURATION);
  }

  @action
  idle() {
    this.state = NotificationState.Idling;

    if (!this.args.testMode) {
      this.next(this.close, this.idleDuration);
    }
  }

  @action
  close() {
    this.state = NotificationState.Closing;

    this.next(this.dismiss, CLOSE_DURATION);
  }

  @action
  dismiss() {
    this.notifications.dismiss(this.args.notification);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Notification: typeof Notification;
  }
}
