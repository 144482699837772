import a0 from "../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../components/open-check/index.ts";
window.define('mobile-web/components/open-check', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "OWEbWqXQ",
  "block": "[[[1,[28,[35,0],[\"Open Check\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,null,null]],[],false,[\"page-title\",\"open-check\"]]",
  "moduleName": "mobile-web/templates/open-check.hbs",
  "isStrictMode": false
});