import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  collapsible?: boolean;
  maxHeight?: number;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class ExpandableText extends Component<Signature> {
  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isCollapsed = this.args.collapsible ?? false;
  @tracked contentElement?: HTMLElement;

  // Getters and setters
  get contentHeight(): number {
    return this.contentElement?.scrollHeight ?? 0;
  }
  get maxHeight(): number {
    return this.args.maxHeight ?? 80;
  }
  get contentStyle(): SafeString | undefined {
    return !this.args.collapsible
      ? undefined
      : this.isCollapsed
      ? htmlSafe(`max-height: ${this.maxHeight}px;`)
      : htmlSafe(`max-height: ${this.contentHeight}px;`);
  }
  get showExpand(): boolean {
    return !!this.args.collapsible && !!this.contentElement && this.isOverflowing;
  }
  get isOverflowing(): boolean {
    return (this.contentElement?.scrollHeight ?? 0) > this.maxHeight;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  setup(el: HTMLElement) {
    this.contentElement = el;
    if (this.args.collapsible) {
      this.isCollapsed = this.isOverflowing;
    }
  }

  @action
  resize() {
    this.contentElement = this.contentElement; // eslint-disable-line no-self-assign
  }

  @action
  update() {
    this.resize();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ExpandableText: typeof ExpandableText;
  }
}
