import RSVP from 'rsvp';

import pick from 'lodash.pick';

import ApplicationAdapter from 'mobile-web/adapters/application';

export type Model = {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  password: string;
  confirmPassword: string;
  acceptedTerms: boolean;
  lastOrderId?: EmberDataId;
  previousOrderIds?: EmberDataId[];
  optIn: boolean;
};

export enum AccountAction {
  Register = 'register',
  Upgrade = 'upgrade',
}

export type UserModelPayload = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  emailAddress: string;
  isGuest: boolean;
  contactPhone: string;
  notifyBySms: boolean;
  notifyByEmail: boolean;
  optIn: boolean;
  allowOrderFollowUps: boolean;
  isUpsellEnabled: boolean;
  optInSms: boolean;
  serveAppToken: string;
};

export default class AccountAdapter extends ApplicationAdapter {
  register(userRegisterModel: Model): RSVP.Promise<{ user: UserModelPayload }> {
    const url = this.buildURL('account');

    return this.ajax(url, 'POST', {
      data: pick(
        userRegisterModel,
        'firstName',
        'lastName',
        'email',
        'contactNumber',
        'password',
        'confirmPassword',
        'acceptedTerms',
        'optIn'
      ),
    });
  }

  upgrade(userRegisterModel: Model): RSVP.Promise<{ user: UserModelPayload }> {
    const url = `${this.buildURL('account')}/upgrade`;

    return this.ajax(url, 'POST', {
      data: pick(
        userRegisterModel,
        'lastName',
        'email',
        'contactNumber',
        'password',
        'confirmPassword',
        'optIn',
        'acceptedTerms',
        'lastOrderId',
        'previousOrderIds'
      ),
    }).then(result => {
      result.user.isGuest = result.user.isGuest ?? false;
      return result;
    });
  }
}
