import a0 from "../popover/index.ts";
window.define('mobile-web/components/popover', function () {
  return a0;
});
import a1 from "../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../header-button/index.ts";
window.define('mobile-web/components/header-button', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "0dAX5kZg",
  "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,\"aria-label\",[28,[37,2],[\"mwc.header.accountButtonYourAccountLabel\"],null]]],[[\"@model\",\"@isHeader\"],[[30,0,[\"accountActions\"]],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@mode\",\"@label\",\"@icon\",\"@testSelector\"],[\"content\",[28,[37,4],null,[[\"text\",\"size\"],[[28,[37,2],[\"mwc.header.accountButtonYourAccountLabel\"],null],\"small\"]]],[28,[37,4],null,[[\"svg\",\"position\",\"ariaLabel\"],[\"account-circle-legacy\",\"left\",\"\"]]],\"account\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@mode\",\"@route\",\"@label\",\"@icon\",\"@onClick\",\"@testSelector\"],[[30,0,[\"mode\"]],\"secure.login\",[28,[37,4],null,[[\"text\",\"size\"],[[28,[37,2],[\"mwc.header.accountButtonSignInLabel\"],null],\"small\"]]],[28,[37,4],null,[[\"svg\",\"position\",\"ariaLabel\"],[\"account-circle-legacy\",\"left\",\"\"]]],[30,0,[\"onLogin\"]],\"login\"]],null],[1,\"\\n\"]],[]]]],[\"@loggedIn\"],false,[\"if\",\"popover\",\"t\",\"header-button\",\"hash\"]]",
  "moduleName": "mobile-web/components/account-button/index.hbs",
  "isStrictMode": false
});