import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { getOrCreateBasketChoice, isParentNew, loadNestedMenuItems } from 'mobile-web/lib/menu';
import { safeNext } from 'mobile-web/lib/runloop';
import BasketChoiceModel from 'mobile-web/models/basket-choice';
import BasketProductModel from 'mobile-web/models/basket-product';
import OptionGroupModel, { DisplayGroup } from 'mobile-web/models/option-group';

import style from './index.m.scss';

interface Args {
  // Required arguments
  basketProduct: BasketProductModel;
  optionGroup: OptionGroupModel;
  displayGroup: DisplayGroup;

  // Optional arguments
  onClick?: Action;
}

export default class ProductCustomizationDisplayGroup extends Component<Args> {
  // Service injections
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked basketChoices: BasketChoiceModel[] = [];

  // Getters and setters

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    safeNext(this, () => {
      let areAllNew = true;
      this.args.displayGroup.choices.forEach(c => {
        const { choice, isNew } = getOrCreateBasketChoice(this.store, this.args.basketProduct, c);
        this.basketChoices.pushObject(choice);
        areAllNew = areAllNew && isNew;
        loadNestedMenuItems(this.store, this.args.basketProduct, c, false);
      });
      if (areAllNew && isParentNew(this.args.basketProduct, this.args.displayGroup.choices[0])) {
        this.args.optionGroup.splitChoiceQuantities();
      }
    });
  }

  // Other methods

  // Tasks

  // Actions
}
