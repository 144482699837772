export default {
  "overlay": "_overlay_g3ezrc",
  "left": "_left_g3ezrc",
  "hidden": "_hidden_g3ezrc",
  "edge": "_edge_g3ezrc",
  "root": "_root_g3ezrc",
  "right": "_right_g3ezrc",
  "tag": "_tag_g3ezrc",
  "content": "_content_g3ezrc"
};
