import { getOwner } from '@ember/application';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';

import OnPremiseService from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

const tabRoute = (route: { prototype: Route }): void => {
  const original = route.prototype.beforeModel;
  route.prototype.beforeModel = function (transition: Transition) {
    const owner = getOwner(this) as AnyObject;
    const onPremise: OnPremiseService = owner.lookup('service:on-premise');
    const router: RouterService = owner.lookup('service:router');
    const vendor: VendorService = owner.lookup('service:vendor');

    if (!onPremise.hasOpenCheck) {
      transition.abort();
      if (onPremise.isEnabled && vendor.vendor) {
        router.replaceWith('menu.vendor', vendor.vendor.slug);
      } else {
        router.replaceWith('index');
      }
    } else if (original) {
      original.call(this, transition);
    }
  };
};

export default tabRoute;
