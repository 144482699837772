import a0 from "../../form-field/index.ts";
window.define('mobile-web/components/form-field', function () {
  return a0;
});
import a1 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a1;
});
import a2 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a2;
});
import a3 from "../../../helpers/perform.js";
window.define('mobile-web/helpers/perform', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "i+vmLCz8",
  "block": "[[[10,0],[15,0,[30,0,[\"style\",\"root\"]]],[14,\"data-test-linkRewards-form\",\"\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@label\",\"@hideLabel\",\"@placeholder\",\"@inputValue\",\"@class\"],[[28,[37,1],[\"scheme-\",[30,1,[\"id\"]]],null],[28,[37,2],[\"mwc.linkRewards.linkAccountLabel\"],[[\"name\"],[[30,1,[\"schemeName\"]]]]],true,[30,1,[\"identifierFieldName\"]],[30,0,[\"memberId\"]],[30,0,[\"style\",\"field\"]]]],null],[1,\"\\n  \"],[8,[39,3],[[16,\"disabled\",[30,0,[\"isDisabledLink\"]]]],[[\"@onClick\",\"@variant\",\"@testSelector\"],[[28,[37,4],[[30,0,[\"linkAccountTask\"]]],null],\"minor\",[28,[37,1],[\"linkRewards-\",[30,1,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"mwc.linkRewards.linkAccountButton\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@scheme\"],false,[\"form-field\",\"concat\",\"t\",\"button\",\"perform\"]]",
  "moduleName": "mobile-web/components/link-rewards/form/index.hbs",
  "isStrictMode": false
});