import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { ModelRegistry } from 'ember-data/model';
import RSVP from 'rsvp';

import { host } from 'mobile-web/lib/hybrid-util';
import FeaturesService from 'mobile-web/services/features';

import ApplicationAdapter from './application';

export enum OrderRequestType {
  Reorder = 'reorder',
  Edit = 'edit',
  Arrival = 'arrival',
}

export function buildOrderURL(
  namespace: string,
  requestType?: string,
  id?: EmberDataId
): string | undefined {
  if (requestType === OrderRequestType.Reorder) {
    return `${host()}/${namespace}/baskets/reorder`;
  }
  if (requestType === OrderRequestType.Edit) {
    return `${host()}/${namespace}/orders/${id}/update`;
  }
  if (requestType === OrderRequestType.Arrival) {
    return `${host()}/${namespace}/orders/${id}/arrived`;
  }
  return undefined;
}

export default class OrderAdapter extends ApplicationAdapter {
  @service features!: FeaturesService;

  urlForFindRecord<K extends keyof ModelRegistry>(
    id: string,
    modelName: K,
    snapshot: DS.Snapshot<K>
  ): string {
    const hash = snapshot.adapterOptions
      ? (snapshot.adapterOptions as Dict<string>).hash
      : undefined;

    if (hash) {
      const baseUrl = this.buildURL('order');
      return `${baseUrl}/${id}?hash=${encodeURIComponent(hash)}`;
    }
    return super.urlForFindRecord(id, modelName, snapshot);
  }

  checkIn(id: EmberDataId, hash: unknown): RSVP.Promise<unknown> {
    const baseUrl = this.buildURL('order');
    const url = `${baseUrl}/${id}/checkin`;
    const data = { id, hash };

    return this.ajax(url, 'POST', { data });
  }

  arrived(id: EmberDataId, hash: string, message?: string): RSVP.Promise<unknown> {
    const baseUrl = this.buildURL('order');
    const url = `${baseUrl}/${id}/arrived`;
    const data = { id, hash, message };

    return this.ajax(url, 'POST', { data });
  }

  cancel(id: EmberDataId): RSVP.Promise<unknown> {
    const baseUrl = this.buildURL('order');
    const url = `${baseUrl}/${id}/cancel`;

    return this.ajax(url, 'POST');
  }

  buildURL(_modelName?: unknown, id?: string, _snapshot?: unknown, requestType?: string): string {
    const url = buildOrderURL(this.namespace, requestType, id);
    return url ?? super.buildURL(...arguments);
  }
}
