import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { getOrCreateBasketChoice, loadNestedMenuItems } from 'mobile-web/lib/menu';
import { safeNext } from 'mobile-web/lib/runloop';
import { classes } from 'mobile-web/lib/utilities/classes';
import BasketChoiceModel from 'mobile-web/models/basket-choice';
import ChoiceModel from 'mobile-web/models/choice';
import OptionGroupModel from 'mobile-web/models/option-group';
import FocusManagerService, { CategoryName, FocusData } from 'mobile-web/services/focus-manager';

import style from './index.m.scss';

interface Args {
  // Required arguments
  optionGroup: OptionGroupModel;

  // Optional arguments
  openFormationGroup?: boolean;
  onClose?(): void;
  onOpen?(): void;
}

export default class ProductCustomizationFormationGroup extends Component<Args> {
  // Service injections
  @service focusManager!: FocusManagerService;
  @service store!: DS.Store;

  // Untracked properties
  private focusData?: FocusData;
  style = style;

  // Tracked properties
  @tracked basketChoice!: BasketChoiceModel;
  @tracked highlighted = false;
  @tracked isOpen = false;
  @tracked modalOptionGroup?: OptionGroupModel;
  @tracked modalBody?: HTMLElement;

  // Getters and setters
  get name(): string {
    return this.choice?.name ?? '';
  }

  get choice(): ChoiceModel | undefined {
    return this.args.optionGroup.choices.objectAt(0);
  }

  get rootClass(): string {
    return classes(this.style.root, {
      [this.style.highlight]: this.highlighted,
    });
  }

  get siblingFormationGroups(): OptionGroupModel[] {
    const optionGroup = this.modalOptionGroup;
    if (optionGroup) {
      const siblings = optionGroup.parentChoice
        ? (optionGroup.parentChoice.optionGroups.content as OptionGroupModel[])
        : optionGroup.product.firstLevelOptionGroupModels;

      return siblings.filter(og => !isEmpty(og.formationGroup));
    }
    return [];
  }

  get currentIndex(): number {
    return this.siblingFormationGroups.findIndex(og => og === this.modalOptionGroup);
  }

  get previousFormationGroup(): OptionGroupModel | undefined {
    return this.currentIndex > 0 ? this.siblingFormationGroups[this.currentIndex - 1] : undefined;
  }

  get nextFormationGroup(): OptionGroupModel | undefined {
    return this.currentIndex < this.siblingFormationGroups.length - 1
      ? this.siblingFormationGroups[this.currentIndex + 1]
      : undefined;
  }

  get isModalSubmitDisabled(): boolean {
    return !this.modalOptionGroup?.canSubmit;
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    // The `next` is needed because we reference the basketChoices array in the optionGroup
    // component, and Ember doesn't like you to change arrays in the same runloop that you
    // reference them.
    safeNext(this, () => {
      const choice = this.args.optionGroup.choices.objectAt(0)!;
      const basketProduct = this.args.optionGroup.activeBasketProduct!;
      const { choice: basketChoice } = getOrCreateBasketChoice(this.store, basketProduct, choice);
      this.basketChoice = basketChoice;

      loadNestedMenuItems(this.store, basketProduct, choice, true);
    });
  }

  // Other methods
  initializeFormationGroup(optionGroup: OptionGroupModel) {
    optionGroup.basketChoices.objectAt(0)!.quantity = 1;
  }

  deinitializeFormationGroup(optionGroup: OptionGroupModel) {
    const basketChoice = optionGroup.basketChoices.objectAt(0)!;
    if (
      basketChoice.choice.content!.optionGroups.every(og => og.selectedBasketChoices.length === 0)
    ) {
      basketChoice.quantity = 0;
    }
  }

  goToError() {
    this.focusManager.focusFirst(CategoryName.OptionGroupError);
  }

  // Tasks

  // Actions
  @action
  handleNext() {
    if (this.isModalSubmitDisabled) {
      this.goToError();
      return;
    }

    this.deinitializeFormationGroup(this.modalOptionGroup!);
    if (this.nextFormationGroup) {
      this.initializeFormationGroup(this.nextFormationGroup);
      this.modalOptionGroup = this.nextFormationGroup;
      this.modalBody?.scrollTo(0, 0);
    } else {
      this.closeModal();
    }
  }

  @action
  handlePrevious() {
    this.deinitializeFormationGroup(this.modalOptionGroup!);
    this.initializeFormationGroup(this.previousFormationGroup!);
    this.modalOptionGroup = this.previousFormationGroup;
    this.modalBody?.scrollTo(0, 0);
  }

  @action
  openModal() {
    this.initializeFormationGroup(this.args.optionGroup);
    this.modalOptionGroup = this.args.optionGroup;
    this.isOpen = true;
    this.args.onOpen?.();
  }

  @action
  closeModal() {
    this.isOpen = false;
    this.deinitializeFormationGroup(this.args.optionGroup);
    this.args.onClose?.();
  }

  @action
  register(target: HTMLButtonElement) {
    this.focusData = {
      allowFocus: () => !this.args.optionGroup.canSubmit && !this.args.openFormationGroup,
      onFocus: () => {
        this.highlighted = true;
      },
      target,
    };

    this.focusManager.register(CategoryName.OptionGroupError, this.focusData);
  }

  @action
  deregister() {
    if (this.focusData) {
      this.focusManager.deregister(CategoryName.OptionGroupError, this.focusData);
    }
  }
}
