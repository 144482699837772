export default {
  "root": "_root_oxlb8j",
  "mainHeader": "_mainHeader_oxlb8j",
  "subHeader": "_subHeader_oxlb8j",
  "input": "_input_oxlb8j",
  "button": "_button_oxlb8j",
  "form": "_form_oxlb8j",
  "isCreateAccount": "_isCreateAccount_oxlb8j",
  "isCheckoutAuth": "_isCheckoutAuth_oxlb8j"
};
