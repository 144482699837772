import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Storage } from '@capacitor/storage';

interface Args {
  // Required arguments

  // Optional arguments
  onClose?: Action;
  testSelector?: string;
}

interface Signature {
  Args: Args;
}

export default class DeveloperMenu extends Component<Signature> {
  // Service injections

  // Tracked properties
  @tracked changeUrlOptionModalIsOpen = false;
  @tracked vendorSlugModalIsOpen = false;
  @tracked menuItemModalIsOpen = false;
  @tracked devMenuOption = '';
  @tracked vendorSlug = '';
  @tracked menuItem = '';

  // Untracked properties

  // Getters and setters

  // Constructor

  // Other Methods
  async handleBaseUrlAction(): Promise<void> {
    const baseUrlKey = 'JS_BASE_URL';
    const { value } = await Storage.get({ key: baseUrlKey });

    // eslint-disable-next-line no-alert
    const inputBaseUrl = window.prompt(
      'Enter backend url. Clear the input value to remove the existing url. Force close the app after pressing "OK"',
      value ?? ''
    );
    await Storage.set({ key: baseUrlKey, value: inputBaseUrl ?? '' });
  }

  goToFirstProduct() {
    const products = document.body.querySelectorAll('[data-test-productcard]');
    if (products.length === 0) {
      return;
    }
    const product = products[0];
    if (product instanceof HTMLElement) {
      product.click();
    }
  }

  // Tasks

  // Actions
  @action
  async handleDevMenuSubmit() {
    const option = this.devMenuOption.trim();
    if (option === '1') {
      await this.handleBaseUrlAction();
    } else if (option === '2') {
      this.vendorSlugModalIsOpen = true;
    } else if (option === '3') {
      this.menuItemModalIsOpen = true;
    } else if (option === '4') {
      this.goToFirstProduct();
      this.close();
    }
  }

  @action
  close() {
    this.args.onClose?.();
  }

  @action
  handleVendorSlugAction(): void {
    if (this.vendorSlug) {
      this.close();
      window.location.href = `/menu/${this.vendorSlug}`;
    }
  }

  @action
  handleMenuItemAction(): void {
    if (this.vendorSlug && this.menuItem) {
      this.close();
      window.location.href = `/menu/${this.vendorSlug}/products/${this.menuItem}`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DeveloperMenu: typeof DeveloperMenu;
  }
}
