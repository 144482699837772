import a0 from "./link/index.ts";
window.define('mobile-web/components/logo/link', function () {
  return a0;
});
import a1 from "../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "6sZ9VxE6",
  "block": "[[[8,[39,0],[[16,0,[30,0,[\"style\",\"root\"]]],[24,\"data-test-logo\",\"\"],[17,1]],[[\"@isError\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"picture\"],[15,0,[30,0,[\"style\",\"picture\"]]],[12],[1,\"\\n    \"],[10,\"source\"],[14,\"data-test-logo-source\",\"\"],[15,\"srcset\",[30,0,[\"largeImage\"]]],[14,\"media\",\"(min-width: 768px)\"],[12],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@key\",\"@defaultMessage\",\"@substitutions\"],[\"LOGO_ALT_TEXT\",\"mwc.header.logoAltText\",[28,[37,2],null,[[\"channelName\"],[[30,0,[\"channel\",\"name\"]]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"img\"],[15,0,[30,0,[\"style\",\"image\"]]],[14,\"data-test-logo-image\",\"\"],[15,\"src\",[30,0,[\"smallImage\"]]],[15,\"alt\",[30,3,[\"value\"]]],[12],[13],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@isError\",\"altText\"],false,[\"logo/link\",\"x-content\",\"hash\"]]",
  "moduleName": "mobile-web/components/logo/index.hbs",
  "isStrictMode": false
});