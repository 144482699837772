import Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { MediaService } from 'ember-responsive';

import { Location, FooterModel } from 'mobile-web/components/footer';
import { State, HeaderModel } from 'mobile-web/components/header';
import { Loading, Loaded, Error } from 'mobile-web/lib/data';
import { noop } from 'mobile-web/lib/utilities/_';
import ChannelService from 'mobile-web/services/channel';
import LoadingService from 'mobile-web/services/loading';
import { Notification, NotificationType } from 'mobile-web/services/notifications';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import PageTitleService from 'mobile-web/services/page-title';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

export const APPLICATION_FRAME_ID = 'root';
export const ANNOUNCER_CONTAINER_ID = 'olo-announcer';

interface Args {
  // Required arguments

  // Optional arguments
  errorData?: ErrorEvent;
  isLoading?: boolean;
  onLogout?: Action;
}

export default class ApplicationFrame extends Component<Args> {
  // Service injections
  @service channel!: ChannelService;
  @service loading!: LoadingService;
  @service orderCriteria!: OrderCriteriaService;
  @service pageTitle!: PageTitleService;
  @service router!: Router;
  @service session!: SessionService;
  @service media!: MediaService;

  // Untracked properties
  uniqueId = APPLICATION_FRAME_ID;
  announcerContainerId = ANNOUNCER_CONTAINER_ID;
  style = style;

  // Tracked properties

  // Getters and setters
  get isLoading(): boolean {
    return this.args.isLoading ?? false;
  }

  get routeClass(): string | undefined {
    return this.loading.isSplash ? style.index : undefined;
  }

  get headerState(): State {
    const currentRouteName = this.router.currentRouteName;

    if (currentRouteName === 'application' || currentRouteName === 'index') {
      return State.Home;
    } else if (/^secure\.checkout/.test(currentRouteName)) {
      return State.Checkout;
    } else if (/^secure\.group-order\.participant-confirmation/.test(currentRouteName)) {
      return State.GroupOrderParticipantConfirmation;
    }

    return State.Default;
  }

  get footerModel(): FooterModel {
    if (this.args.errorData) {
      return Error.withValue(this.args.errorData.message);
    }
    if (!this.channel.current) {
      return new Loading();
    }

    return Loaded.withValue({
      location: Location.Bottom,
    });
  }

  get headerModel(): HeaderModel {
    if (this.args.errorData) {
      return Error.withValue(this.args.errorData.message);
    }
    if (!this.channel.current) {
      return new Loading();
    }

    return Loaded.withValue({
      isRestrictedFlow: this.session.isRestrictedFlow,
      isEmbedded: this.session.isEmbeddedMode,
      loggedIn: this.session.isLoggedIn,
      onLogout: this.args.onLogout ?? noop,
      pageTitle: this.pageTitle.titleWithoutSuffix,
      showOffersInbox: this.channel.settings?.showOffersInbox ?? false,
      signOnAllowed: this.session.signOnAllowed,
      state: this.headerState,
    });
  }

  // Constructor

  // Other methods
  notificationsFilter(notification: Notification) {
    switch (notification.type) {
      case NotificationType.ProductRemoved:
      case NotificationType.ProductUpdated:
      case NotificationType.UpsellAdded:
        return false;
      default:
        return true;
    }
  }

  // Tasks

  // Actions
}
