import RouterService from '@ember/routing/router-service';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import { computedLocal, computedSession } from 'mobile-web/lib/computed';
import { UserData } from 'mobile-web/lib/customer';
import dayjs from 'mobile-web/lib/dayjs';
import {
  keepPastOrders,
  multiOrderData,
  MultiOrderData,
  OnPremiseExperience,
} from 'mobile-web/lib/on-premise';
import CheckModel from 'mobile-web/models/check';
import BasketService from 'mobile-web/services/basket';
import BusService from 'mobile-web/services/bus';
import ErrorService from 'mobile-web/services/error';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';

import VendorService from './vendor';

export const getGeneratedDineInEmail = () => 'dinein+' + dayjs().unix() + '@olo.com';
export const ON_PREMISE_JUNK_LAST_NAME = '-';
export const ON_PREMISE_JUNK_PHONE_NUMBER = '5055555555';

export default class OnPremiseService extends Service {
  // Service injections
  @service basket!: BasketService;
  @service bus!: BusService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service session!: SessionService;
  @service store!: DS.Store;
  @service storage!: StorageService;
  @service vendor!: VendorService;

  // Untracked properties
  @computedLocal private multiOrderData?: MultiOrderData;
  @computedLocal private tablePosRefData?: string;
  @computedSession openCheck?: boolean;
  @computedLocal private vendorSlugForOpenCheck?: string;

  // Tracked properties

  // We have to do this because we cannot decorate with both @computedLocal and @tracked
  @tracked private trackedMultiOrderData?: MultiOrderData;
  @tracked private trackedTablePosRef?: string;
  @tracked check?: CheckModel;

  // Getters and setters
  get multiOrder(): MultiOrderData | undefined {
    // this.trackedMultiOrderData would be undefined if we never called the setter.
    // If we did, it would be the same as this.multiOrderData.
    return this.trackedMultiOrderData ?? this.multiOrderData;
  }

  set multiOrder(value: MultiOrderData | undefined) {
    this.trackedMultiOrderData = this.multiOrderData = value;
  }

  get tablePosRef(): string | undefined {
    // This must be backed by an in-memory tracked field in case localstorage keys can't be written to
    return this.trackedTablePosRef ?? this.tablePosRefData;
  }

  set tablePosRef(value: string | undefined) {
    this.trackedTablePosRef = this.tablePosRefData = value;
  }

  get isEnabled(): boolean {
    return !!this.multiOrder || this.openCheck === true;
  }

  get experienceType(): OnPremiseExperience {
    if (this.multiOrder) {
      return OnPremiseExperience.MultiOrder;
    } else if (this.openCheck === true) {
      return OnPremiseExperience.OpenCheck;
    }

    return OnPremiseExperience.Default;
  }

  get hasOpenCheck(): boolean {
    const sentLength = this.check?.sentBasketProducts.length;
    return sentLength !== undefined && sentLength > 0;
  }

  // Constructor

  // Other methods
  onBootstrapInit(): void {
    const check = this.store.peekAll('check').objectAt(0);
    if (check) {
      this.check = check;
    }

    if (
      this.basket.basket?.onPremiseDetails?.experienceType === OnPremiseExperience.MultiOrder &&
      !this.multiOrder &&
      this.vendor.vendorSlug
    ) {
      this.multiOrder = multiOrderData(this.vendor.vendorSlug);
    }

    if (this.multiOrder || this.openCheck) {
      if (this.router.currentURL === '/') {
        this.storage.showExitOnPremiseModal = true;
        return;
      }
    }

    if (this.multiOrder) {
      const data = this.multiOrder;

      const now = dayjs();
      const start = dayjs(data.start);
      if (start.add(24, 'hours').isBefore(now)) {
        this.endMultiOrder();
        return;
      }

      const user = this.session.currentUser;
      const pastOrders = data.pastOrders;
      if (pastOrders.length && !keepPastOrders(user, pastOrders)) {
        this.multiOrder = { ...this.multiOrder, pastOrders: [] };
      }

      const lastOrderTime = data.pastOrders[data.pastOrders.length - 1]?.timePlaced;
      const actionTimes = [lastOrderTime, data.continueTime, data.start].filter(
        t => t !== undefined
      ) as string[];
      const lastActionTime = [...actionTimes].sort()[actionTimes.length - 1];
      if (dayjs(lastActionTime).add(2, 'hours').isBefore(now)) {
        this.storage.showOnPremiseContinueModal = true;
      }
    }
  }

  async endMultiOrder(): Promise<void> {
    if (!this.multiOrder) {
      return;
    }

    this.multiOrder = undefined;
    this.tablePosRef = undefined;

    if (this.session.localGuestUser?.contactNumber === ON_PREMISE_JUNK_PHONE_NUMBER) {
      this.session.localGuestUser = undefined;
    }

    if (this.session.isSavedGuest) {
      await this.session.logout();
    }

    this.orderCriteria.searchOrderCriteria = undefined;
  }

  async setOnPremiseDetails(
    tablePosRef: string | undefined,
    experienceType: OnPremiseExperience,
    vendorSlug: string
  ): Promise<void> {
    this.setExperienceType(experienceType, vendorSlug);
    this.setTablePosRef(tablePosRef);

    if (this.basket.basket) {
      try {
        await this.setBasketOnPremiseDetails();
      } catch (e) {
        this.error.reportError(e);
      }
    }
  }

  setExperienceType(experience: OnPremiseExperience, vendorSlug: string): void {
    if (
      experience === OnPremiseExperience.MultiOrder ||
      experience === OnPremiseExperience.OpenCheck
    ) {
      if (this.orderCriteria.isValidHandoffMode('DineIn')) {
        this.orderCriteria.updateSearchOrderCriteria('DineIn');
      }
    }
    if (experience === OnPremiseExperience.MultiOrder) {
      if (!this.multiOrder) {
        this.multiOrder = multiOrderData(vendorSlug);
      }
    } else {
      this.multiOrder = undefined;
    }
    this.openCheck = experience === OnPremiseExperience.OpenCheck;
    if (this.openCheck) {
      this.vendorSlugForOpenCheck = vendorSlug;
    }
  }

  async setTablePosRef(table: string | undefined): Promise<void> {
    if (!this.orderCriteria.isValidHandoffMode('DineIn')) {
      this.tablePosRef = undefined;
      this.error.sendUserMessage({
        detail: this.intl.t('mwc.errors.onPremiseHandoffRequired'),
      });
    } else {
      this.orderCriteria.updateSearchOrderCriteria('DineIn');
      this.tablePosRef = table;
    }

    // TODO: Temporarily here for easy testing
    if (this.tablePosRef === 'create' && !this.check) {
      await this.createCheck(this.tablePosRef);
    }
  }

  async setBasketOnPremiseDetails(): Promise<void> {
    if (this.basket.basket) {
      await this.basket.basket.setOnPremiseDetails({
        tablePosReference: this.tablePosRef,
        experienceType: this.experienceType,
      });
    }
  }

  async createCheck(tablePosRef: string): Promise<void> {
    try {
      const check = await this.store.collectionAction('check', 'createCheck', {
        tablePosRef,
      });
      this.check = check;
    } catch {
      this.error.reportError('Failed to create check');
    }
  }

  async sendBasket(): Promise<void> {
    try {
      await this.store.collectionAction('check', 'sendBasket', void 0);
    } catch {
      this.error.reportError('Failed to send basket');
    }
  }

  goToMultiOrderConfirmation(): void {
    if (!this.multiOrder) {
      return;
    }

    const latestOrderId =
      this.multiOrder.pastOrders[this.multiOrder.pastOrders.length - 1].displayId;

    this.router.transitionTo('thank-you', latestOrderId);
  }

  goToStoreVendorPage(): void {
    if (this.multiOrder) {
      this.router.transitionTo('menu.vendor', this.multiOrder.vendorSlug, {
        queryParams: { multiOrder: true },
      });
    }
    if (this.openCheck) {
      this.router.transitionTo('menu.vendor', this.vendorSlugForOpenCheck!, {
        queryParams: { openCheck: true },
      });
    }
  }

  basketStartOver(): void {
    this.basket.basket?.startOver();
    this.basket.clear();
  }

  prepareGuestUser(user: UserData): void {
    if (!user.emailAddress) {
      user.emailAddress = getGeneratedDineInEmail();
    }
    if (!user.lastName) {
      user.lastName = ON_PREMISE_JUNK_LAST_NAME;
    }
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    'on-premise': OnPremiseService;
  }
}
