import Component from '@ember/component';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import { tagName } from '@ember-decorators/component';

import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

@tagName('')
export default class RecentOrdersRoute extends Component {
  // Service injections
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;

  // Required arguments
  model!: DS.RecordArray<OrderSearchResultModel>;

  // Optional arguments

  // Class fields
  style = style;

  // Computed properties

  // Init

  // Other methods

  // Actions
  @action
  startOrder() {
    this.router.transitionTo('index');
  }
}
