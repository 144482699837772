import a0 from "../../helpers/eq.js";
window.define('mobile-web/helpers/eq', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "bUEmH92V",
  "block": "[[[41,[28,[37,1],[[30,1],1],null],[[[1,\"  \"],[11,\"h1\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],2],null],[[[1,\"  \"],[11,\"h2\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],3],null],[[[1,\"  \"],[11,\"h3\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],4],null],[[[1,\"  \"],[11,\"h4\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,1],5],null],[[[1,\"  \"],[11,\"h5\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"h6\"],[17,2],[12],[18,3,null],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[]]]],[]]]],[\"@level\",\"&attrs\",\"&default\"],false,[\"if\",\"eq\",\"yield\"]]",
  "moduleName": "mobile-web/components/heading/index.hbs",
  "isStrictMode": false
});