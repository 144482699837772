import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { Location, FooterModel } from 'mobile-web/components/footer';
import { Loading, Loaded } from 'mobile-web/lib/data';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import ChannelService from 'mobile-web/services/channel';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import SessionService from 'mobile-web/services/session';
import SideMenuService from 'mobile-web/services/side-menu';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

import style from './index.m.scss';
interface Args {
  // Required arguments
  onLogout: Action;
}
export default class SideMenu extends Component<Args> {
  // Service injections
  @service sideMenu!: SideMenuService;
  @service channel!: ChannelService;
  @service session!: SessionService;
  @service router!: RouterService;
  @service onPremise!: OnPremiseService;
  @service storage!: StorageService;
  @service analytics!: AnalyticsService;
  @service groupOrder!: GroupOrderService;
  @service vendor!: VendorService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get footerModel(): FooterModel {
    if (!this.channel.current) {
      return new Loading();
    }

    return Loaded.withValue({
      showContent: true,
      location: Location.SideMenu,
    });
  }

  get isGuest(): boolean {
    return !this.session.isLoggedIn;
  }

  get userFirstName(): string {
    return this.session.currentUser?.firstName ?? '';
  }

  get showOffersInbox(): boolean {
    return this.channel.settings?.showOffersInbox ?? false;
  }

  get showAccount(): boolean {
    return (
      this.session.signOnAllowed && !this.onPremise?.multiOrder?.pastOrders?.length //will need update for open check ("has open tab")
    );
  }

  get showGroupOrderStartLink(): boolean {
    return (
      this.vendor.vendor?.slug !== undefined &&
      this.groupOrder.isEnabled &&
      !this.groupOrder.hasGroupOrder
    );
  }

  get loginRoute(): string {
    return this.session.internalSignOnAllowed ? 'secure.login' : '';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  async onPastOnPremiseOrders() {
    if (!this.onPremise.multiOrder?.pastOrders) {
      return;
    }

    this.sideMenu.close();

    if (this.onPremise.multiOrder) {
      this.analytics.trackEvent(AnalyticsEvents.SideMenuPreviousOrders);
    }

    this.onPremise.goToMultiOrderConfirmation();
  }

  @action
  async onStartGroupOrder() {
    this.analytics.trackEvent(AnalyticsEvents.StartGroupOrder, () => ({
      [AnalyticsProperties.LinkLocation]: 'Side Menu',
    }));
    this.router.transitionTo('secure.group-order.start', {
      queryParams: { slug: this.vendor.vendor?.slug },
    });
  }

  @action
  onLogin(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.session.goToLogin();
  }
}
