export default {
  "minor": "_minor_q6s5m9",
  "major": "_major_q6s5m9",
  "buttonContent": "_buttonContent_q6s5m9",
  "label": "_label_q6s5m9",
  "small": "_small_q6s5m9 _label_q6s5m9",
  "large": "_large_q6s5m9 _label_q6s5m9",
  "iconWrapper": "_iconWrapper_q6s5m9",
  "icon": "_icon_q6s5m9",
  "left": "_left_q6s5m9 _iconWrapper_q6s5m9",
  "right": "_right_q6s5m9 _iconWrapper_q6s5m9"
};
