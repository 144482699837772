import a0 from "./heading/index.ts";
window.define('mobile-web/components/content-placeholders/heading', function () {
  return a0;
});
import a1 from "./text/index.ts";
window.define('mobile-web/components/content-placeholders/text', function () {
  return a1;
});
import a2 from "./img/index.ts";
window.define('mobile-web/components/content-placeholders/img', function () {
  return a2;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "ZYLX08NU",
  "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"heading\",\"text\",\"img\"],[[50,\"content-placeholders/heading\",0,null,[[\"animated\"],[[30,0,[\"animated\"]]]]],[50,\"content-placeholders/text\",0,null,[[\"animated\"],[[30,0,[\"animated\"]]]]],[50,\"content-placeholders/img\",0,null,[[\"animated\"],[[30,0,[\"animated\"]]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
  "moduleName": "mobile-web/components/content-placeholders/index.hbs",
  "isStrictMode": false
});