import a0 from "../../../helpers/media.js";
window.define('mobile-web/helpers/media', function () {
  return a0;
});
import a1 from "../../header-button/index.ts";
window.define('mobile-web/components/header-button', function () {
  return a1;
});
import a2 from "../../button/index.ts";
window.define('mobile-web/components/button', function () {
  return a2;
});
import a3 from "../../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a3;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "iEIV7wqU",
  "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[11,0],[24,\"data-test-linkRewards-button\",\"\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[\"isDesktop\"],null],[[[1,\"      \"],[8,[39,2],null,[[\"@mode\",\"@label\",\"@icon\",\"@testSelector\",\"@onClick\"],[[30,2],[28,[37,3],null,[[\"text\",\"size\"],[[30,0,[\"buttonText\"]],\"small\"]]],[28,[37,3],null,[[\"svg\",\"ariaLabel\",\"position\"],[\"star\",\"\",\"left\"]]],\"rewardButton\",[30,0,[\"onClick\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@variant\",\"@testSelector\",\"@fullWidth\",\"@onClick\"],[\"minor\",\"rewardButton\",true,[30,0,[\"onClick\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"buttonContent\"]]],[12],[1,\"\\n          \"],[8,[39,5],null,[[\"@ariaLabel\",\"@icon\",\"@class\"],[\"\",\"star\",[30,0,[\"style\",\"starIcon\"]]]],null],[1,\"\\n          \"],[1,[30,0,[\"buttonText\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@mode\"],false,[\"if\",\"media\",\"header-button\",\"hash\",\"button\",\"svg\"]]",
  "moduleName": "mobile-web/components/link-rewards/button/index.hbs",
  "isStrictMode": false
});