import a0 from "../feedback-banner/index.ts";
window.define('mobile-web/components/feedback-banner', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "Ha+TFOjn",
  "block": "[[[41,[30,0,[\"userFeedback\",\"messages\"]],[[[1,\"  \"],[10,0],[14,1,\"feedback-banners\"],[15,0,[30,0,[\"style\",\"bannerContainer\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"userFeedback\",\"messages\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"message\"],false,[\"if\",\"each\",\"-track-array\",\"feedback-banner\"]]",
  "moduleName": "mobile-web/components/feedback-banners/index.hbs",
  "isStrictMode": false
});