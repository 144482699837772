import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import { Variant } from 'mobile-web/components/button';
import { HeadingLevel } from 'mobile-web/components/heading';
import { isErr } from 'mobile-web/lib/result';
import Validation, { ValidationConfig, ValidationResult } from 'mobile-web/lib/validation';
import AnalyticsService, { AnalyticsEvents } from 'mobile-web/services/analytics';
import ErrorService from 'mobile-web/services/error';
import SessionService from 'mobile-web/services/session';

import style from './index.m.scss';

class Model {
  @tracked email = '';
  @tracked password = '';
}

const VALIDATION_CONFIG: ValidationConfig<Model> = {
  bindings: [
    {
      targetProp: 'email',
      ruleName: 'notBlank',
      message: 'Email cannot be empty.',
    },
    {
      targetProp: 'email',
      ruleName: 'email',
      message: 'Please enter a valid email address.',
    },
    {
      targetProp: 'password',
      ruleName: 'notBlank',
      message: 'Password cannot be empty.',
    },
  ],
};

interface Args {
  // Required arguments
  onSubmit: (email: string, password: string) => void;

  // Optional arguments
  headingLevel?: HeadingLevel;
}

interface Signature {
  Element: HTMLFormElement;
  Args: Args;
}

export default class SignInForm extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service session!: SessionService;

  // Untracked properties
  style = style;

  // Tracked properties

  @tracked model: Model;
  @tracked passwordResetIsOpen = false;
  @tracked _validationResult?: ValidationResult;

  // Getters and setters
  get headingLevel(): HeadingLevel {
    return this.args.headingLevel ?? 1;
  }

  get variant(): Variant {
    const model = this.model;
    const invalid = isEmpty(model.email) || isEmpty(model.password);
    return invalid ? Variant.SoftDisabled : Variant.Main;
  }

  get validationResult(): ValidationResult | undefined {
    if (this.session.forcedPasswordReset) {
      return Validation.validate(this.model, {
        bindings: [
          {
            targetProp: 'password',
            ruleName: 'fail',
            message: this.intl.t('mwc.password.forcedReset'),
          },
        ],
      });
    }
    return this._validationResult;
  }

  // Constructor
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.model = new Model();
    this.analytics.trackEvent(AnalyticsEvents.ViewSignInForm, undefined, { bucket: 'all' });
  }

  // Other methods

  // Tasks

  // Actions
  @action
  async submit(event: Event) {
    event.preventDefault();
    this._validationResult = Validation.validate(this.model, VALIDATION_CONFIG);
    if (isErr(this.validationResult!)) {
      return;
    }

    try {
      await this.analytics.trackEvent(AnalyticsEvents.SignIn);
    } catch (err) {
      this.error.sendExternalError(err);
    }

    this.args.onSubmit(this.model.email, this.model.password);
  }
}
