export default {
  "topButtons": "_topButtons_1x8cvw",
  "topButtonsRow": "_topButtonsRow_1x8cvw",
  "orderCriteriaBtn": "_orderCriteriaBtn_1x8cvw",
  "multiOrdersButtonWrapper": "_multiOrdersButtonWrapper_1x8cvw",
  "multiOrdersButton": "_multiOrdersButton_1x8cvw",
  "multiOrdersButtonContent": "_multiOrdersButtonContent_1x8cvw",
  "multiOrdersButtonIcon": "_multiOrdersButtonIcon_1x8cvw",
  "alertWrapper": "_alertWrapper_1x8cvw",
  "cfm": "_cfm_1x8cvw",
  "cfmIcon": "_cfmIcon_1x8cvw",
  "cfmContent": "_cfmContent_1x8cvw",
  "row": "_row_1x8cvw",
  "linkButton": "_linkButton_1x8cvw"
};
