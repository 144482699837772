export default {
  "root": "_root_z5jcew",
  "highlight": "_highlight_z5jcew",
  "border-fade": "_border-fade_z5jcew",
  "header": "_header_z5jcew",
  "name": "_name_z5jcew",
  "edit": "_edit_z5jcew",
  "modal": "_modal_z5jcew",
  "button": "_button_z5jcew",
  "buttonText": "_buttonText_z5jcew",
  "doneButton": "_doneButton_z5jcew"
};
