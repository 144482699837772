import ArrayProxy from '@ember/array';
import { action } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import Transition from '@ember/routing/-private/transition';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import MenuVendorController from 'mobile-web/controllers/menu/vendor';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import { isDelivery, HandoffMode, mapPostalCode } from 'mobile-web/lib/order-criteria';
import { isTruthy } from 'mobile-web/lib/query-params';
import { isOk } from 'mobile-web/lib/result';
import { PreviousRoute } from 'mobile-web/lib/routing';
import isSome from 'mobile-web/lib/utilities/is-some';
import Channel from 'mobile-web/models/channel';
import LoyaltyAccountModel from 'mobile-web/models/loyalty-account';
import OrderSearchResultModel, {
  OrderSearchResultStatus,
} from 'mobile-web/models/order-search-result';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChannelService from 'mobile-web/services/channel';
import ContentService from 'mobile-web/services/content';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import LoyaltyService, { LoadedLoyaltyData, LoyaltyResults } from 'mobile-web/services/loyalty';
import OnPremiseService from 'mobile-web/services/on-premise';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

export type Model = {
  vendor: Vendor;
  recentOrders?: ArrayProxy<OrderSearchResultModel>;
  loyalty: Array<{
    account: LoyaltyAccountModel | undefined;
    schemeName: string;
  }>;
  pageTitle: string;
};

export default class VendorRoute extends Route {
  previousRoute: PreviousRoute = {
    label: 'Locations',
    route: 'vendor-search-results',
    models: [],
  };

  @service content!: ContentService;
  @service loyalty!: LoyaltyService;
  @service orderCriteria!: OrderCriteriaService;
  @service session!: SessionService;
  @service('vendor') vendorService!: VendorService;
  @service channel!: ChannelService;
  @service basket!: BasketService;
  @service('error') errorService!: ErrorService;
  @service storage!: StorageService;
  @service onPremise!: OnPremiseService;
  @service intl!: IntlService;
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;
  @service groupOrder!: GroupOrderService;
  @service router!: RouterService;

  @alias('channel.current')
  currentChannel?: Channel;
  @readOnly('session.isLoggedIn')
  isLoggedIn!: boolean;

  async setupController(
    controller: MenuVendorController,
    model: ModelForRoute<VendorRoute>,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);

    const isExternal = !this.storage.firstRouteName;
    const onPremiseQueryParam = controller.multiOrder
      ? 'multiorder'
      : controller.openCheck
      ? 'opencheck'
      : undefined;
    const hasTableNumber = !!controller.tablePosRef;
    this.analytics.trackEvent(AnalyticsEvents.LandedOnVendorMenu, () => {
      const properties: AnyObject = {
        [AnalyticsProperties.ExternalNavigation]: isExternal,
        [AnalyticsProperties.HasTableNumber]: hasTableNumber,
      };
      if (onPremiseQueryParam) {
        properties[AnalyticsProperties.OnPremiseQueryParameter] = onPremiseQueryParam;
      }
      if (controller.handoff) {
        properties[AnalyticsProperties.HandoffQueryParameter] = controller.handoff;
      }
      return properties;
    });

    if (controller.handoff !== undefined) {
      const handoffMode = this.getSearchHandoffMode(controller.handoff);
      const isValidCriteria = this.orderCriteria.updateSearchOrderCriteria(handoffMode);
      controller.set('skipPreCheck', !isValidCriteria || this.basket.basket !== undefined);
    }

    if (isTruthy(controller.openCriteria)) {
      this.orderCriteria.openModal();
    }

    let experienceType: OnPremiseExperience = this.onPremise.experienceType;
    let tablePosRef: string | undefined = this.onPremise.tablePosRef;

    if (isSome(controller.multiOrder)) {
      if (['0', 'false'].includes(controller.multiOrder)) {
        experienceType = OnPremiseExperience.Default;
      } else {
        experienceType = OnPremiseExperience.MultiOrder;
      }
    }

    if (isSome(controller.openCheck)) {
      if (['0', 'false'].includes(controller.openCheck)) {
        experienceType = OnPremiseExperience.Default;
      } else {
        experienceType = OnPremiseExperience.OpenCheck;
      }
    }

    // use '?tableposref=' or '?tableposref=none' to clear table
    if (isSome(controller.tablePosRef)) {
      if (['', 'none'].includes(controller.tablePosRef)) {
        tablePosRef = undefined;
      } else {
        tablePosRef = controller.tablePosRef;
      }
    }

    if (
      isSome(controller.multiOrder) ||
      isSome(controller.openCheck) ||
      isSome(controller.tablePosRef) ||
      this.onPremise.isEnabled
    ) {
      this.onPremise.setOnPremiseDetails(
        tablePosRef,
        experienceType,
        this.vendorService.vendor!.slug
      );
    }

    next(this, () => {
      controller.set('multiOrder', undefined);
      controller.set('tablePosRef', undefined);
      controller.set('openCheck', undefined);
      controller.set('handoff', undefined);
      controller.set('openCriteria', undefined);
    });
  }

  async pageTitle(vendor: Vendor): Promise<string> {
    const menuTitle = await this.content.getEntry('MENU_TITLE', {
      VendorName: vendor.name,
      City: vendor.address.city,
      State: vendor.address.state,
    });

    return menuTitle ? menuTitle : vendor.name;
  }

  async model(params: { vendor_slug: string }): Promise<Model> {
    const isLoggedIn = this.isLoggedIn;
    const vendor = await this.vendorService.ensureVendorLoaded(params.vendor_slug);

    this.channel.savedCurrentCountry = vendor.address.country?.toLowerCase();

    const loadedLoyaltyData: LoadedLoyaltyData[] = [];

    if (isLoggedIn) {
      const results: LoyaltyResults = await taskFor(this.loyalty.loadModel).perform(vendor.id);
      loadedLoyaltyData.push(...results.filter(isOk).map(r => r.value));
    }

    const loyalty = loadedLoyaltyData.map(data => ({
      account: data.account,
      schemeName: data.membership.schemeName,
    }));

    const recentOrders = this.session.isLoggedIn
      ? this.store
          .findAll('order-search-result')
          .then(results =>
            results.filter(
              r =>
                r.vendorSlug === params.vendor_slug && r.status === OrderSearchResultStatus.Complete
            )
          )
      : undefined;

    if (!this.orderCriteria.criteria.isDefault) {
      const oc = this.orderCriteria.criteria;
      if (isDelivery(oc) && oc.deliveryAddress && !oc.deliveryAddress.id) {
        this.channel.savedCurrentCountry = mapPostalCode(oc.deliveryAddress.zipCode);
        const newAddress = await this.store.collectionAction('address', 'addDeliveryAddress', {
          ...oc.deliveryAddress,
          basketId: this.basket.basket?.id,
        });
        oc.deliveryAddress.id = newAddress.id;
      }
    }

    return RSVP.hash({ vendor, loyalty, recentOrders, pageTitle: this.pageTitle(vendor) });
  }

  getSearchHandoffMode(handoff: string): HandoffMode {
    switch (handoff.toLocaleLowerCase()) {
      case 'counterpickup':
        return 'CounterPickup';
      case 'curbsidepickup':
        return 'CurbsidePickup';
      case 'delivery':
        return 'MultiDelivery';
      case 'drivethru':
        return 'DriveThru';
      case 'dinein':
        return 'DineIn';
      default:
        return 'Unspecified';
    }
  }

  @action
  refreshVendorModel() {
    this.refresh();
  }
}
