import a0 from "../../../helpers/page-title.ts";
window.define('mobile-web/helpers/page-title', function () {
  return a0;
});
import a1 from "../../../components/routes/menu/vendor/products-route/index.ts";
window.define('mobile-web/components/routes/menu/vendor/products-route', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "/JlD+B2x",
  "block": "[[[1,[28,[35,0],[[30,1,[\"pageTitle\"]]],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@isLoading\",\"@model\"],[false,[30,1]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"page-title\",\"routes/menu/vendor/products-route\"]]",
  "moduleName": "mobile-web/templates/menu/vendor/products.hbs",
  "isStrictMode": false
});