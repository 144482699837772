import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import IntlService from 'ember-intl/services/intl';

import BusService from 'mobile-web/services/bus';
import ContentService, { SafeString, EmptySafeString } from 'mobile-web/services/content';

import style from './index.m.scss';

export enum StaticContentType {
  CONTACT_US = 'contact-us',
  USER_AGREEMENT = 'user-agreement',
  PRIVACY_POLICY = 'privacy-policy',
  OPT_OUT_GUIDE = 'opt-out-guide',
}

export type ShowStaticContentArgs = {
  type: StaticContentType;
  buttonLabel?: string;
  onButtonClick?: Action;
};

declare module 'mobile-web/services/bus' {
  interface EventRegistry {
    showStaticContent: ShowStaticContentArgs;
  }
}

export default class StaticContentModal extends Component {
  // Service injections
  @service bus!: BusService;
  @service('content') contentService!: ContentService;
  @service intl!: IntlService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked isOpen = false;
  @tracked content: SafeString = EmptySafeString;
  @tracked title = '';
  @tracked buttonLabel = '';
  @tracked onButtonClick?: Action;

  // Getters and setters

  // Constructor
  constructor(owner: unknown, args: UnknownObject) {
    super(owner, args);

    this.bus.on('showStaticContent', this.showStaticContent);
  }

  willDestroy(): void {
    super.willDestroy();

    this.bus.off('showStaticContent', this.showStaticContent);
  }

  // Other methods
  async open(modalType: StaticContentType) {
    // Reset static content in case there is old data in there
    this.title = '';
    this.content = EmptySafeString;
    this.isOpen = true;

    switch (modalType) {
      case StaticContentType.CONTACT_US:
        this.title = this.intl.t('mwc.footer.contactUsText');
        this.content = await this.contentService.getHTML('CONTACT_US_HTML');
        break;
      case StaticContentType.USER_AGREEMENT:
        this.title = this.intl.t('mwc.footer.userAgreementText');
        this.content = await this.contentService.getHTML('USERAGREEMENT_HTML');
        break;
      case StaticContentType.PRIVACY_POLICY:
        this.title = this.intl.t('mwc.footer.privacyPolicyText');
        this.content = await this.contentService.getHTML('PRIVACY_HTML');
        break;
      case StaticContentType.OPT_OUT_GUIDE:
        this.title = await this.contentService.getEntry('ONLINE_TRACKING_PAGE_LINK_NAME');
        this.content = await this.contentService.getHTML('ONLINE_TRACKING_PAGE_HTML', {
          BrandContent: await this.contentService.getEntry('ONLINE_TRACKING_PAGE_BRAND_HTML'),
        });
        break;
      default: {
        this.isOpen = false;
        break;
      }
    }
  }

  // Tasks

  // Actions
  @action
  close() {
    this.isOpen = false;
    this.title = '';
    this.content = EmptySafeString;
  }

  @action
  closeButton() {
    this.close();
    this.onButtonClick?.();
  }

  @action
  showStaticContent(eventArgs: ShowStaticContentArgs) {
    this.buttonLabel = eventArgs.buttonLabel ?? this.intl.t('mwc.footer.closeText');
    this.onButtonClick = eventArgs.onButtonClick;
    this.open(eventArgs.type);
  }
}
