import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

import CustomLinkModel from 'mobile-web/models/custom-link';
import DeviceService from 'mobile-web/services/device';

import style from './index.m.scss';

interface Args {
  // Required arguments

  // Optional arguments
  onClick?: Action;
  route?: string;
  href?: string;
  testSelector?: string;
  customLink?: CustomLinkModel;
}

export default class FooterElement extends Component<Args> {
  // Service injections
  @service device!: DeviceService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get isInternalCustomLink() {
    return isEmpty(this.args.customLink?.url);
  }

  get testSelector(): string {
    return this.args.customLink ? `custom-link-${this.linkTitle}` : this.args.testSelector ?? '';
  }

  get linkTitle() {
    return this.args.customLink ? dasherize(this.args.customLink?.linkLabelText) : '';
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  openInApp(url: string) {
    this.device.openInAppBrowser(url);
  }
}
