export default {
  "root": "_root_bmxwqw",
  "handoffSummaryNew": "_handoffSummaryNew_bmxwqw",
  "handoffMessageNew": "_handoffMessageNew_bmxwqw",
  "minorText": "_minorText_bmxwqw",
  "sectionContainer": "_sectionContainer_bmxwqw",
  "section": "_section_bmxwqw",
  "orderSummarySection": "_orderSummarySection_bmxwqw _section_bmxwqw",
  "simpleSection": "_simpleSection_bmxwqw _section_bmxwqw",
  "buttonItem": "_buttonItem_bmxwqw",
  "reorderButton": "_reorderButton_bmxwqw",
  "buttonContainer": "_buttonContainer_bmxwqw",
  "thanksMessageDetails": "_thanksMessageDetails_bmxwqw"
};
