import { helper } from '@ember/component/helper';

import { Dayjs } from 'mobile-web/lib/dayjs';
import Vendor from 'mobile-web/models/vendor';

export function vendorTime(utc: Dayjs, vendor: Vendor): Dayjs {
  return utc.add(vendor.utcOffset, 'hour');
}

type ParamsHash = {
  utc: Dayjs;
  vendor: Vendor;
};
function vendorTimeHelper(_params: unknown, hash: ParamsHash) {
  return vendorTime(hash.utc, hash.vendor);
}

export default helper(vendorTimeHelper);
