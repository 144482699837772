import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';

import style from './index.m.scss';

export default class AppStoreBadges extends Component {
  // Service injections
  @service channel!: ChannelService;
  @service device!: DeviceService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get showAndroidBadge(): boolean {
    return (
      (this.device.isWebAndroid || this.device.isWebOnly) && !!this.channel.androidAppIdentifier
    );
  }

  get showiOSBadge(): boolean {
    return (this.device.isWebIOS || this.device.isWebOnly) && !!this.channel.iOSAppIdentifier;
  }

  get show(): boolean {
    return this.showAndroidBadge || this.showiOSBadge;
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
