import a0 from "../../../../breadcrumbs/index.ts";
window.define('mobile-web/components/breadcrumbs', function () {
  return a0;
});
import a1 from "../../../../single-use-category/index.ts";
window.define('mobile-web/components/single-use-category', function () {
  return a1;
});
import { createTemplateFactory } from "../..\\..\\..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "5Z7bcuVc",
  "block": "[[[11,0],[24,\"data-test-routes-menu-vendor-singleUseRoute\",\"\"],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"header\"]]],[14,\"data-test-routes-menu-vendor-singleUseRoute-header\",\"\"],[12],[1,\"\\n    \"],[8,[39,0],[[16,0,[30,0,[\"style\",\"breadcrumbs\"]]],[24,\"data-test-routes-menu-vendor-singleUseRoute-breadcrumbs\",\"\"]],[[\"@breadcrumbs\"],[[30,2]]],null],[1,\"\\n    \"],[10,\"h1\"],[15,0,[30,0,[\"style\",\"heading\"]]],[14,\"data-test-routes-menu-vendor-singleUseRoute-heading\",\"\"],[12],[1,[30,3]],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,4]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@category\"],[[30,5]]],null],[1,\"\\n\"]],[5]],null],[13]],[\"&attrs\",\"@breadcrumbs\",\"@heading\",\"@singleUseCategories\",\"category\"],false,[\"breadcrumbs\",\"each\",\"-track-array\",\"single-use-category\"]]",
  "moduleName": "mobile-web/components/routes/menu/vendor/single-use-route/index.hbs",
  "isStrictMode": false
});