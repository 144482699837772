import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { ensureSafeComponent } from '@embroider/util';
import { WithBoundArgs } from '@glint/template';
import { RulesContext } from 'ember-animated';
import { dropTask } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

import Svg from 'mobile-web/components/svg';
import { getDuration } from 'mobile-web/lib/animation';
import { MILLISECONDS } from 'mobile-web/lib/time/durations';
import { classes } from 'mobile-web/lib/utilities/classes';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import AnimationService from 'mobile-web/services/animation';
import FeaturesService from 'mobile-web/services/features';
import { up, down } from 'mobile-web/transitions/slide';

import style from './index.m.scss';

export enum Variant {
  Main = 'main',
  Minor = 'minor',
  Destructive = 'destructive',

  /**
   * A "soft disabled" button appears disabled visually but is still interactive so that users can trigger validation.
   */
  SoftDisabled = 'softDisabled',
}

type Size = 1000 | 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100;

interface Args {
  // Required arguments

  // Optional arguments

  // Common
  icon?: WithBoundArgs<typeof Svg, 'icon'>;
  label?: string;
  onClick?: Action;
  size?: Size;
  testSelector?: string;
  variant?: EnumOrValue<Variant>;
  // Uncommon
  disableAnimation?: boolean;
  fullWidth?: boolean;
  highlightContent?: number;
  textClass?: string;
}

interface Signature {
  Element: HTMLButtonElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class Button extends Component<Signature> {
  // Service injections
  @service animation!: AnimationService;
  @service analytics!: AnalyticsService;
  @service features!: FeaturesService;

  // Untracked properties
  duration = getDuration(100 * MILLISECONDS);
  elementId = guidFor(this);
  style = style;

  // Tracked properties
  @tracked
  buttonElement!: HTMLButtonElement;

  // Getters and setters
  get icon() {
    return this.args.icon ? (ensureSafeComponent(this.args.icon, this) as Args['icon']) : undefined;
  }

  get iconClass(): string {
    return classes(style.icon, style.iconSvg);
  }

  get dataTestButton(): string {
    return this.args.testSelector || this.elementId;
  }

  get variant(): EnumOrValue<Variant> {
    return this.args.variant ?? Variant.Main;
  }

  get size(): Size {
    return this.args.size ?? 500;
  }

  get rootClass(): string {
    return classes(style.button, style[this.variant], style[`size-${this.size}`], {
      [style.fullWidth]: this.args.fullWidth,
      [style.hasHighlightContent]: this.args.highlightContent !== undefined,
    });
  }

  // Constructor

  // Other methods
  rules({ oldItems, newItems }: RulesContext) {
    if ((oldItems[0] as number) < (newItems[0] as number)) {
      return up;
    }
    return down;
  }

  // Tasks
  buttonShakeTask = taskFor(this.buttonShakeInstance);
  @dropTask *buttonShakeInstance() {
    yield this.animation.shakeTask.perform(this.buttonElement);
  }
  // Actions
  @action
  onClick(event: Event) {
    if (
      this.features.flags['cot-disabled-button-shake-olo-26979'] &&
      this.args.variant === Variant.SoftDisabled
    ) {
      this.buttonShakeTask.perform();
    }

    if (this.variant === Variant.SoftDisabled) {
      const element = event.currentTarget as HTMLElement;

      this.analytics.trackEvent(AnalyticsEvents.DisabledButtonTapped, () => ({
        [AnalyticsProperties.Label]: this.args.label ?? element.textContent?.trim(),
      }));
    }

    this.args.onClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Button: typeof Button;
  }
}
