export default {
  "root": "_root_1vm10l",
  "left": "_left_1vm10l",
  "navigationButton": "_navigationButton_1vm10l",
  "home": "_home_1vm10l",
  "homeLoading": "_homeLoading_1vm10l",
  "logo": "_logo_1vm10l",
  "logoLoading": "_logoLoading_1vm10l",
  "right": "_right_1vm10l",
  "accountLoading": "_accountLoading_1vm10l",
  "cartLoading": "_cartLoading_1vm10l",
  "accountButton": "_accountButton_1vm10l",
  "basket": "_basket_1vm10l",
  "notifications": "_notifications_1vm10l",
  "background": "_background_1vm10l"
};
