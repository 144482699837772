import a0 from "../../../modifiers/did-insert.js";
window.define('mobile-web/modifiers/did-insert', function () {
  return a0;
});
import a1 from "../../x-content/index.ts";
window.define('mobile-web/components/x-content', function () {
  return a1;
});
import a2 from "../../heading-section/index.ts";
window.define('mobile-web/components/heading-section', function () {
  return a2;
});
import a3 from "../../../helpers/t.js";
window.define('mobile-web/helpers/t', function () {
  return a3;
});
import a4 from "../../heading-section/subsection/index.ts";
window.define('mobile-web/components/heading-section/subsection', function () {
  return a4;
});
import a5 from "../../post-checkout/subsection/index.ts";
window.define('mobile-web/components/post-checkout/subsection', function () {
  return a5;
});
import a6 from "../../post-checkout/thanks-message/index.ts";
window.define('mobile-web/components/post-checkout/thanks-message', function () {
  return a6;
});
import { createTemplateFactory } from "../..\\..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "0x+6FeI9",
  "block": "[[[11,0],[16,0,[30,0,[\"style\",\"container\"]]],[24,\"data-test-check-in\",\"\"],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"style\",\"content\"]]],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@key\"],[\"RESP_MANUAL_FIRE_POSTCHECKIN_HEADER\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"value\"]],[[[1,\"        \"],[10,\"h1\"],[14,\"data-test-check-in-manual-fire-header\",\"\"],[12],[1,\"\\n          \"],[1,[30,1,[\"value\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[1]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@key\"],[\"MANUAL_FIRE_LABEL\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"isLoaded\"]],[[[1,\"        \"],[8,[39,3],[[16,0,[30,0,[\"style\",\"section\"]]]],[[\"@heading\"],[[28,[37,4],[\"mwc.postCheckout.instructionsHeader\"],[[\"label\"],[[30,2,[\"value\"]]]]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],null,null,[[\"content\"],[[[[1,\"\\n                \"],[10,2],[15,0,[30,0,[\"style\",\"instructions\"]]],[14,\"data-test-check-in-manual-fire-instructions\",\"\"],[12],[1,\"\\n                  \"],[8,[39,1],null,[[\"@key\"],[\"MANUAL_FIRE_PICKUP_INSTRUCTIONS\"]],null],[1,\"\\n                \"],[13],[1,\"\\n              \"]],[]]]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[10,0],[15,0,[30,0,[\"style\",\"thanksMessageDetails\"]]],[14,\"data-test-check-in-thanks-detail\",\"\"],[12],[1,\"\\n          \"],[8,[39,7],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"content\",\"labelContent\"],false,[\"did-insert\",\"x-content\",\"if\",\"heading-section\",\"t\",\"heading-section/subsection\",\"post-checkout/subsection\",\"post-checkout/thanks-message\"]]",
  "moduleName": "mobile-web/components/routes/check-in-route/index.hbs",
  "isStrictMode": false
});