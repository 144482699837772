import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { classes } from 'mobile-web/lib/utilities/classes';
import ScrollService from 'mobile-web/services/scroll';

import style from './index.m.scss';

interface Args {
  // Required arguments
  alt: string;
  parentClass: string;
  testName: string;

  // Optional arguments
  src?: string;
}

interface Signature {
  Element: HTMLImageElement;

  Args: Args;
}

export default class SkeletonImage extends Component<Signature> {
  // Service injections
  @service scroll!: ScrollService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked imageLoaded = false;

  // Getters and setters
  get imageClass() {
    return classes(this.args.parentClass, {
      [this.style.hidden]: !this.imageLoaded,
    });
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  async setImageLoaded() {
    if (this.scroll.isAutoScrolling) {
      await this.scroll.scrollStop();
    }
    this.imageLoaded = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SkeletonImage: typeof SkeletonImage;
  }
}
