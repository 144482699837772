import a0 from "../svg/index.ts";
window.define('mobile-web/components/svg', function () {
  return a0;
});
import { createTemplateFactory } from "../..\\..\\externals\\@ember\\template-factory";
export default createTemplateFactory({
  "id": "021D3Dxa",
  "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[30,0,[\"style\",\"button\"]]],[16,\"aria-label\",[30,0,[\"ariaLabel\"]]],[16,\"data-test-formEditButton\",[30,1]],[17,2],[4,[38,0],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"style\",\"labelInputContainer\"]]],[12],[1,\"\\n\\n    \"],[10,1],[15,0,[30,0,[\"labelClassName\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"        \"],[8,[30,0,[\"icon\"]],null,[[\"@class\"],[[30,0,[\"style\",\"labelIcon\"]]]],null],[1,\"\\n\"]],[]],null],[41,[51,[30,3]],[[[1,\"        \"],[10,1],[15,0,[30,0,[\"labelTextClass\"]]],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,5],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"style\",\"input\"]]],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[15,0,[30,0,[\"style\",\"placeholder\"]]],[12],[1,[30,0,[\"placeholder\"]]],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[8,[39,3],null,[[\"@ariaLabel\",\"@class\",\"@icon\"],[\"\",[30,0,[\"style\",\"icon\"]],\"pencil\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"isEditorOpen\"]],[[[1,\"  \"],[18,6,[[28,[37,5],null,[[\"closeEditor\"],[[30,0,[\"closeEditor\"]]]]]]],[1,\"\\n\"]],[]],null]],[\"@name\",\"&attrs\",\"@hideLabel\",\"@label\",\"@value\",\"&default\"],false,[\"on\",\"if\",\"unless\",\"svg\",\"yield\",\"hash\"]]",
  "moduleName": "mobile-web/components/form-edit-button/index.hbs",
  "isStrictMode": false
});