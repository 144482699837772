import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import RSVP, { resolve } from 'rsvp';

import {
  dropTaskGroup,
  enqueueTask,
  restartableTask,
  task,
  TaskGenerator,
  TaskGroup,
  timeout,
} from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { Loaded } from 'mobile-web';
import { UserData } from 'mobile-web/lib/customer';
import { DELAY_MS } from 'mobile-web/lib/debounce';
import BasketModel from 'mobile-web/models/basket';
import BasketProductModel from 'mobile-web/models/basket-product';
import FavoriteModel from 'mobile-web/models/favorite';
import OrderModel from 'mobile-web/models/order';
import OrderSearchResultModel from 'mobile-web/models/order-search-result';
import ProductModel from 'mobile-web/models/product';
import VendorSearchResultModel from 'mobile-web/models/vendor-search-result';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BusService from 'mobile-web/services/bus';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService, { errorForUser } from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import GroupOrderService from 'mobile-web/services/group-order';
import NotificationsService, { NotificationType } from 'mobile-web/services/notifications';
import OnPremiseService from 'mobile-web/services/on-premise';
import StorageService from 'mobile-web/services/storage';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';
import VendorService from 'mobile-web/services/vendor';

export default class BasketService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bus!: BusService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service error!: ErrorService;
  @service features!: FeaturesService;
  @service globalEvents!: GlobalEventsService;
  @service groupOrder!: GroupOrderService;
  @service intl!: IntlService;
  @service notifications!: NotificationsService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;
  @service vendor!: VendorService;

  // Untracked properties
  private untrackedActiveBasketProduct?: BasketProductModel;

  // Tracked properties
  @tracked basket?: BasketModel;
  @tracked private _isOpen = false;
  @tracked trackedActiveBasketProduct?: BasketProductModel;

  // Getters and setters
  get basketProducts(): BasketProductModel[] {
    return this.basket?.basketProducts.toArray() ?? [];
  }

  get isCallcenterOrder(): boolean {
    return this.basket?.isCallcenterOrder ?? false;
  }

  get displayProducts(): BasketProductModel[] {
    return this.groupOrder.hasGroupOrder
      ? this.groupOrder.currentUserProducts
      : this.basketProducts;
  }

  get displayTotal(): number {
    return this.groupOrder.isParticipantMode
      ? this.groupOrder.currentUserProducts.reduce((total, p) => total + p.totalCost, 0)
      : this.basket?.total ?? 0;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  get activeBasketProduct(): BasketProductModel | undefined {
    return this.trackedActiveBasketProduct;
  }

  set activeBasketProduct(bp: BasketProductModel | undefined) {
    // See https://github.com/emberjs/ember.js/issues/19192#issuecomment-807796249
    this.trackedActiveBasketProduct = this.untrackedActiveBasketProduct = bp;
  }

  get hasOnlySingleUseProducts(): boolean {
    return this.basketProducts.every((pro: BasketProductModel) => pro.product.get('isSingleUse'));
  }

  get receivingUser(): UserData | undefined {
    return this.basket?.receivingUser ?? this.storage.receivingCustomer;
  }

  // Constructor

  // Other methods
  safeGetActiveBasketProduct(): BasketProductModel | undefined {
    return this.untrackedActiveBasketProduct;
  }

  clear(): void {
    this.basket?.unloadRecord();
    this.basket = undefined;
  }

  loadBasket(basketId: EmberDataId): RSVP.Promise<Loaded<BasketModel>> {
    return this.store.findRecord('basket', String(basketId).toUpperCase()).then(basket => {
      this.basket = basket;
      return basket;
    });
  }

  refreshBasket(): RSVP.Promise<Loaded<BasketModel>> {
    if (this.basket) {
      return this.store
        .findRecord('basket', this.basket.id.toUpperCase(), { reload: true })
        .then(basket => {
          this.basket = basket;
          return this.basket;
        });
    }
    throw new Error('Cannot refresh a non-existent basket!');
  }

  createBasket(vendor = this.vendor.vendor): RSVP.Promise<BasketModel> {
    if (this.basket && this.basket.vendor.get('id') === vendor?.id) {
      return resolve(this.basket);
    }

    const basket = this.store.createRecord('basket', { vendor });

    return basket.save().then(
      () => {
        this.basket = basket;
        this.globalEvents.trigger(GlobalEventName.CreateBasket, basket.serializeForGlobalData());
        if (this.onPremise.tablePosRef || this.onPremise.isEnabled) {
          return basket.setOnPremiseDetails({
            tablePosReference: this.onPremise.tablePosRef,
            experienceType: this.onPremise.experienceType,
          });
        }
        return basket;
      },
      reason => {
        // If saving failed, we need to clean up the data from the local store
        basket.unloadRecord();
        throw reason;
      }
    );
  }

  onVendorUpdate(vendorId: EmberDataId): void {
    if (this.basket?.vendor.get('id') !== vendorId) {
      this.clear();
    }
  }

  // Private methods

  // For baskets created from orders (recent orders, faves).
  // These baskets have no handoff or timewanted information.
  private async replaceOrderBasket(basket: BasketModel, openCart = true): Promise<BasketModel> {
    const vendorId = basket.belongsTo('vendor').id();
    if (vendorId) {
      const vendor = await this.store.findRecord('vendor', vendorId, { reload: true });
      this.vendor.set('vendor', vendor);
    }
    this.basket = basket;

    if (openCart) {
      if (this.router.currentRouteName === 'menu.vendor.index') {
        this.open();
      } else {
        this.router.transitionTo('menu.vendor', this.vendor.vendor!.slug, {
          queryParams: { openBasket: true },
        });
      }
    }

    return basket;
  }

  /**
   * If the channel has special instructions and recipient disabled,
   * this method returns a matching product that is already in the basket.
   *
   * @param product product to search for
   * @returns `BasketProductModel` matching the given `ProductModel`
   */
  public findQuickAddModalProduct(product: ProductModel): BasketProductModel | undefined {
    const channelSettings = this.channel.settings;
    if (
      channelSettings?.showSpecialInstructions ||
      channelSettings?.showProductRecipientNameLabel
    ) {
      return undefined;
    }

    return this.findQuickUpdateProduct(product);
  }

  /**
   * This method returns an existing quick-add product in the
   * basket that matches the given vendor product id.
   *
   * @param product product to search for
   * @returns `BasketProductModel` matching the given `ProductModel`
   */
  public findQuickUpdateProduct(
    vendorProduct: ProductModel | undefined,
    recipientName = '',
    specialInstructions = ''
  ): BasketProductModel | undefined {
    if (!vendorProduct?.quickAddSupported) {
      return undefined;
    }

    const existingBlankBasketProduct = this.basket?.basketProducts
      ?.toArray()
      .find(
        p =>
          p.belongsTo('product').id() === vendorProduct.id &&
          (p.recipientName ?? '') === recipientName &&
          (p.specialInstructions ?? '') === specialInstructions
      );
    return existingBlankBasketProduct;
  }

  /**
   * Attempts to consolidate a given basketProduct into an existing
   * basketProduct in the cart.
   *
   * This only consolidates if the basketProduct supports quick add and if its
   * specialInstructions and recipientName are an exact match. If this is the
   * case, the incoming quantity is added to the exising quantity in the basket
   * and the existing product is returned.
   *
   * @param basketProduct product to attempt to consolidate
   * @returns consolidated basket product
   */
  public consolidate(basketProduct: BasketProductModel): BasketProductModel {
    if (!(basketProduct.product.content?.quickAddSupported ?? false)) {
      return basketProduct;
    }

    const existingBasketProduct = this.findQuickUpdateProduct(
      basketProduct.product.content,
      basketProduct.recipientName,
      basketProduct.specialInstructions
    );

    if (existingBasketProduct && basketProduct !== existingBasketProduct) {
      existingBasketProduct.quantity += basketProduct.quantity;
      // `basketProduct` will get unloaded by product-customization's `willDestroy`
    }

    return existingBasketProduct ?? basketProduct;
  }

  private quickAddNotify() {
    this.notifications.success({
      message: this.intl.t('mwc.notifications.quickAdded'),
      type: NotificationType.ProductQuickAdded,
    });
  }

  /**
   * Gets an existing basket product that is able to be quick-added to, or if
   * one doesn't exist, creates a new basket-product.
   */
  private getQuickAddBasketProduct(product: ProductModel): BasketProductModel {
    return (
      this.basket?.basketProducts
        .toArray()
        .find(
          basketProduct =>
            basketProduct.canQuickAdd && basketProduct.belongsTo('product').id() === product.id
        ) ??
      this.store.createRecord('basket-product', {
        quantity: 0,
        product,
        vendor: product.get('vendor'),
      })
    );
  }

  // Tasks

  @restartableTask *updateTipTask(amount: number): TaskGenerator<void> {
    yield timeout(DELAY_MS);
    const basket = this.basket!;
    const newBasket = (yield basket.updateTip({ tip: amount })) as BasketModel;
    this.basket = newBasket;
  }

  @dropTaskGroup orderBasketGroup!: TaskGroup<void>;

  reorderTask = taskFor(this.reorderGenerator);
  @task({ group: 'orderBasketGroup' })
  *reorderGenerator(
    order: OrderModel | OrderSearchResultModel | FavoriteModel
  ): Generator<unknown, void, BasketModel> {
    const basket = yield order.reorder();
    if (isEmpty(basket.failures)) {
      yield this.replaceOrderBasket(basket);
    } else {
      const hasSuccesses = basket.basketProducts.length > 0;
      this.bus.trigger('confirm', {
        title: this.intl.t('mwc.reorder.failureModal.title'),
        content: this.intl.t('mwc.reorder.failureModal.body', {
          productList: basket.failures.map(f => f.productName).join(', '),
        }),
        buttonLabel: hasSuccesses
          ? this.intl.t('mwc.reorder.failureModal.addTheRestButton')
          : this.intl.t('mwc.reorder.failureModal.viewMenuButton'),
        buttonTestSelector: 'primaryFailureButton',
        cancelLabel: hasSuccesses
          ? this.intl.t('mwc.reorder.failureModal.viewMenuButton')
          : undefined,
        cancelTestSelector: 'secondaryFailureButton',
        onConfirm: async () => {
          await basket.activate();
          await this.replaceOrderBasket(basket);
        },
        onCancel: async () => {
          if (hasSuccesses) {
            this.router.transitionTo('menu.vendor', (await basket.vendor).slug);
          }
        },
      });
    }
  }

  editOrderTask = taskFor(this.editOrderGenerator);
  @task({ group: 'orderBasketGroup' })
  *editOrderGenerator(order: OrderModel): TaskGenerator<void> {
    const basket = yield order.edit();
    yield this.replaceOrderBasket(basket);
  }

  @task({ group: 'orderBasketGroup' })
  *transferTask(newVendor: VendorSearchResultModel): TaskGenerator<void> {
    // It is possible to get here without a basket if the order criteria modal gets an error.
    // If we don't have a basket, then just say we successfully "transferred"
    if (!this.basket || this.vendor.vendor?.get('slug') === newVendor.slug) {
      this.analytics.trackEvent(AnalyticsEvents.ChangeStoreLocation, () => ({
        [AnalyticsProperties.WarningShown]: false,
        [AnalyticsProperties.HasBasket]: true,
      }));

      yield this.basket;
    } else {
      try {
        const basket = yield this.basket!.transfer({ vendorId: newVendor.id });
        yield this.replaceOrderBasket(basket, false);

        const clean = isEmpty(this.basket.warnings);

        this.analytics.trackEvent(AnalyticsEvents.ChangeStoreLocation, () => ({
          [AnalyticsProperties.WarningShown]: !clean,
          [AnalyticsProperties.HasBasket]: true,
        }));

        if (clean) {
          const message =
            this.device.viewport !== 'Desktop'
              ? this.intl.t('mwc.basketTransferModal.successMessageShort')
              : this.intl.t('mwc.basketTransferModal.successMessage', { name: newVendor.name });

          this.notifications.success({
            message,
            type: NotificationType.CartTransferred,
          });
        } else {
          this.userFeedback.add({
            type: Type.Warning,
            title: this.intl.t('mwc.basketTransferModal.warningPartialTitle'),
            message: this.intl.t('mwc.basketTransferModal.warningPartialBody', {
              name: newVendor.name,
              products: this.basket.warnings.join(', '),
            }),
          });
        }
      } catch (e) {
        this.error.reportError(e);
      }
    }
  }

  public quickAddTask = taskFor(this.quickAddInstance);
  @task
  private *quickAddInstance(product: ProductModel): TaskGenerator<BasketProductModel> {
    this.quickAddNotify();
    const basketProduct = yield this.incrementQuickAddBasketProductTask.perform(product);
    return basketProduct;
  }

  /**
   * Queues up quick-adds so that basket-products are consolidated whenever
   * possible.
   */
  private incrementQuickAddBasketProductTask = taskFor(this.incrementQuickAddBasketProductInstance);
  @enqueueTask
  private *incrementQuickAddBasketProductInstance(
    product: ProductModel
  ): TaskGenerator<BasketProductModel> {
    const basketProduct = this.getQuickAddBasketProduct(product);

    try {
      basketProduct.quantity += 1;

      yield basketProduct.saveTask.perform({
        eventName: 'Quick Add',
      });

      this.globalEvents.trigger(GlobalEventName.AddToCart, basketProduct.serializeForGlobalData());
    } catch (e) {
      const isNew = basketProduct.get('isNew');

      basketProduct.rollbackAttributes();

      if (errorForUser(e)) {
        this.error.reportError(e);
      } else {
        const message = this.intl.t(`mwc.notifications.failures.${isNew ? 'added' : 'updated'}`);
        this.notifications.error({
          message,
          type: NotificationType.ProductQuickAdded,
        });
        this.error.trackError(e, message);
      }
    }

    return basketProduct;
  }

  // Actions
  @action
  open(): void {
    if (!this._isOpen) {
      this._isOpen = true;
    }
  }

  @action
  close(): void {
    if (this._isOpen) {
      this._isOpen = false;
    }
  }

  @action
  toggle(): void {
    this._isOpen = !this._isOpen;
  }
}

declare module '@ember/service' {
  interface Registry {
    basket: BasketService;
  }
}
