import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import DS from 'ember-data';

import zip from 'lodash.zip';

import LoyaltyMembership from 'mobile-web/models/loyalty-membership';
import LoyaltyScheme from 'mobile-web/models/loyalty-scheme';
import Vendor from 'mobile-web/models/vendor';

import style from './index.m.scss';

interface Args {
  // Required arguments
  linkedSchemeNames: string[];
  memberships: LoyaltyMembership[];
  schemes: LoyaltyScheme[];
  vendor: Vendor;

  // Optional arguments
  onLink?: Action;
}

interface Signature {
  Args: Args;
}

export default class LinkRewardsSchemes extends Component<Signature> {
  // Service injections
  @service router!: RouterService;
  @service store!: DS.Store;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters
  get linkableSchemes(): LoyaltyScheme[] {
    return this.args.schemes.filter(
      s => s.canLink && !this.args.linkedSchemeNames.includes(s.schemeName)
    );
  }

  get linkedSchemes() {
    const schemeArray = this.args.schemes.filter(s =>
      this.args.linkedSchemeNames.includes(s.schemeName)
    );

    const qualifyingRewards = schemeArray.map(s => {
      const account = this.store.peekRecord('loyalty-account', s.id);
      if (s.vendor.id !== this.args.vendor.id) {
        return false;
      }
      return (account?.qualifyingLoyaltyRewards?.length ?? 0) > 0;
    });
    return zip(schemeArray, qualifyingRewards);
  }

  // Constructor

  // Other methods

  // Tasks

  // Actions
  @action
  viewRewards(scheme: LoyaltyScheme) {
    this.router.transitionTo('menu.vendor.rewards', scheme.vendor.slug);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'link-rewards/schemes': typeof LinkRewardsSchemes;
  }
}
