import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

// @ts-ignore
import EmberPageTitleService from 'ember-page-title/services/page-title';

import AnnouncerService from 'mobile-web/services/announcer';

export const SEPARATOR = ' - ';
export const SUFFIX = `${SEPARATOR}Order Online`;

export default class PageTitleService extends EmberPageTitleService {
  // Service injections
  @service announcer!: AnnouncerService;

  // Untracked properties

  // Tracked properties
  @tracked title = 'Order Online'; // Initial value only matters for tests

  // Getters and setters
  get titleWithoutSuffix(): string {
    return this.title.replace(SUFFIX, '');
  }

  // Constructor

  // Other methods
  titleDidUpdate(title: string): void {
    this.title = title;
    this.announcer.announceRouteChange();
  }

  // Tasks

  // Actions
}

declare module '@ember/service' {
  interface Registry {
    'page-title': PageTitleService;
  }
}
