import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import LoadingService from 'mobile-web/services/loading';

import style from './index.m.scss';

export default class ApplicationLoading extends Component {
  // Service injections
  @service loading!: LoadingService;

  // Untracked properties
  style = style;

  // Tracked properties

  // Getters and setters

  // Constructor

  // Other methods

  // Tasks

  // Actions
}
