export default {
  "root": "_root_1atqjf",
  "allTotal": "_allTotal_1atqjf",
  "top": "_top_1atqjf",
  "heading": "_heading_1atqjf",
  "receivedText": "_receivedText_1atqjf",
  "notice": "_notice_1atqjf",
  "orderMore": "_orderMore_1atqjf",
  "headingSection": "_headingSection_1atqjf",
  "contentsRow": "_contentsRow_1atqjf",
  "createAccountContainer": "_createAccountContainer_1atqjf"
};
